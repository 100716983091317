import { Component, Input, AfterViewInit, OnDestroy } from '@angular/core';
import { BsCurrentClassInfoService } from '../../services/bs-current-class-info.service';

import { BsAssignmentsService } from '../../services/bs-assignments.service';

import {IProjectInfo, IAssignment} from "@cf-platform/cf-core-cms";

import { BsAssignmentTesterService } from '../../services/bs-assignment-tester.service';

//import { CFLMS } from '@cf-platform/cf-lms';
//import { CFAdmin } from '@cf-platform/cf-admin';
import { CFApiClient } from '@cf-platform/cf-api-client';
import { Router } from '@angular/router';

@Component({
	selector: 'app-bs-assignment-edit',
	templateUrl: './bs-assignment-edit.component.html',
	styleUrls: ['./bs-assignment-edit.component.css']
})
export class BsAssignmentEditComponent implements AfterViewInit, OnDestroy {

	assignmentList: Array<IAssignment>;
	assignment: IAssignment;
	cciSub: any;
	currentClassInfo: import("../../services/bs-current-class-info.service").CFCurrentClassInfo;

	constructor(
		private currentClassInfoService: BsCurrentClassInfoService,
		private assignmentService: BsAssignmentsService,
		private tester: BsAssignmentTesterService,
		private router: Router) {

			this.cciSub = this.currentClassInfoService.currentClassInfoHandler.subscribe(cci => this.currentClassInfo = cci);
	}

	get basePath(): string {
		const p = '/class/utm/' + this.currentClassInfo.theClass.classId + '/a_edit/';
		return p;
	}

	_assignmentId: string;
	@Input() set assignmentId(assignmentId: string) {
		this._assignmentId = assignmentId;
		this.findAssignment(assignmentId);
	}
	get assignmentId() {
		return this._assignmentId;
	}

	async loadAssignments() {
		console.log('Loading assignments list...');
		this.assignmentList = await this.currentClassInfo.theClass.getAssignments();
		console.log('Loaded assignments list.');
		return true;
	}

	findAssignment(id) {
		console.log('Finding assignment: ' + this.assignmentId);

		this.assignment = null;
		this.project = null;
		if (this.assignmentId !== '' && this.assignmentList) {
			for (const a of this.assignmentList) {
				if (a.assignmentId === this.assignmentId
					|| a.__key === this.assignmentId) {
					this.assignment = a;
					if (a.project) {
						a.project.isValid = true;
					}
					this.project = a.project;

					const me = this;
					this.project.saveFile =  function() {
						return me.saveAssignment();
					};
					this.project.saveAssignment =  function() {
						return me.saveAssignment();
					};

					if (!this.assignment.type) {
						this.assignment.type = 'project';
					}
				}
			}
		}
	}


	project: IProjectInfo;

	ngAfterViewInit() {
		this.loadAssignments().then(() => {
			this.findAssignment(this.assignmentId);
		});
	}

	async saveAssignment() {
		const aid = this.assignmentId;
		const assignment = this.assignment;
		if (!aid) {
			alert('Cannot save. No assignment id.');
			return;
		}
		if (!assignment) {
			alert('Cannot save. No assignment object.');
			return;
		}
		const ao = this.assignmentService.updateAssignmentAsInstructor(this.currentClassInfo.theClass.classId, aid, assignment);
	}

	async newAssignmentWithProject(pType: string) {
		const ao = this.assignmentService.newAssignmentWithProject(this.currentClassInfo.theClass.classId, pType);
	}

	async goParent() {

	}

	async deleteItem(event: Event) {

	}

	async findLMSAssignmentId() {

		const cfAPIClient = CFApiClient.getInstance();
		const lmsAID = await cfAPIClient.lmsFindLinkedAssignment(this.currentClassInfo.theClass.classId, this.assignmentId);
		if (lmsAID) {
			this.assignment.lms_assignment_id = '' + lmsAID;
		} else {
			setTimeout(() => {
				alert('Sorry: the lms_assignment_id was not found!');
			}, 0);
		}


	}

	copyAssignment() {
		setTimeout(() => {
			console.log('debug this');
			let newUrl = document.location.pathname;
			newUrl = newUrl.replace('a_edit', 'a_copy');
			// TODO: fix this hack...
			this.router.navigateByUrl(newUrl);
		}, 0);
	}



	ngOnDestroy() {
		this.cciSub.unsubscribe();
	}
}
