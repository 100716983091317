import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject ,  Observable } from 'rxjs';


export interface EZClassUserInfo{
	validUser: boolean,
	currentUserEmail?: string,
	currentUserKey?: string,
	validClass: boolean,
	classId?: string,
	isInstructor?: boolean
}

@Injectable({
	providedIn: 'root'
})
export class EzClassUserServiceService {

	// constructor() { }

	private itemsSource = new BehaviorSubject<EZClassUserInfo>({validUser:false, validClass:false});

	EZClassUserInfoHandler = this.itemsSource.asObservable();

    setEZClassUserInfo(item: EZClassUserInfo) {
        this.itemsSource.next(item);
	}


}
