import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
// import { } from 'firebase/angular';
import { AngularFireDatabase } from '@angular/fire/database';
import * as firebase from 'firebase/app';

@Injectable()
export class BsLoggerService {
	logRef: firebase.database.Reference;
	user: firebase.User;
	userId: string;
	userEmail: string;
	logLevel: number = 5;  // 0 - none, 1 - important, 5 - all
	consoleLevel: number = 5;

	constructor(public afAuth: AngularFireAuth, private db: AngularFireDatabase) {
		this.logRef = firebase.database().ref().child('/log');

		const authObserver = afAuth.authState.subscribe(u => {
			if (u) {
				this.user = u;
				this.userEmail = u.email;
				if (!u.email || u.email === '') {
					if (u.providerData.length > 0) {
						this.userEmail = u.providerData[0].email;
					}
					else {
						this.userEmail = 'none';
					}
				}
				this.userId = u.uid;
			}
			else {
				this.user = null;
				this.userEmail = 'none';
				this.userId = 'none';
			}
		});
	}

	logCmd(from: string, msg: string, level: number, data: any): Promise<void> {

		const prom = new Promise<void>((resolve, reject) => {

			const dataC = { ...data }; // shallow copy
			const obj = {
				userEmail: this.userEmail,
				from: from,
				msg: msg,
				userId: this.userId,
				level: level,
				date: firebase.database.ServerValue.TIMESTAMP,
				lDate: new Date().toString()
			};

			if (dataC) {
				console.log('Debug log here');
				obj['data'] = JSON.parse(JSON.stringify(dataC));
				if (dataC.proj && dataC.proj.user && dataC.proj.projPath) {
					obj['user_proj_path'] = dataC.proj.user + '/' + dataC.proj.projPath;
				}
			}

			if (level <= this.consoleLevel) {
				let d = '';
				if (data) {
					d = JSON.stringify(data);
					if (d.length > 100) {
						d = d.substr(0, 98) + '...';
					}
					d = ' data: ' + d;
				}
				console.log('Logger(' + level + ') - user:' + this.userEmail + ' msg: ' + msg + d);
			}
			if (level <= this.logLevel) {
				this.logRef.push(obj)
					.push().set({
					})
					.then(function () {
						resolve();
					})
					.catch(function () {
						resolve();
					});
			}
			return resolve();
		});
		return prom;
	}

	// Yes, this is a silly place for this to be...
	getVersionsOfFile(user_proj_path) {
		console.log('Setting up versions for: ' + user_proj_path);

		const obVersions = this.db.list('/log', ref => ref.orderByChild('user_proj_path').equalTo(user_proj_path).limitToLast(10)).snapshotChanges()
			.map((data) => {  // sort by date // https://stackoverflow.com/questions/41224749/angular-2-sort-list-from-observable
				data.sort((a, b) => {
					return a['date'] > b['date'] ? -1 : 1;
				});
				return data;
			});
		return obVersions;
	}

	getUsersLogsByEmail(user_email) {
		console.log('Getting logs for: ' + user_email);

		const obVersions = this.db.list('/log', ref => ref.orderByChild('userEmail').equalTo(user_email).limitToLast(20))
			.snapshotChanges()
			.map(actions => {
				return actions.map(action => ({ __key: action.key, ...action.payload.val(), sDate: action.payload.val()['lDate'].substr(0, 11) }));
			})
			.map((data) => {  // sort by date // https://stackoverflow.com/questions/41224749/angular-2-sort-list-from-observable
				data.sort((a, b) => {
					return a['date'] > b['date'] ? -1 : 1;
				});
				return data;
			});
		return obVersions;
	}




	getLogs() {
		console.log('Getting logs...');

		const obVersions = this.db.list('/log', ref => ref.orderByChild('date').limitToLast(2000))
			.snapshotChanges()
			.map(actions => {
				return actions.map(action => ({ __key: action.key, ...action.payload.val(), sDate: action.payload.val()['lDate'].substr(0, 11) }));
			})
			/*
			.map((data) => {  // sort by date // https://stackoverflow.com/questions/41224749/angular-2-sort-list-from-observable
				data.sort((a, b) => {
					return a['date'] > b['date'] ? -1 : 1;
				});
				return data;
			}) */
			;
		return obVersions;
	}

	clearLogs() {
		console.log('Clearing all logs');
		this.db.object('/log').set({});
	}
}
