import { Component, OnInit, Input, ViewChild, NgZone } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';
import { BsCommentsService, BsCommentsManager } from '../../../../services/bs-comments.service';
import { ElementRef, Renderer2 } from '@angular/core';
import { AngularFireList } from '@angular/fire/database';

@Component({
	selector: 'app-bs-comment-list',
	templateUrl: './bs-comment-list.component.html',
	styleUrls: ['./bs-comment-list.component.css']
})
export class BsCommentListComponent implements OnInit {
	constructor(private bsComments: BsCommentsService, public zone: NgZone) { }
	@Input() objectKey: string; // the key of the object to attach comments to
	@Input() currentUserEmail: string;
	@Input('item') item;
	@ViewChild('inputField', { static: true }) input: ElementRef;
	bsCommentsManager: BsCommentsManager;
	commentsOrig: Observable<any[]>;
	comments: Observable<any[]>;
	static newestTime = 0;
	limit: number = 5;
	showAllButtonFlag: boolean = false;
	updateFlags = {};
	serverTime = 0;
	serverTimeS: Subject<number>;
	feedDbPath = '/class/201720_UTM_CSIC_221';

	getStuff() {
		this.bsCommentsManager = this.bsComments.getCommentsManager(this.feedDbPath);
		this.comments = this.bsCommentsManager.getComments(this.objectKey, this.limit);
	}

	max(n1: number, n2: number): number {
		if (n1 === undefined) {
			n1 = 0;
		}
		if (n2 === undefined) {
			n2 = 0;
		}
		if (n1 > n2) {
			return n1;
		}
		return n2;
	}

	getFormatedUpdateTime(item) {
		const uTime: number = this.max(item.create_timestamp, item.update_timestamp);
		const d = new Date(uTime);
		const ds = d.toDateString() + ' ' + d.toTimeString();
		return ds;
	}

	ngOnInit() {
		this.getStuff();
		this.comments.subscribe((comments) => {
			if (comments.length >= this.limit) {
				this.showAllButtonFlag = true;
			}
			else {
				this.showAllButtonFlag = false;
			}
			this.bsCommentsManager.getServerTime()
				.then((t) => {
					console.log('The server time is: ' + t);
					this.serverTime = t;
					const now = this.serverTime;

					for (const c in comments) {
						const cv = comments[c];
						let cTime = cv['update_timestamp'];
						if (!cTime) {
							cTime = cv['create_timestamp'];
						}
						if (this.serverTime && (((this.serverTime - cTime) <= 5000) || (cTime > this.serverTime))) {
							this.updateFlags[cv['$key']] = true;
							cv['_updateFlag'] = true;
							console.log('Updated: ' + cv['$key']);
							setTimeout((ctime) => {
								this.updateFlags[cv['$key']] = false;
								cv['_updateFlag'] = false;
							}, 2000);
						}
						else {
							this.updateFlags[cv['$key']] = false;
							cv['_updateFlag'] = false;
						}
					}
				});
		});
	}
	// mat-form-field: boolean {
	get canComment(): boolean {
			return this.item && this.item.allowComments;
	}   // TODO

	addingCommentFlag: boolean = false;
	newCommentText: string = '';


	newComment() {
		if (!this.canComment) {
			this.addingCommentFlag = false;
			return;
		}
		this.addingCommentFlag = true;
		setTimeout(() => {
			this.input.nativeElement.focus();
		}, 200);
	}

	cancelNewComment() {
		this.addingCommentFlag = false;
	}

	saveNewComment(text: string) {
		if (!this.canComment) {
			return;
		}
		console.log('Add Comment to: ' + this.objectKey + ' = ' + text);
		this.bsCommentsManager.addComment(this.objectKey, this.currentUserEmail, text);
		this.newCommentText = '';
		this.addingCommentFlag = false;
	}

	deleteCommentPressed(comment) {
		if (!this.canComment) {
			return;
		}
		const cKey: string = comment['__key'];
		// DONE: Add are you sure code here...
		// TODO: Make a better confirm
		//       http://stackoverflow.com/questions/41684114/angular-2-easy-way-to-make-a-confirmation-dialog
		if (confirm('Are you sure to delete this comment?')) {
			this.bsCommentsManager.deleteComment(cKey);
		}
	}

	editCommentPressed(comment) {
		if (!this.canComment) {
			return;
		}
		comment._editFlag = true;
		comment._origText = comment.text;
		console.log('Edit Comment Pressed');
	}

	cancelEditedComment(comment) {
		comment.text = comment._origText;
		delete comment._origText;
		delete comment._editFlag;
	}

	saveEditedComment(comment) {
		if (!this.canComment) {
			return;
		}
		delete comment._origText;
		delete comment._editFlag;
		this.bsCommentsManager.saveComment(comment);
	}
}
