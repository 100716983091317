// import { Injectable } from '@angular/core';
import * as sio from 'socket.io-client';

//@Injectable()
export class CFServerManager {

	private static _instance: CFServerManager;

	currentUser: any;
	status: any = {};
	isConnected = false;
	isJoined = false; // new
	lastError: any;
	onStatusUpdate: any;
	onCompilerOutput: any;
	onProgramOutput: any;
	botId: string;
	socket: any;
	socket1: any;


	static theManager() {  // get singleton
		if (!CFServerManager._instance) {
			CFServerManager._instance = new CFServerManager();
		}
		return CFServerManager._instance;
	}


	constructor() {
		console.log('CFServerManager Singleton constructor here');
		this.socket = sio;
		this.currentUser = undefined;
		this.status = {};
		this.initWithSocket(this.socket);
	}

	initWithSocket(socket) {
		const me = this;
		this.isConnected = false;

		// pull stuff from old socket provider code...
		const host = 'https://foo.compileit.online:443';
		const hostOpts = {
			secure: true,
			rejectUnauthorized: false
		};

		const s = sio.connect(host, hostOpts);
		console.log('Hi');
		this.socket1 = s;

		// Fired upon connecting.
		this.socket1.on('connect', function () {
			me.socket1.id = me.socket1.io.engine.id; //
			console.log('server socket connect: Socket ID(' + me.socket1.id + ')');
			me.isConnected = true;

			if (me.currentUser) {
				console.log('Rejoining...');
				me.doClientJoinAs(me.currentUser);
			}
			else {
				console.log('Not joined yet...');
			}
		});

		this.socket1.on('connect_error', function (e) {
			me.socket1.id = me.socket1.io.engine.id; //
			console.log('server socket error: ' + e);
			me.lastError = e;
		});

		// Fired upon a disconnection
		this.socket1.on('disconnect', function () {
			console.log('server socket disconnect!');
			me.isConnected = false;
		});

		this.socket1.on('status_update', function (msg) {

			if (msg !== undefined && msg.type !== undefined) {
				if (msg.type === 'counts') {
					delete msg['type'];
					me.status = msg;
				}
				else if (msg.type === 'users' && msg.users !== undefined) {
					me.status.users = msg.users;
				}
				if (me.onStatusUpdate) {
					me.onStatusUpdate(me.status);
				}
			}
		});

		this.socket1.on('chat message', function (msg) {

			if ((typeof msg === 'object')) {
				if (msg.botId !== undefined) {
					me.botId = msg.botId;
				}

				if (msg.type === 'compiler') {
					console.log('COMPILER OUTPUT: ' + msg);
					if (me.onCompilerOutput) {
						me.onCompilerOutput(msg);
					}
				}
				else if (msg.type === 'run') {
					console.log('PROGRAM OUTPUT: ' + msg);
					if (me.onProgramOutput) {
						me.onProgramOutput(msg);
					}
				}
				else {
					if (msg.message !== undefined) {
						console.log('RUN STATUS0: ' + msg.message);
					}
					else {
						console.log('RUN STATUS1: ' + msg);
					}
				}
			}
			else {
				console.log('RUN STATUS2: ' + msg);

				const ms: string = msg;
				if (ms.startsWith('Please wait')) {  // hack for please wait, clicking run too soon
					if (me.onProgramOutput) {
						msg = { done: true, run_stdout: msg, type: 'run' };
						me.onProgramOutput(msg);
					}
				}
			}
		});
	}

	doClientJoinAs(currentUser) {
		console.log('Debug doClientJoinAs');
		this.currentUser = currentUser;
		const me = this;

		// tells the server that client is ready and passes along auth info
		const cSocket = this.socket1; // .Socket(); // seems hacky ... due to SocketProvider

		if (cSocket === null || cSocket.id === undefined) {
			console.log('doClientJoin() ERROR:SERVER_SOCKET NOT READY');
			return;
		}

		if (!this.isConnected) {
			console.log('doClientJoin() ERROR:SERVER_SOCKET NOT CONNECTED YET');
			return;
		}

		if (!currentUser) {
			console.log('doClientJoin() ERROR:CURRENT USER IS NULL');
			return;
		}

		console.log('doClientJoin(' + cSocket.id + ') STARTING');

		let aType = 'anonymous client';
		const msg = {
			uid: 'anonymous',
			token: '',
			pUID: ''
		};

		if (currentUser && !currentUser.isAnonymous) {
			aType = 'authenticated user ' + currentUser.email;

			// if the updateProfile on the firebase user has been called
			if (currentUser.email) {
				msg.uid = currentUser.email;
			}
			else { // else lets use the provider email
				msg.uid = currentUser.providerData[0].email;
			}
			msg.pUID = currentUser.uid;
		}

		console.log('Connected to server.  Joined as ' + aType);

		cSocket.emit('client join', msg);

		me.isJoined = true;
	}

	generateSocketCompileCMD($proj) {
		/// // http://stackoverflow.com/questions/15360112/jump-to-specific-tab-from-another-tab-with-bootstraps-nav-tabs
		const shouldSendForCompile = true;

		// build a dummy anonymous packet and fill in data if
		// a user is present
		const digestable = 'test@test.test................';
		const hash = 'TESTTEST'; // this.md5.createHash(digestable)
		let cmdData = {
			userId: '',
			pUID: 0,
			pToken: '',
			uid: 'test@test.test',
			token: hash,
			project: $proj,
			cmd: 'run',
			id: 'id'
		};

		if (this.currentUser) {
			const token = 'MADEUPTOKENMADEUPTOKEN';
			if (shouldSendForCompile) {
				cmdData = {
					token: '',
					id: '',
					userId: this.currentUser.email,
					uid: 'google:' + this.currentUser.uid,
					pUID: this.currentUser.uid,
					pToken: token,
					project: $proj,
					cmd: 'run'
				};
			}
		}
		return cmdData;
	}

	compileProj($proj) {
		const cmdData = this.generateSocketCompileCMD($proj);
		cmdData.project.compileOn = 'any';
		const cSocket = this.socket1;
		cSocket.emit('chat message', cmdData);
	}

	compileAndRunProject(proj) {
		// this is a big hack for now, till i rewrite things to use the new project stuff
		let src = proj.files[0].data;

		// another big hack for file templates
		if (proj.file_templates) {
			if (proj.file_templates[0].data) {
				const template = proj.file_templates[0].data;
				src = template.replace('/*CODE_HERE*/', src);
			}
		}
		this.compileProj(proj);
	}

	sendInteractiveStop() {
		this.sendInteractiveInput('', {stop: true});
	}
	sendInteractiveInput(uinput, flags = null) {
		const me = this;

		// TODO: check to make sure we are running here
		if (me.botId === '') {
			return;
		}
		const cSocket = this.socket1;

		const cmdData = {
			room: '/#' + me.botId,  // Here be the bug, if multiple people connect to the same bot...
			message: {
				cmd: 'input',
				input: uinput,
				clientId: cSocket.id
			},
			type: 'client_input'
		};
		if (flags) {   // SU19
			cmdData.message = {...cmdData.message, ...flags};
		}
		cSocket.emit('room message', cmdData);
	}
}
