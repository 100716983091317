import { Component, OnInit } from '@angular/core';
import { BsLoggerService } from '../bs-logger.service';
import { Observer } from 'firebase/app';
import { Observable, Subscription } from 'rxjs';

@Component({
	selector: 'app-log-viewer',
	templateUrl: './log-viewer.component.html',
	styleUrls: ['./log-viewer.component.css']
})
export class LogViewerComponent implements OnInit {

	constructor(private logger: BsLoggerService) { }

	theLogs: any[] = [];
	theLogs$: Observable<any>;

	sub: Subscription;

	userToLog = '';

	ngOnInit() {

	}

	unsub() {
		if (this.sub) {
			this.sub.unsubscribe();
		}
	}

	getUserLogs() {
		console.log('Getting logs for: ' + this.userToLog);

		this.theLogs$ = this.logger.getUsersLogsByEmail(this.userToLog);
		this.unsub();
		this.sub = this.theLogs$
		.subscribe(d => {
			console.log('Debug here');
			this.theLogs = d;
			console.log('And here');
		});
	}

	getLogs() {
		console.log('Getting logs...');
		this.theLogs$ = this.logger.getLogs();

		this.unsub();
		this.sub = this.theLogs$
		.subscribe(d => {
			console.log('Debug here');
			this.theLogs = d;
			console.log('And here');
		});
	}

	showLog(log:any) {
		console.log(JSON.stringify(log));
		alert(JSON.stringify(log));
	}

	clearLogs() {
		if (prompt('Are you sure you want to clear all logs?') === 'YES') {
			console.log('Clearing all logs');
			this.logger.clearLogs();
		}
		else {
			console.log('Nope');
		}
	}
}
