import { Component, Input, Output, EventEmitter } from '@angular/core';
import { FolderItem } from '../../../../services/bs-folder.service';
import { Observable } from 'rxjs/Observable';



@Component({
	selector: 'app-bs-folder-item-list',
	templateUrl: './bs-folder-item-list.component.html',
	styleUrls: ['./bs-folder-item-list.component.css']
})
export class BsFolderItemListComponent {

	cols: Array<{header:string, field: string, width: string}> = [];
	globalFilterFields: string[] = [];

	constructor() {

		this.cols = [
			{header:'Name', field:'name', width:'290px'},
			{header:'Type', field:'__type', width: '50px'},
			{header:'Path', field:'projPath', width: '200px'},
		];

		for(const c of this.cols) {
			this.globalFilterFields.push(c.field);
		}
	}

	viewMode = 'list';
	setViewMode(s: string) {
		this.viewMode = s;

	}


	@Input() set itemList (itemList: Observable<FolderItem[]>) {
		console.log('itemList set');
		this._itemList = itemList;
		this._itemList.subscribe( d => {
			console.log('itemList fired');
			setTimeout(() => {
				this.itemArray = d
				.filter( (item) => !item.hidden || this.currentUserEmail==='zbobbradley@gmail.com')
				.map( (item) => {
					item['__type'] = item['projType'] || item.type;
					return item;
				});
			}, 0);
		});
	}
	_itemList: Observable<FolderItem[]>;
	itemArray: FolderItem[];



	@Input() folderPath: string = '/';
	@Input() basePath: string = '';
	@Input() currentUserEmail: string;
	@Output() goItemClicked = new EventEmitter();
	@Output() editItemClicked = new EventEmitter();
	@Output() deleteItemClicked = new EventEmitter();
	@Output() newItemClicked = new EventEmitter();
	@Output() moveItemClicked = new EventEmitter();
	@Input() menuCommands = [];

	goItem(item: FolderItem) {
		this.goItemClicked.emit(item);
	}

	editItem(item: FolderItem) {
		this.editItemClicked.emit(item);
	}

	deleteItem(item: FolderItem) {
		this.deleteItemClicked.emit(item);
	}

	showNewItemDialog(type) {
		this.newItemClicked.emit(type);
	}

	moveItem(item) {
		this.moveItemClicked.emit(item);
	}
}
