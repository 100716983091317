import { Pipe, PipeTransform } from '@angular/core';

/*
@Pipe({
  name: 'values'
})
export class ValuesPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    return null;
  }

}
*/

//
// https://webcake.co/object-properties-in-angular-2s-ngfor/
@Pipe({ name: 'values' })
export class ValuesPipe implements PipeTransform {
	transform(value: any, args?: any[]): Object[] {
		const keyArr: any[] = Object.keys(value),
			dataArr = [],
			keyName = args ? args[0] : 'key';       // zbobb fixed

		keyArr.forEach((key: any) => {
			value[key][keyName] = key;
			dataArr.push(value[key]);
		});

		if (args && args[1]) {                       // zbobb fixed
			dataArr.sort((a: Object, b: Object): number => {
				return a[keyName] > b[keyName] ? 1 : -1;
			});
		}

		return dataArr;
	}
}

//
