//import { Injectable } from '@angular/core';

//import { BsCioServerManagerService } from '../services/bs-cio-server-manager.service';
import { CFServerManager } from '@cf-platform/cf-shared-code-runner';
import { CFLinter} from './cf-linter';


import { IGraderTest } from './cf-class-stuff';

import {IProjectInfo} from "@cf-platform/cf-core-cms";





//import { BsAssignmentsService } from './bs-assignments.service';
//import { BsAssignmentTesterService } from './bs-assignment-tester.service';
import { TimeStuff } from './cf-time-stuff';
import { UtilStuff } from '@cf-platform/cf-core-cms';




type RunResult = { error: boolean, stdOut: string, compilerOut: string};
type PTestLine = {id: number, matched: boolean, spacingProblems: boolean, pattern: string, foundAt: string, foundAtLineNum: number, isReg: boolean};
type MatchResults = {pTestLines: PTestLine[], pOutputLines: POutputLine[], summary: {matched: number, missed: number, spacingErrors: number, unmatchedLines: number, outOfOrder: boolean}};
type POutputLine = {matched: boolean, firstRuleMatched: number, txt: string, markedText: string, spacingProblems: boolean};


type ZTestResult = { error: boolean, percentOff: number, title: string, summary: string, detail: string };

const MARK_OUT = String.fromCharCode(251);
//@Injectable()
export class CFAutoGrader {
	private static _instance: CFAutoGrader;

	server = CFServerManager.theManager();
	zLinter = CFLinter.getInstance();

	public static getInstance() {  // get singleton
		if (!CFAutoGrader._instance) {
			CFAutoGrader._instance = new CFAutoGrader();
		}
		return CFAutoGrader._instance;
	}

	constructor(

	) {

	}

	async sendInput(input:string) {
		console.log('Program started.  Sending input:');

		//this.server.sendInteractiveInput(input); // may need delay
		//console.log('Program started.  Sending input:');
		//console.log('[' + input + ']');

		setTimeout(async() => {
			const inputLines = input.split('\n');

			let didDelay = false;
			for (const line of inputLines) {
				if (line.startsWith('#')) {
					if (!didDelay) { // only delay for first of a run of #
						await UtilStuff.delayFor(500);
						didDelay = true;
					}
				}
				else {
					didDelay = false;
					this.server.sendInteractiveInput(line); // + '\n');
					console.log('Sending input:');
					console.log('[' + line + ']');
					await UtilStuff.delayFor(5);
				}
			}
		}, 0);
	}

	async runProject(project: IProjectInfo, inputScript: string ): Promise<RunResult> {

		const p = new Promise<RunResult>((resolve, reject) => {

			this.server.currentUser = { uid: 'BBBBBB', email: 'zbobbradley@gmail.com' };
			this.server.doClientJoinAs(this.server.currentUser);

			const projCopy = { ...project }; // shallow copy
			//projCopy.files = this.files;
			delete projCopy._files;
			delete projCopy['ups'];

			delete projCopy['detail_inputs'];
			projCopy.type = projCopy.projType;

			let stdOut = '';
			let stdErr = '';
			let compilerOut = '';
			let sentInput = false;

			//this.logger.logCmd('bs-terminal-wrap', 'compile/run', 2, { proj: projCopy });
			this.server.compileAndRunProject(projCopy);



			this.server.onProgramOutput =  (m) => {
				if (m.type === 'run') {
					if (m.run_stdout) {

						if (!sentInput) {
							sentInput = true;
							this.sendInput(inputScript);

							//sentInput = true;
						}

						if (m.run_stdout !== '') {
							if (m.isEcho) {
								let lines: string[] = m.run_stdout.split('\n');
								lines = lines.map( line => {
									if (line === '') {
										return '';
									}
									else {
										return '<span style="color:yellow">' + line + '</span>';
									}
								});
								stdOut += lines.join('\n');
							}
							else {
								stdOut += m.run_stdout;
							}
							console.log('StdOut: ' + m.run_stdout);
						}
						if (m.run_stderr !== '') {
							stdErr += m.run_stderr;
							console.log('StdErr: ' + m.run_stderr);
						}
					}

					if (!!m.done) {
						const r: RunResult = {error: false, stdOut: stdOut, compilerOut: compilerOut};
						console.log('Done - run');
						resolve(r);
					}
				}
			};

			this.server.onCompilerOutput =  (m) => {
				let msg;
				if (m.type === 'compiler') {
					if (m.compiler_stdout || m.compiler_stderr) {

						if (m.compiler_stdout && m.compiler_stdout !== '') {
							//msg = me.hotlinkErrors(me.noHtml(m.compiler_stdout));
							compilerOut += m.compiler_stdout;
							console.log('CompilerStdOut: ' + m.compiler_stdout);

						}
						if (m.compiler_stderr && m.compiler_stderr !== '') {
							compilerOut += m.compiler_stderr;
							console.log('CompilerStdErr: ' + m.compiler_stderr);
						}

					}
					if (!!m.done) {
						const r: RunResult = {error: true, stdOut: stdOut, compilerOut: compilerOut};
						console.log('Done - compiler');
						resolve(r);

					}
				}
			};

			this.server.onStatusUpdate = (m3)  => {
				console.log(m3);
			};

		});

	return p;





	}

	justInput(test: IGraderTest) {
		// returns back just the input lines in the grader script
		// these are the ones that don't start with #
		const lines = test.data.split('\n');
		const inputLines = lines.filter(line => !line.startsWith('#'));
		return inputLines.join('\n');
	}

	printFunc: (s: string) => void;

	async doTest(project: IProjectInfo, test: IGraderTest): Promise<ZTestResult> {
		if (test.type === 'text' || test.type.includes('grep')) {
			return await this.doGraderTest(project, test);
		}
		if (test.type === 'zlint') {
			return await this.doLintTest(project, test);
		}
		if (test.type === 'zlate') {
			return await this.doLateTest(project, test);
		}
		return {error: true, percentOff: 0, title: 'Unknown Test Type', summary: '', detail: ''};
	}

	async doLateTest(project: IProjectInfo, test: IGraderTest): Promise<ZTestResult> {

		const assignment = project.relatedAssignment;
		if (!assignment) {
			return {error: true, percentOff: 0, title: test.name, summary: 'No Related Assignment', detail: ''};
		}
		if (!assignment.dueDate) {
			return {error: false, percentOff: 0, title: test.name, summary: 'Assignment has no Due Date', detail: ''};
		}

		let percentOff = 0;
		let rSummary = 'Submitted on time.  No points off.';
		const daysLate = TimeStuff.calcDaysDiff(assignment.dueDate, project.relatedSubmitDate);
		if (daysLate > 0) {
			let ppd = 3;
			percentOff = Math.round(daysLate * ppd * 10) / 10;
			rSummary = TimeStuff.calcEarlylate(assignment.dueDate, project.relatedSubmitDate);
			rSummary += ' x ' + ppd + ' %PerDay = ' + percentOff + ' percent off';
		}

		return {error: false, percentOff: percentOff, title: test.name, summary: rSummary, detail: ''};
	}

	async doLintTest(project: IProjectInfo, test: IGraderTest): Promise<ZTestResult> {
		let fileIndex = -1;
		for (let i=0; i<project.files.length; i++) {
			if (project.files[i].name === 'main.cpp') {  // todo... look at other files!!!
				fileIndex = 1;
			}
		}
		if (fileIndex > 0) {
			const lintResult = this.zLinter.zLintFile(project.files[fileIndex].data);
			const totalRules = this.zLinter.countRuleChecks() ; // TODO. There is a problem here
			const percentOff = 100 * lintResult.errors.length / totalRules;

			let rSummary = '';
			let rDetails = '';

			const errs = lintResult.errors;

			if (errs.length === 0) {
				rSummary = 'All formatting tests passed!\r\n';
			}
			else {
				let errorMsg = '<table border=1>';
				//debugger;

				let eCount = 0;
				for (const err of errs) {

					errorMsg += '<tr><td>' + err.errorName + '</td>';
					errorMsg += '<td>' + err.errorDesc + '</td></tr>\r\n';
					eCount ++;

				}
				errorMsg += '</table>\r\n';
				const checksCount = this.zLinter.countRuleChecks();
				const checksPassed = checksCount - eCount;
				rSummary += 'Checks Passed: ' + checksPassed + '/' + checksCount;
				rSummary += '    Checks Failed: ' + eCount + '/' + checksCount;
				rSummary += '    Percent Off: -' + (40 * eCount / checksCount);
				//rSummary += 'Formatting errors:\r\n' + errorMsg;

				if (lintResult.errors.length > 0) {
					rDetails += 'Formatting errors:\r\n' + errorMsg;
					rDetails += 'CODE FORMATTING PROBLEMS\r\n';
					rDetails += lintResult.markedCode;
				}

			}



			return {error: false, title: test.name, percentOff: percentOff, summary: rSummary, detail: rDetails};
		}

		return {error: true, title: test.name, percentOff: 0, summary: 'Could not find main.cpp', detail: ''};
	}

	first = true;  // used for delay between tests

	async doGraderTest(project: IProjectInfo, test: IGraderTest): Promise<ZTestResult> {
		let rSummary = '';
		let rDetail = '';

		if (test.data) {

			console.log('Running tests: ' + test.name);
			//this.printFunc('Running tests: ' + test.name + ':');
			//const input = this.justInput(t);
			const inputScript = test.data;
			if (!this.first) {
				// this.printFunc('[Small delay between tests...]\r\n');
				await UtilStuff.delayFor(1100); // todo fix this by sending some sort of admin/grade flag
			}
			this.first = false;
			const r = await this.runProject(project, inputScript);
			//debugger;
			if (!r.error) {
				console.log('OK. thing done now grading');
				let matchR = this.findMatches(test, r.stdOut);
				if (matchR && matchR.summary && (matchR.summary.matched > 0 || matchR.summary.missed > 0)) {
					//this.printFunc('<details><summary>');

					rSummary += ' Matched ' + matchR.summary.matched + ' of ' + (matchR.summary.matched + matchR.summary.missed);
					rSummary += ' Spacing Errors: ' + matchR.summary.spacingErrors;
					rSummary += ' Unmatched Lines: ' + matchR.summary.unmatchedLines;
					let numMissed = matchR.summary.missed + matchR.summary.spacingErrors;
					let numT = matchR.summary.missed + matchR.summary.matched;
					if (numMissed > numT) {
						numMissed = numT;
					}
					//const perOff = (80 * (numMissed / numT) / numRunTests) ;
					const perOff = (numMissed / numT);
					// totalPerOff += perOff;
					rSummary += '  Percent Off: -' + perOff;
					if (matchR.summary.outOfOrder) {
						rSummary += ' (Some matches out of order.';
					}
					//this.printFunc('</summary>');
					//this.printFunc('\r\n');
					let pti = 0;
					let ptLine = matchR.pTestLines[pti];
					let maxPtiLine = ptLine.foundAtLineNum;
					//const unmatched = matchR.pTestLines.filter( (m) => !m.matched);
					let maxRuleMatched = -1;
					let lastRuleMatched = -1;

					for(let i = 0; i < matchR.pOutputLines.length; i++) {
						const pLine = matchR.pOutputLines[i];
						let isOutOfOrder = false;
						if (pLine.matched) {
							if (pLine.firstRuleMatched < lastRuleMatched + 1) {
								isOutOfOrder = true;
							}
							lastRuleMatched = pLine.firstRuleMatched;
						}

						const m = '' + UtilStuff.rightAlignNum(i + 1, 4) + (pLine.matched ? '  ' + UtilStuff.rightAlignNum(pLine.firstRuleMatched + 1, 4) : '  ....') + ': ' + this.decorateLine(isOutOfOrder || pLine.spacingProblems, pLine.txt, pLine.markedText) + '\r\n';

						let m2 = '';
						if (pLine.matched && pLine.spacingProblems) {
							const pattern = matchR.pTestLines[pLine.firstRuleMatched].pattern;
							m2 = '>SP*  ' + UtilStuff.rightAlignNum(pLine.firstRuleMatched + 1, 4) + ': ' + '<span style="background-color:red">' + pattern + '</span>\r\n';

						}

						if (pLine.matched && pLine.firstRuleMatched > maxRuleMatched) {
							maxRuleMatched = pLine.firstRuleMatched;
						}
						while (pti < matchR.pTestLines.length && ptLine.matched) { // skip matched rules
							pti++;
							ptLine = matchR.pTestLines[pti];
						}
						if (maxRuleMatched >= pti) {
							while (pti < matchR.pTestLines.length && !ptLine.matched) {
								let pattern = ptLine.pattern;
								if (test.type.includes('grep')) {
									pattern = UtilStuff.regToText(pattern);
								}
								rDetail += '>***  ' + UtilStuff.rightAlignNum(ptLine.id + 1, 4) + ': ' + '<span style="background-color:red">' + pattern + '</span>\r\n';
								pti++;
								ptLine = matchR.pTestLines[pti];
							}
						}
						rDetail += m;     // TODO:  wtf redo this
						if (m2 !== '') {
							rDetail += m2;
						}



					}
					while (pti < matchR.pTestLines.length && (!ptLine.matched || ptLine.spacingProblems)) {
						let pattern = ptLine.pattern;
						if (test.type.includes('grep')) {
							pattern = UtilStuff.regToText(pattern);
						}
						rDetail += '>***  ' + UtilStuff.rightAlignNum(ptLine.id + 1, 4) + ': ' + '<span style="background-color:red">' + pattern + '</span>\r\n';
						pti++;
						ptLine = matchR.pTestLines[pti];
					}
					//this.printFunc('</details>');
					//this.printFunc('\r\n');

					return {error: false, percentOff: 0, title: test.name, summary: rSummary, detail: rDetail};

				}
				return {error: false, percentOff: 0, title: test.name, summary: rSummary, detail: rDetail};

			}
			else {
				console.log('Thing done, but with errors');
				//this.printFunc('Errors while running test.\r\n');
				return {error: true, percentOff: 0, title: test.name, summary: 'Unknown error', detail: ''};

			}

		}
		else {

			console.log('skipping tests: ' + test.name);
			return {error: true, percentOff: 0, title: test.name , summary: 'No test data', detail: ''};
		}
	}

	async doFormatTest() {

	}

	/*
	async doLateTest() {

	}
	*/

	async doAutoGrader(project: IProjectInfo,
				tests: IGraderTest[],
				printFunc: (s: string) => void  // Function that lets it print in ther terminal
				): Promise<string> {

		this.printFunc = printFunc;

		let r = '';
		if (!this.server.isConnected) {
			r = 'Sorry, the terminal server is not connected.\r\n';
			return r;
		}

		let numRunTests = 0;
		const testsArray = UtilStuff.objToArray(tests);
		for (const t of testsArray) {
			if (t.type === 'text' || t.type.includes('grep')) {
				t.gradeable = true;
				//numRunTests++;
			}
		}

		let gradeableTests: Array<IGraderTest> = testsArray.filter( t => t.gradeable);

		gradeableTests.push( {name: 'Code Formatting', type: 'zlint', data: '', gradeable: true});
		gradeableTests.push( {name: 'Late Submit', type: 'zlate', data: '', gradeable: true});

		numRunTests = gradeableTests.length;

		this.first = true;  // used for delay between tests
		let totalPerOff = 0;

		for (let i = 0; i < numRunTests; i++ ) {
			const t  = gradeableTests[i];
			// TODO: FOR THE LOVE OF DOG.. Bust this up into smaller functions!!!

			await this.printFunc(`Running test ${i+1} of ${numRunTests} : ${t.name}...`);

			const testResult = await this.doTest(project, t);
			totalPerOff += testResult.percentOff; // zzz // (80 * (numMissed / numT) / numRunTests) ;


			console.log(`Running test ${i+1} of ${numRunTests} : ${t.name}`);

			//await this.delayFor(200); // so that print will show up

			this.printFunc('\u001b[{2}J'); // clear last line maybe...
			await this.printFunc(`Test ${i+1} of ${numRunTests} : ${t.name}:`);


			//this.printFunc('Test: ' + t.name);
			this.printFunc('<details><summary>');
			this.printFunc(testResult.summary);
			this.printFunc('</summary>\r\n');
			this.printFunc(testResult.detail);
			this.printFunc('</details>\r\n');
		}
		this.printFunc('Total percent off from run tests: -' + totalPerOff);
		return r;
	}

	superTrim(s: string): string {
		// trims spaces and converts any multiple spaces/tabs into single spaces
		let r = s.trim();
		r = r.replace(/[\t]+/g, ' ');  // tabs to single space
		r = r.replace(/\s{2,}/g, ' ');  // 2 or more whitespace to single space
		return r;
	}

	findMatches(test: IGraderTest, output: string): MatchResults {
		const testLines = test.data.split(/\r?\n/);
		const pTestLines: PTestLine[] = [];
		for (const l of testLines) {
			if (l.trim().startsWith('#')) {
				let r = l;
				let isReg = false;
				if (test.type.includes('grep') ) {
					r = UtilStuff.processWaitStr(r);
					isReg = true;
				}
				else {
					r = r.substr(1); // trim off #
				}
				const c = { id: pTestLines.length, pattern: r, matched: false, spacingProblems: false, foundAt: '', foundAtLineNum: -1, isReg: isReg };
				pTestLines.push(c);
			}
			else {
				//const c = { cmd: 'send', txt: l.trim() };
				//this.scmds.push(c);
			}
		}

		//debugger;

		const pOutputLines: POutputLine[] = [] ;
		for (const line of output.split('\n')) {
			pOutputLines.push({matched: false, firstRuleMatched: -1, txt: line, markedText: line, spacingProblems: false});
		}

		const findUnmatchedLine = (pTest: PTestLine) => {
			let matcher: (s: string, exact: boolean) => RegExpMatchArray|any;


			if (pTest.isReg) {
				const reg = new RegExp(pTest.pattern, 'i');
				matcher = (s, exact) => s.match(reg);
			}
			else {
				matcher = (s, exact) => {
					let mStr = s.toLowerCase();
					let mPattern = pTest.pattern.toLowerCase();
					if (!exact) {
						mStr = this.superTrim(mStr);
						mPattern = this.superTrim(mPattern);
					}
					const fi = mStr.indexOf(mPattern);

					if (fi < 0) {
						return null;
					}
					let r = {0: s.substr(fi, pTest.pattern.length), index: fi};
					if (!exact) {
						r[0] = s;
						r.index = 0;
					}
					return r;
				}
			}
			for (let i = 0; i < pOutputLines.length; i++) {
				const pLine = pOutputLines[i];
				//if (!pLine.matched) {
					//const r = pLine.txt.match(reg);
					let spacingProblems = false;
					let r = matcher(pLine.markedText, true);

					if (!r && test.type === 'text') {
						console.log('debug thingy here');
						r = matcher(pLine.markedText, false);
						if (r) {
							spacingProblems = true;
						}
					}
					if (r) {
						pLine.matched = true;
						pLine.spacingProblems = spacingProblems;
						pLine.spacingProblems = spacingProblems;
						pTest.foundAt = i + ',' + r.index + '-' + r[0].length;
						if (pTest.foundAtLineNum < 0) { // only do first
							pTest.foundAtLineNum = i;
						}
						if (pLine.firstRuleMatched < 0) {
							pLine.firstRuleMatched = pTest.id;
						}
						pTest.matched = true;
						pLine.markedText = pLine.markedText.replace(r[0], MARK_OUT.repeat(r[0].length));  // fix hack later
						return;
					}
				//}

			}
		}

		let cMatch = 0, cMiss = 0, cSpacingErrors = 0, cUnmatchedLines = 0;
		let lastPos = '', someOutOfOrder = false;

		for (const pTest of pTestLines) {
			findUnmatchedLine(pTest);
			if (pTest.matched) {
				console.log('Found (' + pTest.foundAt + ') :' + pTest.pattern );
				cMatch++;

				let pos = pTest.foundAt;
				if (pos < lastPos) {
					someOutOfOrder = true;
				}

			}
			else {
				console.log('Not found: ' + pTest.pattern);
				cMiss++;
			}
		}

		for (const pLine of pOutputLines) {
			if (pLine.spacingProblems) {
				cSpacingErrors++;
			}
			if (!pLine.matched) {
				cUnmatchedLines++;
			}
		}


		const rSum = {matched: cMatch, missed: cMiss, spacingErrors: cSpacingErrors, unmatchedLines: cUnmatchedLines, outOfOrder: someOutOfOrder};
		const r = {pTestLines: pTestLines, pOutputLines: pOutputLines, summary: rSum};

		return r;
	}

	decorateLine(warn: boolean, line: string, markedLine: string): string {
		const color = warn ? 'orange' : 'green';
		const spanOnCode = '<span style="background-color:' + color + '">';
		const spanOffCode = '</span>';

		if (line.length !== markedLine.length) {
			return line;
		}
		let r = '';
		let spanOnFlag = false;
		for(let i=0; i<line.length; i++) {
			const c = line[i];
			if (c !== markedLine[i]) {
				if (!spanOnFlag) {
					r += spanOnCode;
					spanOnFlag = true;
				}
			}
			else {
				if (spanOnFlag) {
					r += spanOffCode;
					spanOnFlag = false;
				}
			}
			r += c;
		}
		if (spanOnFlag) {
			r += spanOffCode;
			spanOnFlag = false;
		}
		return r;
	}

	// moved the following to a utils lib



}
