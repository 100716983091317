import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs/Observable';

import { BsClassesUsersService } from '../services/bs-classes-users.service';
import { UserRepository } from '@cf-platform/cf-core-cms-ng';

@Injectable()
export class OnlyEnrolledGuard implements CanActivate {
	constructor(
		private classesUsers: BsClassesUsersService,
		// OLD private afAuth: AngularFireAuth,
		private userRepo: UserRepository,
		private router: Router) {}
	canActivate(
		next: ActivatedRouteSnapshot,
		state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
			console.log('OnlyEnrolledGuard here');
			return new Observable<boolean>(observer => {

				const classinst = next.params['classinst'];
				const classid = next.params['classid'];
				console.log('classId is: ' + classid);

				// OLD WAY    this.afAuth.authState.subscribe(u => {
				console.log('asking for current user');
				let unSubUser = this.userRepo.getCurrentUser( u =>  {
					console.log('current user hit');
					if (!u) {
						console.log('Not logged in');
						return false;
					}

					this.userRepo.unsubscribeCurrentUser(unSubUser);

					const userEmail = u.email;
					console.log('Email: ' + userEmail);

					console.log('Checking if user is in class...');
					this.classesUsers.isUserInClass(userEmail, classid).then( (isInClass) => {

						if (isInClass) {
							console.log('OK User Is In Class');
							observer.next(true);
						}
						else {
							console.log('User Not In Class');
							// window.alert('You must log in to view this page');
							const url = state.url.toString();
							setTimeout(() => {
								this.router.navigate(['/class/' + classinst + '/' + classid + '/enroll/', url]);
							}, 500);

							observer.next(false);
						}

					});
				});

			});
	}
}
