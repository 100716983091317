import { Component, Input, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { Observable, } from 'rxjs/Observable';
import { merge, concat, BehaviorSubject } from 'rxjs';

import { BsClass } from '../../classes/bs-class';
import { BsClassesUsersService } from '../../services/bs-classes-users.service';
import {  SharedModule } from 'primeng/primeng';
import { HttpClient} from '@angular/common/http'

import { IUser } from '@cf-platform/cf-core-cms';
import { UserRepository } from '@cf-platform/cf-core-cms-ng'
//import { concat } from 'rxjs-compat/operator/concat';

import { BsCurrentClassInfoService, CFCurrentClassInfo } from '../../services/bs-current-class-info.service';
import { BreadcrumbService } from 'projects/cf-web/src/app/breadcrumb.service';

import { CFApiClient } from '@cf-platform/cf-api-client';



/*
interface ICUser {
	name: string;
	firstName: string;
	lastName: string;
	email: string;
	uid: string;
	roll: string;
}
*/

interface TableCol {
	header: string;
	field: string;
	width: string;
	link?: string;
}

interface GBUser extends IUser{
	_type?: string,			// type || role
	_name?: string,			//
	_status?: string,
	urlPhotoURL?: string,	// We wrap the url('') around the photo
	//allTotalPoints: number,	// All Assignments total points
	//stuTotalPoints: number,	// My total points
	//grades: any    			// TODO fix or remove this
}

@Component({
	selector: 'app-bs-class-users',
	templateUrl: './bs-class-users.component.html',
	styleUrls: ['./bs-class-users.component.css']
})
export class BsClassUsersComponent implements OnDestroy {
	selectedUsers: Array<GBUser> = [];
	usersList: Observable<GBUser[]>;
	usersList2: Observable<GBUser[]>;
	pendingUsersList: Observable<GBUser[]>;

	title = 'Users Enrolled In Class';

	users_array: GBUser[] = [];
	pending_users_array: GBUser[] = [];
	all_users_array: GBUser[];
	_bsClass: BsClass;

	cols: Array<TableCol> = [];
	frozenCols: Array<TableCol> = [];
	scrollableCols: Array<TableCol> = [];
	globalFilterFields: Array<string> = [];
	allUsersList: BehaviorSubject<IUser[]>;


	currentClassInfo: CFCurrentClassInfo;
	cciSub: any;

	///@Input()

	set bsClass(bsClass: BsClass) {
		console.log('bs-clas-users class was set');
		this._bsClass = bsClass;
		this.usersList = bsClass.usersO;
		this.allUsersList = new BehaviorSubject([]);
		//this.usersList = bsClass.usersO;
		//this.usersList2 = bsClass.usersO; //this.usersList;
		this.pendingUsersList = bsClass.pendingUsersO;


		if (this.usersList) {
			console.log('bs-class-users  usersList looks good to me');
		}
		else {
			console.log('bs-class-users  userList looks empty');
		}

		try {
			this.cfRef.detectChanges();
		} catch (error) {

		}


		const uMapper = (u) => {
				u._type = [u.type, u.lms_role].join(', ');
				u._name = u.name;
				if (u.lastName && u.firstName) {
					u._name = u.lastName + ', ' + u.firstName;
				}
				u._status = [u.class_status, u.status, u.where].join(', ');
				if (!u.urlPhotoURL) {
					if (u.provider && u.provider.photoURL) {
						u.urlPhotoURL = "url('" + u.provider.photoURL + "')";
					}
				}
				return u;
		}


		this.usersList.subscribe(d => {
			console.log('users component: getUsers p2: Read in ' + d.length + ' users');
			//this.users_array = d.map( uMapper );
			//this.all_users_array = [...this.users_array, ...this.pending_users_array];
			this.users_array = d;
			this.updateAllUsers();
		});

		this.pendingUsersList.subscribe(d => {
			console.log('users component: getPendingUsers p2: Read in ' + d.length + ' users');
			//this.pending_users_array = d.map( uMapper );
			//this.all_users_array = [...this.users_array, ...this.pending_users_array];
			this.pending_users_array = d;
			this.updateAllUsers();
		});


		//this.updateStats();

	}

	updateAllUsers() {
		this.all_users_array = [...this.users_array, ...this.pending_users_array];
		this.title = 'Users Enrolled In Class: ' + this.users_array.length + ' - Pending: ' + this.pending_users_array.length;
		this.allUsersList.next(this.all_users_array);
	}

	selectedUsersChange(e: any) {
		const sUsers = e as Array<GBUser>;
		this.selectedUsers = sUsers;
		console.log('Selected Users are: ' + sUsers.map(u => u._name).join('; '));
	}

	/*
	async updateStats() {
		setTimeout(async() => {
			for(const u of this.users_array) {
				const cu = await this.userRepository.getUser(u.uid);
				u.status = cu.status;
				u.where = cu.where;
				u.what = cu.what;
			}
		}, 500);
	}
	*/

	constructor(
		private classesUsersService: BsClassesUsersService,
		private httpClient: HttpClient,
		private userRepository: UserRepository,
		private cfRef: ChangeDetectorRef,
		private breadcrumbService: BreadcrumbService,
		private currentClassInfoService: BsCurrentClassInfoService) {



			this.cciSub = currentClassInfoService.currentClassInfoHandler.subscribe( cci => {
				if (cci) {


					this.currentClassInfo = cci
					//this.theClass = cci.theClass;
					this.bsClass = cci.theClass;

					if (cci.theClass) {
						this.setClassTitle();


						//this.reloadEverything();
					}
				}
			});


			const nameCol = {header:'name', field:'_name', width: '150px'};

			this.frozenCols = [
				{header:'photo', field:'photo', width: '50px'},
				nameCol
			];
			this.scrollableCols = [
				//{header:'email', field:'email', width:'180px'},
				{header:'status', field:'_status', width: '180px'},
				{header:'lms_role', field:'lms_role', width: '150px'},
				{header:'section', field:'section', width: '150px'},
				{header:'lms_id', field:'lms_id', width: '150px'}
				//{header:'section', field:'section', width: '150px'}
				//{header:'section', field:'section', width: '150px'}
			];

			this.globalFilterFields.push('_name');
			for (const col of this.scrollableCols) {
				this.globalFilterFields.push(col.field);
			}
			console.log('GLobalFilterFields: ', this.globalFilterFields.join(', '));
		}

	ngOnDestroy() {
		this.cciSub.unsubscribe();
	}

	showUserDump(u) {
		console.log(JSON.stringify(u));
	}

	// TODO destroy subscription!!!!
	unenrollUsersFromClass() {
		console.log('unenrollUsersFromClass()');



		if (!this.selectedUsers || this.selectedUsers.length === 0) {
			alert('No selected users!');
			console.log('No selected users!');
			return;
		}
		if (confirm('Press OK to remove the selected users from this class.')) {

			console.log(this.selectedUsers);

			for (const u of this.selectedUsers) {
				console.log('Unenroll: ' + u.email + ' Uid: ' + (u.uid || u.__key));
				this.classesUsersService.removeUserFromClass(u, this._bsClass.classId);
			}
		}
	}

	updateUserClasses() {
		this.classesUsersService.updateUserClasses(this._bsClass.classId);
	}

	async importFromBanner() {
		// TODO. move this and protect it and maybe make it a put
		if (confirm('Press OK to run import.  Note this may take a few seconds.')) {

			console.log('Importing users from banner...');
			//const r = await this.httpClient.get('http://localhost:3333/api/lms/sync/class/' + this._bsClass.classId + '/users')
			//const r = await this.httpClient.get('/api/lms/sync/class/' + this._bsClass.classId + '/users')
			//.subscribe(r => {

			const cfApiClient: CFApiClient = CFApiClient.getInstance();


			const r = await cfApiClient.lmsSyncUsers(this._bsClass.classId);

			console.log('Sync resp: ', r);
			setTimeout(() => {
				alert('Import from banner: ' + JSON.stringify(r));
			}, 1000);

			//});
		}
	}


	theClass: BsClass;

	async setClassTitle() {

		console.log('bs-class-users.component  setClassTitle');

		this.theClass = this.currentClassInfo.theClass;

		this.breadcrumbService.setItems([

			{label: this.theClass.classId, routerLink: '/class/utm/' + this.theClass.classId},
			{label: 'Students'}
		]);
		this.breadcrumbService.setPageInfo({title: 'Class Users: ' + this.theClass.classId, icon: 'person-outline'});

		setTimeout(async() => {
			await this.currentClassInfo.theClass.loadSettings();
			const className = this.currentClassInfo.theClass.settings.name
			if (className) {
				this.breadcrumbService.setPageInfo({title: 'Class Users: ' + className, icon: 'person-outline'});
			}

		}, 1000);

	}
}
