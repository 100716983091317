import { Component, Input, ChangeDetectorRef, OnDestroy, OnInit, NgZone} from '@angular/core';
import { BsCurrentClassInfoService, CFCurrentClassInfo } from '../../services/bs-current-class-info.service';
import { BsClass } from '../../classes/bs-class';
import { BsAssignmentsService } from '../../services/bs-assignments.service';

import { Observable } from 'rxjs/Observable';
import { BsUtilsService } from '@cf-platform/cf-core-cms-ng';
import {  SharedModule } from 'primeng/primeng';
import { TableModule } from 'primeng/table';
import { BsClassesUsersService } from '../../services/bs-classes-users.service';
import { Router, ActivatedRoute } from '@angular/router';
import { BsUsersDialogComponent } from '@cf-platform/cf-core-cms-ng';
import { MatDialog} from '@angular/material'

import {IProjectInfo, IAssignment} from "@cf-platform/cf-core-cms";

import { IUser } from '@cf-platform/cf-core-cms';


import { BreadcrumbService } from 'projects/cf-web/src/app/breadcrumb.service';



interface TableCol {
	header: string;
	field: string;
	width: string;
	link?: string;
}

interface GBUser extends IUser{
	_type?: string,			// type || role
	_name?: string,			//
	urlPhotoURL?: string,	// We wrape the url('') around the photo
	allTotalPoints: number,	// All Assignments total points
	stuTotalPoints: number,	// My total points
	grades: any    			// TODO fix or remove this
}

@Component({
	selector: 'app-bs-grade-book',
	templateUrl: './bs-grade-book.component.html',
	styleUrls: ['./bs-grade-book.component.css']
})
export class BsGradeBookComponent implements OnInit, OnDestroy {
	theClass: BsClass  ;

	theStudents: Array<GBUser> = [];
	selectedUsers: Array<GBUser> = [];
	assignmentList: Array<IAssignment> = [];

	uDbKey: any;
	@Input() assignmentId: string;
	@Input() studentKey: string;

	stuTotalPointsCol: TableCol;
	allTotalPointsCol: TableCol;

	cols: Array<TableCol> = [];
	frozenCols: Array<TableCol> = [];
	scrollableCols: Array<TableCol> = [];
	globalFilterFields: Array<string> = [];
	currentClassInfo: CFCurrentClassInfo;
	cciSub: any;

	constructor(
		private currentClassInfoService: BsCurrentClassInfoService,
		private assignmentsService: BsAssignmentsService,
		private router: Router,
		private classesUsersService: BsClassesUsersService,
		private dialogService: MatDialog,
		private cdRef: ChangeDetectorRef,
		private ngZone: NgZone,
		private breadcrumbService: BreadcrumbService,

		private route: ActivatedRoute,


	) {
		this.cciSub = currentClassInfoService.currentClassInfoHandler.subscribe( cci => {
			if (cci) {


				this.currentClassInfo = cci
				this.theClass = cci.theClass;

				if (cci.theClass) {
					this.setClassTitle();


					this.reloadEverything();
				}
			}
		});

	}

	async reloadEverything(){
		this.loadStudents()
			.then(k => {
				this.loadAssignments()
					.then(j => {


						for( const s of this.theStudents) {
							s._type = s.type || s.lms_role;
							s._name = s.name;
							if (s.lastName && s.firstName) {
								s._name = s.lastName + ', ' + s.firstName;
							}
							//s._name = 'fudge';
							console.log('Debug urlphoto here');
							if (!s.urlPhotoURL) {
								if (s.provider) {
									s.urlPhotoURL = "url('" + s.provider.photoURL + "')";
								}
							}

							s.allTotalPoints = this.allTotalPoints;
							//if (!s.grade_POINTS) {
							//	s.grade_POINTS = 0;
							//}
							//debugger;
						}
						this.theStudents = [...this.theStudents];
						//debugger;

						for( const a of this.assignmentList) {

								a['field'] = 'grade_' + a.assignmentId;
								a['link'] = 'grade_link_' + a.assignmentId;

							//console.log('af2: ' + a['field']);
						}

						const nameCol = {header:'name', field:'_name', width: '150px'};

						this.frozenCols = [
							{header:'photo', field:'photo', width: '50px'},
							nameCol
						];
						this.scrollableCols = [
							{header:'email', field:'email', width:'180px'},
							{header:'type', field:'_type', width: '150px'},
							{header:'section', field:'section', width: '150px'},
						];

						this.globalFilterFields = ['name', 'email', 'type', 'section'];

						this.cols = [...this.frozenCols, ...this.scrollableCols];

						for(let a of this.assignmentList) {
							if (a.assignmentId && a.field) {
								this.globalFilterFields.push(a.field);
							}
							else {
								a.assignmentId = '?';
								a.points = 0;
							}
							const col = {
								header: a.assignmentId + '  (' + a.points + ')',
								field: a.field,
								link: a.link,
								width: '100px'
							};
							this.cols.push(col);
							this.scrollableCols.push(col);
						}

						this.cols = [...this.cols, this.stuTotalPointsCol, this.allTotalPointsCol, nameCol];
						this.scrollableCols = [...this.scrollableCols, this.stuTotalPointsCol, this.allTotalPointsCol, nameCol];

						console.log('dc1');
						this.theStudents = [...this.theStudents];
						this.assignmentList = [...this.assignmentList];
						this.cdRef.detectChanges();
						this.addInStudentGrades();
						console.log('dc2');
						this.theStudents = [...this.theStudents];
						this.assignmentList = [...this.assignmentList];
						this.cdRef.detectChanges();
					});
			});
	}

	reloadNeeded = false;
	async reloadEverythingDeBounce(){
		// This will not reload more than once a second,
		// so that batches don't do a reload storm...
		this.reloadNeeded = true;
		setTimeout(() => {
			if (this.reloadNeeded){
				this.reloadEverything()
				.then(()=>{this.reloadNeeded = false;});
			}
		}, 1000);
	}

	async loadStudents() {
		const uList = await this.theClass.usersO.first().toPromise(); // (d => {
		this.theStudents = uList; // .map(u => {u.lfName2 = "hi: " + u.lfName; return u;})
		return this.theStudents;
	}

	async loadAssignments() {
		console.log('Loading assignments list...');
		const allAssignments = await this.currentClassInfo.theClass.getAssignments();
		this.assignmentList = allAssignments.filter(a => !a.hidden);

		this.calcTotalPoints();
		//const stpo = { name: 'POINTS', assignmentId: 'POINTS', points: this.allTotalPoints, classId: 'POINTS', field: 'POINTS', skipF: 1 };
		//this.assignmentList.push(stpo);
		//const tpo = { name: 'TOTAL POINTS', assignmentId: 'TOTAL_POINTS', points: this.allTotalPoints, classId: 'TOTAL_POINTS', field: 'TOTAL_POINTS', skipF: 1 };
		//this.assignmentList.push(tpo);

		this.stuTotalPointsCol = {header:'POINTS', field:'stuTotalPoints', width:'100px'};
		this.allTotalPointsCol = {header:'TOTAL POINTS', field:'allTotalPoints', width:'100px'},

		console.log('Loaded assignments list.');
		//console.log(this.assignmentList);
		return true;
	}

	allTotalPoints = 0;

	calcTotalPoints() {
		let tp = 0;
		for (const a of this.assignmentList) {
			if (a.points){
				tp += +a.points;
			}
		}
		this.allTotalPoints = tp;


	}

	addInStudentGrades() {   // grades??
		if (this.theStudents) {
			for (const u of this.theStudents) {
				this.uDbKey = BsUtilsService.z_filterEmailAsKey(u.email);
				u.grades = {};
				const e = u.email;
				this.getGradesInfoForStudent(e)
					.then(g => {
						console.log('Grades here');
						u.grades = g;
						if (!g) {
							u.grades = {};
						}
						u.stuTotalPoints = 0;
						for (const ai in this.assignmentList) {

							const aid = this.assignmentList[ai].assignmentId;
							const a = this.assignmentList[ai];


							if (!u.grades[aid]) {
								this.uDbKey = BsUtilsService.z_filterEmailAsKey(e);
								if (a.submitted && a.submitted[this.uDbKey]) {
									u.grades[aid] = { grade: ' ! ', note: '' };
								}
								else {
									u.grades[aid] = { grade: ' - ', note: '' };
								}
							}
							else {
								if (u.grades && u.grades[aid] && u.grades[aid].grade){
									u.stuTotalPoints += +u.grades[aid].grade;
								}
								//debugger;
							}

							u.grades[aid].link = '/class/utm/' + this.theClass.classId + '/grade/' + aid + '/' + u.email;

							//a['field'] = 'grade_' + aid;
							u[a['field']] = u.grades[aid].grade;
							u[a['link']] = u.grades[aid].link;

							//console.log('a[field]:' + a['field']);
						}
						this.theStudents = [...this.theStudents];
						this.cdRef.detectChanges();
					});
			}
		}
	}

	async getGradesInfoForStudent(aStudentEmail): Promise<any> {
		const grades = await this.assignmentsService.getGradesInfoUser(this.currentClassInfo.theClass.classId,
			aStudentEmail);
		return grades;
	}

	async addUserToClass(u){
		const r = await this.classesUsersService.addUserToClass(u, this.theClass.classId);
		return r;
	}

	enrollUsers() {
		//this.router.navigate(['/admin/users'], { queryParams: { classId: this.theClass.classId } });
		const addFun = async (u,ignore) => {
			const r = await this.addUserToClass(u);
			this.reloadEverythingDeBounce();  // THIS IS ASYNC
			return r;
		};
		setTimeout(() => {
			this.ngZone.run( () => {
				BsUsersDialogComponent.showDialog(this.dialogService,
					this.theClass.classId,
					"Class: " + this.theClass.classId,
					addFun
				);
			})
		}, 0);


	}

	async unenrollUsersFromClass() {

		for (const u of this.selectedUsers) {
			console.log("Unenroll user: " + u.email);
			await this.classesUsersService.removeUserFromClass(u, this.theClass.classId);
		}
		this.reloadEverything();
	}

	updateUserClasses() {
		this.classesUsersService.updateUserClasses(this.theClass.classId);
	}

	updateUserInfo() {
		console.log('updateUserInfo()');
		this.classesUsersService.updateUserInfo(this.theClass.classId);
	}

	debug(obj: any): string {
		return JSON.stringify(obj);
	}

	ngOnDestroy() {
		this.cciSub.unsubscribe();
	}

	ngOnInit() {

	}

	//



	async setClassTitle() {


		this.breadcrumbService.setItems([

			{label: this.theClass.classId, routerLink: '/class/utm/' + this.theClass.classId},
			{label: 'GradeBook'}
		]);
		this.breadcrumbService.setPageInfo({title: 'GradeBook: ' + this.theClass.classId, icon: 'book'});

		setTimeout(async() => {
			await this.currentClassInfo.theClass.loadSettings();
			const className = this.currentClassInfo.theClass.settings.name
			if (className) {
				this.breadcrumbService.setPageInfo({title: 'GradeBook: ' + className, icon: 'book'});
			}

		}, 1000);

	}


}
