// -----

import {ZLayeredCmdCanvas, ZCanvasCmdLayer} from './z-layered-cmd-canvas'
import { ContentChild } from '@angular/core';



var _g_turtle_context;
var _g_turtle;

interface ZTurtleCmd {
	cmd: string,
	param1?: string|number,
	param2?: string|number
}

export class ZTurtle {


	private static  _turtle: ZTurtle;



	_x: number;
	_y: number;
	_lineWidth: number;
	_angle: number;
	_colormode:number;
	_color: string;
	_filling: boolean;
	_needBeginpath: boolean;
	_fillcolor: string;
	_penDown: boolean;
	_first: boolean;  // has made first move

	_cmdQueue: Array<ZTurtleCmd> = [];
	_processingCmdQueue = false;

	_speed: number;

	private drawingLayer: ZCanvasCmdLayer;
	private turtleLayer: ZCanvasCmdLayer;




	turtleShape: Path2D;
	turtleShapesDict = {};  // todo: make it a dict of Path2D
	_xCenter: number;
	_yCenter: number;

	static getTurtle(zLayers) {

		if (!ZTurtle._turtle) {
			ZTurtle._turtle = new ZTurtle(zLayers);
		}
		return ZTurtle._turtle;
	}

	enqueueCmd(tcmd: ZTurtleCmd) {
		this._cmdQueue.push(tcmd);
		if (!this._processingCmdQueue) {
			this.processCmdQueue();
		}
	}

	async processCmdQueue() {
		if (this._processingCmdQueue) {
			return;
		}
		this._processingCmdQueue = true;
		while (this._cmdQueue.length > 0) {
			const tcmd = this._cmdQueue.shift();
			await this.doCmd(tcmd);
			//await this.sleep(10);
			await this.pause();
		}
		this._processingCmdQueue = false;
	}

	async doCmd(tcmd: ZTurtleCmd) {

		switch (tcmd.cmd) {
			case 'fd' : await this.forward(+tcmd.param1); break;
			case 'rt' : this.right(+tcmd.param1); break;
			case 'lt' : this.left(+tcmd.param1); break;
			case 'pu' : this.penup(); break;
			case 'pd' : this.pendown(); break;
			case 'bk' : await this.backward(+tcmd.param1); break;
			case 'colormode': this.colormode(+tcmd.param1); break;
			case 'color': this.color('' + tcmd.param1); break;
			case 'fillcolor': this.fillcolor('' + tcmd.param1); break;
			case 'pencolor': this.pencolor('' + tcmd.param1); break;
			case 'pensize': this.pensize(+tcmd.param1); break;
			case 'setpos': this.setposition(+tcmd.param1, +tcmd.param2); break;
			case 'setx': this.setx(+tcmd.param1); break;
			case 'sety': this.sety(+tcmd.param1); break;
			case 'begin_fill': this.begin_fill(); break;
			case 'seth': this.setheading(+tcmd.param1); break;
			case 'end_fill': this.fill(); break;
			case 'home': this.home(); break;
			case 'circle': this.circle(+tcmd.param1); break;
			case 'shape': this.shape('' + tcmd.param1); break;
			case 'stamp': this.stamp(); break;
			case 'speed': this.speed(+tcmd.param1); break;
			default:
				console.log('Error: unknown turtle cmd: ' + tcmd.cmd);
		}
		return;
	}

	setupTurtleShapes() {

		const tSize = 10;

		const defaultShape = new Path2D();
		defaultShape.moveTo(0,0);
		defaultShape.lineTo(-tSize, -tSize);
		defaultShape.lineTo(tSize*2, 0);
		defaultShape.lineTo(-tSize, tSize)
		defaultShape.lineTo(0,0);

		this.turtleShapesDict['arrow'] = defaultShape;
		this.turtleShapesDict['triangle'] = defaultShape;
		this.turtleShapesDict['classic'] = defaultShape;
		this.turtleShape = defaultShape;

		const squareShape = new Path2D();
		squareShape.rect(-tSize,-tSize, 2 * tSize, 2 * tSize);
		this.turtleShapesDict['square'] = squareShape;

		const t = new Path2D();

		const ts = 10

		t.ellipse(0,0,ts,ts*.75,0,0,360);
		t.ellipse(ts,0,ts/2,(ts*.75)/2,0, 1.5*3.1415 , 2.5 * 3.1415);


		this.turtleShapesDict['turtle'] = t;
	}

	redrawEverything() {
		this.updateTurtle();

		this.zLayers.redraw();
	}

	updateTurtle() {

		this.turtleLayer.clearCmds();
		this.turtleLayer.save();
		this.turtleLayer.lineWidth = 2;
		this.turtleLayer.strokeStyle = this._color;
		this.turtleLayer.translate(this._xCenter+this._x, this._yCenter-this._y);
		this.turtleLayer.rotate(this.radians(this._angle));
		this.turtleLayer.stroke(this.turtleShape);
		this.turtleLayer.restore();

	}

	killTurtle() {
		this._cmdQueue = [];
		this.turtleLayer.clearCmds();
		this.drawingLayer.clearCmds();
		delete this.drawingLayer;
		delete this.turtleLayer;
	}



	constructor(
		private zLayers: ZLayeredCmdCanvas){

		this._x = 0;
		this._y = 0;
		this._speed = 6;
		this._angle = 0;
		this._colormode = 1;
		this._color = "black";
		this._fillcolor = "black";
		this._filling = false;
		//this._needBeginpath = true;
		this._lineWidth = 1;
		this._penDown = true;
		this._first = true;

		this.drawingLayer = zLayers.getLayer('drawing');
		this.turtleLayer = zLayers.getLayer('turtles');

		this._xCenter = zLayers.width/2;
		this._yCenter = zLayers.height/2;


		this.setupTurtleShapes();


	}




	lt = this.left;
	left(angle:number){
		this._angle = ((360 + this._angle) -  angle%360)%360;
	}

	rt = this.right;
	right(angle:number){
		this._angle = (this._angle +  angle)%360;
	}

	colormode(range: number){
		if (range != 1 && range != 255){
		console.error("Turtle Error: colormode must be either 1 or 255");
		return;
		}
		this._colormode = range;
	}

	color(c:string) {
		this._color = c;
		this._fillcolor = c;
	}

	fillcolor(c:string) {  // todo, fix arguments to allow rgb
		this._fillcolor = c;
	}

	pencolor(cr:string|number, g:number=undefined, b:number=undefined) {

		if (g == undefined){
		var c:string = cr as string;
		this.color(c);
		} else {
		var r:number = cr as number;
		if (this._colormode == 1){
			r = Math.round(r*255);
			g = Math.round(g*255);
			b = Math.round(b*255);
		}
		if (r > 255) r = 255;   if (r < 0) r = 0;
		if (g > 255) g = 255;   if (g < 0) g = 0;
		if (b > 255) b = 255;   if (b < 0) b = 0;

		var rh:string = r.toString(16);  if (rh.length == 1) rh = '0' + rh;
		var gh:string = g.toString(16);  if (gh.length == 1) gh = '0' + gh;
		var bh:string = b.toString(16);  if (bh.length == 1) bh = '0' + bh;
		var c:string = '#' + rh + gh + bh;
		console.log("pencolor:" + c);
		this.color(c);


		}
	}

	//pencolor(r:number, g:number: b:number){}

	width = this.pensize;
	pensize(width:number){
		this._lineWidth = width;
	}

	up = this.penup();
	pu = this.penup();
	penup(){
		this._penDown = false;
	}

	down = this.pendown();
	pd = this.pendown();
	pendown(){
		this._penDown = true;
	}

	_drawfromto(x1:number,y1:number, x2:number,y2:number){

		const context = this.drawingLayer;



		if (!this._filling) // && this._needBeginpath)
			context.beginPath();



		//if (this._first || this._x !== x1 || this._y !== y1){
		//
		if (!this._filling || this._first) {
			this._first = false;
			context.moveTo(this._xCenter+x1, this._yCenter-y1);
		}

		context.strokeStyle =this._color;
		context.lineWidth = this._lineWidth;

		context.lineTo(this._xCenter+x2,this._yCenter-y2);

		context.stroke();

	}

	_moveto(x:number, y:number) {
		const context = this.drawingLayer;
		context.moveTo(this._xCenter + x, this._yCenter - y);
	}

	seth = this.setheading;
	setheading(a:number) {
		this._angle = -a;  // TODO: fix all the inverted angles!!!!
	}

	goto = this.setposition;
	setpos= this.setposition;
	setposition(x:number, y:number){  // TODO: should also accept a vector
		if (this._penDown){
			this._drawfromto(this._x, this._y, x,y);
		}
		else {
			this._moveto(x, y);
		}
		this._x = x;
		this._y = y;

		//this.redrawCmds();
		this.redrawEverything();
	}

	setx(x:number) {
		this.setposition(x, this._y);
	}

	sety(y:number) {
		this.setposition(this._x, y);
	}

  // TODONE: setheading  DONE

  // TODONE: home

  home() {
	  this.setposition(0,0);
	  this.setheading(0);
	  this.redrawEverything();
  }

	fd = this.forward;
	async forward(distance:number) {

		for(let i=0; i< 5; i++) {
			await this._forward(distance/5);
		}

	}

	async _forward(distance: number) {

		var dx = distance * Math.cos(this.radians(-this._angle));
		var dy = distance * Math.sin(this.radians(-this._angle));

		var x2 = this._x + dx;
		var y2 = this._y + dy;

		//x2 = Math.round(x2);
		//y2 = Math.round(y2);

		if (this._penDown){
		this._drawfromto(this._x, this._y, x2,y2);
		//console.log("moveTo(" + this._x + "," + this._y + ");lineTo(" + x2 + "," + y2 + ");");
		}
		this._x = x2;
		this._y = y2;

		this.redrawEverything();

		await this.pause();

	}

	async pause() {
		let ms = 50;
		if (this._speed < 0.5 || this._speed > 10) {
			ms = 0;
			return;
		}
		else {
			ms = (10.5 - this._speed) * 10;
		}
		await this.sleep(ms);
	}



	async sleep(time) {
		return new Promise((resolve) => {
			setTimeout(() => {
				resolve();
			}, time);
		});
	}

	back = this.backward;
	bk = this.backward;
	async backward(distance:number){
		await this.forward(-distance);
	}


	begin_fill(){
		const context = this.drawingLayer;
		this._filling = true;
		console.log("Starting path for fill...");
		context.beginPath();
		//this.addCanvasCmd('beginPath');
		//this._needBeginpath = false;
		console.log("After beginPath");
	}

	fill(){
		const context = this.drawingLayer;
		this._filling = false;
		context.fillStyle = this._fillcolor;
		//this.addCanvasCmd('fillStyle', this._fillcolor)
		console.log("filling with: " + this._fillcolor);
		context.fill();
		context.stroke();
		//this.addCanvasCmd('fill');
		//this._needBeginpath = true;
		console.log("After fill");

		//this.redrawCmds();
		this.redrawEverything();
	}

	circle(r: number) {
		console.log('z-turtle circle');
		const context = this.drawingLayer;
		const dx = r * Math.cos(this.degrees(-90 + this._angle));
		const dy = r * Math.sin(this.degrees(-90 + this._angle));
		context.circle(this._xCenter + this._x + dx, this._yCenter - (this._y + dy), r);
		context.stroke();
		this.redrawEverything();
	}

	shape(shapeName: string) {
		console.log('Debug turtle.shape');
		if (this.turtleShapesDict[shapeName]) {
			this.turtleShape = this.turtleShapesDict[shapeName];
		}
	}

	stamp() {
		console.log('Debug turtle.stamp');
		const context = this.drawingLayer;
		//context.stroke(this.turtleShape);

		context.save();
		//this.turtleLayer.lineWidth = 2;
		context.strokeStyle = this._color;
		context.translate(this._xCenter+this._x, this._yCenter-this._y);
		context.rotate(this.radians(this._angle));
		context.stroke(this.turtleShape);
		context.restore();


		//context.stroke();
		this.redrawEverything();
	}

	speed(speed: number) {
		this._speed = speed;
	}




	// http://cwestblog.com/2012/11/12/javascript-degree-and-radian-conversion/
	// Converts from degrees to radians.
	radians = function(degrees) {
		return degrees * Math.PI / 180;
	};

	// Converts from radians to degrees.
	degrees = function(radians) {
		return radians * 180 / Math.PI;
	};





}
