import { Component, Input, Output, EventEmitter, ViewChild, ElementRef, ViewEncapsulation } from '@angular/core';
//import { SafeHtmlPipe, SafeStylePipe } from '../../epic-core/pipes/safe-html.pipe';

@Component({
	selector: 'app-bs-terminal',
	templateUrl: './bs-terminal.component.html',
	styleUrls: ['./bs-terminal.component.css'],
	encapsulation: ViewEncapsulation.None // needed for tooltips... this may break stuff...
})
export class BsTerminalComponent {

	text: string = '';
	userInput: string = 'HI';

	@Input() textSize: string;

	zoomOverflow = 'scroll';
	zoomMaxHeight = 'calc(100vh - 280px)';

	@Output() onTerminalInput = new EventEmitter();
	@ViewChild('userInputThing', { static: true }) userInputThing: ElementRef;
	@ViewChild('terminalTextThing', { static: true }) terminalTextThing: ElementRef;

	constructor() { }

	public clearLastLine() {
		const leol = this.text.lastIndexOf('\r\n');
		if (leol >= 0) {
			this.text = this.text.substr(0,leol + 1);
		}
		else {
			this.text = '';
		}
	}

	public addText(text: string) {

		if (text === '\u001b[{2}J') {
			this.clearLastLine();
			return;
		}

		this.text += text;
		const me = this;
		setTimeout(function () {
			me.userInputThing.nativeElement.scrollIntoView(true);
		}, 100);
	}

	public clear() {
		this.text = '';
	}

	public keyupEnter(event) {
		if (event.keyCode === 13) { // 13 = Enter
			this.userInput = this.userInputThing.nativeElement.innerText; // .innerHTML;
			console.log('Content innerText before: [' + this.userInput + ']');
			this.userInput = this.userInput.replace(/\n/g, '');
			console.log('Content after: [' + this.userInput + ']');
			const t = this.userInput;
			this.userInput = '';
			this.userInputThing.nativeElement.innerHTML = '';
			this.onTerminalInput.emit(t);
		}
	}

	public keypress(event) {
		//console.log(event, event.keyCode, event.keyIdentifier);
	}

	termFocusFunction() {
		this.userInputThing.nativeElement.focus();
	}
	termClickFunction() {
		this.termFocusFunction();
	}

	public focus() {
		this.termFocusFunction();
	}

	zoom() {
		if (this.zoomOverflow === 'scroll') {
			this.zoomOverflow = 'overlay';
			this.zoomMaxHeight = '';
		}
		else {
			this.zoomOverflow = 'scroll';
			this.zoomMaxHeight = 'calc(100vh - 280px)';
		}
		console.log('zoom: ' + this.zoomOverflow);
	}

	@Input() runButtonEnabled: boolean = true;

	@Output() onRun = new EventEmitter();
	runClicked() {
		this.onRun.emit(this.selectedRunType);
	}

	_showPrompt: boolean = false;
	@Input() set showPrompt(v: boolean) {
		//console.log('Show prompt: ' + v);
		const me = this;
		if (v) {
			me._showPrompt = v;
			setTimeout(() => {
				me.userInputThing.nativeElement.focus();
			}, 50);

		}
		else {
			me.userInputThing.nativeElement.blur();
			setTimeout(() => {
				me._showPrompt = v;
			}, 500);
		}
	}

	@Input() selectedRunType;
}
