import { Injectable } from '@angular/core';
import * as firebase from 'firebase/app';
import { isNullOrUndefined, isNull } from 'util';
import { NotImplementedException } from '../exceptions/NotImplementedException';
import { IUser } from '../interfaces/IUser';
import { BsUtilsService } from './bs-utils.service';

import {Router} from '@angular/router';

// tslint:disable-next-line:no-any
type FirebaseListener = (a: firebase.database.DataSnapshot | null, b?: string) => any;
type IUserArray = Array<IUser>;

@Injectable()
export class UserRepository {
	private _db: firebase.database.Reference;
	private _currentUser: IUser;

	constructor(
		private bsUtilsService: BsUtilsService,
		private router: Router
	) {
		this._db = firebase.database().ref().child('/users');


	}

	createUser = async (firebaseUser: firebase.User): Promise<IUser> => {
		const user = {
			creation: firebase.database.ServerValue.TIMESTAMP,
			dbkey: BsUtilsService.z_filterEmailAsKey(firebaseUser.email),
			email: firebaseUser.email,
			firstName: BsUtilsService.firstName(firebaseUser.displayName),
			groups: { user: 1 },
			isAnonymous: firebaseUser.isAnonymous,
			isOnline: true,
			lastName: BsUtilsService.lastName(firebaseUser.displayName),
			name: firebaseUser.displayName,
			photoURL: firebaseUser.photoURL,
			provider: firebaseUser.providerData ? firebaseUser.providerData[0] : null,
			status: 'online',
			timestamp: firebase.database.ServerValue.TIMESTAMP,
			uid: firebaseUser.uid,
			what: null,
			where: null
		};

		await this._db.child(user.uid).set(user);
		return user;
	}


	getCurrentUser = (onUser: (IUser) => void): firebase.Unsubscribe => {

		return firebase.auth().onAuthStateChanged((user) => {
			if (user) {
				this.getUser(user.uid).then((currentUser) => {
					if (!isNullOrUndefined(currentUser)) {
						this._currentUser = currentUser;

						if (user) {
							console.log('Debug user thing here');
							let photoURL = user.photoURL;
							if (user.providerData && user.providerData[0]) {
								photoURL = user.providerData[0].photoURL || user.photoURL;
							}
							if ((photoURL !== this._currentUser.photoURL)
							|| (user.displayName !== this._currentUser.name)) {
								this._currentUser.name = user.displayName;
								this._currentUser.photoURL = photoURL;
								this.updateUser(this._currentUser);
							}
						}
						onUser(currentUser);
					} else {
						this.createUser(user).then((createdUser) => {
							this.getUser(createdUser.uid).then((retrievedUser) => {
								this._currentUser = retrievedUser;
								onUser(retrievedUser);
							});
						});
					}
				});
			} else {
				this._currentUser = null;
				onUser(null);
			}
		});


	}

	unsubscribeCurrentUser = (authUnsubscribe: firebase.Unsubscribe) => {
		if (	authUnsubscribe) {
			authUnsubscribe();
		}
	}


	getUser = async (uid: string): Promise<IUser> => {
		const snapshot: firebase.database.DataSnapshot = await this._db.child(uid).once('value');
		if (isNullOrUndefined(snapshot.val())) {
			return null;
		}
		return {
			creation: snapshot.val().creation,
			dbkey: snapshot.val().dbkey,
			email: snapshot.val().email,
			firstName: snapshot.val().firstName || BsUtilsService.firstName(snapshot.val().name),
			groups: snapshot.val().groups,
			isAnonymous: snapshot.val().isAnonymous,
			isOnline: snapshot.val().isOnline,
			lastName: snapshot.val().lastName || BsUtilsService.lastName(snapshot.val().name),
			name: snapshot.val().name, // snapshot.val().firstName + ' ' + snapshot.val().lastName,
			//photoURL: !isNullOrUndefined(snapshot.val().photoUrl) ? snapshot.val().photoUrl : null,
			photoURL: snapshot.val().photoURL || snapshot.val().provider.photoURL || null,
			provider: snapshot.val().provider,
			status: snapshot.val().status,
			timestamp: snapshot.val().timestamp,
			uid: snapshot.val().uid,
			what: snapshot.val().what,
			where: snapshot.val().where,
			alias: snapshot.val().alias
		};
	}

	// tslint:disable-next-line:no-any
	private rowToIUser(row: any): IUser {
		return {
			creation: row.creation,
			dbkey: row.dbkey,
			email: row.email,
			firstName: row.firstName,
			groups: row.groups,
			isAnonymous: row.isAnonymous,
			isOnline: row.isOnline,
			lastName: row.lastName,
			name: row.firstName + ' ' + row.lastName,
			photoURL: row.photoUrl,
			provider: row.provider,
			status: row.status,
			timestamp: row.timestamp,
			uid: row.uid,
			what: row.what,
			where: row.where,
			alias: row.alias
		};
	}

	getUsers = async (): Promise<Array<IUser>> => {
		const users = Array<IUser>();
		const aggregate: firebase.database.DataSnapshot = await this._db.orderByChild('lastName').once('value');
		const numberOfUsers = aggregate.numChildren();

		aggregate.forEach((snapshot) => {
			if (users.length === numberOfUsers) {
				return true;
			}
			else {
				users.push( this.rowToIUser(snapshot.val()));
			}
		});
		return users;
	}


	watchUsers = (onUsers: (IUserArray) => void): FirebaseListener => { //?
		const listener = this._db.orderByChild('lastName')
		.on('value', (snapshot) => {
			const userRows = snapshot.val();
			const numberOfUsers = snapshot.numChildren();
			const users = Array<IUser>();
			snapshot.forEach( (childSnap) => {
				users.push( this.rowToIUser(childSnap.val()));
				return users.length === numberOfUsers;
			});
			onUsers(users);
		});
		return listener;
	}

	unsubscribeUsers(listener: FirebaseListener) {
		this._db.off('value', listener);
	}

	updateUser = async (user: IUser): Promise<IUser> => {
		user = {
			creation: user.creation ? user.creation : null,
			dbkey: user.dbkey ? user.dbkey : null,
			email: user.email ? user.email : null,
			firstName: user.firstName ? user.firstName : null,
			groups: user.groups ? user.groups : null,
			isAnonymous: user.isAnonymous ? user.isAnonymous : null,
			isOnline: user.isOnline ? user.isOnline : null,
			lastName: user.lastName ? user.lastName : null,
			name: user.name ? user.name : null,
			photoURL: user.photoURL ? user.photoURL : null,
			provider: user.provider ? user.provider : null,
			status: user.status ? user.status : null,
			timestamp: user.timestamp ? user.timestamp : null,
			uid: user.uid ? user.uid : null,
			what: user.what ? user.what : null,
			where: user.where ? user.where : null
		};

		await this._db.child(user.uid).update(user);
		return this.getUser(user.uid);
	}

	deleteUser = async (uid: string): Promise<IUser> => {
		const user = this.getUser(uid);
		await this._db.child(uid).remove();
		return user;
	}

	login = async (): Promise<void> => {
		// https://github.com/firebase/firebaseui-web/issues/61
		let provider = new firebase.auth.GoogleAuthProvider();
			provider.setCustomParameters({
			prompt: 'select_account'
		});
		firebase.auth().signInWithPopup(provider)
		//firebase.auth().signInWithPopup(new firebase.auth.GoogleAuthProvider());
	}

	logout = async (flag=false): Promise<void> => {
		const currentUser: IUser = this._currentUser;
		currentUser.isOnline = false;
		currentUser.what = null;
		currentUser.where = null;

		this.updateUser(currentUser).then(async () => {

			const  delayFor = async (timeout: number): Promise<boolean> => {
				console.log('Delaying for: ' + timeout);
				const p = new Promise<boolean>( (resolve, reject) => {
					setTimeout(() => {
						console.log('Done delaying');
						resolve(true);
					}, timeout);
				});

				return p;
			}
			if (!flag) {
				console.log('Logging out...');
				await delayFor(0);
				const param = 'Logging Out...';
				this.router.navigate(['/wait-msg/', param]);
			} else {
				console.log('Logging out...');
				await delayFor(0);
				const param = 'Logging Out...';
				await this.router.navigateByUrl('/login');
			}

			await delayFor(750);
			await firebase.auth().signOut();

			// wish i could clear browser history here
			// but i guess it is ok, since most pages have guards...

			await this.router.navigateByUrl('/');
			await delayFor(0);

			console.log('Done logging out.');
		});
	}

	// --



}
