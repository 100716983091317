import { BsUtilsService } from '../../services/bs-utils.service';

export interface IBsUser {
	creation: object;
	email: string;
	isAnonymous: boolean;
	isAdmin: boolean;
	name: string;
	firstName: string;
	lastName: string;
	provider?: any;
	photoURL: string;
	status: string;
	timestamp: any;
	groups: any;
	uid: string;
	dbkey?: string; // also a hack
	grades?: any; // hack
	type?: string;
	note?: string;
	section?: string;
}

export class BsUser {
	public _data: any;
	
	get _key() { 
		return this._data.uid; 
	}  // $key
	get uid() { 
		return this._data.uid; 
	}

	get dbKey(): string {
		return BsUtilsService.z_filterEmailAsKey(this.email);
	}

	get creation() { 
		return this._data.creation; 
	}
	get email(): string { 
		return this._data.email || 'unknown@none.com'; 
	}
	get isAnonymous() { 
		return this._data.isAnonymous; 
	}

	public get isAdmin() {
		if (!this._data.groups) {
			return false;
		}
		return !!this._data.groups.admin;
	}

	public isReady: boolean;  // is this real data, ready to go?  used when loading...

	get name(): string { 
		return this._data.name || (this._key + ' (Anonymous)'); 
	}
	get firstName(): string {
		return this._data.firstName || BsUtilsService.firstName(this.name);
	}
	get lastName(): string {
		return this._data.lastName || BsUtilsService.lastName(this.name);
	}
	get lfName(): string {
		return this.lastName + ', ' + this.firstName;
	}


	get provider() { 
		return this._data.provider; }

	get photoURL(): string { 
		return this._data.photoURL; 
	}
	get urlPhotoURL(): string {
		if (this._data.photoURL) {
			return 'url(' + this._data.photoURL + ')';
		}
		return undefined;
	}
	set photoURL(u: string) { 
		this._data.photoURL = u; 
	}

	get status(): string { 
		return this._data.status; 
	}
	set status(s: string) { 
		this._data.status = s; 
	}

	get timestamp() { 
		return this._data.timestamp; 
	}
	set timestamp(t) { 
		this._data.timestamp = t; 
	}

	get type(): string { 
		return this._data.type; 
	}
	set type(t: string) { 
		this._data.type = t; 
	}

	get note(): string { 
		return this._data.note; 
	}
	set note(t: string) { 
		this._data.note = t; 
	}

	get section(): string { 
		return this._data.section; 
	}
	set section(t: string) { 
		this._data.section = t; 
	}


	get what(): string { 
		return this._data.what; 
	}
	get where(): string { 
		return this._data.where; 
	}


}
