interface ZCanvasCmd {
	cmd: string,
	param1: any,
	param2: any,
	param3?: any
}

export class ZCanvasCmdLayer {
	cmds: Array<ZCanvasCmd> = [];

	constructor(private context: CanvasRenderingContext2D) {
	}

	addCmd(cmd: string, param1: any='', param2: any='', param3?: any) {
		this.cmds.push({cmd: cmd, param1: param1, param2: param2, param3: param3});
	}

	clearCmds() {
		this.cmds = [];
	}

	draw() {
		this.parseCmds(this.context, this.cmds);
	}

	parseCmds(context: CanvasRenderingContext2D, cmds: Array<ZCanvasCmd>) {
		this.context.save();
		for (const cmd of cmds) {
			this.parseCmd(context, cmd);
		}
		this.context.restore();
	  }

	parseCmd(context: CanvasRenderingContext2D, cmd: ZCanvasCmd) {
		if (cmd.cmd === 'moveTo'){
			context.moveTo(cmd.param1, cmd.param2);
		}
		else if (cmd.cmd === 'lineTo'){
			context.lineTo(cmd.param1, cmd.param2);
		}
		else if (cmd.cmd === 'strokeStyle'){
			context.strokeStyle =cmd.param1;
		}
		else if (cmd.cmd === 'stroke'){
			if (cmd.param1) {
				context.stroke(cmd.param1);
			}
			else {
				context.stroke();
			}
		}
		else if (cmd.cmd === 'fillStyle'){
			context.fillStyle =cmd.param1;
		}
		else if (cmd.cmd === 'fill'){
			context.fill();
		}
		else if (cmd.cmd === 'lineWidth'){
			context.lineWidth = cmd.param1;
		}
		else if (cmd.cmd === 'beginPath'){
			context.beginPath();
		}
		else if (cmd.cmd === 'closePath'){
			context.closePath();
		}
		else if (cmd.cmd === 'save'){
			context.save();
		}
		else if (cmd.cmd === 'restore'){
			context.restore();
		}
		else if (cmd.cmd === 'translate'){
			context.translate(+cmd.param1, +cmd.param2);
		}
		else if (cmd.cmd === 'rotate'){
			context.rotate(+cmd.param1);
		}
		else if (cmd.cmd === 'circle'){  // not a standard
			const x = +cmd.param1;
			const y = +cmd.param2;
			const r = +cmd.param3;
			context.arc(x,y,r,0,2*Math.PI);
			context.stroke();
		}

	}

	moveTo(x: number, y: number) {
		this.addCmd('moveTo',x,y);
	}

	lineTo(x: number, y: number) {
		this.addCmd('lineTo',x,y);
	}

	set strokeStyle(style: string) {     // TODO: this can take other types
		this.addCmd('strokeStyle', style);
	}

	stroke(path?: Path2D) {
		this.addCmd('stroke', path);
	}

	set fillStyle(fill: string) {     // TODO: this can take other types
		this.addCmd('fillStyle', fill);
	}

	fill() {
		this.addCmd('fill');
	}

	set lineWidth(width: number) {     // TODO: this can take other types
		this.addCmd('lineWidth', width);
	}

	beginPath() {
		this.addCmd('beginPath');
	}

	closePath() {
		this.addCmd('closePath');
	}

	save() {
		this.addCmd('save');
	}

	restore() {
		this.addCmd('restore');
	}

	translate(x:number, y:number) {
		this.addCmd('translate',x,y);
	}

	rotate(angle: number) {
		this.addCmd('rotate', angle);
	}

	circle(x: number, y: number, r: number) {
		this.addCmd('circle', x, y, r);
	}
}

export class ZLayeredCmdCanvas {
	context: CanvasRenderingContext2D;
	layers: Array< ZCanvasCmdLayer >  = [];
	width: number;
	height: number;


	constructor(private canvas: HTMLCanvasElement) {
		this.context = canvas.getContext("2d");
		this.width = canvas.width;
		this.height = canvas.height;

		//this.newLayer();
	}

	addCanvasCmd(layerNum: number, cmd: string, param1:any='', param2:any='') {

		if (layerNum >= this.layers.length) {
			console.error('No such layer');
			return;
		}
		const layer = this.layers[layerNum];

		layer.addCmd(cmd, param1, param2);
	}

	layerDict = {};

	getLayer(layerName: string): ZCanvasCmdLayer {
		if (this.layerDict[layerName]) {
			return this.layerDict[layerName] as ZCanvasCmdLayer
		}
		const layer = new ZCanvasCmdLayer(this.context)
		this.layers.push( layer );
		this.layerDict = layer;
		return layer;
	}

	clearAll() {
		for (const layer of this.layers) {
			layer.clearCmds();
		}
	}

	/*
	clearLayer(layerNum: number) {

		if (layerNum >= this.layers.length) {
			console.error('No such layer');
			return;
		}

		this.layers[layerNum].clearCmds();
	}
	*/

	/*
	getLayer(layerNum: number): ZCanvasCmdLayer {

		if (layerNum >= this.layers.length) {
			console.error('No such layer');
			return;
		}

		return this.layers[layerNum];
	}
	*/

	redraw() {
		this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);

		for(const layer of this.layers) {
			layer.draw();
		}
	}
}
