import { Component, OnInit, Input, ChangeDetectorRef, ViewChild } from '@angular/core';

import {IProjectInfo, IAssignment} from "@cf-platform/cf-core-cms";


import { BsUserProjectsService } from '@cf-platform/cf-core-cms-ng';//'../../services/bs-user-projects.service';
import { IFolderItem } from '@cf-platform/cf-core-cms';//'../../services/bs-user-projects.service';

import { BsAssignmentsService } from '../../services/bs-assignments.service';

//import { IFolderItem } from '../../../../../apps/web-client/src/app/cms-core/interfaces/IFolderItem';
import { IMyAssignmentStats } from '../../interfaces/IMyAssignmentStats';
import { BsAssignmentTesterService } from '../../services/bs-assignment-tester.service';

//import * as firebase from 'firebase/app';
//import { CFMsgSysClient } from '@cf-platform/cf-shared-msg-sys';


@Component({
	selector: 'app-my-assignment-detail',
	templateUrl: './my-assignment-detail.component.html',
	styleUrls: ['./my-assignment-detail.component.css']
})
export class MyAssignmentDetailComponent implements OnInit {

	@Input() currentUserEmail: string;
	@Input() classId: string;
	//@Input() assignmentId: string;
	@Input() item: IFolderItem;

	assignment: IAssignment;
	project: IProjectInfo;
	isAssignmentLoaded = false;
	hasStartedAssignment = false;

	stats: IMyAssignmentStats;

	myStat = 'loading...';

	@ViewChild('startAssignmentButton', { static: false }) startAssignmentButton: HTMLButtonElement;

	//msgSys = CFMsgSysClient.getInstance(firebase.database(), firebase.database.ServerValue.TIMESTAMP, firebase.auth());


	constructor(
		private assignmentsService: BsAssignmentsService,
		private projectService: BsUserProjectsService,
		private cdRef: ChangeDetectorRef,
		private tester: BsAssignmentTesterService
	) { }

	ngOnInit() {
		setTimeout(() => {
			this.loadAssignmentInfo();
		}, 500);
	}

	async loadAssignmentInfo() {

		const nAssignment = await this.assignmentsService.getAssignment(this.classId, this.item.assignmentId);

		/*
		if (this.assignment && this.assignment.__key == nAssignment.__key)
		{
			// a hack till we move the submitted stuff somewhere else...
			this.assignment.started = nAssignment.started;
			this.assignment.submitted = nAssignment.submitted;
			this.assignment.graded = nAssignment.graded;

			this.cdRef.detectChanges();
			console.log("Ignoring minor assignment changes...");
			return;
		}
		*/




		this.assignment = nAssignment;

		//this.assignment = await this.assignmentsService.getAssignment(this.classId, this.item.assignmentId);
		if (!this.assignment) {
			return;
		}
		/*
		if (this.assignment.started) {
			this.startedCount = Object.keys(this.assignment.started).length;
		}
		if (this.assignment.submitted) {
			if (this.assignment.submitted) {
				this.submittedCount = Object.keys(this.assignment.submitted).length;
			}
			if (this.assignment.graded) {
				this.gradedCount = Object.keys(this.assignment.graded).length;
			}
		}
		*/
		const projPath = this.classId + ':' + this.item.assignmentId;
		const projectUrl = '/my/projects/' + projPath;
		const aName: string = this.assignment.project.name;
		if (!aName.startsWith(projPath)) {
			this.assignment.project.name = projPath + ': ' + aName;
		}
		this.assignment.projPath = projPath;  // this prob needs to be done somewhere else...
		this.project = await this.projectService.loadUserProject(this.currentUserEmail, projPath);


		this.hasStartedAssignment = !!this.project;
		this.isAssignmentLoaded = true;


		this.refreshStats();
	}

	refreshStats() {
		// This does a few lookups so we do it in the backgorund...
		setTimeout(async () => {
			this.stats = await this.assignmentsService.getUserStatsForAssignment(this.currentUserEmail, this.assignment, this.project);
			this.myStat = this.stats.myStat;
			this.cdRef.detectChanges();
		}, 100);
	}



	async startAssignmentClicked() {
		console.log('startAssignmentClicked');
		this.startAssignmentButton.disabled = true;

		if (!!this.assignment && !!this.assignment.project && !this.hasStartedAssignment) {
			await this.projectService.addProject(this.currentUserEmail, this.assignment.project, this.assignment.projPath);
			const p = await this.projectService.loadUserProject(this.currentUserEmail, this.assignment.projPath);
			this.assignmentsService.markStarted(this.classId, this.item.assignmentId, this.currentUserEmail);

			console.log('Assignment project loaded...');
			this.project = p;
			this.hasStartedAssignment = !!this.project;
			console.log('Assignment project refreshed...');

			this.refreshStats();
		}
	}

	/*
	async doMsgThing() {
		zzz
		setTimeout(async () => {
			await this.msgSys.waitTillReady()


			const ser = await this.msgSys.findProvider('AutoGrade');
			if (ser) {
				await this.msgSys.sendMsg(ser, 'AutoGrade', 'Whats up?');
			}

		}, 1000);
	}
	*/

	async submitAssignmentClicked() {
		console.log('submitAssignmentClicked');
		let m = 'submit';
		if (this.stats && this.stats.hasSubmittedAssignment) {
			m = 'RE-Submit';
		}
		if (!confirm('Are you sure you want to ' + m + ' this assignment?')) {
			console.log('Submit canceled');
			return;
		}

		if (!!this.assignment && !!this.assignment.project && this.hasStartedAssignment) {
			const p = await this.projectService.getProjectCopy(this.currentUserEmail, this.assignment.projPath);

			await this.assignmentsService.submit(this.classId, this.assignment, this.currentUserEmail, p);

			// this.hasSubmittedAssignment = true;
			this.refreshStats();

			console.log('Assignment project submitted...');

			//this.doMsgThing();
		}
	}

}
