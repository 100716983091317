import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';


import { CfGuiCoreNgModule } from '@cf-platform/cf-gui-core-ng';


import { BsTerminalComponent } from './bs-terminal/bs-terminal.component';
import { BsTerminalWrapComponent } from './bs-terminal-wrap/bs-terminal-wrap.component';
import { ZbWebFrameComponent } from './zb-web-frame/zb-web-frame.component';

import { CfTurtleNgModule } from '@cf-platform/cf-turtle-ng'



@NgModule({
	imports: [
		CommonModule,
		CfGuiCoreNgModule,
		CfTurtleNgModule

	],
	declarations: [
		BsTerminalComponent,
		BsTerminalWrapComponent,
		ZbWebFrameComponent

	],
	exports: [
		BsTerminalComponent,
		BsTerminalWrapComponent,
		ZbWebFrameComponent
	]
})
export class TerminalCoreModule { }
