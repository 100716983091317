import { Component, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { Observable } from 'rxjs/Observable';
//import '../../epic-core/pipes/safe-html.pipe';
//import { BsClass } from '../bs-class';
//import { BsClassesUsersService } from '../../services/bs-classes-users.service';
import { SharedModule } from 'primeng/primeng';
//import { HttpClient} from '@angular/common/http'
import { Router } from '@angular/router'

import { IUser } from '@cf-platform/cf-core-cms';
//import { UserRepository } from '../../epic-core/services/userRepository'


// Interfaces for Internal Use Only ??
export interface CFTableCol {
	header: string;
	field: string;
	width: string;
	link?: string;
	html?: boolean;
	cType?: string;
}

export interface CFRow {
	__id: string;
	__desc: string;
	__click: () => void;
}


//

@Component({
  selector: 'cf-table',
  templateUrl: './cf-table.component.html',
  styleUrls: ['./cf-table.component.css']
})
export class CFTableComponent { // implements OnInit {

	_selectedRows: Array<CFRow> = [];
	allRows: Array<CFRow> = [];
	_rowsList: Observable<CFRow[]>;

	_columns: Array<CFTableCol> = [];

	globalFilterFields: Array<string> = [];

	_height  = "calc(100vh - 200px)";
	rowSub: any;

	set selectedRows (rows: Array<CFRow>) {
		this._selectedRows = rows;
		this.selectedRowsChange.emit(this._selectedRows);
	};
	get selectedRows(): Array<CFRow> {
		return this._selectedRows;
	}


	@Output() selectedRowsChange = new EventEmitter<Array<CFRow>>();

	@Input() title = 'CFTable';
	@Input() set height(height:string) {
		if (height === "short") {
			this._height = "calc(60vh - 200px)";
		}
	}

	zoom = false;
	doZoom() {
		if (!this.zoom) {
			this._height = '400vh';
			this.zoom = true;
			this.cdRef.detectChanges();
		} else {
			this.height = 'small'
			this._height = "calc(60vh - 200px)";
			this.zoom = false
		}
	}

	@Input() set rowsList(rList: Observable<CFRow[]>) {
		console.log('cf-table rowsList was set');
		this._rowsList = rList;

		if (this.rowSub) {
			this.rowSub.unsubscribe();  // todo add on destroy unsub
		}
		if (!rList) {
			console.log('cf-table RowsList was empty');
			this.allRows = [];
			return;
		}
		console.log('cf-users-table getting sub');
		this.rowSub = this._rowsList.subscribe(rows => {
			console.log('cf-table got sub');
			setTimeout(() => {
				if (!rows) {
					this.allRows = [];
				}
				else {
					this.allRows = rows; //.map(this.uMapper).sort( (a,b) => (a._name > b._name) ? 1 : -1);
				}
				try {
					this.cdRef.detectChanges();
				} catch (error) {

				}

			}, 5);

		});

	}

	@Input() set columns(cols) {
		console.log('cfTable Debug set columns here');
		console.log(cols);
		this._columns = cols;
		this.updateSearchField();
		this.cdRef.detectChanges();
	}

	updateSearchField() {
		this.globalFilterFields = [];
		for (const col of this._columns) {
			this.globalFilterFields.push(col.field);
		}
		console.log('GLobalFilterFields: ', this.globalFilterFields.join(', '));
	}



	doClick(u) {
		console.log('doClick');
		if (u && u.__click) {
			console.log('Try click...');
			u.__click();
		}
		else if (u && u.__link) {
			this.router.navigateByUrl(u.__link);
		}
	}







	constructor(
		//private classesUsersService: BsClassesUsersService,
		//private httpClient: HttpClient,
		//private userRepository: UserRepository
		private cdRef: ChangeDetectorRef,
		private router: Router
		)
	{

			this.updateSearchField();

	}

	/*
	doClick(row: CFRow) {
		if (row && row.__click) {
			row.__click();
		}
	}
	*/


	// TODO destroy subscription!!!!

}


//

