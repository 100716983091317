import { Injectable, ComponentFactory, ComponentFactoryResolver, ViewContainerRef, ComponentRef, EventEmitter } from '@angular/core';
import { IFolderItem } from '../../../interfaces/IFolderItem';
import { ItemDefaultViewDetailComponent } from './item-default-view-detail/item-default-view-detail.component';
import { ItemDefaultViewComponent } from './item-default-view/item-default-view.component';
import { ItemDefaultViewSummaryComponent } from './item-default-view-summary/item-default-view-summary.component';


export enum ViewTypeE {'summary', 'detail', 'editor', 'new', 'custom'}

export interface ViewThing {
	factory: any;
	inputs: any;
	outputs: any;
}


interface ViewList {
	summary?: ViewThing;
	detail?: ViewThing;
	editor?: ViewThing;
	new?: ViewThing;
}

@Injectable()
export class ItemViewFactoryService {

	itemFactoryDict: {[index: string]: ViewList};

	defaultThing: ViewThing;
	defaultSummaryThing: ViewThing;
	defaultViews: ViewList;

	constructor(private resolver: ComponentFactoryResolver) {
		this.createDefaultThing();
		this.createDefaultViews();
		this.itemFactoryDict = {};
	}


	createDefaultThing() {

		const det_factory: ComponentFactory<ItemDefaultViewDetailComponent> = this.resolver.resolveComponentFactory(ItemDefaultViewDetailComponent);
		const inputs = []; // {item: f, assignmentId: f.assignmentId, classId: this.classId, currentUserEmail: this.currentUser.email };
		const outputs = [];

		this.defaultThing = { factory: det_factory, inputs: inputs, outputs: outputs };

		const sum_factory: ComponentFactory<ItemDefaultViewSummaryComponent> = this.resolver.resolveComponentFactory(ItemDefaultViewSummaryComponent);
		this.defaultSummaryThing = { factory: sum_factory, inputs: inputs, outputs: outputs };

	}

	createDefaultViews() {
		this.defaultViews = {detail: this.defaultThing, summary: this.defaultSummaryThing};
	}



	registerItemType(itemType: string, viewType: ViewTypeE, viewThing: ViewThing) {
		if (!this.itemFactoryDict[itemType]) {
			const vl: ViewList = {};
			this.itemFactoryDict[itemType] = vl;
		}
		this.itemFactoryDict[itemType][viewType] = viewThing;
	}


	// https://netbasal.com/dynamically-creating-components-with-angular-a7346f4a982d

	async constructThingInContainer(item: IFolderItem, vt: ViewThing, container: ViewContainerRef): Promise<ComponentRef<ItemDefaultViewComponent>> {

		const prom = new Promise<ComponentRef<ItemDefaultViewComponent>>((resolve,reject) => {

		let componentRef: ComponentRef<ItemDefaultViewComponent>;
		if (vt && vt.factory) {
			setTimeout(() => {  // this has to be done in the next event cycle
				container.clear();
				// console.log('creating view for: ' + this._item.name );

				componentRef = container.createComponent(vt.factory);
				componentRef.instance.item = item;
				// zzz  inject the inputs here
				for (const k of Object.keys(vt.inputs)) {
					const v = vt.inputs[k];
					componentRef.instance[k] = v;
				}

				for (const k of Object.keys(vt.outputs)) {
					const v = vt.outputs[k];
					const em = componentRef.instance[k] as EventEmitter<any>;
					if (em) {
						console.log('Hooking up eventemitter: ' + k);
						em.subscribe( (e) => v(e));
						// TODO: find some place to unsubscibe this thing in the future
					}
					else {
						console.log('Error: Unable to hook eventemitter: ' + k);
					}
				}

				resolve(componentRef);
			});
		}
		else {
			reject('ViewThing or ViewThing.factory is missing.  Dont know why');
		}
	});
	return prom;

	}

	createViewFor(item: IFolderItem, viewType: ViewTypeE, container) {
		const itemType = item.type;
		let vt = this.defaultThing;


		if (!this.itemFactoryDict[itemType]) {
			if (this.defaultViews[ViewTypeE[viewType]]) {
				vt = this.defaultViews[ViewTypeE[viewType]];
			}
			else {
				vt = this.defaultThing; //  this.missingThing;
			}
		}
		else {
			vt = this.itemFactoryDict[itemType][viewType];
			if (!vt) {
				vt = this.defaultThing;
			}
		}



		return this.constructThingInContainer(item, vt, container);
	}

	/*
	createSummaryViewFor(itemType: string);
	createDetailViewFor(itemType: string);
	createEditorFor(itemType: string);
	createNewIteamDialogFor(itemType: string);
	*/

}
