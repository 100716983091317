import { Component, Input, ViewChild } from '@angular/core';
import { TerminalModule } from 'primeng/primeng';
import { BsTerminalComponent } from '../bs-terminal/bs-terminal.component';
//import { BsCioServerManagerService } from '../../services/bs-cio-server-manager.service';
import { CFServerManager } from '@cf-platform/cf-shared-code-runner';
//import { CFMsgSysClient } from '@cf-platform/cf-shared-msg-sys';
import { CFLinter, CFAutoGrader } from '@cf-platform/cf-class-stuff';

import { BsLoggerService } from '@cf-platform/cf-log';
import { iBsAssignmentTesterService } from '@cf-platform/cf-core-cms'; //../../services/bs-assignment-tester.service';
import { IGraderTest, BsProjectC } from '@cf-platform/cf-core-cms'; //../../epic-core/interfaces/IGraderTest';

// TODO: test tester being sent as an input instead of injected...

import * as firebase from 'firebase/app';


@Component({
	selector: 'app-bs-terminal-wrap',
	templateUrl: './bs-terminal-wrap.component.html',
	styleUrls: ['./bs-terminal-wrap.component.css']
})
export class BsTerminalWrapComponent {

	@ViewChild('terminal', { static: false }) terminal: BsTerminalComponent;

	runButtonEnabled: boolean = true;
	stopButtonEnabled: boolean = false;
	showPrompt: boolean = false;

	showTurtleFlag = false;

	server = CFServerManager.theManager();


	zAutoGrader = CFAutoGrader.getInstance();
	zLinter = CFLinter.getInstance();

	//private tester: iBsAssignmentTesterService;

	constructor(
		//private server: BsCioServerManagerService,
		private logger: BsLoggerService,
		//private tester: iBsAssignmentTesterService,
		//private zLinter: ZLinterService,
		//private zAutoGrader: ZAutoGraderService
		) {

			// todo. move this
			//this.doMsgReg();

		}

	public response: string = '';

	onCommand(event) {
		// in a real application connect to remote service to process the command and update the response field in return
		this.response = 'Unknown command: ' + event.command;
	}

	@Input() project: BsProjectC;

	@Input() tester: iBsAssignmentTesterService;

	interactiveRT: IGraderTest = { name: 'Interactive', type: 'interactive' };
	selectedRunType: IGraderTest = this.interactiveRT;

	runStdIn: IGraderTest = { name: 'Run < std_in.txt', type: 'std_in' };

	_runTests: Array<IGraderTest> = [this.interactiveRT, this.runStdIn];
	_runTestsIncoming: Array<IGraderTest>;
	@Input() set runTests(rt: Array<IGraderTest>) {
		console.log('bs-terminal set runTests');
		this._runTestsIncoming = rt;
		this.setupRunTestsMenu();
	}

	setupRunTestsMenu() {
		this._runTests = [];

		if (!this._runTestsIncoming) {
			return;
		}
		const rt = this._runTestsIncoming;

		console.log('debug std_in menu here');
		this._runTests = [this.interactiveRT]; // array
		if (this.files) {
			for(let i=0; i<this.files.length; i++) { // todo: later redo this to allow any .txt file input
				if (this.files[i].name === 'std_in.txt'){
					this._runTests = this._runTests.concat(this.runStdIn);
					break;
				}
			}
		}
		this._runTests = this._runTests.concat(rt);
		//this._runTests = this._runTests.concat( { name: '----', type: 'break' });

		if (this.project && this.project.projType && this.project.projType.toLowerCase().includes('c')) {
			this._runTests = this._runTests.concat( { name: '*Code Formatting', type: 'zlint'});
		}
		if (this._runTestsIncoming && this._runTestsIncoming.length > 0) {
			this._runTests = this._runTests.concat( { name: '**Grade All', type: 'zAutoGrade'});
		}
	}

	_files;
	@Input() set files(files) {
		this._files = files;
		this.setupRunTestsMenu();
	}
	get files() {
		return this._files;
	}



	@Input() textSize;
	allowHtml: boolean = true;
	first: boolean = false;
	firstOutput: boolean = false;
	firstCompile: boolean = false;
	hasStarted: boolean = false;  // SU19

	noHtml(h: string) {
		console.log('No: ' + h);
		let t: string = h;
		t = t.replace(/\</g, '&lt;');
		t = t.replace(/\>/g, '&gt;');
		console.log('now: ' + t);
		return t;
	}

	printFunc(s: string) {
		this.terminal.addText(s);
	}

	sendFunc(s: string) {
		this.onTerminalInput(s);
	}

	async stopClicked() {
		console.log('Sending STOP command...');
		this.server.sendInteractiveStop();    // TODO... add code for auto grader stop here or there...
	}

	scriptFlag = false;
	scriptSessionId = 0;

	statTimeout;

	std_in_txt = '';
	std_in_txt_sent = false;

	async runClicked(runType: IGraderTest, ) {

		this.showTurtleFlag = false;
		this.scriptFlag = false;
		this.std_in_txt_sent = false;

		if (runType && runType.type && runType.type === 'zlint') {
			console.log('Running code formatting tests: ' + runType.name);
			this.doZLint();
			return;
		}
		else if (runType && runType.type && runType.type === 'zAutoGrade') {
			console.log('Running auto grader: ' + runType.name);
			this.doAutoGrade();
			return;
		}
		else if (runType && runType.type && runType.type === 'std_in') {
			console.log('Running with std_in');

			const files = await this.project.loadFiles();

			for (const fi in files) {
				const f = files[fi];
				if (f.name === 'std_in.txt') {
					this.std_in_txt = f.data + '\n';
				}
			}


		}
		else if (this.tester && runType && runType.type && runType.type !== 'interactive' && runType.type !== 'std_in') {
			console.log('Running with grader script: ' + runType.name);
			this.scriptFlag = true;

			const printF = (s) => {
				this.printFunc(s);
			};
			const sendF = (s) => {
				this.sendFunc(s);
			};
			const gStatElem = document.getElementById('graderStat');
			const statF = (s) => {
				gStatElem.innerHTML = s;
				gStatElem.style.visibility = 'visible';

				if (this.statTimeout) {
					clearTimeout(this.statTimeout);
				}
				this.statTimeout = setTimeout( () => {
					gStatElem.style.visibility = 'hidden';
				}, 5000);
			};
			this.scriptSessionId++;

			this.tester.doTestScript(this.scriptSessionId, runType, printF, sendF, statF);
		}
		else {
			console.log('Running interactively');
		}
		this.realRunClicked();
	}

	async doAutoGrade() {
		this.runButtonEnabled = false;
		this.stopButtonEnabled = true;

		this.terminal.clear();
		this.terminal.addText('Running auto grader...\r\n');

		const projectCopy = await BsProjectC.makeCopy(this.project); // this.project.makeCopy();

		//debugger;

		if (Array.isArray(this.project.files)) {
			// needed for grader screen
			projectCopy.files = this.project.files;
		}



		const printF = async (s: string) => {
			return new Promise((resolve, reject) => {
				setTimeout(() => {
					this.printFunc(s);
					return resolve();
				}, 0);
			});


		};

		await this.zAutoGrader.doAutoGrader(projectCopy,  this._runTests, printF);

		this.runButtonEnabled = true;
		this.stopButtonEnabled = false;
	}

	doZLint() {
		// TODO: most of this code needs to be moved into service...

		this.terminal.clear();
		this.terminal.addText('Running code format tests...\r\n');

		//debugger;
		let fileIndex = 0;
		if (this.project.files[1].name === 'main.cpp') {
			fileIndex = 1;
		}
		const lintResult = this.zLinter.zLintFile(this.project.files[fileIndex].data);
		const errs = lintResult.errors;

		if (errs.length === 0) {
			this.terminal.addText('All formatting tests passed!\r\n');
		}
		else {
			let errorMsg = '<table border=1>';
			//debugger;

			let eCount = 0;
			for (const err of errs) {

				errorMsg += '<tr><td>' + err.errorName + '</td>';
				errorMsg += '<td>' + err.errorDesc + '</td></tr>\r\n';
				eCount ++;

			}
			errorMsg += '</table>\r\n';
			const checksCount = this.zLinter.countRuleChecks();
			const checksPassed = checksCount - eCount;
			errorMsg += '\r\nChecks Passed: ' + checksPassed + '/' + checksCount;
			errorMsg += '    Checks Failed: ' + eCount + '/' + checksCount;
			errorMsg += '    Percent Off: -' + (40 * eCount / checksCount);
			this.terminal.addText('Formatting errors:\r\n' + errorMsg);

			if (lintResult.errors.length > 0) {
				this.terminal.addText('\r\nCODE FORMATTING PROBLEMS\r\n');
				this.terminal.addText(lintResult.markedCode);
			}

		}


	}



	// turtle stuff
	findAndEvalScriptThingys(m:string): string {
		const reg = /\[script\](.*)\[\/script\]/gm;
		//const reg = /script(.*)\/script/;

		//const matches = m.matchAll(reg);
		//const matches = reg.exec(m);
		//console.log('MATCHES: ' + JSON.stringify(matches));
		let result;
		let found = false;
		while ( result = reg.exec(m) ) {
			console.log(result);
			if (result[1]) {
				found = true;
				this.showTurtleFlag = true;
				const script = result[1];
				eval(script); // I know this is very dangerous

			}
		}
		if (found) {
			// remove the script thingys so they don't show in the term
			return m.replace(/\[script\](.*)\[\/script\]\n/gm, '');
		}
		return m;
	}


	realRunClicked() {
		const me = this;
		console.log('Run Clicked');
		me.terminal.clear();
		this.terminal.addText('Run Clicked\r\n');

		if (!this.server.isConnected) {
			this.terminal.addText('Sorry, the terminal server is not connected.\r\n');
			if (this.server.lastError) {
				this.terminal.addText('Last error was: ' + this.server.lastError + '\r\n');
			}
			this.terminal.addText('Try again and if it still doesn\'t work, Let BOB know!\r\n');
			const d = new Date();
			const nt = d.toLocaleTimeString();
			const n = d.toLocaleDateString();
			this.terminal.addText('Time: ' + n + ' ' + nt);
			return;
		}

		this.terminal.focus();
		this.first = true;
		this.firstCompile = true;
		this.hasStarted = false;
		this.firstOutput = true;
		this.runButtonEnabled = false;
		this.stopButtonEnabled = true;
		me.showPrompt = true;
		this.server.currentUser = { uid: 'BBBBBB', email: 'zbobbradley@gmail.com' };
		this.server.doClientJoinAs(this.server.currentUser);
		const projCopy = {files:[], ...this.project }; // shallow copy
		projCopy.files = this.files;
		delete projCopy._files;
		//delete projCopy.ups;
		delete projCopy['ups'];
		//delete projCopy.detail_factory;  // hmmm
		//delete projCopy.detail_inputs;
		delete projCopy['detail_inputs'];
		delete projCopy.relatedAssignment;
		projCopy.type = projCopy.projType;

		this.logger.logCmd('bs-terminal-wrap', 'compile/run', 2, { proj: projCopy });
		this.server.compileAndRunProject(projCopy);

		this.server.onProgramOutput =  (origMsg) => {
			let m = origMsg;

			console.log('HEY onProgramOutput');
			if (m && m.run_stdout && m.run_stdout.includes('[script]')) {
				console.log('WHOOP.  I got a script:[' + m.run_stdout + ']');
				m.run_stdout = this.findAndEvalScriptThingys(m.run_stdout);
			}



			if (m.type === 'run') {

				// bot sends a run / debug message when program is first exectuted.
				if (m.debug && !me.hasStarted) {

					if (me.first) {
						me.terminal.clear();
						me.first = false;
					}

					const style = 'style="background-color: #005500;color: #55FF55;"';
					let message = 'PROGRAM STARTED';
					// me.tester.OnProgramDone();  // This was a bug
					if (me.scriptFlag) {
						message += ' : Automatically testing your code.  Please wait...';
					}
					me.terminal.addText('<span ' + style + '>[ ' + message + ' ]</span>\r\n');
					me.firstOutput = false;
					me.hasStarted = true;
				}

				if (this.std_in_txt !== '' && !this.std_in_txt_sent) {

					this.std_in_txt_sent = true;
					const style1 = 'style="background-color: #005500;color: #55FF55;"';
					const style2 = 'style="color: orange;"';
					me.terminal.addText('<span ' + style1 + '>[  Sending std_in.txt Input ]</span>\r\n');
					me.terminal.addText('<span ' + style2 + '>' + this.std_in_txt + '</span>');
					me.terminal.addText('<span ' + style1 + '>[  End Input ]</span>\r\n');

					setTimeout(() => {
						console.log('Sending std_in.txt input...');
						this.server.sendInteractiveInput(this.std_in_txt); // + '\n');

					}, 500);
				}

				if (m.run_stdout) {

					/*  // moved to hasStarted section above...
					if (me.first) {
						me.terminal.clear();
						me.first = false;
					}
					*/

					if (me.firstOutput) {
						/*  // moved to hasStarted section above...
						const style = 'style="background-color: #005500;color: #55FF55;"';
						let message = 'PROGRAM OUTPUT';
						// me.tester.OnProgramDone();  // This was a bug
						if (me.scriptFlag) {
							message += ' : Automatically testing your code.  Please wait...';
						}
						me.terminal.addText('<span ' + style + '>[ ' + message + ' ]</span>\r\n');
						*/
						me.firstOutput = false;
					}

					if (m.run_stdout !== '') {
						if (m.isEcho) {
							if (this.std_in_txt_sent) {
								console.log('Ignoring echoed back input');
							}
							else {
							const style = 'style="color: yellow;"';
							me.terminal.addText('<span ' + style + '>' + m.run_stdout + '</span>');
							}
						}
						else if (me.allowHtml) {
							me.terminal.addText(m.run_stdout);
						}
						else {
							me.terminal.addText(me.noHtml(m.run_stdout));
						}
					}
					if (me.scriptFlag) {
						me.tester.OnStdout(m.run_stdout);
					}
				}
				if (m.run_stderr && m.run_stderr !== '' && m.run_stderr !== '\n') {
					const style = 'style="background-color: black;color: yellow;"';
					let msg = m.run_stderr;
					if (!me.allowHtml) {
						msg = me.noHtml(msg);
					}
					me.terminal.addText('<span ' + style + '>STDERR: ' + msg + '</span>\r\n');
				}
				if (!!m.done) {
					const style = 'style="background-color: gray;color: black;"';
					let msg = 'PROGRAM EXITED';
					if (me.scriptFlag) {
            msg += ' - Script running, please wait...';
            if (me.tester) me.tester.OnProgramDone();  // This is where this should go...
					}
					if (m.run_exit_code !== 0) {
						msg += ' WITH STATUS: ' + m.run_exit_code;
					}
					me.terminal.addText('<span ' + style + '>[ ' + msg + ' ]</span>\r\n');
					me.runButtonEnabled = true;
					me.stopButtonEnabled = false;
					if (me.tester && me.scriptFlag) {
						me.tester.stopScript(me.scriptSessionId, 10000);
					}
					me.showPrompt = false;
				}
			}
		};

		this.server.onCompilerOutput = function (m) {
			let msg;
			if (m.type === 'compiler') {
				if (m.compiler_stdout || m.compiler_stderr) {
					if (me.first) {
						me.terminal.clear();
						me.first = false;
					}
					if (me.firstCompile) {
						const style = 'style="background-color: #555500;color: yellow;"';
						me.terminal.addText('<span ' + style + '>[ COMPILER OUTPUT ]</span>\r\n');
						me.firstCompile = false;
					}
					if (m.compiler_stdout && m.compiler_stdout !== '') {
						msg = me.hotlinkErrors(me.noHtml(m.compiler_stdout));
						me.terminal.addText(msg);
					}
					if (m.compiler_stderr && m.compiler_stderr !== '') {
						msg = me.hotlinkErrors(me.noHtml(m.compiler_stderr));
					}
					me.terminal.addText('STDERR: ' + msg); // me.noHtml(m.compiler_stderr));
				}
				if (!!m.done) {
					const style = 'style="background-color: gray;color: black;"';
					me.terminal.addText('<span ' + style + '>[ DONE ]</span>\r\n');
					me.runButtonEnabled = true;
					me.stopButtonEnabled = false;
					me.showPrompt = false;
					if (me.tester) me.tester.stopScript(me.scriptSessionId);
				}
			}
		};

		this.server.onStatusUpdate = function (m3) {
			console.log(m3);
		};
	}

	hotlinkErrors(t: string): string {
		// https://stackoverflow.com/questions/432493/how-do-you-access-the-matched-groups-in-a-javascript-regular-expression
		let myString = t;
		let match;
		let n;
		do {
			const myRegexp = /(\w+\.[a-z]+)\:(\d+)\:(\d+)\:/ig;
			match = myRegexp.exec(myString);
			console.log('Match: ');
			console.log(match); // abc
			n = myString;
			if (match && match[0] && match[1] && match[2] && match[3]) {
				const orig = match[0];
				const fn = match[1];
				const line = match[2];
				const col = match[3];
				// https://stackoverflow.com/questions/35296704/angular2-how-to-call-component-function-from-outside-the-app
				const s = 'window.pubGoProjectFile(\'' + fn + '\',' + line + ',' + col + ');return false;';
				const link = '<a href="#" style="color: #9999ff;" onclick="' + s + '">' + match[1] + ':' + line + ':' + col + '</a>:';
				n = myString.replace(orig, link);
				console.log('Match Link: ' + n);
				myString = n;
			}
		} while (match && match[0]);
		return n;
	}

	onTerminalInput(t) {
		console.log('onTerminalInput: ' + t);
		this.server.sendInteractiveInput(t);
	}

	goProjectFile(filename, line, col) {
		console.log('goProjectFile');
	}

	zoom() {
		this.terminal.zoom();
	}
}
