import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
	MatButtonModule, MatCardModule, MatDialogModule, MatGridListModule, MatIconModule, MatInputModule, MatMenuModule,
	MatOptionModule, MatSelectModule, MatSidenavModule, MatTabsModule, MatToolbarModule, MatTooltipModule, MatFormFieldModule,
	MatProgressSpinnerModule,  MAT_DIALOG_DATA, MatDialogRef, MatCheckboxModule
} from '@angular/material';

//import { MAT_DIALOG_DATA } from '@angular/material';

import { SharedModule, BreadcrumbModule, MenuModule, TreeModule,
	TerminalModule, InputTextModule, CheckboxModule, ButtonModule, DropdownModule, CardModule } from 'primeng/primeng';
import { TableModule } from 'primeng/table';

import { FormsModule } from '@angular/forms';

import { ValuesPipe } from './pipes/values.pipe';
import { SafeHtmlPipe, SafeStylePipe } from './pipes/safe-html.pipe'

@NgModule({
	declarations: [
		ValuesPipe,
		SafeHtmlPipe,
		SafeStylePipe,
	],

	imports: [
		CommonModule,

		FormsModule,


		// MaterialModule.forRoot(),
		MatButtonModule,
		MatIconModule,
		MatCardModule,
		MatDialogModule,
		MatMenuModule,
		MatTooltipModule,
		MatToolbarModule,
		MatInputModule,
		MatGridListModule,
		MatTabsModule,
		MatSidenavModule,
		MatOptionModule,
		MatSelectModule,
		MatProgressSpinnerModule,
		BrowserAnimationsModule,
		MatCheckboxModule,

		// Prime Stuff

		TableModule,
		SharedModule,
		//TerminalModule,
		BreadcrumbModule,
		MenuModule,
		TreeModule,
		InputTextModule,
		CheckboxModule,
		ButtonModule,
		DropdownModule,
		CardModule
	],
	exports: [
		FormsModule,

		// MaterialModule.forRoot(),
		MatButtonModule,
		MatIconModule,
		MatCardModule,
		MatDialogModule,
		MatMenuModule,
		MatTooltipModule,
		MatToolbarModule,
		MatInputModule,
		MatGridListModule,
		MatTabsModule,
		MatSidenavModule,
		MatOptionModule,
		MatSelectModule,
		MatProgressSpinnerModule,
		BrowserAnimationsModule,
		MatCheckboxModule,


		// Prime Stuff

		TableModule,
		SharedModule,
		//TerminalModule,
		BreadcrumbModule,
		MenuModule,
		TreeModule,
		InputTextModule,
		CheckboxModule,
		ButtonModule,
		DropdownModule,
		CardModule,

		//

		ValuesPipe,
		SafeHtmlPipe,
		SafeStylePipe
	],

	providers: [
		{ provide: MAT_DIALOG_DATA, useValue: {} },
		{ provide: MatDialogRef, useValue: {} }
	]
})
export class CfGuiCoreNgModule { }
