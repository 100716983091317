import * as firebase from 'firebase/app';

// Initialize Firebase
// TODO: Move these to a config file possibly outside of the GIT...
// TODO: Definitly get rid of the prod config before going public/open source
const configProd = {  // Only for actual production use
	apiKey: 'AIzaSyC9Od7zYUE_MAOtDHNMZFvHJZvssrIN-38',
	authDomain: 'compileit.firebaseapp.com',
	databaseURL: 'https://compileit.firebaseio.com',
	projectId: 'project-4830951466086989026',
	storageBucket: 'project-4830951466086989026.appspot.com',
	messagingSenderId: '659602352529',
	appId: ''
};

const configDemo = {  // Demo Server
	apiKey: 'AIzaSyBhxBeBomkv6_ivlfT4YTA682CE8VwgUFQ',
	authDomain: 'code-folio-demo.firebaseapp.com',
	databaseURL: 'https://code-folio-demo.firebaseio.com',
	projectId: 'code-folio-demo',
	storageBucket: 'code-folio-demo.appspot.com',
	messagingSenderId: '445088505323',
	appId: "1:445088505323:web:cfbd50531936c659"
};

const configDev = {  // Dev Server
	apiKey: 'AIzaSyD2FAkV1doy9Uu8DDul1ctRYB9U1czU1gI',
	authDomain: 'code-folio-dev.firebaseapp.com',
	databaseURL: 'https://code-folio-dev.firebaseio.com',
	projectId: 'code-folio-dev',
	storageBucket: 'code-folio-dev.appspot.com',
	messagingSenderId: '112296289434',
	appId: ''
};

export let config = configProd;
enum CFRunModes { dev, demo, prod }
const CFRunModeNames = ['DEV', 'DEMO', 'PROD'];
const CFRunModeTitles = [' ~ DEV', ' ~ DEMO', ' *'];


// TODO: MOVE THIS TO A CONFIG IN BUILD SCRIPT
//
const cfRunMode: CFRunModes = CFRunModes.prod;  // *** SET RUNMODE HERE *****
//

const cfRunModeName: string = CFRunModeNames[cfRunMode];
const cfRunModeTitle: string = CFRunModeTitles[cfRunMode];

window['_cfRunMode'] = cfRunMode; // * global hacks
window['_cfRunModeName'] = cfRunModeName;
window['_cfRunModeTitle'] = cfRunModeTitle;

switch (+cfRunMode) { // https://stackoverflow.com/questions/27747437/typescript-enum-switch-not-working
	case CFRunModes.demo: { config = configDemo; break; }
	case CFRunModes.dev: { config = configDev; break; }
	case CFRunModes.prod: { config = configProd; break; }
	default: {
		console.error('INVALID RUNMODE!!!');
		// debugger;
	}
}
console.log('RunMode: ' + cfRunModeName);

const firebaseApp = firebase.initializeApp(config);


