import { Component, OnInit, OnDestroy, ComponentFactory, ComponentFactoryResolver } from '@angular/core';
import { Location } from '@angular/common';
import { MatDialogModule, MatDialogRef, MatSidenavModule, MatSidenavContainer, MatDialog } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';
import { Observable } from 'rxjs/Observable';


import {IProjectInfo, } from "@cf-platform/cf-core-cms";


import { BsUserProjectsService } from '../../../services/bs-user-projects.service';
import { BsProjectC } from '@cf-platform/cf-core-cms'
import { BsFolderItemComponent } from '../../bs-feed-system/bs-folder/bs-folder-item/bs-folder-item.component';
import { BsProjectDialogComponent } from '../bs-project-dialog/bs-project-dialog.component';
import { BsCurrentUserService } from '../../../epic-core/services/bs-current-user.service';
import * as firebase from 'firebase/app';
import { BsProjectComponent } from '../bs-project/bs-project.component';
import { ItemViewFactoryService, ViewThing, ViewTypeE } from '../../bs-feed-system/bs-folder/item-view-factory.service';

import {MatSnackBar} from '@angular/material';
import { BreadcrumbService } from 'projects/cf-web/src/app/breadcrumb.service';

@Component({
	selector: 'app-bs-my-projects',
	templateUrl: './bs-my-projects.component.html',
	styleUrls: ['./bs-my-projects.component.css']
})
export class BsMyProjectsComponent implements OnInit, OnDestroy {

	private bsUserProjectsSub: any; // Subscription
	item: IProjectInfo;
	projectList: Observable<IProjectInfo[]>;
	currentUserO: Observable<any>;
	currentUserOsub: any; // Subscription;
	currentUser: firebase.User;
	currentUserEmail: string;
	currentPath: string;
	routeSub: any; // Subscription;

	//projectArray: IProjectInfo[];
	isProject: boolean = false;

	constructor(
		private breadcrumbService: BreadcrumbService,
		public afAuth: AngularFireAuth,
		private bsUserProjects: BsUserProjectsService,
		private router: Router,
		private location: Location,
		private BsCurrentUserService: BsCurrentUserService,
		public dialog: MatDialog,
		private resolver: ComponentFactoryResolver,
		private itemViewFactory: ItemViewFactoryService,
		private snackBar: MatSnackBar ) {


			this.breadcrumbService.setItems([
				{label: 'My Projects'},
			]);
			this.breadcrumbService.setPageInfo({title:'My Projects', icon: 'folder'});

		}

	giveFeedback(message:string) {
		const snackBarRef = this.snackBar.open(message, 'OK', {
			duration: 2000
		});

	}

	ngOnInit() {
		this.currentUserO = this.afAuth.authState;
		this.currentUserOsub = this.currentUserO.subscribe(u => {
			this.currentUser = u;
			this.currentUserEmail = u.email;
			if (!u.email) {
				if (u.providerData[0]) {
					this.currentUserEmail = u.providerData[0].email; // HACK HACK
				}
			}
			this.setupViews();
			this.updatePath();
		});

		this.routeSub = this.router.events.subscribe(v => {
			console.log('Hi from router.events.subscribe');
			this.updatePath();
		});
	}

	ngOnDestroy() {
		console.log('mProjects destroy - unsubscribing things...');
		if (this.routeSub) {
			this.routeSub.unsubscribe(); // dispose
		}
		if (this.currentUserOsub) {
			this.currentUserOsub.unsubscribe();
		}
		if (this.bsUserProjectsSub) {
			this.bsUserProjectsSub.unsubscribe();
		}
		if (this.projectListSub) {
			this.projectListSub.unsubscribe();
		}
	}


	lastProject: IProjectInfo;

	updatePath() {
		console.log('Hello from onPathChange');

		if (!this.currentUser) {
			this.item = null;
			return;
		}

		let newPath: string = this.location.path();  // this is a terrible way to do this!
		if (!newPath.startsWith('/my/projects/')) {
			newPath = '/';
		}
		else {
			newPath = newPath.substr('/my/projects/'.length);


			this.breadcrumbService.setItems([
				{label: 'My Projects', routerLink: ['/my/projects']},
				{label: newPath}
			]);
			this.breadcrumbService.setPageInfo({title: 'My Projects / ' + newPath, icon: 'folder'});

		}
		this.BsCurrentUserService.updateStatus(true, 'My Projects', 'path: ' + newPath);

		if (newPath === this.currentPath) {
			return;
		}
		this.currentPath = newPath;
		const path = this.currentPath;



		if (!path || path === '' || path === '/') {
			this.isProject = false;
			const f: IProjectInfo = {
				name: 'My Projects',
				description: 'Here are your projects',
				type: 'folder',
				projType: 'folder',
				projPath: '',
				parent_path: '/my',
				rel_path: 'projects',
				my_path: '/my/projects/',
				user: 'zzz',
				create_timestamp: '',
				sort: ''
			};
			f.buttons = [{title: 'New Project...', click: () => this.showNewItemDialog('project')}];

			f['getIcon2'] = function () {
				return { name: 'folder' };
			};
			f['_key'] = 'my_projects_zz';

			this.projectList = this.bsUserProjects.getUsersProjects(this.currentUserEmail);






			f['childItems'] = this.projectList;
			this.item = f;

			this.projectListSub = this.projectList.subscribe(data => {
				setTimeout(() => {
					this.projectListCount = data.length;
					//this.projectArray = data;
					console.log('Debug projectList here');
				}, 10);

			});
		}
		else {
			this.isProject = true;
			console.log('Starting project load here...');
			this.bsUserProjectsSub = this.bsUserProjects.getUserProject(this.currentUserEmail, this.currentPath)
				.subscribe(p => {
					console.log('HERE BE A CHANGE');


					if (!this.lastProject ||  !p.projPath ||  this.lastProject.projPath !== p.projPath) {
						// only reload if it is a different project
						this.item = p;
					}
					else {
						// something changed about the project.
						for (let k in p) {
							this.item[k] = p[k];
							// TODO: only need to copy fields.  Displays errors when it tries to copy getters
						}
					}
					this.lastProject = p;

					// https://netbasal.com/dynamically-creating-components-with-angular-a7346f4a982d

					// <app-bs-project *ngIf="item.type=='project' " [project]="item " (goParentClicked)="goParent(); " (deleteItemClicked)="deleteItem($event); "></app-bs-project>

					//const det_factory: ComponentFactory<BsProjectComponent> = this.resolver.resolveComponentFactory(BsProjectComponent);
					//p.detail_factory = det_factory;
					//p.detail_inputs = {project: p,   currentUserEmail: this.currentUser.email };

				});
		}
	}

	setupViews() {
		const det_factory: ComponentFactory<BsProjectComponent> = this.resolver.resolveComponentFactory(BsProjectComponent);
		//p.detail_factory = det_factory;
		const detail_inputs = {currentUserEmail: this.currentUser.email };
		const detail_outputs = {deleteItemClicked: (item) => {
			return this.deleteItem(item);
		} };
		const vt: ViewThing = {factory: det_factory, inputs: detail_inputs, outputs: detail_outputs};
		this.itemViewFactory.registerItemType('project', ViewTypeE.detail, vt);


	}

	projectListSub: any;
	projectListCount: number;

	_basePath = '/my/projects';

	goItem(item) {
		console.log('bs-my-projects: goItem');
		this.goPath(item['my_path']);
	}

	goPath(path: string) {
		console.log('Navigating to path: ' + path);
		this.BsCurrentUserService.updateStatus(true, 'My Projects', 'go:' + path);
		const me = this;
		me.router.navigateByUrl(path);
	}

	goParent() {
		console.log('GoParent');
		if (this.currentPath === '/') {
			this.goPath('/');
		}
		else {
			this.goPath('/my/projects');
		}
	}


	deleteProjectClicked(project: BsProjectC) {
		console.log('deleteProjectClicked');
		const me = this;
		const projName = project.name;
		if (confirm('Are you sure to delete this project: ' + project.name)) {
			console.log('Deleting project...');

			project.deleteProject()
				.then( () => {
					console.log('I think the project was deleted!');
					setTimeout(() => {
						this.giveFeedback('Deleted project: ' + projName);

						setTimeout(() => {

							me.goPath('/my/projects');
						}, 50);

					}, 50);

				})
				.catch(function (e) {
					console.log('There was an error.');
					setTimeout(() => {
						this.giveFeedback('There was an error deleting project: ' + projName);
					}, 50);
				});

		}

	}

	deleteItem(item) {
		this.deleteProjectClicked(item);
	}

	// NON DRY - similar or dups in bs-folder-view.component
	scrollToBottom(): void {
		setTimeout(() => {
			document.scrollingElement.scrollTop = document.scrollingElement.scrollHeight;
		}, 600);
	}

	showNewItemDialog(itemType: string) {
		this.scrollToBottom();
		const me = this;

		const data = {
			type: itemType,
			projectListCount: this.projectListCount
		};
		const dialogRef = this.dialog.open(BsProjectDialogComponent, { data: data });

		dialogRef.afterClosed().subscribe(result => {
			if (!result || !result.cmd) {
				console.log('Invalid dialog result');
			}
			else if (result.cmd.toLowerCase() === 'cancel') {
				console.log('Dialog canceled');
			}
			else {
				console.log('you said: ' + result.cmd);
				console.log('Project Name: ' + result.project.name);
				console.log('Project Type: ' + result.project.type);
				this.bsUserProjects.newProject(this.currentUserEmail, result.project.name, result.project.type)
					.then(proj => {
						me.goPath(proj.my_path);
					});
			}
		});
	}
}
