import { IUser, IUserToken} from '@cf-platform/cf-core-cms';
import * as firebase from 'firebase/app'
import { UnifiedFirebaseDB } from '@cf-platform/unified-firebase-db';

import axios  from 'axios';

export interface CFACResult<T> {
	//ok: boolean;
	error?: any;
	message?: string;
	data?: T;
	debug?: any;
}

interface CFApiParams {
	rType: string,
	what: string,
	classId?: string,
	assignmentId?: string,
	userId?: string
}

export class CFApiClient {

	//
	mode = "PROD";  // PROD  DEMO  LOCAL
	//


	server = '';


	private currentUser: firebase.User;
	private currentUserToken: IUserToken;
	private dbMan: UnifiedFirebaseDB;
	private static _instance: CFApiClient;

	//cfAdmin: CFApiClient = CFApiClient.getInstance();

	public static getInstance() {  // get singleton
		if (!CFApiClient._instance) {
			CFApiClient._instance = new CFApiClient();
		}
		return CFApiClient._instance;
	}

	private constructor(

	) {
		console.log('cf-api-client');
		if (this.mode === 'PROD') {
			this.server = 'https://compileit.online';
			console.log('cf-api-client  mode: PROD');
		}
		else if (this.mode === 'DEMO') {
			this.server = 'https://code-folio-demo.firebaseapp.com';
			console.log('cf-api-client  mode: DEMO');
		}
		else if (this.mode === 'LOCAL') {
			this.server = 'http://localhost:3333';
			console.log('cf-api-client  mode: LOCAL');
		}
		else {
			console.log('cf-api-client  need a mode!')
		}




		firebase.auth().onAuthStateChanged((user) => {
			this.currentUser = user;
			const db = firebase.database();
			this.dbMan = new UnifiedFirebaseDB(db,'/');

			//this.getUserToken();
		});
	}

	public async doUserToken() {
		if (!this.currentUser || !this.currentUser.uid) {
			console.log('cf-api-client user not ready');
			return;
		}
		let tokenId = await this.dbMan.getOnce(['user_settings',this.currentUser.uid,'token']);
		let token: IUserToken;

		if (tokenId) {
			token = await this.getTokenInfo(tokenId);
		}

		if (!token || !token.valid) {
			token = await this.genNewUserToken();
		}

		this.currentUserToken = token;
	}

	public async getTokenInfo(tokenId: string): Promise<IUserToken> {
		const tokenInfo = await this.dbMan.getOnce(['users_tokens',tokenId]);
		return tokenInfo as IUserToken;
	}

	public async genNewUserToken() : Promise<IUserToken> {
		if (!this.currentUser || !this.currentUser.uid) {
			return null;
		}

		let userTokenInfo: IUserToken = {
			creation: UnifiedFirebaseDB.TIMESTAMP,
			uid: this.currentUser.uid,
			valid: true,
			token: '?'
		}

		userTokenInfo = await this.dbMan.pushValAndKeyIt(['users_tokens'], userTokenInfo, 'token');

		if (!userTokenInfo) {
			return null;
		}
		await this.dbMan.updateVal(['user_settings',this.currentUser.uid], {token: userTokenInfo.token});
		return userTokenInfo;
	}

	//

	 public  async getReady() : Promise<boolean> {
		if (!this.currentUser) {
			console.log('No current user');
			return false;
		}
		if (!this.currentUserToken || !this.currentUserToken.valid) {
			await this.doUserToken();

			if (!this.currentUserToken || !this.currentUserToken.valid) {
				return false;
			}
		}

		return true;
	}


	public async addUserToClass(user: IUser, classId: string): Promise<CFACResult<any>> {

		console.log('Debug cf-api-client  addUserToClass')

		return await this.addUserIdToClass(user.uid || user.__key,classId);

	}

	public async addUserIdToClass(userId: string, classId: string): Promise<CFACResult<any>> {

		console.log('Debug cf-api-client  addUserToClass')

		return await this.sendCmd({rType: 'class', what: 'enroll', userId, classId});

	}

	public async lmsPushGrade(userId: string, classId: string, assignmentId: string): Promise<CFACResult<any>> {
		console.log('Debug cf-api-client  lmsPushGrade')
		return await this.sendCmd({rType: 'lms', what: 'pushgrade', userId, classId, assignmentId});
	}

	public async lmsSyncUsers(classId: string): Promise<CFACResult<any>> {
		console.log('Debug cf-api-client  lmsSyncUsers')
		return await this.sendCmd({rType: 'lms', what: 'syncusers', classId});
	}


	async lmsFindLinkedAssignment(classId: string, assignmentId: string): Promise<number> {
		console.log('Debug findLinkedAssignmentOnLMS lmsSyncUsers')
		const r = await this.sendCmd({rType: 'lms', what: 'findLinkedAssignment', classId, assignmentId});
		if (!r || !r.data) {
			return 0;
		}
		return r.data['lms_assignment_id'];
	}

	public async sendCmd(rParams: CFApiParams): Promise<CFACResult<any>> {

		if (!await this.getReady()) {
			return {error: 'Not Ready'};
		}

		const rUrl = this.genReqUrl(rParams);


		try {
			const response = await axios.get(rUrl);

			console.log(response);
			if (response && response.status === 200 && response.data) {
				return response.data;
			}
			if (!response || response.status !== 200) {
				return {error: 'Error connecting'};
			}
			if (response.data && response.data.err ) {
				return {error: response.data.err}
			}
			return {error: 'Unknown'};

		  } catch (error) {
			console.error(error);
			if (error.response && error.response.data && error.response.data.err) {
				return {error: error.response.data.err};
			}
			return {error: error};
		  }

	}



	public async removeUserFromClass(user: IUser, classId: string): Promise<CFACResult<any>> {
		console.log('Debug removeUserFromClass');

		if (!user || !user.uid || !classId) {
			console.log('No user or class!');
			return {error: 'No user or class!'};
		}

		const uid = user.uid || user.__key;

		return await this.removeUserByIdFromClass(uid, classId);
	}

	public async removeUserByIdFromClass(userId: string, classId: string): Promise<CFACResult<any>> {
		console.log('Debug removeUserByIdFromClass');

		return await this.sendCmd({rType:'class', what:'unenroll', userId, classId});


	}





	genReqUrl(rParams: CFApiParams) {

		const host = this.server;
		//if (rType.startsWith('classuser')) {
			let url;
			if (rParams.rType.startsWith('lms')) {
				url = host + `/api/lms/`;
			}
			else if (rParams.rType.startsWith('class')) {
				url = host + `/api/class/`;
			}
			else {
				console.error('cf-api-clien genReqURL rType error');
				return '';
			}
			url += `${rParams.what}/`;
			if (rParams.classId) {
				url += `class/${rParams.classId}/`;
			}
			if (rParams.assignmentId ) {
				url += `assignment/${rParams.assignmentId}/`;
			}
			if (rParams.userId) {
				url += `user/${rParams.userId}/`;
			}

			url += `?access_token=${this.currentUserToken.token}`;
			return url;

	}


}
