import { Component, OnInit } from '@angular/core';
import { ItemDefaultViewComponent } from '../item-default-view/item-default-view.component';

@Component({
	selector: 'app-item-default-view-summary',
	templateUrl: './item-default-view-summary.component.html',
	styleUrls: ['./item-default-view-summary.component.css']
})
export class ItemDefaultViewSummaryComponent extends ItemDefaultViewComponent implements OnInit {

	constructor() {
		super();
	}

	ngOnInit() {
	}

}
