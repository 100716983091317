import { Component, OnInit, Inject } from '@angular/core';
import { BsUsersComponent } from '../bs-users/bs-users.component';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import { IAddUserToClass } from '../../interfaces/IAddUserToClass';

@Component({
  selector: 'app-bs-users-dialog',
  templateUrl: './bs-users-dialog.component.html',
  styleUrls: ['./bs-users-dialog.component.css']
})
export class BsUsersDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<BsUsersDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any

  ) { }

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit() {
  }

  static showDialog(dialog: MatDialog, classId, className, addToClassFunc: IAddUserToClass){
    //debugger;
    let dialogRef = dialog.open(BsUsersDialogComponent, {
      width: '90%',
      height: '90%',
      data: { name: className, classId: classId, addToClassFunc: addToClassFunc }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      //this.animal = result;
    });
  }



}
