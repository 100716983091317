import { Component, OnInit, Input } from '@angular/core';
//import { iterateListLike } from '@angular/core/src/change_detection/change_detection_util';
import { IFolderItem } from '../../../../interfaces/IFolderItem';

@Component({
	selector: 'app-item-default-view',
	templateUrl: './item-default-view.component.html',
	styleUrls: ['./item-default-view.component.css']
})
export class ItemDefaultViewComponent implements OnInit {

	@Input() item: IFolderItem;

	constructor() { }

	ngOnInit() {
	}

}
