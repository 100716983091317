import { Component, OnInit, Input, OnDestroy, ChangeDetectorRef, NgZone } from '@angular/core';
//import { BsUsersService } from '../../services/bs-users.service';
//import { BsUser } from './bs-user';
import { IUser } from '../../interfaces/IUser';
import { UserRepository } from '../../services/userRepository';
//import { Observable } from 'rxjs/Observable';
//import '../../pipes/safe-html.pipe';
import {  SharedModule } from 'primeng/primeng';
import { ActivatedRoute, Router } from '@angular/router';

import '../../interfaces/IAddUserToClass';
import { IAddUserToClass } from '../../interfaces/IAddUserToClass';

import {MatSnackBar} from '@angular/material';
import { BehaviorSubject } from 'rxjs';

@Component({
	selector: 'app-bs-users',
	templateUrl: './bs-users.component.html',
	styleUrls: ['./bs-users.component.css']
})
export class BsUsersComponent implements OnInit, OnDestroy {

	title = 'All Users';

	users_array: IUser[];
	users_dict_by_uid: {};
	users_dict_by_email: {};

	_selectedUsers: IUser[];
	_selectedUserEmails: string = '';
	private usersSub;

	allUsersList: BehaviorSubject<IUser[]>;

	get selectedUsers(): IUser[] {
		return this._selectedUsers;
	}
	set selectedUsers(su: IUser[]) {
		this._selectedUsers = su;
		let emails: string = '';
		for (const u of this._selectedUsers) {
			emails += u.email + '; ';
		}
		this._selectedUserEmails = emails;
	}

	static addUsersToGroupDialog() { //??

	}

	//?? possibly rename this
	@Input() addUserToClassFunc: IAddUserToClass;

	syncUsers() {  // select the users in the email list box...
		const emailList = this._selectedUserEmails.split(';');
		const susers = [];
		for (const e of emailList) {
			const te = e.trim();
			const u = this.users_dict_by_email[te];
			if (u) {
				susers.push(u);
			}
			else {
				console.log('Not Found: ' + te);
			}
		}
		this.selectedUsers = susers;
	}

	constructor(
		private userRepo: UserRepository,
		private route: ActivatedRoute,
		private router: Router,
		private snackBar: MatSnackBar,
		private cdRef: ChangeDetectorRef,
		private ngZone: NgZone
	) {
		this.allUsersList = new BehaviorSubject([]);
	}

	@Input('classId') selectedGroupId: string;

	ngOnInit() {

		this.usersSub = this.userRepo.watchUsers( (users) => {
			//debugger;

			console.log('users component: getUsers p2: Read in ' + users.length + ' users');

			this.users_array = users;
			const udict = {};
			const edict = {};
			for (const u of this.users_array) {
				u['nameLF'] = u.lastName + ', ' + u.firstName;
				udict[u.uid] = u;
				edict[u.email] = u;
				// console.log(u.email + ',' + u.firstName + ',' + u.lastName + ',' + u.name + ',' + u.uid);
			}
			this.users_dict_by_uid = udict;
			this.users_dict_by_email = edict;

			this.title = 'All Users: ' + this.users_array.length;

			let n = 500;
			if (this.users_array.length < n) {
				n = this.users_array.length;
			}
			this.allUsersList.next(this.users_array.slice(0,n));

			this.cdRef.detectChanges();
		});
	}

	ngOnDestroy() {
		this.userRepo.unsubscribeUsers(this.usersSub);
	}

	showUserDump(u) {
		console.log(JSON.stringify(u));
	}

	batchNote: string = '';
	batchSection: string = '';
	batchType: string = '';

	giveFeedback(message:string) {
		let snackBarRef = this.snackBar.open(message, 'OK', {
			duration: 2000
		});

		//this.snackBar.openFromComponent(this, {
		//  duration: 500,
		//});
	}

	async enrollUsers() {
		console.log('Enrolling the following users into: ' + this.selectedGroupId);
		for (const u of this.selectedUsers) {
			console.log('enroll: ' + u.email);
			if (this.batchNote) {
				u.note = this.batchNote;
			}
			if (this.batchSection) {
				u.section = this.batchSection;
			}
			if (this.batchType) {
				u.type = this.batchType;
			}

			// debugger; //

			if (!this.addUserToClassFunc){
				console.log('Error addUserToClassFunc not set up!');
				this.giveFeedback('Error addUserToClassFunc not set up!');
			}
			else {
				// const r = await this.classesUsersService.addUserToClass(u, this.selectedGroupId);
				const r = await this.addUserToClassFunc(u, this.selectedGroupId);

				// debugger;        // //  check to see if this works

				if (r && !r.error) {
					console.log('User added ok: ' + u.email);
					this.giveFeedback('User added ok: ' + u.email);
				}
				else {
					console.log('Error adding user: ' + u.email);
					this.giveFeedback('Error adding user: ' + u.email);
					if (r.error) {
						this.giveFeedback('Error: ' + r.error);
					}
				}
			}
		}
		this.selectedUsers = [];
	}

	selectedUsersChange(e: any) {

		setTimeout(() => {
			this.ngZone.run( () => {

				const sUsers = e as Array<IUser>;
				this.selectedUsers = sUsers;
				console.log('Selected Users are: ' + sUsers.map(u => u.name).join('; '));

				this.cdRef.detectChanges();
			})
		}, 0);


	}
}
