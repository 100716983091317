import { Routes, RouterModule } from '@angular/router';
import { ModuleWithProviders } from '@angular/core';
//import { DashboardComponent } from './demo/view/dashboard.component';
//import { SampleDemoComponent } from './demo/view/sampledemo.component';
//import { FormsDemoComponent } from './demo/view/formsdemo.component';
//import { DataDemoComponent } from './demo/view/datademo.component';
//import { PanelsDemoComponent } from './demo/view/panelsdemo.component';
//import { OverlaysDemoComponent } from './demo/view/overlaysdemo.component';
//import { MenusDemoComponent } from './demo/view/menusdemo.component';
//import { MessagesDemoComponent } from './demo/view/messagesdemo.component';
//import { MiscDemoComponent } from './demo/view/miscdemo.component';
//import { EmptyDemoComponent } from './demo/view/emptydemo.component';
//import { ChartsDemoComponent } from './demo/view/chartsdemo.component';
//import { FileDemoComponent } from './demo/view/filedemo.component';
//import { UtilsDemoComponent } from './demo/view/utilsdemo.component';
//import { DocumentationComponent } from './demo/view/documentation.component';
import { AppMainComponent } from './app.main.component';
import { AppNotfoundComponent } from './pages/app.notfound.component';
import { AppErrorComponent } from './pages/app.error.component';
import { AppAccessdeniedComponent } from './pages/app.accessdenied.component';
import { AppLoginComponent } from './pages/app.login.component';

import { CFHomeComponent } from './cf_views/cfhome.component';
import { OnlyLoggedInFireBaseGuard } from './only-logged-in-fire-base.guard';
import { BsMyProjectsComponent } from '@cf-platform/cf-core-cms-ng';
import { LogViewerComponent } from '@cf-platform/cf-log';
import { OnlyEnrolledGuard, BsAssignmentGraderComponent, BsClassComponent, BsClassUsersComponent,
	BsGradeBookComponent, BsMyGradesComponent, EnrollComponent, CfClassSettingsComponent,
	CfAssignmentCopierComponent} from '@cf-platform/cf-class-stuff-ng';
import { CfUserProfileComponent } from 'libs/cf-core-cms-ng/src/lib/cf-core-cms-ng/components/cf-user-profile/cf-user-profile.component';



export const routes: Routes = [
    { path: '', component: AppMainComponent, canActivate: [OnlyLoggedInFireBaseGuard],
        children: [
			{ path: '', component: CFHomeComponent },

			{
				path: 'my/projects', canActivate: [OnlyLoggedInFireBaseGuard],
				children: [{ path: '', component: BsMyProjectsComponent },
				{ path: '**', component: BsMyProjectsComponent }]
			},

			{ path: 'logz', component: LogViewerComponent },

			{ path: 'profile', component: CfUserProfileComponent },

			{ path: 'class/:classinst/:classid/enroll/:path', component: EnrollComponent},



			{
				path: 'class/:classinst/:classid', canActivate: [OnlyLoggedInFireBaseGuard, OnlyEnrolledGuard],
				children: [
					{ path: 'grade_book', component: BsGradeBookComponent},

					{ path: 'students', component: BsClassUsersComponent},
					{ path: 'my_grades', component: BsMyGradesComponent},
					{ path: 'settings', component: CfClassSettingsComponent},
					{ path: 'a_copy', component: CfAssignmentCopierComponent},

					{ path: 'grade', component: BsAssignmentGraderComponent,
				children: [
					{ path: '**', component: BsAssignmentGraderComponent},
				]},


					//{ path: 'grade/:assignmentId', component: BsAssignmentGraderComponent},
					//{ path: 'grade/:assignmentId/:studentKey', component: BsAssignmentGraderComponent},
					//{ path: 'grade/:assignmentId/:studentKey/:submitId', component: BsAssignmentGraderComponent},

					{ path: '', component: BsClassComponent },
				{ path: '**', component: BsClassComponent }]
			},

            // { path: 'dash', component: DashboardComponent },
            // { path: 'sample', component: SampleDemoComponent },
            // { path: 'forms', component: FormsDemoComponent },
            // { path: 'data', component: DataDemoComponent },
            // { path: 'panels', component: PanelsDemoComponent },
            // { path: 'overlays', component: OverlaysDemoComponent },
            // { path: 'menus', component: MenusDemoComponent },
            // { path: 'messages', component: MessagesDemoComponent },
            // { path: 'misc', component: MiscDemoComponent },
            // { path: 'empty', component: EmptyDemoComponent },
            // { path: 'charts', component: ChartsDemoComponent },
            // { path: 'file', component: FileDemoComponent },
            // { path: 'utils', component: UtilsDemoComponent },
			// { path: 'documentation', component: DocumentationComponent },


        ]
    },
    {path: 'error', component: AppErrorComponent},
    {path: 'accessdenied', component: AppAccessdeniedComponent},
    {path: '404', component: AppNotfoundComponent},
	{path: 'login', component: AppLoginComponent},
	{path: 'login/:go', component: AppLoginComponent},
    {path: '**', redirectTo: '/404'},

];

export const AppRoutes: ModuleWithProviders = RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'});
