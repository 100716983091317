// components
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CfGuiCoreNgModule } from '@cf-platform/cf-gui-core-ng';
import { CfCoreCmsNgModule } from '@cf-platform/cf-core-cms-ng';

import { BsAssignmentEditComponent } from './components/bs-assignment-edit/bs-assignment-edit.component';
import { BsAssignmentGraderComponent } from './components/bs-assignment-grader/bs-assignment-grader.component';
import { BsCurrentClassMenuComponent  } from './components/bs-current-class-menu/bs-current-class-menu.component';
import { BsGradeBookComponent  } from './components/bs-grade-book/bs-grade-book.component';
import { BsMyGradesComponent } from './components/bs-my-grades/bs-my-grades.component';
import { EnrollComponent } from './components/enroll/enroll.component';
import { MyAssignmentDetailComponent } from './components/my-assignment-detail/my-assignment-detail.component';
import { MyAssignmentStatComponent } from './components/my-assignment-stat/my-assignment-stat.component';
import { BsClassComponent } from './components/bs-class/bs-class.component';
import { BsClassUsersComponent } from './components/bs-class-users/bs-class-users.component'


// classes
import { BsClass } from './classes/bs-class';

// services
import { BsAssignmentTesterService} from './services/bs-assignment-tester.service';
import { BsAssignmentsService} from './services/bs-assignments.service';
import { BsCurrentClassInfoService} from './services/bs-current-class-info.service';
import { BsUserProjectsService } from '@cf-platform/cf-core-cms-ng' //'./services/bs-user-projects.service';
import { BsClassesUsersService } from './services/bs-classes-users.service'
import { EzClassUserServiceService } from './services/ez-class-user-service.service';


import { OnlyEnrolledGuard } from './guards/only-enrolled.guard';
import { CfClassSettingsComponent } from './components/cf-class-settings/cf-class-settings.component';
import { CfClassPopupComponent } from './components/cf-class-popup/cf-class-popup.component';
import { CfAssignmentCopierComponent } from './components/cf-assignment-copier/cf-assignment-copier.component';



@NgModule({
  declarations: [
	  BsAssignmentEditComponent,
	  BsAssignmentGraderComponent,
	  BsCurrentClassMenuComponent,
	  BsGradeBookComponent,
	  BsMyGradesComponent,
	  EnrollComponent,
	  MyAssignmentDetailComponent,
	  MyAssignmentStatComponent,
	  BsClassComponent,
	  BsClassUsersComponent,
	  CfClassSettingsComponent,
	  CfClassPopupComponent,
	  CfAssignmentCopierComponent
  ],
  imports: [
	CommonModule,
	CfCoreCmsNgModule,
	CfGuiCoreNgModule
  ],
  providers: [
	BsAssignmentTesterService,
	BsAssignmentsService,
	BsCurrentClassInfoService,
	BsUserProjectsService,
	BsClassesUsersService,

	EzClassUserServiceService,

	OnlyEnrolledGuard
  ],
  exports: [
	BsAssignmentEditComponent,
	BsAssignmentGraderComponent,
	BsCurrentClassMenuComponent,
	BsGradeBookComponent,
	BsMyGradesComponent,
	EnrollComponent,
	MyAssignmentDetailComponent,
	MyAssignmentStatComponent,
	BsClassComponent,
	BsClassUsersComponent,

	//BsClass,

	//BsAssignmentTesterService,
	//BsAssignmentsService,
	//BsCurrentClassInfoService,
	//BsUserProjectsService,
	//BsClassesUsersService
  ],
  entryComponents: [
	MyAssignmentDetailComponent,
	MyAssignmentStatComponent,
		//MyAssignmentDetailComponent,
		// BsProjectDialogComponent,
  ]
})
export class CfClassStuffNgModule { }
