import {Component, Input, OnInit, AfterViewInit, OnDestroy, ElementRef, Renderer, ViewChild} from '@angular/core';
import {trigger, state, style, transition, animate} from '@angular/animations';
import {Location} from '@angular/common';
import {Router} from '@angular/router';
import {MenuItem, ScrollPanel} from 'primeng/primeng';
import {AppMainComponent} from './app.main.component';
import { UserRepository } from '@cf-platform/cf-core-cms-ng';
import { Observable } from 'rxjs';
import { BsClass, BsClassesUsersService, BsCurrentClassInfoService } from '@cf-platform/cf-class-stuff-ng';
//import { BsClassesUsersService } from '@cf-platform/cf-class-stuff-ng';
import { IUser } from '@cf-platform/cf-core-cms';
//import { BsCurrentClassInfoService } from 'apps/web-client/src/app/services/bs-current-class-info.service';



@Component({
    selector: 'app-menu',
    templateUrl: './app.menu.component.html'
})
export class AppMenuComponent implements OnInit, AfterViewInit {

    @Input() reset: boolean;

	model: any[];
	origModel: any[] = [];

    @ViewChild('scrollPanel', { static: true }) layoutMenuScrollerViewChild: ScrollPanel;
	theUser: any;
	myClassesMenu: { label: string; icon: string; items: any[]; };
	cciSub: any;
	currentClassInfo: any;
	theClass: any;
	user: any;
	assignmentsList: import("/Users/bobbradley/Documents/git/bean-sprout/web-client/libs/cf-core-cms/src/index").IAssignment[];
	userIsInstructor: boolean;
	settingsMenu: { label: string; icon: string; items: any[]; };;

	constructor(public app: AppMainComponent,
		private userRepository: UserRepository,
		private classesUsers: BsClassesUsersService,
		private currentClassInfoService: BsCurrentClassInfoService,) {}

    ngAfterViewInit() {
      setTimeout(() => {this.layoutMenuScrollerViewChild.moveBar(); }, 100);
    }

    ngOnInit() {

		this.userRepository.getCurrentUser( u => {this.theUser = u ;
			this.user = u;
			this.getUsersClasses(u);
		});


		this.cciSub = this.currentClassInfoService.currentClassInfoHandler.subscribe(cci => {

			this.currentClassInfo = cci;
			//if (cci || this.theClass) {

				//if (this.theClass != cci.theClass) {
					if (cci) {
						this.theClass = cci.theClass;
					} else {
						this.theClass = null;
					}
					this.doClassMenus();

				//}
			//}
		});



		this.myClassesMenu = {label: 'My Classes', icon: 'school', items: []};


		this.settingsMenu = {label: 'Settings', icon: 'settings', items: [
			{
                label: 'Menu Modes', icon: 'settings',
                items: [
                    {label: 'Static Menu', icon: 'view_quilt', command: (event) => {this.app.layoutMode = 'static'; }},
                    {label: 'Overlay Menu', icon: 'flip_to-front', command: (event) => {this.app.layoutMode = 'overlay'; }},
                    {label: 'Horizontal Menu', icon: 'border_horizontal', command: (event) => {this.app.layoutMode = 'horizontal'; }},
                    {label: 'Light Menu', icon: 'label', command: (event) => {this.app.darkMenu = false; }},
                    {label: 'Dark Menu', icon: 'label_outline', command: (event) => {this.app.darkMenu = true; }},
                    {
                        label: 'Orientation', icon: 'format_align_right',
                        items: [
                            {label: 'LTR', icon: 'format_align_left', command: (event) => {this.app.isRTL = false; }},
                            {label: 'RTL', icon: 'format_align_right', command: (event) => {this.app.isRTL = true; }}
                        ]
                    }
                ]
            },
            {
                label: 'Colors', icon: 'palette',
                items: [
                    {
                        label: 'Layout Palette', icon: 'palette',
                        items: [
                            {
                                label: 'Flat', icon: 'format_paint',
                                items: [
                                    {label: 'Blue Grey - Green', icon: 'brush', command: (event) => {this.changeLayout('bluegrey'); }},
                                    {label: 'Indigo - Pink', icon: 'brush', command: (event) => {this.changeLayout('indigo'); }},
                                    {label: 'Pink - Amber', icon: 'brush', command: (event) => {this.changeLayout('pink'); }},
                                    {label: 'Deep Purple - Orange', icon: 'brush', command: (event) => {this.changeLayout('deeppurple'); }},
                                    {label: 'Blue - Amber', icon: 'brush', command: (event) => {this.changeLayout('blue'); }},
                                    {label: 'Light Blue - Blue Grey', icon: 'brush',
                                        command: (event) => {this.changeLayout('lightblue'); }},
                                    {label: 'Cyan - Amber', icon: 'brush', command: (event) => {this.changeLayout('cyan'); }},
                                    {label: 'Teal - Red', icon: 'brush', command: (event) => {this.changeLayout('teal'); }},
                                    {label: 'Green - Brown', icon: 'brush', command: (event) => {this.changeLayout('green'); }},
                                    {label: 'Light Green - Purple', icon: 'brush', command: (event) => {this.changeLayout('lightgreen'); }},
                                    {label: 'Lime - Blue Grey', icon: 'brush', command: (event) => {this.changeLayout('lime'); }},
                                    {label: 'Yellow - Teal', icon: 'brush', command: (event) => {this.changeLayout('yellow'); }},
                                    {label: 'Amber - Pink', icon: 'brush', command: (event) => {this.changeLayout('amber'); }},
                                    {label: 'Orange - Indigo', icon: 'brush', command: (event) => {this.changeLayout('orange'); }},
                                    {label: 'Deep Orange - Cyan', icon: 'brush', command: (event) => {this.changeLayout('deeporange'); }},
                                    {label: 'Brown - Cyan', icon: 'brush', command: (event) => {this.changeLayout('brown'); }},
                                    {label: 'Grey - Indigo', icon: 'brush', command: (event) => {this.changeLayout('grey'); }}
                                ]
                            },
                            {
                                label: 'Special', icon: 'format_paint',
                                items: [
                                    {label: 'Reflection', icon: 'brush', command: (event) => {this.changeLayout('reflection'); }},
                                    {label: 'Moody', icon: 'brush', command: (event) => {this.changeLayout('moody'); }},
                                    {label: 'Cityscape', icon: 'brush', command: (event) => {this.changeLayout('cityscape'); }},
                                    {label: 'Cloudy', icon: 'brush', command: (event) => {this.changeLayout('cloudy'); }},
                                    {label: 'Storm', icon: 'brush', command: (event) => {this.changeLayout('storm'); }},
                                    {label: 'Palm', icon: 'brush', command: (event) => {this.changeLayout('palm'); }},
                                    {label: 'Flatiron', icon: 'brush', command: (event) => {this.changeLayout('flatiron'); }}
                                ]
                            },
                        ]
                    },
                    {
                        label: 'Themes', icon: 'brush', badge: '5',
                        items: [
                            {label: 'Blue Grey - Green', icon: 'brush', command: (event) => {this.changeTheme('bluegrey'); }},
                            {label: 'Indigo - Pink', icon: 'brush', command: (event) => {this.changeTheme('indigo'); }},
                            {label: 'Pink - Amber', icon: 'brush', command: (event) => {this.changeTheme('pink'); }},
                            {label: 'Purple - Pink', icon: 'brush', command: (event) => {this.changeTheme('purple'); }},
                            {label: 'Deep Purple - Orange', icon: 'brush', command: (event) => {this.changeTheme('deeppurple'); }},
                            {label: 'Blue - Amber', icon: 'brush', command: (event) => {this.changeTheme('blue'); }},
                            {label: 'Light Blue - Blue Grey', icon: 'brush', command: (event) => {this.changeTheme('lightblue'); }},
                            {label: 'Cyan - Amber', icon: 'brush', command: (event) => {this.changeTheme('cyan'); }},
                            {label: 'Teal - Red', icon: 'brush', command: (event) => {this.changeTheme('teal'); }},
                            {label: 'Green - Brown', icon: 'brush', command: (event) => {this.changeTheme('green'); }},
                            {label: 'Light Green - Purple', icon: 'brush', command: (event) => {this.changeTheme('lightgreen'); }},
                            {label: 'Lime - Blue Grey', icon: 'brush', command: (event) => {this.changeTheme('lime'); }},
                            {label: 'Yellow - Teal', icon: 'brush', command: (event) => {this.changeTheme('yellow'); }},
                            {label: 'Amber - Pink', icon: 'brush', command: (event) => {this.changeTheme('amber'); }},
                            {label: 'Orange - Indigo', icon: 'brush', command: (event) => {this.changeTheme('orange'); }},
                            {label: 'Deep Orange - Cyan', icon: 'brush', command: (event) => {this.changeTheme('deeporange'); }},
                            {label: 'Brown - Cyan', icon: 'brush', command: (event) => {this.changeTheme('brown'); }},
                            {label: 'Grey - Indigo', icon: 'brush', command: (event) => {this.changeTheme('grey'); }}
                        ]
                    }
                ]
			}
		]
		};


        this.origModel = [
			{label: 'Home', icon: 'dashboard', routerLink: ['/']},
			{label: 'My Projects', icon: 'folder', routerLink: ['/my/projects']},
			this.myClassesMenu,

			this.settingsMenu,

			/*
            {
                label: 'Components', icon: 'list', badge: '2', badgeStyleClass: 'orange-badge',
                items: [
                    {label: 'Sample Page', icon: 'desktop_mac', routerLink: ['/sample']},
                    {label: 'Forms', icon: 'input', routerLink: ['/forms']},
                    {label: 'Data', icon: 'grid_on', routerLink: ['/data']},
                    {label: 'Panels', icon: 'content_paste', routerLink: ['/panels']},
                    {label: 'Overlays', icon: 'content_copy', routerLink: ['/overlays']},
                    {label: 'Menus', icon: 'menu', routerLink: ['/menus']},
                    {label: 'Messages', icon: 'message', routerLink: ['/messages']},
                    {label: 'Charts', icon: 'insert_chart', routerLink: ['/charts']},
                    {label: 'File', icon: 'attach_file', routerLink: ['/file']},
                    {label: 'Misc', icon: 'toys', routerLink: ['/misc']}
                ]
            },
            {
                label: 'Template Pages', icon: 'get_app',
                items: [
                    {label: 'Empty Page', icon: 'hourglass_empty', routerLink: ['/empty']},
                    {label: 'Landing Page', icon: 'flight_land', url: 'assets/pages/landing.html', target: '_blank'},
                    {label: 'Login Page', icon: 'verified_user', routerLink: ['/login'], target: '_blank'},
                    {label: 'Error Page', icon: 'error', routerLink: ['/error'], target: '_blank'},
                    {label: '404 Page', icon: 'error_outline', routerLink: ['/404'], target: '_blank'},
                    {label: 'Access Denied Page', icon: 'security', routerLink: ['/accessdenied'], target: '_blank'}
                ]
            },
            {
                label: 'Menu Hierarchy', icon: 'menu',
                items: [
                    {
                        label: 'Submenu 1', icon: 'subject',
                        items: [
                            {
                                label: 'Submenu 1.1', icon: 'subject',
                                items: [
                                    {label: 'Submenu 1.1.1', icon: 'subject'},
                                    {label: 'Submenu 1.1.2', icon: 'subject'},
                                    {label: 'Submenu 1.1.3', icon: 'subject'},
                                ]
                            },
                            {
                                label: 'Submenu 1.2', icon: 'subject',
                                items: [
                                    {label: 'Submenu 1.2.1', icon: 'subject'},
                                    {label: 'Submenu 1.2.2', icon: 'subject'}
                                ]
                            },
                        ]
                    },
                    {
                        label: 'Submenu 2', icon: 'subject',
                        items: [
                            {
                                label: 'Submenu 2.1', icon: 'subject',
                                items: [
                                    {label: 'Submenu 2.1.1', icon: 'subject'},
                                    {label: 'Submenu 2.1.2', icon: 'subject'},
                                    {label: 'Submenu 2.1.3', icon: 'subject'}
                                ]
                            },
                            {
                                label: 'Submenu 2.2', icon: 'subject',
                                items: [
                                    {label: 'Submenu 2.2.1', icon: 'subject'},
                                    {label: 'Submenu 2.2.2', icon: 'subject'}
                                ]
                            },
                        ]
                    }
                ]
            },
            {label: 'Utils', icon: 'build', routerLink: ['/utils']},
			{label: 'Docs', icon: 'find_in_page', routerLink: ['/documentation']}
			*/
		];
		this.model = [...this.origModel];
    }

    changeTheme(theme) {
        const themeLink: HTMLLinkElement = document.getElementById('theme-css') as HTMLLinkElement ;
        themeLink.href = 'assets/theme/theme-' + theme + '.css';
    }

    changeLayout(theme) {
        const layoutLink: HTMLLinkElement = document.getElementById('layout-css') as HTMLLinkElement ;
        layoutLink.href = 'assets/layout/css/layout-' + theme + '.css';
	}



	// DUPLICATE CODE WITH CFHOME... FIX SOON

	private classesList: Observable<BsClass[]>;
	public _classesList: BsClass[] = [];
	classCount = 0;
	firstClassLoad = true;


	async doClassMenus() {

		if (this.currentClassInfo && this.currentClassInfo.theClass) {
			// gather assignments

			const theClass = this.currentClassInfo.theClass as BsClass;
			this.assignmentsList = await theClass.getAssignments();
			console.log('Debug assignments menu here');


			// gather user is instructor

			this.userIsInstructor = false;
			if (this.user && this.currentClassInfo && this.currentClassInfo.theClass) {
				this.userIsInstructor = await this.currentClassInfo.theClass.userIsInstructor(this.user);
			}
		}

		this.doMenu();
	}

	doMenu() {
		// reset menu
		this.model = [...this.origModel];

		if (!this.currentClassInfo || !this.theClass || !this.theClass.classId) {
			return;
		}

		// todo fix this later
		const fudgePath = '/class/utm/' + this.currentClassInfo.theClass.classId + '/';

		// my grades menu

		this.model = [
			...this.model,
			{label: 'My Grades', icon:'star', routerLink: fudgePath + 'my_grades'}
		];


		// assignments

		//const theClass = this.currentClassInfo.theClass as BsClass;
		const aItems = [];

		console.log('Debug assignments menu here');

		for (const a of this.assignmentsList) {
			if (!a.hidden) {
				aItems.push({label: a.name, icon: 'assignment', routerLink: fudgePath + 'assignments/' + a.__key});
			}
		}

		// , routerLink:fudgePath + 'assignments'
		this.model = [
			...this.model,
			{label: 'Assignments', icon:'assignment', items: aItems}
		];


		// instructor menu

		console.log('Debug Instructor Menu:  ', this.theClass.classId)
		if (this.userIsInstructor) {
			this.model = [
				...this.model,
				{label: 'Instructor', icon:'person_pin', items:[
					{label: 'Grade Book', icon:'book', routerLink:fudgePath + 'grade_book'},
					{label: 'Grade', icon: 'star', routerLink:fudgePath + 'grade'},
					{label: 'Students', icon: 'person', routerLink:fudgePath + 'students'},
					{label: 'Assignments', icon: 'assignment', routerLink:fudgePath + 'a_edit'},
					{label: 'Class Settings', icon: 'settings', routerLink:fudgePath + 'settings'}
				]}
			];
		}
	}

	getUsersClasses(u: IUser) {

		if (u) {
			this.classesList = this.classesUsers.getClassesForUserId(u.uid);
			this.classesList.subscribe(d => {
				console.log('Debug user classes here');

				this._classesList = d.sort((a,b) => (a['id']>b['id']) ? -1 : 1 );


				this.myClassesMenu.items = [];
				for(let c of this._classesList) {
					let cLink = c['id'];
					let sname = c['id'].replace(/_UTM_/g,' ');
					sname = sname.replace(/_/g, ' ');
					this.myClassesMenu.items.push({label: sname, routerLink: '/class/utm/' + cLink, icon: 'school'});
				}

				this.doClassMenus();

				console.log('New class count: ', this._classesList.length);
				console.log('Last class count: ', this.classCount);
				console.log('firstClassLoad: ', this.firstClassLoad);

				//this.classesTitle = '(' + d.length + ')';
				try {
					//this.cd.detectChanges()
				} catch (error) {

				}
				;
				// setTimeout(() => {
				// 	this.getClassSettings(); // why not async?
				// }, 50);

				const c = this._classesList.length;
				/*
				if (!this.firstClassLoad) {
					if (c > this.classCount  ) {
						this.ngZone.run( () => {
							this.showToast('Class Added', 'Looks like you have a new class.');
						});
					}
					else if (c < this.classCount) {
						this.ngZone.run( () => {
							this.showToast('Class Removed', 'Looks like you had a class removed.', 'warn');
						});
					}
				}
				*/
				this.classCount = c;
				this.firstClassLoad = false;
			});
		}
		else {
			this.classesList = null;
			//this.classesTitle = '(No User)';
		}
		try {
			//this.cd.detectChanges();
		} catch (error) {

		}

	}

/* 	async getClassSettings() {

		// this._classesList.forEach(async c => {
		for (const k in this._classesList) {
			const c = this._classesList[k];
			console.log('debug me');
			let id = c.classId || c['id'];
			c.settings = await this.classesUsers.loadClassSettings(id);
			this._classesList[k].settings = c.settings; // ? do we have to
			let s: any = {width: '100%', height: '120px'};
			if (c.settings && c.settings.image) {
				console.log('found a settings');
				s =	{...s, 'background-position' : 'center',
							'background-image': 'url("' + c.settings.image + '"'};
			}
			else {
				s = {...s, 'background-color' : 'gray'};
			}
			c['style'] = s;
			this._classesList[k]['style'] = c['style'];
			console.log(this._classesList[k]['style']);
			try {
				//this.cd.detectChanges();
			} catch (error) {

			}


			console.log('and here');
		}
		setTimeout(() => {
			//this.isReady = true;
			try {
				//this.cd.detectChanges();
			} catch (error) {

			}

		}, 500);


	} */

	/*
	joinClassId: string;

	joinClass(id) {
		if (id !== '') {
			//setTimeout(() => {
				this.ngZone.run(() => this.router.navigateByUrl('/class/utm/' + id));
			//}, 50);

		}
	}


	goMyProjects() {
		this.router.navigateByUrl('/my/projects');
	}


	goClass(c: BsClass) {
		this.router.navigateByUrl('/class/utm/' + c['id']);
	}
	*/
}

@Component({
    /* tslint:disable:component-selector */
    selector: '[app-submenu]',
    /* tslint:enable:component-selector */
    template: `
        <ng-template ngFor let-child let-i="index" [ngForOf]="(root ? item : item.items)">
            <li [ngClass]="{'active-menuitem': isActive(i)}" [class]="child.badgeStyleClass">
                <a [href]="child.url||'#'" (click)="itemClick($event,child,i)" *ngIf="!child.routerLink"
                   [attr.tabindex]="!visible ? '-1' : null" [attr.target]="child.target"
                   (mouseenter)="onMouseEnter(i)" class="ripplelink">
                    <i class="material-icons">{{child.icon}}</i>
                    <span class="menuitem-text">{{child.label}}</span>
                    <i class="material-icons layout-submenu-toggler" *ngIf="child.items">keyboard_arrow_down</i>
                    <span class="menuitem-badge" *ngIf="child.badge">{{child.badge}}</span>
                </a>

                <a (click)="itemClick($event,child,i)" *ngIf="child.routerLink"
                   [routerLink]="child.routerLink" routerLinkActive="active-menuitem-routerlink"
                   [routerLinkActiveOptions]="{exact: true}" [attr.tabindex]="!visible ? '-1' : null" [attr.target]="child.target"
                   (mouseenter)="onMouseEnter(i)" class="ripplelink">
                    <i class="material-icons">{{child.icon}}</i>
                    <span class="menuitem-text">{{child.label}}</span>
                    <i class="material-icons layout-submenu-toggler" *ngIf="child.items">>keyboard_arrow_down</i>
                    <span class="menuitem-badge" *ngIf="child.badge">{{child.badge}}</span>
                </a>
                <ul app-submenu [item]="child" *ngIf="child.items && isActive(i)" [visible]="isActive(i)" [reset]="reset"
                    [parentActive]="isActive(i)" [@children]="(app.isHorizontal())&&root ? isActive(i) ?
                    'visible' : 'hidden' : isActive(i) ? 'visibleAnimated' : 'hiddenAnimated'"></ul>
            </li>
        </ng-template>
    `,
    animations: [
        trigger('children', [
            state('void', style({
                height: '0px'
            })),
            state('hiddenAnimated', style({
                height: '0px'
            })),
            state('visibleAnimated', style({
                height: '*'
            })),
            state('visible', style({
                height: '*',
                'z-index': 100
            })),
            state('hidden', style({
                height: '0px',
                'z-index': '*'
            })),
            transition('visibleAnimated => hiddenAnimated', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)')),
            transition('hiddenAnimated => visibleAnimated', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)')),
            transition('void => visibleAnimated, visibleAnimated => void',
                animate('400ms cubic-bezier(0.86, 0, 0.07, 1)'))
        ])
    ]
})
export class AppSubMenuComponent {

    @Input() item: MenuItem;

    @Input() root: boolean;

    @Input() visible: boolean;

    _reset: boolean;

    _parentActive: boolean;

    activeIndex: number;

    constructor(
		public app: AppMainComponent,
		public router: Router,
		public location: Location,
		public appMenu: AppMenuComponent) {}

    itemClick(event: Event, item: MenuItem, index: number) {
        if (this.root) {
            this.app.menuHoverActive = !this.app.menuHoverActive;
            event.preventDefault();
        }

        // avoid processing disabled items
        if (item.disabled) {
            event.preventDefault();
            return true;
        }

        // activate current item and deactivate active sibling if any
        if (item.routerLink || item.items || item.command || item.url) {
            this.activeIndex = (this.activeIndex as number === index) ? -1 : index;
        }

        // execute command
        if (item.command) {
            item.command({originalEvent: event, item});
        }

        // prevent hash change
        if (item.items || (!item.url && !item.routerLink)) {
          setTimeout(() => {
            this.appMenu.layoutMenuScrollerViewChild.moveBar();
          }, 450);

          event.preventDefault();
        }

        // hide menu
        if (!item.items) {
            if (this.app.isMobile()) {
                this.app.sidebarActive = false;
                this.app.mobileMenuActive = false;
            }

            if (this.app.isHorizontal()) {
                this.app.resetMenu = true;
            } else {
                this.app.resetMenu = false;
            }

            this.app.menuHoverActive = !this.app.menuHoverActive;
        }
    }

    onMouseEnter(index: number) {
        if (this.root && this.app.menuHoverActive && this.app.isHorizontal()
            && !this.app.isMobile() && !this.app.isTablet()) {
            this.activeIndex = index;
        }
    }

    isActive(index: number): boolean {
        return this.activeIndex === index;
    }

    @Input() get reset(): boolean {
        return this._reset;
    }

    set reset(val: boolean) {
        this._reset = val;

        if (this._reset && (this.app.isHorizontal() || this.app.isOverlay())) {
            this.activeIndex = null;
        }
    }

    @Input() get parentActive(): boolean {
        return this._parentActive;
    }

    set parentActive(val: boolean) {
        this._parentActive = val;

        if (!this._parentActive) {
            this.activeIndex = null;
        }
    }
}
