// bs-assignment-tester service   10/2017 Bob Bradley
// This is the thing that can auto test a user's program
// It is currently called from bs-terminal-wrap compoent...

// NOTE: 5/28/2018 BB The lastest fixes I made today
//                    seem to make it much more reliable, but...
// TODO: Need to rewrite this mess as a state machine process!

// TODO FIX
// This is a BAD TEST CASE OF WHERE IT TAKES FOREVER AND HANGS...
// http://localhost:4200/class/utm/201840_UTM_CSIS_221_01/grade/lab_01_2/muhsoru@ut_utm_edu

import { Injectable } from '@angular/core';
import { IGraderTest, iBsAssignmentTesterService } from '@cf-platform/cf-core-cms';
import { UtilStuff } from '@cf-platform/cf-core-cms';



@Injectable()
export class BsAssignmentTesterService implements iBsAssignmentTesterService {

	private styleMatched = 'style="background-color: #88ff88;color: black;"';
	private styleNotFound = 'style="background-color: #ff8888;color: black;"';

	private script: string;
	private runTest: IGraderTest;
	private programIsDone: boolean = false;

	constructor() { }

	public OnStdout(stdout: string) {  // called when program outputs something
		const lns = stdout.split(/\r?\n/);

		let start = 0;
		if (this.outputA.length > 0 && lns.length > 0) {
			// append the first line of output to the last received **
			this.outputA[this.outputA.length - 1].txt += lns[0];
			start = 1;
		}

		for (let i = start; i < lns.length; i++) {  // push the rest  **
			const ln = lns[i];
			const o = { txt: ln, matched: false };
			this.outputA.push(o);
		}
	}

  public OnProgramDone() {  // called when program is done
    console.log("Program says it is done.");
		this.programIsDone = true;
	}

	private scmds: any[];  // array of script commands to process
	private scline = 0;    // pointer to current script command

	private outputA: any[]; // array of output lines to process and flags
	private outputLinesP = 0;    // pointer to current line processing
	private outputLinesLastMatch = 0; // pointer to the last successful match
  private outputLinesLastMatchOrSendTime = 0; // time of last match // or send

  private currentCmd: any = {txt:'NONE'}; // the current command being processed wait/send

	//

	private printFunc; // function to use to print output back to user
	private sendFunc;  // function used to send input to running program
	private statFunc;  // function to show grader status to user
	private scriptSessionId: number;

	public doTestScript(scriptSessionId: number, runTest: IGraderTest,
		printFunc: (s: string) => void,  // Function that lets it print in ther terminal
		sendFunc: (s: string) => void,   // Function that lets it send data to the process
		statFunc: (s: string) => void) { // Function to display current status
		this.statFunc = statFunc;
		console.log('Debug script');
		this.statFunc('Grader Started');

		this.printFunc = printFunc;
		this.sendFunc = sendFunc;
		this.scriptSessionId = 0;
		this.programIsDone = false;

		if (runTest) {
			this.runTest = runTest;
			this.script = runTest.data;
		}

		this.scmds = [];
		this.outputA = [];
		this.outputLinesP = 0;
		this.outputLinesLastMatch = 0;
		this.scline = 0;
		this.outputLinesLastMatchOrSendTime = new Date().getTime();

		const lines = this.script.split(/\r?\n/);
		for (const l of lines) {
			if (l.trim().startsWith('#')) {
				const r = UtilStuff.processWaitStr(l);
				const c = { cmd: 'wait', reg: r, txt: l.trim() };
				this.scmds.push(c);
			}
			else {
				const c = { cmd: 'send', txt: l.trim() };
				this.scmds.push(c);
			}
		}
		///// TODO... NEED TO REDO THIS WITHOUT THE INTERVAL THING...
		this.scriptTimer = setTimeout(() => this.processScript(), 500);
	}

	private scriptTimer;
	public stopScript(scriptSessionId: number, timeo = 0) {
		if (scriptSessionId !== this.scriptSessionId) {
			// hack needed because of the scriptInterval timer thing that keeps firing...
			// may not need it now... but might...
			console.log('stopScript called with old sessionId. Ignoring.');
			return;
		}

		if (timeo > 0) {
			// If a time is given, stop the script in that many seconds.
			console.log('Script will be stopped in: ' + timeo);
			setTimeout(() => {
				console.log('stopScript timer expired...');
				this.stopScript(this.scriptSessionId, 0);
			}, timeo);
			return;
		}

		if (!this.scriptTimer) { // .scriptInterval){
			console.log('Script already stopped');
			return;
		}

		clearTimeout(this.scriptTimer);
		this.scriptTimer = null;

		if (this.scline < this.scmds.length) {
			console.log('Grader Script stopped, before end of script!!!!!!!!!!!!');
			const c = this.scmds[this.scline];
			console.log('CMD: ' + c.cmd + ': ' + c.txt);
		}
		else {
			console.log('Grade Script done! All steps finished!!!!!!!!');
		}

		let numWaits = 0;
		let numWaitsFound = 0;

		let nf = '';
		for (const c of this.scmds) {
			if (c.cmd === 'wait') {
				numWaits++;
				if (!c.found) {
					nf += 'NOT FOUND: ' + c.txt + ': Started at LN: ' + c.startedAt + '\r\n'; // + " : " + this.outputA[c.startedAt].txt + "\r\n";
				}
				else {
					numWaitsFound++;
					nf += 'Found ---: ' + c.txt + ': LN: ' + c.foundAt + '\r\n'; // + ": " + c.reg + ": " + c.foundLine + "\r\n";
				}
			}
		}
		let style;
		if (nf !== '') {
			style = 'style="background-color: #555500;color: yellow;"';
		}
		this.printFunc('<span ' + style + '>[ SCRIPT REPORT ]</span>\r\n');

		let sc = 0;
		let cmd = this.scmds[sc];
		for (let i = 0; i < this.outputA.length; i++) {
			let foundBadOne = false;
			while (sc < this.scmds.length) { // } && this.scmds[sc].startedAt <= i){
				cmd = this.scmds[sc];
				if (cmd.cmd === 'wait' && cmd.found) {
					if (cmd.foundAt >= i) {
						break;
					}
				}
				if (cmd.cmd === 'wait' && !cmd.found) {
					foundBadOne = true;
					let m = 'WANT:' + cmd.txt; //  + ": Started at LN: " + cmd.startedAt;
					m = '<span ' + this.styleNotFound + '>' + m + '</span>';
					this.printFunc(m + '\r\n');
				}
				sc++;
			}

			let fi = '    ' + i;
			fi = fi.substr(fi.length - 4);
			let ln = this.outputA[i].txt;
			if (this.outputA[i].matched) {
				ln = '<span ' + this.styleMatched + '>' + ln + '</span>';
			}
			this.printFunc(fi + ': ' + ln + '\r\n');
		}

		let style2 = this.styleMatched;
		if (numWaits !== numWaitsFound) {
			style2 = this.styleNotFound;
		}
		let m = 'There were ' + numWaitsFound + ' of ' + numWaits + ' matches found!';
		this.statFunc(m);
		m = '<span ' + style2 + '>' + m + '</span>';

		this.printFunc(m + '\r\n');

		this.printFunc('<span ' + style + '>[ SCRIPT FINISHED ]</span>\r\n');
	}

	private processScript() {
		console.log('ProcessScript...');
		if (!this.scriptTimer) { // this.scriptInterval){
			console.log('Script already stopped');
			this.statFunc('Grader script stopped.');
			return;
		}

		if (this.scline >= this.scmds.length) {
			console.log('Done with script');
			this.statFunc('Done with grader script.');
			this.stopScript(this.scriptSessionId);
			return;
		}

    let cmd = this.scmds[this.scline];
    this.currentCmd = cmd;

		console.log('CMD: ' + cmd.cmd + ': ' + cmd.txt);
		this.statFunc('CMD: ' + cmd.cmd + ': ' + cmd.txt);

		if (cmd.cmd === 'send') {
			this.sendFunc(cmd.txt);
      this.scline++;
      this.outputLinesLastMatchOrSendTime = new Date().getTime(); // zzz
		}
		else if (cmd.cmd === 'wait') {
			if (!cmd.startedAt) {
				cmd.startedAt = this.outputLinesP;
			}
			while (cmd.cmd === 'wait' && this.outputLinesP < this.outputA.length) {
        let ln: string = this.outputA[this.outputLinesP].txt;
				ln = ln.toLowerCase();
				let matched = false;
				if (this.runTest.type === 'text') {
					let m: string = cmd.txt;
					if (m[0] === '#') {
						m = m.substr(1);
					}
					m = m.toLowerCase();
					//matched = (ln.trim().indexOf(m) >= 0);
					matched = (ln.indexOf(m) >= 0);
				}
				else {
					matched = !!ln.match(cmd.reg);
				}
				if (matched) {
					console.log('Found match: ' + cmd.txt);
					cmd.found = true;
					cmd.foundLine = ln;
					cmd.foundAt = this.outputLinesP;
					this.outputA[this.outputLinesP].matched = true;
					this.outputLinesLastMatch = this.outputLinesP;
					this.outputLinesLastMatchOrSendTime = new Date().getTime();
					this.scline++;
					if (this.scline < this.scmds.length) {
            cmd = this.scmds[this.scline];
            this.currentCmd = cmd;
						if (!cmd.startedAt) {
							cmd.startedAt = this.outputLinesP;
						}
					}
					else {
						console.log('We are done with script!');
						this.stopScript(this.scriptSessionId);
						break;
					}
				}
				else {
					{
            console.log('Not found... Skipping Line...');
            console.log('cmd: ' + cmd.txt);
            // dump last few scanned lines for debugging
            let st=this.outputLinesP - 3;
            if (st < 0)
              { st = 0; }
            for(var i=st; i <= this.outputLinesP; i++){
              console.log('LN(' + i + '): ' + this.outputA[i].txt);
            }

					}
				}
        if (this.outputLinesP < (this.outputA.length)) // ??
            this.outputLinesP++;
			}

			if (cmd.cmd === 'wait' && this.outputLinesP >= this.outputA.length) {
				const now = new Date().getTime();

        let timeTillNext = 2000;
        if (this.outputLinesP == this.outputLinesLastMatch){
          timeTillNext = 5000; // wait longer if on last line
          console.log('On last line, so waiting longer');
        }
				if (this.programIsDone) {
					timeTillNext = 200;
				}

				if ((now - this.outputLinesLastMatchOrSendTime) > timeTillNext) {
          console.log('Match not found: ' + this.currentCmd.txt);
          console.log('Rolling back to look for next cmd');
					this.outputLinesLastMatchOrSendTime = new Date().getTime();
					this.outputLinesP = this.outputLinesLastMatch;

					this.scline++;
					if (this.scline < this.scmds.length) {
            cmd = this.scmds[this.scline];
            this.currentCmd = cmd;
					}
					else {
						console.log('We are done with script!');
						this.stopScript(this.scriptSessionId);
						return;
					}
				}
			}
		}

		let waitTime = 500; // mSec
		if (this.programIsDone) {
			waitTime = 50;
		}
		setTimeout(() => {
			this.processScript();
		}, waitTime);
	}
}
