import { Component, OnInit, Input, AfterViewInit, OnDestroy, ChangeDetectorRef } from '@angular/core';

import {IProjectInfo, IAssignment} from "@cf-platform/cf-core-cms";

import { BsAssignmentsService } from '../../services/bs-assignments.service';
import { BsUserProjectsService } from '@cf-platform/cf-core-cms-ng';
import { IClassAssignmentStats } from '../../interfaces/IClassAssignmentStats';
import { IMyAssignmentStats } from '../../interfaces/IMyAssignmentStats';
import { IFolderItem } from '@cf-platform/cf-core-cms';
import { Router } from '@angular/router';

import { EzClassUserServiceService, EZClassUserInfo } from '../../services/ez-class-user-service.service';

@Component({
	selector: 'app-my-assignment-stat',
	templateUrl: './my-assignment-stat.component.html',
	styleUrls: ['./my-assignment-stat.component.css']
})
export class MyAssignmentStatComponent implements OnInit, AfterViewInit, OnDestroy {

	@Input() currentUserEmail: string;
	@Input() classId: string;
	//@Input() assignmentId: string;
	@Input() item: IFolderItem; //??

	assignment: IAssignment;

	userIsInstructor = false;
	needsGrading = false;

	// class stats
	classStats: IClassAssignmentStats;

	// my stats
	myStats: IMyAssignmentStats;


	gradeInfo: any; //??
	isAssignmentLoaded: boolean;

	unSubForStats: any;

	get myStat(): string {

		if (!this.myStats || !this.myStats.isAssignmentLoaded) {
			return '?';
		}
		return this.myStats.myStat;

	}

	isLate(): boolean {
		if (this.myStats) {
			return this.myStats.isLate;
		}
		return false;
	}

	ezClassUserInfo: EZClassUserInfo = {validClass:false, validUser:false};

	constructor(
		private assignmentsService: BsAssignmentsService,
		private projectService: BsUserProjectsService,
		private cdRef: ChangeDetectorRef,
		private router: Router,
		private ezClassUserServiceService: EzClassUserServiceService
	) { }

	goGrade() {
		//console.log("DEBUG goGrade");
		//debugger;
		const gPath = "/class/utm/" + this.classId + "/grade/" + this.assignment.__key;
		this.router.navigateByUrl(gPath);

		return false;
	}

	sub: any;

	ngOnInit() {

	}

	ngAfterViewInit() {
		setTimeout(() => {
			this.loadAssignmentInfo();
			// TODO fix zbobb stuff with call to isinstructor
			//if (this.currentUserEmail === "zbobbradley@gmail.com") {
			//if (this.ezClassUserInfo.validClass && this.ezClassUserInfo.isInstructor)
			//	this.userIsInstructor = true;
			//}

			console.log("debug:ez");
			this.sub = this.ezClassUserServiceService.EZClassUserInfoHandler.subscribe(u => {
				console.log("EZClassUserInfoHandler", u);
				this.ezClassUserInfo = u;

				this.userIsInstructor = (u.validClass && u.isInstructor);
			});


		}, 500);
	}

	ngOnDestroy() {
		this.cdRef.detach(); // do this
		if (this.unSubForStats) {
			this.unSubForStats();
		}

		if (this.sub) {
            this.sub.unsubscribe();
        }
	}



	async loadAssignmentInfo() {

		if (this.cdRef['destroyed']) {
			//console.log('ignoring invalid loadAssignmentInfo');
			return;
		}

		console.log("Debug loadAssignmentInfo");
		const nAssignment = await this.assignmentsService.getAssignment(this.classId, this.item.assignmentId);


		this.isAssignmentLoaded = false;

		//this.classStats = this.assignmentsService.getClassStatsForAssignment(nAssignment);


		this.assignment = nAssignment;

		if (!this.assignment) {
			return;
		}

		if (this.unSubForStats) {
			this.unSubForStats();
			this.unSubForStats = null;
		}
		//console.log('subbing to class stats for: ' + this.assignment.assignmentId);
		this.unSubForStats = this.assignmentsService.watchClassStatsForAssignment(this.assignment,
			(classStats) => {
				setTimeout(() => {  // this should be done async
					//console.log('my-assignment-stat got new classStats');
					this.classStats = classStats;

					this.needsGrading = (this.classStats.gradedCount < this.classStats.submittedCount);

					this.cdRef.detectChanges();
				});

			});


		setTimeout( async() => {


			const projPath = this.classId + ':' + this.item.assignmentId;
			const projectUrl = '/my/projects/' + projPath;
			const aName: string = this.assignment.project.name;
			if (!aName.startsWith(projPath)) {
				this.assignment.project.name = projPath + ': ' + aName;
			}
			this.assignment.projPath = projPath;
			const project = await this.projectService.loadUserProject(this.currentUserEmail, projPath);

			this.isAssignmentLoaded = true;

			this.myStats = await this.assignmentsService.getUserStatsForAssignment(this.currentUserEmail, this.assignment, project);

			//this.cdRef.detectChanges();

		}, 500);
	}

}
