import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { AngularFireAuth } from '@angular/fire/auth';
import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
//import { AngularFireDatabase } from '@angular/fire/database';
import { BsUser, IBsUser } from '../bs-admin/bs-users/bs-user';
import { BsUtilsService } from './bs-utils.service';
import { Subscription } from 'rxjs/Subscription';

@Injectable()
export class BsCurrentUserService {
	public currentFbUserOb: Observable<firebase.User>;
	public currentFbUser: firebase.User;
	public currentUserOb: Observable<BsUser>;
	public currentUser: BsUser;
	private bsCurrentUserSubject: BehaviorSubject<BsUser> = new BehaviorSubject<BsUser>(null);

	private uSub: Subscription;
	private cbsuSub: Subscription;

	private userRef: firebase.database.Reference;

	reauthFlag: boolean = false;

	constructor(private afAuth: AngularFireAuth) {
		const me = this;

		console.log('BsCurrentUserService: Starting up...');
		console.log('user subscribe');

		this.currentFbUserOb = afAuth.authState; // dup
		this.currentUserOb = this.bsCurrentUserSubject;


		this.uSub = this.currentFbUserOb.subscribe(user => {
			console.log('BsCurrentUserService: FB User subscribe fire');
			this.currentFbUser = user;

			if (user === null) {
				console.log('Ready');
			}
			else if (user.email == null && user.providerData[0].email == null) {
				console.log('User\'s email was not set. Doing a reauth request');
				if (me.reauthFlag) {
					console.log('Already did a reauth. Please hit your browser reload to try again.');
					return;
				}
				const provider = new firebase.auth.GoogleAuthProvider();
				provider.addScope('email');

				user.reauthenticateWithRedirect(provider).then(() => {
					// User re-authenticated.
					console.log('Reauth was successful');
				},
					// An error happened.
					(error) => {
						console.log('Reauth was UNsuccessful');
					});
			}
			else {
				// onlne persistance...
				console.log('Not Ready');

				if (this.userRef) {
					this.userRef.off();
				}

				this.userRef = firebase.database().ref().child('/users/' + this.currentFbUser.uid);
				this.userRef.onDisconnect().update({ status: 'offline', timestamp: firebase.database.ServerValue.TIMESTAMP });
				this.updateStatus(true, null, null);

				if (!afAuth['zSignOut']) {
					afAuth['zSignOut'] = () => {
						console.log('Doing patched update signout...');
						me.userRef.update({ status: 'offline-signout', timestamp: firebase.database.ServerValue.TIMESTAMP })
							.then(() => {
								console.log('Updated OK signout...');
								me.bsCurrentUserSubject.next(null);
								afAuth.auth.signOut();
							})
							.catch(() => {
								console.log('Updated Err signout...');
								me.bsCurrentUserSubject.next(null);
								afAuth.auth.signOut();
							});
					};
				}

				this.userRef.on('value', (snap) => {
					let uData: any = snap.val();
					user = me.currentFbUser;

					if (user == null) {
						console.log('userRef change, but fbUser is null');
						me.userRef.off(); // stop trying to write if there is no user
						return;
					}

					if (uData == null) {
						console.log('uData was null');
						uData = {};
					}

					const ud: IBsUser = JSON.parse(JSON.stringify(uData)); // as IBsUser;
					if (ud.name !== user.displayName) {
						ud.name = user.displayName;
						ud.firstName = BsUtilsService.firstName(user.displayName);
						ud.lastName = BsUtilsService.lastName(user.displayName);
					}

					if (user.providerData) {
						ud.provider = user.providerData[0];
					}

					ud.photoURL = user.photoURL;
					ud.status = 'online';
					ud.timestamp = firebase.database.ServerValue.TIMESTAMP;
					ud.uid = user.uid;
					ud.groups = uData.groups || { user: 1 };

					// THIS CODE NEEDS TO BE REDONE... RUN THE RISK OF LOOPING UPDATE STORMS

					if (!ud.creation || !ud.email || !ud.dbkey) {
						if (!ud.email) {
							ud.email = ud.provider.email; // new fix hack
						}

						if (!ud.creation) {
							ud.creation = firebase.database.ServerValue.TIMESTAMP;
						}

						console.log('Hello dbkey');
						// if (!ud.dbkey)
						ud.dbkey = BsUtilsService.z_filterEmailAsKey(ud.email); // new hack

						ud.email = user.email;
						if (!ud.email) {
							if (ud.provider && ud.provider.email) {
								ud.email = ud.provider.email;
							}
						}

						ud.isAnonymous = user.isAnonymous;
						// isAdmin: false, // todo
						me.userRef.update(ud)
							.catch((e) => {
								console.log('ERROR writing to user data.');
								me.userRef.off();
							});   // only write back if this is the first time.
					}


					const bu = new BsUser();
					bu._data = ud;
					bu.isReady = true;

					me.bsCurrentUserSubject.next(bu);
				}, () => {
					console.log('Error retreiving user info.');
				});

				this.updateStatus(true, null, null);

				if (this.cbsuSub) {
					this.cbsuSub.unsubscribe();
				}

				this.cbsuSub = this.currentUserOb.subscribe(u => {
					if (u != null && this.currentFbUser != null && this.currentFbUser.uid === u._key) {
						console.log('BsCurrentUserService: BS User subscribe fire: ' + u.name);
						console.log('Ready');
					}
					else {
						console.log('BsCurrentUserService: BS User subscribe fire: null');
					}
					console.log(u);
					this.currentUser = u;

				});
			}
		});
	}

	statStuff: any = {};

	updateStatus(isOnline: boolean, where: string, what: string) {
		// This function will update the status now if there is a valid user
		//  or stash the info so that when the user is processed it will save then...

		console.log('BsCurrentUserService: updateStatus');

		if (where != null) {
			this.statStuff.where = where;
		}
		if (what != null) {
			this.statStuff.what = what;
		}
		this.statStuff.status = isOnline ? 'online' : 'offline';


		if (this.currentFbUser && this.userRef) {

			this.statStuff.timestamp = firebase.database.ServerValue.TIMESTAMP;

			const me = this;
			this.userRef.update(this.statStuff)
				.then(() => {
					me.statStuff = {};
				});
		}
	}
}
