import { Component, OnInit, Input, Output, ViewEncapsulation } from '@angular/core';
import {SelectItem} from 'primeng/api';
//import { BsClass } from '../../classes/bs-class';
//import { EventEmitter } from 'events'; // bad
import { EventEmitter } from '@angular/core';

@Component({
  selector: 'cf-class-popup',
  templateUrl: './cf-class-popup.component.html',
  styleUrls: ['./cf-class-popup.component.css'],
  encapsulation:ViewEncapsulation.None
})
export class CfClassPopupComponent implements OnInit {

	@Input() set classIds(classes: Array<string>) {
		const classItems = classes.map( cId => {
			return {label: cId, value: cId} as SelectItem;
		});

		this.classItems = [ this.pleaseSelect, ...classItems];
	}
	@Input() selectedClassId;

	@Output() classChange = new EventEmitter<string>();

	pleaseSelect = {label: '(Select a class)', value:''};

	classItems: SelectItem[];
	//selectedClassItem: SelectItem = this.pleaseSelect;

  	constructor() { }

	ngOnInit() {
	}

	classChanged(e) {
		console.log('cf-class-popup got classChanged: ' + e.value); //this.selectedClassItem.value);
		const cId = e.value;
		//this.selectedClassItem = cId; // {label: cId, value: cId} as SelectItem;
		this.classChange.emit(cId);
	}

}
