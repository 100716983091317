// TODO ... REDO THIS CRAP OF DO.. TO JUST BE AN INTERFACE AND OR SIMPLE CLASS
// DO AWAY WITH THE .DATA AND GETTERS AND SETTERS

import { Observable } from 'rxjs/Observable';
import 'rxjs/Rx';
import { Subject } from 'rxjs/Subject';
import { AngularFireDatabase, AngularFireList } from '@angular/fire/database';
import * as firebase from 'firebase/app';
import { Injectable } from '@angular/core';
import { BsAttachmentService, BsAttachmentManager } from './bs-attachment.service';
import { BsCommentsService, BsCommentsManager } from './bs-comments.service';
import { BsUtilsService } from '../epic-core/services/bs-utils.service';
import { IFolderItem } from '../interfaces/IFolderItem';


const getIcon = function (itemType) {
	const iconInfo = { color: 'yellow', name: 'info' };

	if (itemType === 'folder') {
		iconInfo.name = 'folder';
	}
	else if (itemType === 'note') {
		iconInfo.name = 'note';
	}
	else if (itemType === 'event') {
		iconInfo.name = 'event';
	}
	else if (itemType === 'assignment') {
		iconInfo.name = 'playlist_add_check';
	}
	return iconInfo;
};

/* moved interface to interface folder...

export interface IFolderItem {
	name: string;
	description: string;
	parent_path: string;
	rel_path: string;
	my_path: string;
	type: string;
	user: string;
	create_timestamp: any;
	sort: any;
	classId?: string;
	assignmentId?: string;
	hidden?: boolean;
	attachments?: any;
}
*/

export class FolderItem implements IFolderItem {
	constructor(public _data,  // this prob needs to be private
		public bsFoldersManager: BsFolderManager,
		public bsAttachmentsManager: BsAttachmentManager,
		public bsCommentsManager: BsCommentsManager) { }

	summary_html = '';

		// need to move these to a different interface...
		summary_factory: any;
		summary_inputs: any;
		//detail_factory: any;
		detail_inputs: any;


	get hidden(): boolean {
		return this._data.hidden;
	}
	set hidden(hidden: boolean) {
		this._data.hidden = hidden;
		// todo save zzz
	}

	get _key(): string {
		return this._data['__key'];
	}

	get name(): string {
		return this._data.name;
	}

	set name(n: string) {
		this._data.name = n;
	}

	get description(): string {
		return this._data.description;
	}

	set description(d: string) {
		this._data.description = d;
	}


	get summary(): string {
		return this._data.summary;
	}

	set summary(d: string) {
		this._data.summary = d;
	}


	get parent_path(): string {
		return this._data.parent_path;
	}

	set parent_path(p: string) {
		this._data.parent_path = p;
	}

	get my_path(): string {
		return this._data.my_path || this._linkPath;
	}

	rel_path: string = this._data.rel_path;

	get type(): string {
		return this._data.type;
	}

	set type(t: string) {
		this._data.type = t;
	}

	get sort(): string {
		return this._data.sort;
	}

	public classId?: string;

	get assignmentId(): string {
		return this._data.assignment_id || this._data.assignmentId || this._data.rel_path;
	}

	set assignmentId(aid: string) {
		this._data.assignment_id = aid;
	}

	private _attachments: Observable<any[]>;
	get attachments(): Observable<any[]> {
		if (!this._attachments) {
			this._attachments = this.bsAttachmentsManager.getAttachments(this._key);
		}
		return this._attachments;
	}

	private _comments: Observable<any[]>;
	get comments(): Observable<any[]> {
		if (!this._comments) {
			this._comments = this.bsCommentsManager.getComments(this._key, 5); // CHANGE THIS
		}
		return this._comments;
	}

	private _childItems: Observable<Array<FolderItem>>;
	get childItems(): Observable<Array<FolderItem>> {
		if (!this._childItems) {
			this._childItems = this.bsFoldersManager.getFolders(this.my_path);
		}
		return this._childItems;
	}

	set childItems(childItems: Observable<Array<FolderItem>>) {
		this._childItems = childItems;
	}


	get _linkPath(): string {
		return this.parent_path + this.rel_path;
	}

	foo: string = 'fudge';

	getIcon2() {
		return getIcon(this.type);
	}
}

@Injectable()
export class BsFolderService {

	constructor(private db: AngularFireDatabase,
		private bsAttachments: BsAttachmentService,
		private bsComments: BsCommentsService,
		private bsUtils: BsUtilsService) {
	}

	getFolderManagerFor(feedDbPath: string): BsFolderManager {
		const bs: BsFolderManager = new BsFolderManager(this.db, feedDbPath, this.bsAttachments, this.bsComments);
		return bs;
	}
}




export class BsFolderManager {

	queryList: Observable<any>;
	// https://forum.ionicframework.com/t/type-observable-any-is-not-assignable-to-type-firebaselistobservable-any/75683
	fbQueryList: AngularFireList<any>;

	currentFolderPath: string = '';

	public bsAttachmentsManger: BsAttachmentManager;
	public bsCommentsManager: BsCommentsManager;

	constructor(private db: AngularFireDatabase, private feedDbPath: string, private bsAttachments: BsAttachmentService, private bsComments: BsCommentsService) {
		this.bsAttachmentsManger = bsAttachments.getAttachmentManagerFor(this.feedDbPath);
		this.bsCommentsManager = bsComments.getCommentsManager(this.feedDbPath);
	}

	// path can be either string or Subject<string>  maybe
	getFolders(path: any): Observable<Array<FolderItem>> {

		if (typeof path === 'string') {
			this.currentFolderPath = path;
			console.log('Getting folder items for string parent path: ' + path);
		}
		else {
			path.subscribe(s => {
				this.currentFolderPath = s;
				console.log('Getting folder items for subject parent path: ' + s);
			});    // TODO unsubscribe!!!!!
		}

		this.fbQueryList = this.db.list(this.feedDbPath + '/feed/folders', ref => ref.orderByChild('parent_path').equalTo(path));
		this.fbQueryList.valueChanges().subscribe(d => { });
		return this.getFoldersFromObservableData(this.fbQueryList);
	}

	getFoldersFromObservableData(oData: AngularFireList<any>): Observable<Array<FolderItem>> {

		this.queryList = oData.snapshotChanges().map((actions) => {
			const data = actions.map(action => ({ __key: action.key, ...action.payload.val() }));
			const fItems = new Array<FolderItem>();
			for (const d in data) {
				fItems.push(new FolderItem(data[d], this, this.bsAttachmentsManger, this.bsCommentsManager));
			}

			// Sort the list by the sort field
			fItems.sort((a, b) => {
				return a.sort < b.sort ? -1 : 1;
			});
			return fItems;
		});
		return this.queryList;
	}

	getItem(path: string): Observable<FolderItem> {

		this.fbQueryList = this.db.list(this.feedDbPath + '/feed/folders', ref => ref.orderByChild('my_path').equalTo(path).limitToLast(1));
		const item: Subject<FolderItem> = new Subject<FolderItem>();   // i smell a leak ?
		this.fbQueryList.snapshotChanges().map(actions => {
			return actions.map(action => ({ __key: action.key, ...action.payload.val() }));
		}).subscribe(n => {
			if (n && n.length > 0) {
				const obj = n[0];
				const f: FolderItem = new FolderItem(obj, this, this.bsAttachmentsManger, this.bsCommentsManager);
				item.next(f);
			}
			else {
				item.next(null);
			}
		});
		return item;
	}

	addFolderItem(itemType: string, rel_path: string, itemName: string, itemDesc: string, userEmail: string, assignmentId?: string) {
		const c: IFolderItem = {
			type: itemType,
			user: userEmail,
			name: itemName,
			description: itemDesc,
			create_timestamp: firebase.database.ServerValue.TIMESTAMP,
			parent_path: this.currentFolderPath,
			rel_path: rel_path,
			my_path: BsUtilsService.joinPaths(this.currentFolderPath, rel_path), // still not dry 1
			sort: firebase.database.ServerValue.TIMESTAMP
		};

		const p = c['my_path'];
		if (!p.startsWith('/')) {
			c['my_path'] = '/' + p;
		}

		if (assignmentId) {
			c.assignmentId = assignmentId;
			c['assignment_id'] = assignmentId; // hack hack
		}

		if (!c.parent_path.startsWith('/')) {
			c.parent_path = '/' + c.parent_path;
		}
		if (!this.fbQueryList) {
			this.getFolders(this.currentFolderPath);
		}
		this.fbQueryList.push(c);
	}

	saveFolderItem(item: FolderItem) {
		item._data.my_path = BsUtilsService.joinPaths(item.parent_path, item.rel_path);   // still not dry 1
		item._data.update_timestamp = firebase.database.ServerValue.TIMESTAMP;
		if (!item._data.create_timestamp) {
			item._data.create_timestamp = firebase.database.ServerValue.TIMESTAMP;
		}
		if (!item._data.sort) {
			item._data.sort = item._data.create_timestamp;
		}
		return this.fbQueryList.update(item._key, item._data);
	}

	newFolderItem(itemType: string, rel_path: string, parent_path: string, userEmail: string): IFolderItem {
		const f: IFolderItem = {
			type: itemType,
			name: '',
			description: '',
			parent_path: parent_path,
			rel_path: rel_path,
			my_path: parent_path + rel_path,
			user: userEmail,
			create_timestamp: firebase.database.ServerValue.TIMESTAMP,
			sort: firebase.database.ServerValue.TIMESTAMP
		};
		return f;
	}

	deleteFolderItem(item: FolderItem) {
		item._data.parent_path_orig = item.parent_path;
		item.parent_path += '/DELETED';
		item._data.is_deleted = true;
		console.log('Delete stashing item: ' + item._key);
		this.saveFolderItem(item);
	}

	moveItem(item: FolderItem, newPath: string) {
		const origPath = item.parent_path;
		const origMyPath = item.my_path;
		console.log('Moving item from: ' + origPath + '  to: ' + newPath);

		item._data.parent_path_orig = item.parent_path;
		item.parent_path = newPath;
		this.saveFolderItem(item);

		if (item.type === 'folder') {
			console.log('Moving folder children...');
			const childItems = this.getFolders(origMyPath);
			childItems.first().subscribe(data => {
				for (const d of data) {
					this.moveItem(d, BsUtilsService.joinPaths(newPath, item.rel_path));
				}
			});
		}
	}
}
