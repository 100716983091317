import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { ItemFrameComponent } from '../item-frame/item-frame.component';
import { ItemViewFactoryService } from '../item-view-factory.service';

@Component({
	selector: 'app-item-card-frame',
	templateUrl: './item-card-frame.component.html',
	styleUrls: ['./item-card-frame.component.css']
})
export class ItemCardFrameComponent extends ItemFrameComponent implements OnInit, OnDestroy, AfterViewInit {

	// The functions for this class are in the base class
	// The html & css are different

	viewMode = 'summary';

	constructor(itemViewFactory: ItemViewFactoryService) {
		super(itemViewFactory);
	}

}
