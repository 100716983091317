import { Component, OnInit, ViewChild, ElementRef, NgZone, Input } from '@angular/core';

import { ZLayeredCmdCanvas, ZCanvasCmdLayer } from '../z-layered-cmd-canvas';
import { ZTurtle } from '../z-turtle';

@Component({
	selector: 'z-turtle-scanvas',
	templateUrl: './z-turtle-canvas.component.html',
	styleUrls: ['./z-turtle-canvas.component.css']
})
export class ZTurtleCanvasComponent implements OnInit {

@ViewChild('myCanvas',{static: true}) myCanvas : ElementRef;
	zCanvas: ZLayeredCmdCanvas;
	title = "Turtle Power Graphics"
	bgcolor = 'white'

  	constructor(private _ngZone: NgZone) {

	}

  	public turtle1: ZTurtle;

	public async pubDoTurtle(cmd, id=null, param1=null, param2=null) {
		console.log("Hello from pubDoTurtle");
		//this._ngZone.run(() => this.doStartTest());
		//var me = this;
		await this._ngZone.run(async () =>   {
			console.log("Hi from in the zone");
			console.log(this);
			await this.doTurCmd(cmd, id, param1, param2);

			//this.doFunStuff(f);
			return true;
		});
		//return true;
	}

	managedTurtles: Array<ZTurtle> = [];

	public async doTurCmd(cmd, id=null, param1=null, param2=null) {
		if (cmd === 'clear') {
			this.zCanvas.clearAll();
			for(const t of this.managedTurtles) {
				t.killTurtle();
			}
			this.managedTurtles = [];
			this.title = "Turtle Power Graphics";
			this.bgcolor = "white";
		}
		else if (cmd === 'screen.title') {
			this.title = param1
		}
		else if (cmd === 'screen.bgcolor') {
			this.bgcolor = param1
		}
		else if (cmd === 'new') {
			this.managedTurtles.push(new ZTurtle(this.zCanvas));
		}
		else if (id < this.managedTurtles.length) {
			this.managedTurtles[id].enqueueCmd({cmd: cmd, param1: param1, param2: param2});
		}

	}

	public doFunStuff(f){
		var ff = f.bind(this);
		ff();
	}

  public async doStartTest(){
    //var turtle1 = turtle.Turtle(); //new Turtle(ctx);

	let testNum = 2;

	if (testNum >- 1) {
		var turtle1 = this.turtle1;


		turtle1.fillcolor("blue");
		turtle1.begin_fill();
		turtle1.pensize(4);

		//for i in range(5): # this actually goes 5 times, not 6
		for(var i = 1; i<= 5; i++){


			await turtle1.forward(100)
			turtle1.left(180) //# point to your butt first, now it will work
			turtle1.left(36)
			//turtle1.left(72);
		}
		turtle1.fill();
		turtle1.left(20);
		await turtle1.forward(10);

	}
	if (testNum >= 2) {

		console.log('Debug test 2 here');

		var tess = new ZTurtle(this.zCanvas); // turtle.Turtle()       # Create tess and set some attributes
		tess.color("pink"); //hotpink")
		tess.pensize(5)


		await tess.forward(80)             //# Make tess draw equilateral triangle
		tess.left(120)
		await tess.forward(80)
		tess.left(120)
		await tess.forward(80)
		tess.left(120)               //# Complete the triangle

		tess.right(180)              //# Turn tess around
		await tess.forward(80)             //# Move her away from the origin
	}
	if (testNum >= 3) {

		var alex = new ZTurtle(this.zCanvas); // turtle.Turtle()       # Create alex


		await alex.forward(50)             //# Make alex draw a square
		alex.left(90)
		await alex.forward(50)
		alex.left(90)
		await alex.forward(50)
		alex.left(90)
		await alex.forward(50)
		alex.left(90)

	}

	//let spiral = turtle.getTurtle(this.zCanvas)
	const spiral = new ZTurtle(this.zCanvas);

	for (let i = 1; i < 19; i++) {
		await spiral.forward(i * 14)
		spiral.right(144)
	}


	this.zCanvas.clearAll();

	let painter = new ZTurtle(this.zCanvas);

	painter.pencolor("blue")

	for (let i=0; i<50; i++){
		await painter.forward(50)
		painter.left(123) //# Let's go counterclockwise this time
	}

	painter.pencolor("red")
	for (let i=0; i<50; i++){
		await painter.forward(100)
		painter.left(123)
	}


	// --

	const randint = (min,max) => Math.floor(Math.random()*(max-min+1)+min);


	this.zCanvas.clearAll();

	let x = 1;
	const t = new ZTurtle(this.zCanvas);

	while (x < 400) {

		let r = randint(0,255) //#makes variables r,g,b whose value is an integer,
		let g = randint(0,255) //# which is between 0 and 255. It is random, and
		let b = randint(0,255) //# changes every time the loop runs

		t.colormode(255) //# this is something that is irrelevant at this point
				//# check the pythondocs link at the end for more info


		t.pencolor(r,g,b) //# changes the color of the pen to the rgb coordinates
						//# obtained by the variables r, g, b changing each time

		await t.fd(50 + x)
		t.rt(90.911)


		x = x+1
	}


  }

  	ngOnInit() {
	  	this.zCanvas = new ZLayeredCmdCanvas(this.myCanvas.nativeElement);

		var ctx = this.myCanvas.nativeElement.getContext("2d");
		//_g_turtle_context = ctx;

		window['TurtlePower'] = this.pubDoTurtle.bind(this);

		this.turtle1 = new ZTurtle(this.zCanvas);

		setTimeout(() => {
			// this._ngZone.run(() =>  this.doStartTest());
		}, 1000);




		/*
			ctx.beginPath();
			ctx.moveTo(20,20);
			ctx.lineTo(20,100);
			ctx.moveTo(20,90);
			ctx.lineTo(100,100);
			ctx.lineTo(20,20);
			ctx.stroke();
			//ctx.rect(20, 20, 150, 100);
			ctx.fillStyle = "red";
			ctx.fill();
		*/

			//ctx.moveTo(0,0);
			//ctx.lineTo(200,100);
			//ctx.stroke();

			/*
			var t = new Turtle(ctx);

			t.color("blue");            //# Tell tess to change her color
			t.pensize(3);               //# Tell tess to set her pen width

			t.forward(50);
			t.left(120);
			t.forward(50);
			*/

		/*
			var tess = new turtle(ctx); // turtle.Turtle()       # Create tess and set some attributes
			tess.color("pink"); //hotpink")
			tess.pensize(5)

			var alex = new turtle(ctx); // turtle.Turtle()       # Create alex

			tess.forward(80)             //# Make tess draw equilateral triangle
			tess.left(120)
			tess.forward(80)
			tess.left(120)
			tess.forward(80)
			tess.left(120)               //# Complete the triangle

			tess.right(180)              //# Turn tess around
			tess.forward(80)             //# Move her away from the origin

			alex.forward(50)             //# Make alex draw a square
			alex.left(90)
			alex.forward(50)
			alex.left(90)
			alex.forward(50)
			alex.left(90)
			alex.forward(50)
			alex.left(90)

		*/

		/*
		var sally = turtle.Turtle() //#make sally
		//sally.shape("turtle") #change shape
		sally.forward(100)
		sally.right(90)
		sally.forward(100)
		sally.right(90)
		sally.forward(100)
		sally.right(90)
		sally.forward(100)
		*/


		/*
		var painter = turtle.Turtle()

		painter.pencolor("blue")

		//for i in range(50):
		for(var i = 1; i<=50; i++){
			painter.forward(50)
			painter.left(123) //# Let's go counterclockwise this time
		}

		painter.pencolor("red")
		//for i in range(50):
		for(var i = 1; i<= 50; i++){
			painter.forward(100)
			painter.left(123)
		}
		*/


		console.log("debug here");

		/*
		var turtle1 = turtle.Turtle(); //new Turtle(ctx);

		turtle1.fillcolor("blue");
		turtle1.begin_fill();
		turtle1.pensize(4);

		//for i in range(5): # this actually goes 5 times, not 6
		for(var i = 1; i<= 5; i++){


			turtle1.forward(100)
			turtle1.left(180) //# point to your butt first, now it will work
			turtle1.left(36)
			//turtle1.left(72);
		}
		turtle1.fill();
		*/

		var turtle1 = ZTurtle.getTurtle(this.zCanvas);
		///window['zturtle'] = {};
		///window['zturtle']['turtle1'] = turtle1;
		//_g_turtle = {};
		//_g_turtle.turtle1 = turtle1;
		//window['zturtle'] = _g_turtle;

		this.turtle1 = turtle1;

		/*
		ctx.beginPath();
		ctx.moveTo(120,20);
		ctx.lineTo(120,100);
		ctx.moveTo(120,90);
		ctx.lineTo(200,100);
		ctx.lineTo(120,20);
		ctx.stroke();
		//ctx.rect(20, 20, 150, 100);
		ctx.fillStyle = "red";
		ctx.fill();
		*/


		/*
		var ninja = turtle.Turtle()

		//ninja.speed(10)

		//for i in range(180):
		for(var i = 1; i <= 180; i++){
			ninja.color("red");
			//ninja.pencolor(1,.5,0);
			ninja.forward(100)
			ninja.right(30)
			ninja.pencolor(0,1,0);
			ninja.forward(20)
			ninja.left(60)
			ninja.pencolor(0,0,1);
			ninja.forward(50)
			ninja.right(30)

			ninja.penup()
			//ninja.pendown()
			ninja.setposition(0, 0)
			ninja.pendown()
			//ninja.penup()

			ninja.right(2)
		}
		*/


	}

	@Input() hidden = false;

	close() {
		this.hidden = true;
	}

}


