//export * from "./lib/cf-core-cms";
export {IFolderItem} from './lib/IFolderItem';
export {IUser} from './lib/IUser';
export {IUserToken} from './lib/IUserToken';
export {IGroups} from './lib/IGroups';
export {IProvider} from './lib/IProvider';
export {IProjectInfo} from './lib/IProjectInfo'

export * from "./lib/cf-util-stuff";

// this one is sort of classroomy
export {IAssignment } from './lib/IAssignment';
export { IGraderTest} from './lib/IGraderTest';
export { BsProjectC, ProjectType  } from './lib/BSProjectC'
export { iBsUserProjectsService} from './lib/i-bs-user-projects.service'
export { iBsAssignmentTesterService } from './lib/i-bs-assignment-tester.service'

