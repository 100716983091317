export class TimeStuff {

	// TODO: add something to fix hardocded timezone

	public static calcEarlylate(dueDate: string, subDate: number): string {

		if (!dueDate) {
			return '?';
		}

		let daysDiff = this.calcDaysDiff(dueDate, subDate);

		let earlyLate = 'Late';
		if (daysDiff <= 0) {
			earlyLate = 'Early';
			daysDiff = -daysDiff;
		}
		if (daysDiff < 1) {
			let hrs = daysDiff * 24;
			hrs = Math.round(hrs * 10) / 10; // round to 1 decimal place
			earlyLate = hrs + ' hours ' + earlyLate;
		}
		else {
			daysDiff = Math.round(daysDiff * 10) / 10; // round to 1 decimal place
			earlyLate = daysDiff + ' days ' + earlyLate;
		}

		return earlyLate;
	}

	// will return negative if early
	public static calcDaysDiff(dueDate: string, subDate: number): number {

		if (!dueDate) {
			return 0;
		}
		//if (assignment.dueTime) {
		//	console.log('Warning dueTime not implmented yet!  Right now assuming it is due at 11:59:59pm.')
		//}

		let dd = Date.parse(dueDate);
		dd = dd + 6 * 60 * 60 * 1000; // quick hack for time zone.  TODO fix later
		dd = dd + ( 24 * 60 * 60 * 1000) - 1;  // quick hack to make it due 11:59:59pm.  TODO fix later...
		const od = subDate - dd;
		const daysDiff = od / (60 * 60 * 24 * 1000);
		//daysDiff = Math.round(daysDiff * 10) / 10; // round to 1 decimal place
		return daysDiff;
	}
}
