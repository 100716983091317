//import { Injectable } from '@angular/core';
//import { PasswordModule } from 'primeng/primeng';
//import { listenToElementOutputs } from '@angular/core/src/view/element';




interface LintRule {
	name: string;
	desc: string;
	checkCount: number;
	test(code: string): LintError[];
	// passed?: boolean;
}


enum ErrorLevel {None, Message, Warning, Error}
interface LintError {
	level: ErrorLevel;
	errorName: string;
	errorDesc: string;
	lineNumbers: number[];
}

interface LintResult {
	errors: LintError[];
	markedCode: string;
}


type FunInfo = { name: string, pos: number};

//@Injectable()
export class CFLinter {

	private static _instance: CFLinter;

	rules: Array<LintRule> = [];

	static getInstance() {  // get singleton
		if (!CFLinter._instance) {
			CFLinter._instance = new CFLinter();
		}
		return CFLinter._instance;
	}

	countRuleChecks() {
		let c = 0;
		for (const r of this.rules) {  // TODO: I think there is a problem here, because some rules return multiple...
			c += r.checkCount;
		}
		return c;
	}

	constructor() {
		this.defineRules();
	}

	findMain(lines: string[]): number {


		for (let i = 0; i < lines.length; i++) {
			// const regMain = new RegExp('int\s+main\s*\(\s*\)');
			const regMain = /int\s+main\s*\(\s*\)/;
			const line = lines[i];
			if (regMain.test(line)) {
				return i;
			}
		}
		return -1;

	}

	static findFirstBraceLine(lines: string[], startLine: number): number {

		for (let i = startLine; i < lines.length; i++) {
			const line = lines[i];

			if (line.includes('{')) {
				return i;
			}
		}

		return -1;
	}

	static findClosingBraceLine(lines: string[], startLine: number): number {

		let count = 0;
		let first = 0;

		for (let i = startLine; i < lines.length; i++) {
			const line = lines[i];

			for (const c of line) {
				if (c === '{') {
					count++;
					first = 1;
				}
				else if (first && c === '}') {
					count --;
				}
				if (first && count === 0) {
					return i;
				}
			}

		}

		return -1;
	}

	static filterComments(lines: string[]): string[] {
		const code = lines.join('\n');
		const rCode = this.filterCommentsFromCode(code);
		return rCode.split('\n');

	}

	static filterCommentsFromCode(code: string): string {
		const BLANK = ' ';

		let rCode = '';

		let i = 0;
		let len = code.length;

		while (i < len) {
			const c = code[i];
			if (c === '"') {	// if double quote, copy till non escaped double quote
				rCode += '"';
				let lastC = c;
				i++;
				while ( i < len && code[i] !== '"' && lastC !== '\\' ) {
					lastC = code[i];
					rCode += code[i];
					i++;
				}
				if (i < len) {
					rCode += code[i];
					i++;
				}
			}
			else if (c === '/') {
				i++;
				if ( i < len && code[i] === '/') {  // // blank till end of line
					rCode += BLANK + BLANK;
					i++; //i++;
					while (i < len && code[i] !== '\n') {
						rCode += BLANK;
						i++;
					}
				}
				else if (i < len && code[i] === '*' ) { // /*  blank till */
					rCode += BLANK + BLANK;
					i++; //i++;
					while (i < (len - 1) && !(code[i] === '*' && code[i + 1] === '/' )) {
						if (code[i] == '\n') {
							rCode += '\n';
						} else {
							rCode += BLANK;
						}
						i++;
					}
					if (i < len - 1) {
						rCode += BLANK + BLANK;
						i++; i++;
					}
				}
				else {
					rCode += code[i];	// default is copy
					i++;
				}
			}
			else {
				rCode += code[i];	// default is copy
				i++;
			}
		}

		return rCode;
	}

	static indentCount(line: string): number {
		if (!line || line.length == 0) {
			return -1;
		}
		if (line.trim() === '') {	// ignore blank or all spaces/tabs
			return -1;
		}

		if ( /^\/\//.test(line) ) {  // ignore commented line
			return -1;
		}

		// what about /* */

		let count = 0;
		for(let i = 0; i < line.length; i++) {
			let c = line[i];
			if (c === ' ') {
				count++;
			}
			else if (c === '\t') {
				count += 4;
			}
			else {
				return count;
			}
		}
		return count;
	}

	static isIndented(lines: string[], braceStart: number, braceEnd: number): boolean {

		const startLine = braceStart + 1;
		const endLine = braceEnd - 1;

		for (let i = startLine; i <= endLine; i++) {
			const line = lines[i];

			const lineInC = CFLinter.indentCount(line);
			if (lineInC !== -1 && lineInC < 4) {
				return false;
			}
		}

		return true;
	}

	static isContentIndented(lines: string[], startLine: number): Boolean {

		const firstBracePos = CFLinter.findFirstBraceLine(lines, startLine);
		const lastBracePos = CFLinter.findClosingBraceLine(lines, firstBracePos);

		//console.log('main header is at: ' + mainLinePos);
		//console.log('main starts at: ' + firstBracePos);
		//console.log('main ends at: ' + lastBracePos);

		return CFLinter.isIndented(lines, firstBracePos, lastBracePos);

	};


	// todo:  add isPrototype to funinfo and return that by searching for ; instead of {
	// todo:  fix multi line function headers / prototypes
	static findFunctions(lines: string[]): FunInfo[] {

		CFLinter.filterComments(lines);

		const fLines: Array<FunInfo> = [];

		for (let i = 0; i < lines.length; i++) {
			const line = lines[i];

			const reg = /^\s*[a-zA-Z0-9]+\s+([a-zA-Z0-9_]+)\s*\([a-zA-Z0-9_,\s\&]*\)\s*[{]{0,1}/;
			if ( reg.test(line) ) {
				const m = reg.exec(line);
				//debugger;
				let n = '?';
				if (m && m.length >= 2) {
					n = m[1];
				}

				fLines.push({name: n, pos: i});
			}
		}
		return fLines;
	}

	// todo.... review this thing and maybe make a programming contest problem out of it...
	static getLineRanges(lines: number[]) {
			//debugger;
			let r = '';
			if (lines.length === 0) {
				return '';
			}
			else if (lines.length === 1) {
				return '' + lines[0];
			}

			let linesC = [...lines]; // make a copy

			let lastN = linesC.shift();
			r = '' + lastN;

			let skipping = 0;
			let lastStart = lastN;

			//debugger;

			while (linesC.length > 0) {
				let cN = linesC.shift();
				if (cN === lastN + 1) { //  && linesC.length !== 0) {
					skipping = 1;
				}
				else {
					skipping = 0;
				}
				if (!skipping) {
					if (lastStart === lastN ) {
						r += ', ' + cN;
					}
					else {
						r +=  '-' + lastN + ', ' + cN;
					}
					lastStart = cN;
				}

				lastN = cN;
			}
			if (skipping) {
				r += '-' + lastN;
			}

			return r;

	}

	defineRules() {

		let commentsRule: LintRule;
		commentsRule = {
			name: 'Comments At Top',
			desc: 'You should put basic comments at the top of your main file.',
			checkCount: 1,
			test: function (code: string)  {
				const errs: LintError[] = [];
				let errors = '';
				//const lines = code.split('\r\n');
				const lineNums = [];
				//const line0 = lines[0];
				const startsWithSlSl = code.trim().indexOf('//') === 0;
				const startsWithSlStar = code.trim().indexOf('/*') === 0;
				if (! (startsWithSlSl || startsWithSlStar) ) {
					lineNums.push(0 + 1);
					errors = this.desc;
				}
				if (errors !== '') {
					errs.push({errorName: this.name, errorDesc: errors, level: ErrorLevel.Error, lineNumbers: lineNums});
				}
				return errs;
			}
		};

		const funcRule1: LintRule = {
			name: 'functionHeaderNoIndent',
			desc: 'Function header & prototypes should not be indented.  They should start in the first column.',
			checkCount: 1,
			test: function (code: string) {
					//const err: TestReturn = {name: this.name, desc: this.desc, passed: true, errors: []};
					const errs: LintError[] = [];
					const lineNums = [];
					const lines = code.split(/\r\n|\r|\n/);

					const funs = CFLinter.findFunctions(lines);

					let errors = '';
					let delm = 'The following function headers or prototypes should not be indented: ';;
					for (const fi of funs) {
						if (CFLinter.indentCount(lines[fi.pos]) > 0) {
							errors += delm + (1 + +fi.pos) + ':' + fi.name;
							delm = ', ';
							lineNums.push(1 + +fi.pos);
						}
					}

					if (errors !== '') {
						//alert(errors);
						//return false;
						//err.passed = false;
						errs.push({errorName: this.name, errorDesc: errors, level: ErrorLevel.Error, lineNumbers: lineNums});
					}

					return errs;
			}
		};

		const funcRule2: LintRule = {
			name: 'functionCodeIndent',
			desc: 'Statements inside of functions should be indented.',
			checkCount: 1,
			test: function(code: string)  {
					//const err: TestReturn = {name: this.name, desc: this.desc, passed: true, errors: []};
					const errs: LintError[] = [];
					const lineNums = [];
					const lines = code.split(/\r\n|\r|\n/);

					const funs = CFLinter.findFunctions(lines);

					let errors = '';
					let delm = 'The following functions need the statements inside them to be indented at least 4 spaces or 1 tab: ';;
					for (const fi of funs) {
						if (! lines[fi.pos].includes(';')) { // quick prototype hack
							if (!CFLinter.isContentIndented(lines, fi.pos) ) {
								errors += delm + (1 + +fi.pos) + ':' + fi.name;
								delm = ', ';
								lineNums.push(1 + +fi.pos);
							}
						}
					}

					if (errors !== '') {
						errs.push({errorName: this.name, errorDesc: errors, level: ErrorLevel.Error, lineNumbers: lineNums })
					}

					return errs;
			}
		};

		const funcRule3: LintRule = {
			name: 'functionHSpace',
			desc: 'Function headers should be preceded with either a comment or blank line.',
			checkCount: 1,
			test: function(code: string)  {
					//const err: TestReturn = {name: this.name, desc: this.desc, passed: true, errors: []};
					const errs: LintError[] = [];
					const lineNums = [];
					const lines = code.split(/\r\n|\r|\n/);

					const funs = CFLinter.findFunctions(lines);

					const fLines = CFLinter.filterComments(lines);

					let errors = '';
					let delm = 'The following function headers need comments or a blank line above them: ';;
					for (const fi of funs) {
						if (! lines[fi.pos].includes(';')) { // quick prototype hack
							if (fLines[fi.pos - 1].trim() !== '') {
								errors += delm + (1 + +fi.pos) + ':' + fi.name;
								delm = ', ';
								lineNums.push(1 + +fi.pos);
							}
						}
					}

					if (errors !== '') {
						errs.push({errorName: this.name, errorDesc: errors, level: ErrorLevel.Error, lineNumbers: lineNums })
					}

					return errs;
			}
		};



		const curleyRule1: LintRule = {
			name: 'curley1',
			desc: 'Closing curley braces (}) should line up with the line that the opening brace ({) is on.',
			checkCount: 3,
			test: function(code: string)  {
					//const err: TestReturn = {name: this.name, desc: this.desc, passed: true, errors: []};
					const errs: LintError[] = [];
					const lineNums = [];
					const lines = code.split(/\r\n|\r|\n/);

					const funs = CFLinter.findFunctions(lines);

					const fLines = CFLinter.filterComments(lines);

					let errors = '';
					let delm = 'The following closing braces (}) do not seem to line up with their opening brace: ';;
					const braces = [];

					let errors2 = '';
					let delm2 = 'The following closing braces (}) should be on a line by themselves.';
					const lineNums2 = [];

					let errors3 = '';
					let delm3 = 'The following lines do not appear to be indented enough.';
					const lineNums3 = [];

					let currentIndent = 0;
					for (var i = 0; i < fLines.length; i++)  {
						const line = fLines[i];
						const trimLine = line.trim();
						const indentCount = CFLinter.indentCount(line);

						const startsWithOB = trimLine.indexOf('{') === 0;  // starts with   {
						const startsWithCB = trimLine.indexOf('}') === 0;  // starts with   }

						{
							//debugger;
							const cIndentCount = CFLinter.indentCount(line);
							let cPos = currentIndent;
							if (startsWithCB) {
								//cPos -= 4;
								if (braces.length > 0) {
									cPos = braces[braces.length - 1];
								} else {
									cPos = 0;
								}
							}
							if (cIndentCount >= 0 && cIndentCount < cPos) {
								//if (!line.includes('{')){
									//errors3 += delm3 + (1 + i);
									//delm3 = ', ';
									errors3 = delm3;
									lineNums3.push(1 + i);
								//}
							}
						}

						// TODO:  fix to allow  } else {
						const cbElseOb = /^\}\s*(else)\s*\{$/.test(line.trim());
						if (cbElseOb) {
							continue;
						}

						const includesPar = /\)\s*\{/.test(line);   		// includes ){
						const startsWithElse = /else\s*\{/.test(line);	// or  else {

						if ( startsWithOB || includesPar  || startsWithElse) {
								braces.push(indentCount);
								currentIndent = indentCount + 4;
						}


						// TODO: maybe allow if (x) {...;}
						// TODO: maybe noting to the right of { unless ended on same line or array}
						// TODO:         if (palindromeChecker(sentence) == true) { p++;

        								// }
						// TODO:  if ()  & while ()
						// TODO: switch


						if (trimLine.includes('}')) {
							const oc = braces.pop();
							currentIndent = oc;

							const cIndentCount = CFLinter.indentCount(line);
							if (oc !== cIndentCount) {
								errors += delm + (1 + i);
								delm = ', ';
								lineNums.push(1 + i);
							}
							const openCloseTest = /\{\.+\}/.test(trimLine);
							const byItSelf = /^\}[;]*$/.test(trimLine);
							if (!openCloseTest && !byItSelf) {  // not open and closed and not by itself
								errors2 += delm2 + (1 + i);
								delm2 = ', ';
								lineNums2.push(1 + i);
							}

						}

					}

					if (errors !== '') {
						errs.push({errorName: this.name, errorDesc: errors, level: ErrorLevel.Error, lineNumbers: lineNums })
					}

					if (errors2 !== '') {
						errs.push({errorName: this.name, errorDesc: errors2, level: ErrorLevel.Error, lineNumbers: lineNums2 })
					}

					if (lineNums3.length > 0) { //errors3 !== '') {
						//const lineNumsCopy = [...lineNums3]; // something was eating lineNums3
						errors3 += ' ' + CFLinter.getLineRanges(lineNums3);
						errs.push({errorName: this.name, errorDesc: errors3, level: ErrorLevel.Error, lineNumbers: lineNums3 })
					}

					return errs;
			}
		};

		this.rules.push(commentsRule);
		this.rules.push(funcRule1);
		this.rules.push(funcRule2);
		this.rules.push(funcRule3);
		this.rules.push(curleyRule1);
	}

	zLintFile(code: string): LintResult {
		//alert('Roger');
		return this.runRules(code);
	}

	runRules(code: string): LintResult {

		const testReturns: LintError[] = [];
		const errs: LintError[] = [];
		let failedCount = 0;
		for (const rule of this.rules) {
			const tr = rule.test(code);
			for(const err of tr){
				errs.push(err);
				if (err.level !== ErrorLevel.None) {
					failedCount++;
				}
			}

		}

		const markedCode = this.getMarkedUpCode(code, errs);

		return {errors: errs, markedCode: markedCode};

	}

	getMarkedUpCode(code: string, lintErrors: LintError[]): string {
		console.log('DEBUG getMarkedUpCode');

		let r = '';
		const lines = code.split(/\r\n|\r|\n/);

		const lineErrors: Array<LintError | null> = [];
		for (let i = 0; i < lines.length; i++){
			lineErrors.push(null);
		}

		for (const err of lintErrors) {
			for (const ln of err.lineNumbers) {
				lineErrors[ ln - 1 ] = err;
			}
		}

		const okSpan = '<span>';
		const errSpan = '<span style="background-color:red" class="tooltip">';
		const spanEnd = '</span>';

		for (let i = 0; i < lines.length; i++) {
			const line = lines[i] || '';
			const err = lineErrors[i];
			const flag = (err === null) ? '   ' : ' * ';
			const spanStart = (err === null) ? okSpan : errSpan;
			const toolTip = (err === null) ? '' : '<span class="tooltiptext">' + err.errorDesc + '</span>';

			r += flag + this.rightAlignNum(i + 1, 4) + ': ';
			r += spanStart + '' + this.encode(line) + '' + toolTip + '' + spanEnd + '\r\n';
		}

		return r;
	}

	// TODO: there is prob a better version of this somewhere
	encode(line: string): string {
		let r = line;
		r = r.replace(/\</g, '&lt;');
		r = r.replace(/\>/g, '&gt;');
		return r;
	}

	// TODO: I think the rightAlign stuff is in 3 places.  Need to merge
	rightALignStr(s: string, width: number): string {
		if (s.length > width) {
			return s;
		}
		let r = ' '.repeat(width) + s;
		r = r.substr(r.length - width);
		return r;
	}

	rightAlignNum(n: number, width: number): string {
		return this.rightALignStr(n.toString(), width);
	}



}
