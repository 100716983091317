
export  class UtilStuff {

	// I think this is the same as sleep in the graderbotapp...
	public static async delayFor(timeout: number): Promise<boolean> {
		console.log('Delaying for: ' + timeout);
		const p = new Promise<boolean>( (resolve, reject) => {
			setTimeout(() => {
				console.log('Done delaying');
				resolve(true);
			}, timeout);
		});

		return p;
	}

	public static rightALignStr(s: string, width: number): string {
		if (s.length > width) {
			return s;
		}
		let r = ' '.repeat(width) + s;
		r = r.substr(r.length - width);
		return r;
	}

	public static rightAlignNum(n: number, width: number): string {
		return this.rightALignStr(n.toString(), width);
	}

	public static regToText(s: string): string {
		let r = s;
		r = r.replace(/\\\$/g, '$');
		r = r.replace(/\.\*/g, ' ');
		r = r.replace(/\\b/g, ' ');
		r = r.replace(/\\\./g, '.');
		return r;
	}



	public static processWaitStr(s: string) {
		s = s.trim().substr(1); // get rid of starting #
		s = s.replace(/\./g, '\\.');    // escape periods
		s = s.replace(/\s{2,}/g, '.*'); // change 2 or more spaces into a skip all
		s = s.replace(/\+/g, '\\+');
		s = s.toLowerCase();
		return s;
	}


	public static objToArray(objWithKeys:any, addKey = false): Array<any> {
		// http://stackoverflow.com/questions/14788652/how-to-filter-key-value-with-ng-repeat-in-angularjs
		// and changes by bob
		const a = [];
		//let i = 0;
		for (const k in objWithKeys) {
			if (objWithKeys.hasOwnProperty(k)) {
				const v = objWithKeys[k];
				if (addKey) {
					v.__key = k;
				}
				a.push(v);
			}
		}

		return a;
	}

	public static clientTypeDetect(): string {
		let clientInfo = '?';

		if ( typeof window !== "undefined") {
			// https://stackoverflow.com/questions/9847580/how-to-detect-safari-chrome-ie-firefox-and-opera-browser

			// tslint:disable-next-line:no-eval
			clientInfo = eval(`
				// Opera 8.0+
				var isOpera = (!!window.opr && !!opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;

				// Firefox 1.0+
				var isFirefox = typeof InstallTrigger !== 'undefined';

				// Safari 3.0+ "[object HTMLElementConstructor]"
				var isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && safari.pushNotification));

				// Internet Explorer 6-11
				var isIE = /*@cc_on!@*/false || !!document.documentMode;

				// Edge 20+
				var isEdge = !isIE && !!window.StyleMedia;

				// Chrome 1 - 71
				var isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);

				// Blink engine detection
				var isBlink = (isChrome || isOpera) && !!window.CSS;


				//var output = 'Detecting browsers by ducktyping:<hr>';
				let output  = ''
				if (isFirefox) 	output += 'Firefox, ';
				if (isChrome) 	output += 'Chrome, ';
				if (isSafari) 	output += 'Safari, ';
				if (isOpera) 	output += 'Opera, ';
				if (isIE) 		output += 'IE, ';
				if (isEdge) 	output += 'Edge, ';
				if (isBlink) 	output += 'Blink, ';
				output += ' ' + navigator.userAgent;
				output;
			`);


		} else {
			// tslint:disable-next-line:no-eval
			clientInfo = eval(`
				var os = require('os');
				'Node: ' + process.version + ' OS: ' + os.platform() + os.release();
			`);

		}

		console.log('ClientType info: ' + clientInfo);
		return clientInfo;

	}


}
