import {Component} from '@angular/core';

@Component({
    selector: 'app-footer',
    template: `
        <div class="layout-footer clearfix">
            <a href="dashboard.xhtml">
                <img alt="logo-colored" src="assets/cf/c_logo-colored.png" />
            </a>
            <span class="footer-text-right">
                <span class="material-icons">copyright</span>
                <span>2020 Bob Bradley All Rights Reserved</span>
            </span>
        </div>
    `
})
export class AppFooterComponent {

}
