import { Component, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { TreeModule, TreeNode } from 'primeng/primeng';
import { Observable } from 'rxjs/Observable';
import { BsFolderService, BsFolderManager, FolderItem } from '../../../services/bs-folder.service';
import { BsFolderItemComponent } from '../bs-folder/bs-folder-item/bs-folder-item.component';
//import { ICFCurrentClassInfo } from '@cf-platform/cf-class-stuff'; //  ../../../services/bs-current-class-info.service';

@Component({
	selector: 'app-bs-folder-tree',
	templateUrl: './bs-folder-tree.component.html',
	styleUrls: ['./bs-folder-tree.component.css']
})
export class BsFolderTreeComponent implements OnDestroy {

	//feedDbPath: string = '/class/201720_UTM_CSIC_221';
	bsFolderManager: BsFolderManager;
	_currentPath: string = '';
	_rootItem: Observable<FolderItem>;
	//_currentClassInfo: CFCurrentClassInfo;

	treeItems = [];
	selectedFile: TreeNode;

	@Input() folderMode = false;
	rootItemSub: any;
	//cpSub: any;

	//@Input() set currentClassInfo(cci: CFCurrentClassInfo) {
	@Input() set rootItem(rootItem: Observable<FolderItem>) {
		this._rootItem = rootItem;
		//this._currentClassInfo = cci;
		this.expandCurrentPath(this.treeItems);


		if (rootItem) {
			//this._rootItem = cci.rootItem;
			this.rootItemSub = rootItem.subscribe(data => {
				const root = [data];
				this.treeItems = this.treeNodesFromItems(root);
				this.expandCurrentPath(this.treeItems);

				//if (this.cpSub) {
				//	this.cpSub.unsubscribe();
				//}
				// todo fix: this.cpSub = cci.theClassPathStore.handler.subscribe(cp => this._currentPath = cp);
			});
		}
		else {
			console.log('Folder Tree got EMPTY root');
		}
	}

	@Output() goItemClicked = new EventEmitter();

	constructor(private bsFolders: BsFolderService) { }

	treeNodeFromItem(d) {
		let expandedIcon = 'fa fa-folder-open';
		let collapsedIcon = 'fa fa-folder';
		if (d.type !== 'folder') {
			expandedIcon = 'fa fa-file';
			collapsedIcon = 'fa fa-file';
		}
		const o: any = {};
		o.label = d.name;
		o.data = d;
		o.expandedIcon = expandedIcon;
		o.collapsedIcon = collapsedIcon;
		o.leaf = d.type !== 'folder';
		o.selectable = true;
		if (d.type !== 'folder') {
			o.selectable = !this.folderMode;
		}
		return o;
	}

	treeNodesFromItems(data) {
		const treeItems = [];

		for (const d of data) {
			if (d && !d.hidden) {  // TODO: let admins see hiden items
				const o = this.treeNodeFromItem(d);
				treeItems.push(o);
			}
		}
		return treeItems;
	}

	expandCurrentPath(tItems) {
		let cPath = this._currentPath;
		if (!this._currentPath) {
			cPath = '/';
		}
		else if (!this._currentPath.startsWith('/')) {
			cPath = '/' + this._currentPath;
		}
		for (const n of tItems) {
			if (cPath.startsWith(n.data.my_path)) {
				n.expanded = true;
				this.selectedFile = n;
				this.loadNode({ node: n });
				return;
			}
		}
	}

	goItem(item: FolderItem) {
		this.goItemClicked.emit(item);
	}

	loadNode(event) {
		console.log('loadNode: ' + event.node.label);
		if (event.node && !event.node.leaf) {
			const item = event.node.data;

			if (!event.node.children) {
				item.childItems.subscribe(data => {   // first
					event.node.children = this.treeNodesFromItems(data);
					this.expandCurrentPath(event.node.children);
				});
			}
			else {
				this.expandCurrentPath(event.node.children);
			}

		}
	}

	nodeSelected(event) {
		console.log('Tree node selected');
		const item = event.node.data;
		this.goItem(item);
		if (item.type === 'folder') {
			if (!event.node.expanded) {
				this.loadNode(event);
				event.node.expanded = true;
			}
		}
	}


	ngOnDestroy() {
		this.rootItemSub.unsubscribe();
		//this.cpSub.unsubscribe();
	}
}
