import { Component, ViewEncapsulation, OnDestroy } from '@angular/core';
import { UserRepository } from '@cf-platform/cf-core-cms-ng';

import { ActivatedRoute, Router } from '@angular/router';
import { IUser } from '@cf-platform/cf-core-cms';


@Component({
  selector: 'app-login',
  templateUrl: './app.login.component.html',
  styleUrls: ['./app.login.component.css'],
  encapsulation: ViewEncapsulation.None

})
export class AppLoginComponent implements OnDestroy {
	userSub: any;

	constructor(
		private _userRepository: UserRepository,
		private activatedRoute: ActivatedRoute,
		private router: Router) {}

	login = async () => {
		await this._userRepository.login();
		this.userSub = this._userRepository.getCurrentUser((user) => this.onUserAuth(user));
	}

	onUserAuth = (user: IUser) => {
		//this.user = user;

		if (user) {
			const params = this.activatedRoute.snapshot.params;
			if (params && params['go'] ) {
				console.log('Redirecting...');
				const url = params['go'];  // if we login in with a go param, then go to that url
				//window.location.href = url;  // doing this old school way for now
				this.router.navigateByUrl(url);
				return;
			}
			else {
				console.log('Redirecting to /...');
				//const url = params['go'];  // if we login in with a go param, then go to that url
				//window.location.href = url;  // doing this old school way for now
				this.router.navigateByUrl('/');
				return;
			}
		}


		//this.loading = false;
		//this.cd.detectChanges();
		//console.log('User: ' , this.user);

	}


	ngOnDestroy() {
		this._userRepository.unsubscribeCurrentUser(this.userSub);
	}

}
