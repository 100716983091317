// http://stackoverflow.com/questions/31548311/angular-2-html-binding

import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';


@Pipe({
	name: 'safeHtml'
})
export class SafeHtmlPipe implements PipeTransform {

	constructor(private sanitizer: DomSanitizer) { };

	transform(style: any) {
		return this.sanitizer.bypassSecurityTrustHtml(style);
		// return this.sanitizer.bypassSecurityTrustStyle(style);
		// return this.sanitizer.bypassSecurityTrustXxx(style); - see docs
	}

}

@Pipe({
	name: 'safeStyle'
})
export class SafeStylePipe implements PipeTransform {

	constructor(private sanitizer: DomSanitizer) { };

	transform(style: any) {
		// return this.sanitizer.bypassSecurityTrustHtml(style);
		return this.sanitizer.bypassSecurityTrustStyle(style);
		// return this.sanitizer.bypassSecurityTrustXxx(style); - see docs
	}

}
