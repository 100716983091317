import { Component, Input, ChangeDetectorRef, NgZone, OnDestroy} from '@angular/core';
import { BsCurrentClassInfoService, CFCurrentClassInfo } from '../../services/bs-current-class-info.service';
import { BsClass } from '../../classes/bs-class';
import { BsAssignmentsService } from '../../services/bs-assignments.service';

import { Observable } from 'rxjs/Observable';
import {  SharedModule } from 'primeng/primeng';
import { BsUtilsService, BsCurrentUserService } from '@cf-platform/cf-core-cms-ng';
//import { checkAndUpdateView } from '@angular/core/src/view/view';

import { ViewEncapsulation } from '@angular/core';

import {IProjectInfo, IAssignment} from "@cf-platform/cf-core-cms";

import { BehaviorSubject } from 'rxjs';
//import { CFTableCol } from 'libs/cf-core-cms-ng/src/lib/cf-core-cms-ng/cf-user-table/cf-user-table.component';

import { BreadcrumbService } from 'projects/cf-web/src/app/breadcrumb.service';


interface CFTableCol {  // todo fix the import above and remove this
	header: string;
	field: string;
	width: string;
	link?: string;
}

class GradeInfo {
	_totalPoints: number = 0;
	get totalPoints(): number { return this._totalPoints; }
	set totalPoints(p: number) { this._totalPoints = p; this.updateGradeStr(); }

	_userPoints: number = 0;
	get userPoints(): number { return this._userPoints; }
	set userPoints(p: number) { this._userPoints = p; this.updateGradeStr(); }

	gradeStr: string = '?';

	constructor(
	) { }

	updateGradeStr() {
		this.gradeStr = '?';
		if (this.totalPoints === 0) {
			return;
		}
		const tp = this.userPoints;
		const per = 100 * tp / this.totalPoints;
		const g: string = '' + tp + ' (' + (per).toFixed(1) + '%)';
		this.gradeStr = g;
	}
}

@Component({
	selector: 'app-bs-my-grades',
	templateUrl: './bs-my-grades.component.html',
	styleUrls: ['./bs-my-grades.component.css'],
	encapsulation: ViewEncapsulation.None
})
export class BsMyGradesComponent implements OnDestroy {
	theClass: BsClass;
	selectedStudentEmail: string;
	currentClassInfo: CFCurrentClassInfo;
	cciSub: any;
	///@Input()
	get studentKey() {
		return this.selectedStudentEmail;
	}
	set studentKey(sk: string) {
		this.gradeMode = true;
		this.switchToStudent(sk);
	}
	gradeMode: boolean = false;
	totalGrade: GradeInfo = new GradeInfo();
	selectedGrade: GradeInfo = new GradeInfo();
	assignmentArray: Array<IAssignment>;
	assignmentList: BehaviorSubject<Array<IAssignment>> = new BehaviorSubject([]);


	columns: CFTableCol[] = [];

	constructor(
		private currentClassInfoService: BsCurrentClassInfoService,
		private assignmentsService: BsAssignmentsService,
		private currentUserService: BsCurrentUserService,
		private cdRef: ChangeDetectorRef,
		private ngZone: NgZone,
		private breadcrumbService: BreadcrumbService,
	) {

		this.cciSub = currentClassInfoService.currentClassInfoHandler.subscribe(cci => {
			this.currentClassInfo = cci;
			if (!cci) {
				return;
			}
			this.theClass = cci.theClass;
			console.log('Debug myGrades here');

			this.setClassTitle();

			this.columns = [
				{header: 'Assignment', field: 'idName', width: '200px', link: 'link'},
				{header: 'Note', field: '__note', width: '300px'},
				{header: 'DueDate', field: 'dueDate', width: '100px'},
				{header: 'Grade', field: '__grade', width: '50px'}
			]

			this.currentUserService.currentUserOb.subscribe(u => {
				let e = '';
				if (u) {
					e = u.email;
				}
				if (!this.selectedStudentEmail || this.selectedStudentEmail !== e) {

					this.switchToStudent(e);
				}
			});
		})

	}

	switchToStudent(email: string) {
		console.log('SwitchToStudent: ' + email);

		if (!email || email === '') {
			return;
		}

		this.selectedStudentEmail = email;
		this.loadAssignments()
			.then(j => {
				this.addInStudentGrades()
					.then(k => {
						const me = this;

						setTimeout(() => {
							this.flagDueAndDoneAssignments();
							me.computeTotals();
							me.computeSelectedTotals();
						}, 1000);
					});
			});
	}

	_selectedAssignments: Array<IAssignment> = [];
	get selectedAssignments(): Array<IAssignment> {
		return this._selectedAssignments;
	}
	set selectedAssignments(sa: Array<IAssignment>) {
		setTimeout(() => {
			this.ngZone.run(() => {
				this._selectedAssignments = sa;
				this.computeSelectedTotals();
				this.cdRef.detectChanges();
			});
		}, 0);


	}

	flagDueAndDoneAssignments() {
		// flag assignments that have due dates that have past or that have been graded
		//  and add them to the selectAssignments list
		console.log('Debug flagDueAndDoneAssignments');
		const now = Date.now();
		const sa = [];
		for (const a of this.assignmentArray) {
			a.key = a.__key || a.assignmentId || a.id; // hack to try to fix selection
			a['__id'] = a.__key;


			const isDue: boolean = a.dueDate && Date.parse(a.dueDate) <= now;
			if (isDue) {
				a.isDue = true;
			}
			const isDone = this.grades[a.key] && this.grades[a.key].isGraded;

			if (isDue || isDone) {
				sa.push(a);
			}
		}
		console.log('Hey');
		this.selectedAssignments = sa;
		this.cdRef.detectChanges();
	}

	computeTotals() {
		this.totalGrade.totalPoints = 0;
		for (const a of this.assignmentArray) {
			if (a && a.points) {
				this.totalGrade.totalPoints += +a.points;
			}
		}
		this.assignmentList.next(this.assignmentArray);
	}

	computeSelectedTotals() {
		this.selectedGrade.totalPoints = 0;
		this.selectedGrade.userPoints = 0;
		for (const a of this.selectedAssignments) {
			if (a && a.points) {
				console.log('Debug computeselectedtotals here');
				this.selectedGrade.totalPoints += +a.points;
				const ns: string = this.grades[a.assignmentId].grade || '0';
				let n: number = 0;
				if (!ns.includes(' - ') && !ns.includes(' ! ')) {
					n = parseInt(ns, 10);
				}
				this.selectedGrade.userPoints += +n;
			}
		}
	}

	async loadAssignments() {
		console.log('Loading assignments list...');
		this.assignmentArray = [];
		const aListAll = await this.currentClassInfo.theClass.getAssignments();
		const aListVis: Array<IAssignment> = [];

		for (const a of aListAll) { // aList
			a.idName = a.assignmentId + ': ' + a.name;
			a['__desc'] = a.idName;

			/*
			 * TODO: We are hiding hidden assignments,
			 * but student should not retrieve hidden assignments at all
			 * and that will need to be a DB rules or cloud function fix.
			 */
			if (!a.hidden) {
				aListVis.push(a);
			}
		}
		this.assignmentArray = aListVis;
		this.assignmentList.next(this.assignmentArray);
		console.log('Loaded assignments list.');
		return true;
	}

	grades = {};

	async addInStudentGrades() {   // grades??

		const uDbKey = BsUtilsService.z_filterEmailAsKey(this.selectedStudentEmail);
		this.grades = {};
		const e = this.selectedStudentEmail;
		const g = await this.getGradesInfoForStudent(e);
		console.log('Grades here');
		this.grades = g;
		if (!g) {
			this.grades = {};
		}
		this.totalGrade.userPoints = 0;

		for (const ai in this.assignmentArray) {
			const aid = this.assignmentArray[ai].assignmentId;
			const assignment = this.assignmentArray[ai];
			if (!this.grades[aid]) {
				this.grades[aid] = { grade: ' - ', note: '', isGraded: false };
			}
			else {
				if (this.grades[aid].grade) {
					this.totalGrade.userPoints += +this.grades[aid].grade;
					this.grades[aid].isGraded = true;
				}
			}
			if (this.gradeMode) {
				this.grades[aid].link = '/class/utm/' + this.theClass.classId + '/grade/' + aid + '/' + this.selectedStudentEmail;
			}
			else {
				this.grades[aid].link = '/class/utm/' + this.theClass.classId + '/assignments/' + aid;
			}
			// TODO DONE: /bean/ is a temp fix... need the current class to pass down its current url path

			assignment['__note'] = this.grades[aid].note;
			assignment['__grade'] = this.grades[aid].grade;


		}
		this.assignmentList.next(this.assignmentArray);
		this.getSubmits(e);
		return true;
	}

	async getSubmits(aStudentEmail) {
		for (const ai in this.assignmentArray) {
			const aid = this.assignmentArray[ai].assignmentId;
			if (aid && this.grades[aid] && this.grades[aid].grade === ' - ') {
				const hasSubed = await this.assignmentsService.hasSubmitted(this.theClass.classId, aid, aStudentEmail);
				if (hasSubed) {
					this.grades[aid].grade = ' ! ';
					this.grades[aid].isSubmitted = true;
				}
			}
		}
	}

	async getGradesInfoForStudent(aStudentEmail): Promise<any> {
		const grades = await this.assignmentsService.getGradesInfoUser(this.currentClassInfo.theClass.classId,
			aStudentEmail);
		return grades;
	}

	ngOnDestroy() {
		this.cciSub.unsubscribe();
	}


	//theClass: BsClass;

	async setClassTitle() {

		console.log('bs-class-users.component  setClassTitle');

		this.theClass = this.currentClassInfo.theClass;

		this.breadcrumbService.setItems([

			{label: this.theClass.classId, routerLink: '/class/utm/' + this.theClass.classId},
			{label: 'My Grades'}
		]);
		this.breadcrumbService.setPageInfo({title: 'My Grades: ' + this.theClass.classId, icon: 'star'});

		setTimeout(async() => {
			await this.currentClassInfo.theClass.loadSettings();
			const className = this.currentClassInfo.theClass.settings.name
			if (className) {
				this.breadcrumbService.setPageInfo({title: 'My Grades: ' + className, icon: 'star'});
			}

		}, 1000);

	}
}
