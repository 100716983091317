import { Component, OnInit, Input, AfterViewInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { FolderItem } from '../../../../services/bs-folder.service';

@Component({
	selector: 'app-item-attachments',
	templateUrl: './item-attachments.component.html',
	styleUrls: ['./item-attachments.component.css']
})
export class ItemAttachmentsComponent implements OnInit, OnDestroy {

	_item: FolderItem;
	attachments = [];
	attachmentsSub: Subscription;

	constructor() { }

	ngOnInit() {
	}

	@Input() set item(item: FolderItem) {
		if (item !== this._item) {
			this._item = item;
			// this.renderItem();
		}

		if (item && item.attachments) {
			if (this.attachmentsSub) {
				this.attachmentsSub.unsubscribe();
			}

			// The template async stuff is buggy, so we manually do the sub here
			this.attachmentsSub = item.attachments.subscribe(a => {
				// console.log('debug attachements sub');
				this.attachments = a;
			} );
		}
	}

	ngOnDestroy() {

		if (this.attachmentsSub) {
			this.attachmentsSub.unsubscribe();
		}
	}

}


