//export * from "./lib/cf-core-cms-ng";
export * from "./lib/cf-core-cms-ng/cf-core-cms-ng.module"
export { BsUserProjectsService } from './lib/cf-core-cms-ng/services/bs-user-projects.service'
export { FolderItem, BsFolderService, BsFolderManager } from './lib/cf-core-cms-ng/services/bs-folder.service'
export { CFTableCol } from './lib/cf-core-cms-ng/components/cf-table/cf-table.component'
export { BsFolderItemComponent } from './lib/cf-core-cms-ng/components/bs-feed-system/bs-folder/bs-folder-item/bs-folder-item.component';
export { BsMyProjectsComponent } from './lib/cf-core-cms-ng/components/bs-projects/bs-my-projects/bs-my-projects.component'

export { ItemViewFactoryService, ViewTypeE, ViewThing } from './lib/cf-core-cms-ng/components/bs-feed-system/bs-folder/item-view-factory.service';
export { NewableItem } from './lib/cf-core-cms-ng/components/bs-feed-system/bs-folder/newable-item';

// epic core stuff
export { BsUsersComponent } from './lib/cf-core-cms-ng/epic-core/bs-admin/bs-users/bs-users.component'
export { BsUsersDialogComponent } from './lib/cf-core-cms-ng/epic-core/bs-admin/bs-users-dialog/bs-users-dialog.component'
export { UserRepository } from './lib/cf-core-cms-ng/epic-core/services/userRepository'
export { BsUtilsService } from './lib/cf-core-cms-ng/epic-core/services/bs-utils.service'
export { BsCurrentUserService } from './lib/cf-core-cms-ng/epic-core/services/bs-current-user.service';
export {ISettings, UserSettings} from './lib/cf-core-cms-ng/epic-core/services/userSettings';
// todo move those ISettings, UserSettings


