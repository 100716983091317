import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, QueryList } from '@angular/core';
import { BsLoggerService } from '@cf-platform/cf-log';
import { AceEditorComponent } from 'ng2-ace-editor';
import 'brace';
import 'brace/theme/ambiance';
import 'brace/theme/eclipse';
import 'brace/theme/mono_industrial';
import 'brace/theme/tomorrow_night';
import 'brace/theme/chaos';
import 'brace/theme/github';
import 'brace/theme/monokai';
import 'brace/theme/tomorrow_night_blue';
import 'brace/theme/chrome';
import 'brace/theme/idle_fingers';
import 'brace/theme/pastel_on_dark';
import 'brace/theme/tomorrow_night_bright';
import 'brace/theme/clouds';
import 'brace/theme/iplastic';
import 'brace/theme/solarized_dark';
import 'brace/theme/tomorrow_night_eighties';
import 'brace/theme/clouds_midnight';
import 'brace/theme/katzenmilch';
import 'brace/theme/solarized_light';
import 'brace/theme/twilight';
import 'brace/theme/cobalt';
import 'brace/theme/kr_theme';
import 'brace/theme/sqlserver';
import 'brace/theme/vibrant_ink';
import 'brace/theme/crimson_editor';
import 'brace/theme/kuroir';
import 'brace/theme/terminal';
import 'brace/theme/xcode';
import 'brace/theme/dawn';
import 'brace/theme/merbivore';
import 'brace/theme/textmate';
import 'brace/theme/dreamweaver';
import 'brace/theme/merbivore_soft';
import 'brace/theme/tomorrow';

/*
// all the modes modes
import 'brace/mode/abap';
import 'brace/mode/abc';
import 'brace/mode/actionscript';
import 'brace/mode/ada';
import 'brace/mode/apache_conf';
import 'brace/mode/applescript';
import 'brace/mode/asciidoc';
import 'brace/mode/assembly_x86';
import 'brace/mode/autohotkey';
import 'brace/mode/batchfile';
import 'brace/mode/bro';
import 'brace/mode/c9search';
import 'brace/mode/c_cpp';
import 'brace/mode/cirru';
import 'brace/mode/clojure';
import 'brace/mode/cobol';
import 'brace/mode/coffee';
import 'brace/mode/coldfusion';
import 'brace/mode/csharp';
import 'brace/mode/css';
import 'brace/mode/curly';
import 'brace/mode/d';
import 'brace/mode/dart';
import 'brace/mode/diff';
import 'brace/mode/django';
import 'brace/mode/dockerfile';
import 'brace/mode/dot';
import 'brace/mode/drools';
import 'brace/mode/eiffel';
import 'brace/mode/ejs';
import 'brace/mode/elixir';
import 'brace/mode/elm';
import 'brace/mode/erlang';
import 'brace/mode/forth';
import 'brace/mode/fortran';
import 'brace/mode/ftl';
import 'brace/mode/gcode';
import 'brace/mode/gherkin';
import 'brace/mode/gitignore';
import 'brace/mode/glsl';
import 'brace/mode/gobstones';
import 'brace/mode/golang';
import 'brace/mode/groovy';
import 'brace/mode/haml';
import 'brace/mode/handlebars';
import 'brace/mode/haskell';
import 'brace/mode/haskell_cabal';
import 'brace/mode/haxe';
import 'brace/mode/hjson';
import 'brace/mode/html';
import 'brace/mode/html_elixir';
import 'brace/mode/html_ruby';
import 'brace/mode/ini';
import 'brace/mode/io';
import 'brace/mode/jack';
import 'brace/mode/jade';
import 'brace/mode/java';
import 'brace/mode/javascript';
import 'brace/mode/json';
import 'brace/mode/jsoniq';
import 'brace/mode/jsp';
import 'brace/mode/jsx';
import 'brace/mode/julia';
import 'brace/mode/kotlin';
import 'brace/mode/latex';
import 'brace/mode/lean';
import 'brace/mode/less';
import 'brace/mode/liquid';
import 'brace/mode/lisp';
import 'brace/mode/live_script';
import 'brace/mode/livescript';
import 'brace/mode/logiql';
import 'brace/mode/lsl';
import 'brace/mode/lua';
import 'brace/mode/luapage';
import 'brace/mode/lucene';
import 'brace/mode/makefile';
import 'brace/mode/markdown';
import 'brace/mode/mask';
import 'brace/mode/matlab';
import 'brace/mode/mavens_mate_log';
import 'brace/mode/maze';
import 'brace/mode/mel';
import 'brace/mode/mips_assembler';
import 'brace/mode/mipsassembler';
import 'brace/mode/mushcode';
import 'brace/mode/mysql';
import 'brace/mode/nix';
import 'brace/mode/nsis';
import 'brace/mode/objectivec';
import 'brace/mode/ocaml';
import 'brace/mode/pascal';
import 'brace/mode/perl';
import 'brace/mode/pgsql';
import 'brace/mode/php';
import 'brace/mode/plain_text';
import 'brace/mode/powershell';
import 'brace/mode/praat';
import 'brace/mode/prolog';
import 'brace/mode/properties';
import 'brace/mode/protobuf';
import 'brace/mode/python';
import 'brace/mode/r';
import 'brace/mode/razor';
import 'brace/mode/rdoc';
import 'brace/mode/rhtml';
import 'brace/mode/rst';
import 'brace/mode/ruby';
import 'brace/mode/rust';
import 'brace/mode/sass';
import 'brace/mode/scad';
import 'brace/mode/scala';
import 'brace/mode/scheme';
import 'brace/mode/scss';
import 'brace/mode/sh';
import 'brace/mode/sjs';
import 'brace/mode/smarty';
import 'brace/mode/snippets';
import 'brace/mode/soy_template';
import 'brace/mode/space';
import 'brace/mode/sql';
import 'brace/mode/sqlserver';
import 'brace/mode/stylus';
import 'brace/mode/svg';
import 'brace/mode/swift';
import 'brace/mode/swig';
import 'brace/mode/tcl';
import 'brace/mode/tex';
import 'brace/mode/text';
import 'brace/mode/textile';
import 'brace/mode/toml';
import 'brace/mode/tsx';
import 'brace/mode/twig';
import 'brace/mode/typescript';
import 'brace/mode/vala';
import 'brace/mode/vbscript';
import 'brace/mode/velocity';
import 'brace/mode/verilog';
import 'brace/mode/vhdl';
import 'brace/mode/wollok';
import 'brace/mode/xml';
import 'brace/mode/xquery';
import 'brace/mode/yaml';
*/

// My Modes
import 'brace/mode/c_cpp';
import 'brace/mode/html';
import 'brace/mode/python';
import 'brace/mode/text';
import 'brace/mode/css';
import 'brace/mode/javascript';

@Component({
	selector: 'app-bs-editor-wrap',
	templateUrl: './bs-editor-wrap.component.html',
	styleUrls: ['./bs-editor-wrap.component.css']
})
export class BsEditorWrapComponent implements OnInit {

	public options: string = '';
	private _file: any;
	private _data: string;
	private networkInput: boolean = false;
	_textSize;
	_fVersion: string = '-1';  // -1 magic number for current version
	readOnly: boolean = false;
	modes = ['c_cpp', 'css', 'html', 'javascript', 'python', 'text'];

	@Input() height: string;  // full or not
	@Input() set file(info: any) {
		// console.log('editor Wrap set file');
		this._file = info;
		if (!info.mode) {
			this._file.mode = this.findModeForFileName(this._file.name);
		}
	}
	@Input() theme: string;
	@Input() set textSize(t) {
		// console.log('editor textSize');
		this._textSize = t;
		if (this.editor) {
			this.editor.getEditor().setOptions({ fontSize: t });
		}
	}
	@Input() project;  // used to restore versions
	@Input()
	set data(d: string) {
		this._data = d;
		if (this.fVersion === '-1') {
			this.selData = d;
		}
	}

	@Output() dataChanged = new EventEmitter();

	@ViewChild('editor', { static: true }) editor: any;

	public get mode(): string {
		return this._file.mode;
	}
	public set mode(nMode: string) {
		this._file.mode = nMode;
	}

	public selData: string; // the data to display
	// could be an older version

	get data(): string {
		return this._data;
	}

	public get type() {
		return this._file.type;
	}

	constructor(private logger: BsLoggerService) { }

	public goToLine(line, col) {
		console.log('goToLine: ' + line + ':' + col);
		const ed = this.editor.getEditor();

		// https://stackoverflow.com/questions/23748743/ace-editor-go-to-line
		ed.resize(true);
		setTimeout( () => { // These setTimeouts are needed to allow it to resize and scroll...
			ed.scrollToLine(50, true, true, function () { });

			setTimeout( () => { // ...before going to the line
				ed.gotoLine(line, col, true);
			}, 50);
		}, 50);


	}

	findModeForFileName(name: string) {
		const n: string = name.toLowerCase();
		if (n.endsWith('.c') || n.endsWith('.cpp')) {
			return 'c_cpp';
		}
		else if (n.endsWith('.py')) {
			return 'python';
		}
		else if (n.endsWith('.js')) {
			return 'javascript';
		}
		else if (n.endsWith('.css')) {
			return 'css';
		}
		else if (n.endsWith('.htm') || n.endsWith('.html')) {
			return 'html';
		}
		return 'text';
	}

	ngOnInit() {
		// console.log('NE here');
		this.editor.style.position = 'absolute';
		this.editor.style.bottom = '0px';
		const ed = this.editor.getEditor();
		ed.resize();
	}

	onChange(data) {
	}

	onChanged(data) {
		if (this.fVersion !== '-1') {
			return;  // don't directly update versions of the file
		}
		if (this.networkInput) {
			console.log('Ignoring network data');
			return;
		}

		// console.log('Changed: '); // + ' [' + data + ']');
		this.dataChanged.emit(data);
	}

	get fVersion() {
		return this._fVersion;
	}
	set fVersion(fid) {
		const findByKey = function (a: any[], key, v) {
			for (const o of a) {
				if (o[key] === v) {
					return o;
				}
			}
			return null;
		};

		this._fVersion = fid;
		if (fid === '-1') {
			this.selData = this._data;
			this.readOnly = false;
		}
		else { // prev version
			this.readOnly = true;
			let p: any;
			const pp = findByKey(this.fVersions, 'fId', fid);
			if (pp) {
				p = pp.project.data.proj;
			}

			if (!p) {
				console.log('Version not found');
				return;
			}
			const fi = this._file.__key;
			const f = findByKey(p.files, '_key', fi);
			if (!f) {
				return;
			}
			this.selData = f.data; // '//Data from: ' + fid + '\r\n' +
		}
	}
	fVersions = [{ title: 'Current Version...', fId: '-1', project: null }];
	obVersions;

	getVersions() {
		if (this.obVersions) {
			return; // already got versions
		}
		console.log('Update versions');
		const v = { title: 'Wait', fId: '-1', project: null }; // {lDate:'Current Version'};
		this.fVersions = [v];

		const user_proj_path = this.project.user + '/' + this.project.projPath;
		this.obVersions = this.logger.getVersionsOfFile(user_proj_path);
		this.obVersions.subscribe(d => {
			console.log('Got versions...');
			this.reloadVersionsList(d);
		});
	}

	reloadVersionsList(d) {
		this.fVersions = [{ title: 'current', fId: '-1', project: null }];
		for (const pi in d) {
			const p = d[pi];
			const o = { title: p.lDate, fId: pi, project: p };
			this.fVersions.push(o);
		}
	}
}
