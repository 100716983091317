import { Injectable } from '@angular/core';
import * as firebase from 'firebase/app';
import { UserRepository } from './userRepository';
import { IUser } from "../interfaces/IUser"


export interface ISettings {
	editor_theme: string;
	editor_text_size: string;
} // also see defaults below



@Injectable()
export class UserSettings {
	private _db: firebase.database.Reference;
	private _currentUser: IUser;

	defaults: ISettings = {
			editor_theme: 'xcode',
			editor_text_size: 'regular'
		};

	constructor(private userRepo: UserRepository) {
		this._db = firebase.database().ref().child('/user_settings');
		userRepo.getCurrentUser((u) => {
			this._currentUser = u;
			// TODO unsub or if this is really a singleton, then don't worry about it
			// TODO test it out sometime
		})

	}

	async getUserSettings(uid: string): Promise<ISettings> {
		const v = await this._db.child(uid).once('value');
		const vv = v.val()  || {};


		//debugger;
		for (const k of Object.keys(this.defaults)) {
			if (!vv[k]) {
				vv[k] = this.defaults[k];
			}
		}

		return vv;
	}

	async setUserSettings(uid: string, settings: ISettings): Promise<ISettings> {
		const v = await this._db.child(uid ).update(settings);
		return v;
	}

	async getCUserSettings(): Promise<ISettings> {
		return this.getUserSettings(this._currentUser.uid);
	}

	async setCUserSettings(settings: ISettings): Promise<ISettings> {
		return this.setUserSettings(this._currentUser.uid, settings);
	}

}
