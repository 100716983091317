import { Component, OnInit, OnDestroy, ChangeDetectorRef, NgZone } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BsClassesUsersService, IEnrollmentRequest } from '../../services/bs-classes-users.service';
import { UserRepository } from '@cf-platform/cf-core-cms-ng';
import { IUser } from '@cf-platform/cf-core-cms';

@Component({
	selector: 'app-enroll',
	templateUrl: './enroll.component.html',
	styleUrls: ['./enroll.component.css']
	})
	export class EnrollComponent implements OnInit, OnDestroy {

		classid: string;
		classinst: string;
		path: string;

		isClassChecked = false;
		canAutoEnrollInClass = false;
		isEnrolled = false;
		isError = false;
		errorMsg = '';

		user: IUser;
		enrollmentReq: IEnrollmentRequest;
		unsub: any;


		constructor(
			private route: ActivatedRoute,
			private classesUsersService: BsClassesUsersService,
			private users: UserRepository,
			private cdRef: ChangeDetectorRef,
			private ngZone: NgZone) {

		}

		refresh() {
			setTimeout(() => {
				this.ngZone.run( () => {
					this.cdRef.detectChanges();
				})

			}, 500);
		}

		ngOnInit() {

			setTimeout(() => {
				this.classid = this.route.snapshot.params['classid'];
				this.classinst = this.route.snapshot.params['classinst'];
				this.path = this.route.snapshot.params['path'];

				this.users.getCurrentUser( u => {
					this.user = u;

					this.ngZone.run(() => this.doChecks());


				});
			}, 500);



		}

		async doChecks() {
			console.log('debug here');



			await this.checkIfCanAutoEnrollInClass(this.classid);
			if (!this.canAutoEnrollInClass) {
				this.refresh();
				return;
			}

			await this.checkEnrolled();
			if (this.isEnrolled) {
				this.refresh();
				return;
			}


			let didIt = await this.classesUsersService.addUserToClass(this.user, this.classid);
			if (didIt && !didIt.error) {
				this.isEnrolled = true;
				return;
			}
			if (didIt && didIt.error) {
				console.log(didIt.error, didIt.data);
				this.errorMsg = didIt.error + ': ' + didIt.data;
			}
			this.isError = true;

			/*


			if (this.unsub) {
				this.unsub();
			}
			if (this.user) {
				this.unsub = this.classesUsersService.watchEnrollmentRequest(this.user, this.classid, enrollmentReq => {
					setTimeout(async () => {
						this.enrollmentReq = enrollmentReq;
						this.refresh();

						setTimeout(async () => {
							await this.checkEnrolled();
							this.refresh();
						}, 2000);

					}, 5);

				});

				this.createReq();

			}
			*/
		}

		async checkEnrolled() {
			this.isEnrolled = await this.classesUsersService.isUserInClass(this.user.email, this.classid);
		}

		async checkIfCanAutoEnrollInClass(classid) {
			this.canAutoEnrollInClass = await this.classesUsersService.canAutoEnrollInClass(classid);
			this.isClassChecked = true;
		}

		/*
		async createReq() {
			await this.classesUsersService.createEnrollmentRequest(this.user, this.classid);
		}
		*/

		ngOnDestroy() {
			if (this.unsub) {
				this.unsub();
			}
		}



}
