import { Component, AfterViewInit, Input, ChangeDetectorRef, ViewChild, OnDestroy } from '@angular/core';
import { BsCurrentClassInfoService, CFCurrentClassInfo } from '../../services/bs-current-class-info.service';
import { BsClass } from '../../classes/bs-class';
import { BsAssignmentsService } from '../../services/bs-assignments.service';

import { BsProjectC } from '@cf-platform/cf-core-cms'; // bs-user-projects.service';
import { Observable } from 'rxjs/Observable';
import { BsUtilsService } from '@cf-platform/cf-core-cms-ng';

import { Table} from 'primeng/table';

import {IProjectInfo, IAssignment} from "@cf-platform/cf-core-cms";


//import { ZLinterService } from '../../services/z-linter.service';
import { CFLinter, IGradeInfo } from '@cf-platform/cf-class-stuff';
import {UtilStuff} from '@cf-platform/cf-core-cms';
import { BehaviorSubject } from 'rxjs';
import { IUser } from '@cf-platform/cf-core-cms';
//import { toArray } from 'rxjs-compat/operator/toArray';
import { CFTableCol } from '@cf-platform/cf-core-cms-ng'; //src/lib/cf-core-cms-ng/cf-table/cf-table.component'

import { Router, ActivatedRoute } from '@angular/router';

import { BreadcrumbService } from 'projects/cf-web/src/app/breadcrumb.service';
import { BsAssignmentTesterService } from '../../services/bs-assignment-tester.service';

import { CFApiClient } from '@cf-platform/cf-api-client';

import { BsUserProjectsService } from '@cf-platform/cf-core-cms-ng';



interface Submitter extends IUser {
	__note?: string,
	__earlyLate?: string,
	__grade?: string,
	__gradePlus?: string,
	__submitId?: string,
	__newSubmitFlag?: boolean,
	__click?: () => void
}

@Component({
	selector: 'app-bs-assignment-grader',
	templateUrl: './bs-assignment-grader.component.html',
	styleUrls: ['./bs-assignment-grader.component.css']
})
export class BsAssignmentGraderComponent implements AfterViewInit, OnDestroy {

	studentSubmits: any[] = [];
	studentSubmitsDict: any = {}; // keyed on date
	_frell = 'debug';

	@ViewChild('searchDt', { static: false }) searchTable: Table;


	columns: CFTableCol[] = [
		//{header: 'EMail', field: 'email', width:'150px'},
		{header: 'Note', field: '__note', width:'200px'},
		{header: 'Early/Late', field: '__earlyLate', width:'100px'},
		{header: 'Grade', field: '__gradePlus', width:'100px'}
	];
	nextStuKey: string;
	nextSubmittedStuKey: string;
	studentSubmitter: Submitter;
	currentClassInfo: CFCurrentClassInfo;
	cciSub: any;


	get basePath(): string {
		const p = '/class/utm/' + this.currentClassInfo.theClass.classId + '/grade/';
		return p;
	}

	dateToString(t): string {
		const d = new Date(t);
		return d.toString();
	}

	assignmentList: Array<IAssignment> = [];
	submittersList: BehaviorSubject< Submitter[] > = new BehaviorSubject([]);
	submitters: Submitter[] = [];
	submittersDict: {[index:string] : Submitter } = { };	// dict of submitters
	// https://basarat.gitbooks.io/typescript/docs/types/index-signatures.html



	lastClassId = '';

	async loadAssignments() {
		if (!this.theClass) {
			console.log('No class yet');
			return;
		}
		if (!this.theClass.classId) {
			console.log('Cannot load assignments yet.');
			return;
		}
		if (this.lastClassId === this.theClass.classId) {
			console.log('Already loading class assignments for: ' + this.lastClassId);
			return;
		}
		this.lastClassId = this.theClass.classId;

		console.log('Loading assignments list...');
		this.assignmentList = await this.currentClassInfo.theClass.getAssignments();
		console.log('Loaded assignments list.');
		return true;
	}

	async findAssignment(id) {
		console.log('Finding assignment: ' + this.assignmentId);


		await this.getTheStudents();


		if (!this.assignmentList || this.assignmentList.length === 0) {
			console.log('Loading the assignments');
			const w = await this.loadAssignments();
		}

		this.assignment = null;
		if (this._assignmentId && this._assignmentId !== '' && this.assignmentList) {
			for (const a of this.assignmentList) {
				if (a.assignmentId === this._assignmentId) {
					this.assignment = a;
				}
			}
		}

		//this.reloadStudentData();


		//this.findSubmittersForAssignment(this.assignment);
	}

	async reloadStudentData() {
		this.studentSubmits = [];
		this.studentSubmitsDict = {};
		await this.addInEnrolledStudents();
		this.submittersList.next(this.submitters);
		setTimeout(() => {
			this.findNewSubmits();
		}, 3000);
	}



	// NON DRY - similar  (but dif) or dups in bs-folder-view.component
	scrollToBottom(): void {
		setTimeout(() => {

			const element = document.getElementById('studentThing');
			if (element) {
				element.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
			}
			else {
				document.scrollingElement.scrollTop = document.scrollingElement.scrollHeight;
			}

		}, 600);
	}


	lastClassFS = '';

	// got rid of a big old function here

	/*
	updateFilteredTable() {
		setTimeout(() => {
			// https://stackoverflow.com/questions/42587424/primeng-datatable-doesnt-refresh

			// this band-aid fixes the bug
			// that datatable will not update if there is a filter applied
			// unless you do this apparently...
			// TODO:     this.searchTable.filter(null,null,null);
		}, 500);
	}
	*/


	async addInThisStudent(stu: IUser) {
		const uDbKey = stu.dbkey || BsUtilsService.z_filterEmailAsKey(stu.email);

		let u = this.submittersDict[uDbKey];

		if (!u) {
			//console.log('p2: ' + uDbKey);
			u = stu;
			this.submitters.push(u);
			this.submittersDict[uDbKey] = u;
		} else {
			u.name = stu.name;
			u.firstName = stu.firstName;
			u.lastName = stu.lastName;
		}

		const e = uDbKey;
		const g = await this.getGradeInfoForStudent(e);

		this.addInUsersGradeInfo(u, g);
	}
	async addInEnrolledStudents() {
		console.log('Add in enroled students...');

		await this.getTheStudents();

		if (!this._theStudents || this._theStudents.length === 0) {
			console.log('Enrolled students not found!');
			return;
		}


		for (const allU of this._theStudents) {

			this.addInThisStudent(allU);

			//
		}
		this.submittersList.next(this.submitters);


	}



	calcEarlyLate(subDate: number): string {
		return BsAssignmentsService.calcEarlylateFA(this.assignment, subDate);
	}




	async findNewSubmitForStudent(classId: string, assignmentId: string, submitter: Submitter) {
		const ss = await this.assignmentsService.getSubmitsFor(classId, assignmentId, submitter.dbkey);

		for (const sub of ss) {
			if (sub.submitDate > submitter.__submitId) {
				submitter.__newSubmitFlag = true;
				//this.submittersDict[e].__newSubmitFlag = true;
				submitter.__gradePlus = submitter.__grade + ' *NEW*';
				//this.submittersDict[e].gradePlus = g.gradePlus;
				debugger;
				break;
			}
		}
	}

	async findNewSubmits() {
		console.log('Finding new submits...');

		const classId = this.currentClassInfo.theClass.classId;
		const assignmentId = this.assignmentId;

		if (!assignmentId) {
			console.log('findNewSubmits called, but no assignment yet!');
			return;
		}

		// for each of this assignments student graded attempts
		// check to see if there was a new attempt submitted
		for (const e in this.submittersDict) { // gradeInfoDict
			const g = this.submittersDict[e];
			const stuKey = e;
			console.log('FNS: ' + stuKey);
			console.log(g);

			await this.findNewSubmitForStudent(classId, assignmentId, g);
		}

		//this.updateFilteredTable();
		this.submittersList.next(this.submitters);
	}


	theClass: BsClass;
	_theStudents: IUser[] = [];

	async getTheStudents() : Promise<Array<IUser>> {
		if (!this.theClass) {
			return [];
		}
		if (!this._theStudents || this._theStudents.length === 0) {
			console.log('Loading enrolled students...');
			this._theStudents = await this.theClass.usersO.first().toPromise();
		}
		return this._theStudents;
	}

	zLinter = CFLinter.getInstance();

	constructor(
		private currentClassInfoService: BsCurrentClassInfoService,
		private assignmentsService: BsAssignmentsService,
		private ref: ChangeDetectorRef,
		private router: Router,
		private route: ActivatedRoute,
		private breadcrumbService: BreadcrumbService,
		private tester: BsAssignmentTesterService,
		private projectService: BsUserProjectsService
		//private zLinter: ZLinterService)
	) {

		this.cciSub = currentClassInfoService.currentClassInfoHandler.subscribe(cci => {

			this.currentClassInfo = cci;
			if (cci) {
				if (this.theClass != cci.theClass) {
					this.theClass = cci.theClass;
					this.startStuff();

				}
				this.assignmentId = this._assignmentId;
					this.studentKey = this._studentKey;
					this.submitId = this._submitId;

				this.setClassTitle();

				//              0   1     2    3     4
				//  classPath    /grade/asid/stuid/subid

				const cPaths = cci.theClassPath.split('/');

				if (cPaths.length > 2) {
					this.assignmentId = cPaths[2];
				} else {
					this.assignmentId = '';
				}
				if (cPaths.length > 3) {
					this.studentKey = cPaths[3];
				} else {
					this.studentKey = '';
				}
				if (cPaths.length > 4) {
					this.submitId = cPaths[4];
				} else {
					this.submitId = '';
				}

				this.setClassTitle();
				this.findAssignment(this.assignmentId);


			} else {
				console.log('No class yet...');
			}


		})

		/*
		this.route.params.subscribe( params => {
			console.log('Got params here');
			//const params = this.route.snapshot.params;
			this.assignmentId = params['assignmentId'];
			this.studentKey = params['studentKey'];
			this.submitId = params['submitId'];


			this.setClassTitle();
			this.findAssignment(this.assignmentId);
			//this.reloadStudentData();

		})
		*/

	}

	async setClassTitle() {

		console.log('setClassTitle stuffs');

		if (!this.theClass || !this.theClass.classId) {
			this.breadcrumbService.setItems([]);
			this.breadcrumbService.setPageInfo({title: 'Grade', icon:'update'})
			return;
		}

		let path = '/class/utm/' + this.theClass.classId;
		const items: [{label:string, routerLink?: string}] = [{label: this.theClass.classId, routerLink: path}];

		if (!this._assignmentId) {
			items.push({label: 'Grade'});
		} else {
			path += '/grade';
			items.push({label: 'Grade', routerLink: path});

			if (!this._studentKey) {
				items.push({label: this._assignmentId});
			} else {
				path += '/' + this._assignmentId;
				items.push({label: this._assignmentId, routerLink: path});

				if (!this._submitId) {
					items.push({label: this._studentKey});
				}
				else {
					path += '/' + this._studentKey;
					items.push({label: this.studentKey, routerLink: path});

					items.push({label: this._submitId});

				}
			}
		}

		this.breadcrumbService.setItems( items);

		this.breadcrumbService.setPageInfo({title: 'Grade: ' + this.theClass.classId, icon: 'star'});

		setTimeout(async() => {
			await this.currentClassInfo.theClass.loadSettings();
			const className = this.currentClassInfo.theClass.settings.name
			if (className) {
				this.breadcrumbService.setPageInfo({title: 'Grade: ' + className, icon: 'star'});
			}

		}, 1000);

	}

	doneStartStuff = false;
	async startStuff() {


		if (!this.doneStartStuff) {
			await this.getTheStudents();
			this.doneStartStuff = true;
		}

	}

	assignment: IAssignment;

	_assignmentId: string = '';
	////@Input()
	set assignmentId(assignmentId: string) {
		if (this._assignmentId !== assignmentId) {
			// only update if different...
			this._assignmentId = assignmentId || '';
			this.findAssignment(assignmentId);
			this.reloadStudentData();
		}
	}
	get assignmentId(): string {
		return this._assignmentId;
	}

	_studentKey: string;
	////@Input()
	set studentKey(studentKey: string) {
		console.log('Debug set studentKey');
		const rStudentKey = (studentKey || '').replace('%40', '@');
		if (this._studentKey != rStudentKey) {
			this._studentKey = rStudentKey;
			this.studentSubmitter = null;

			this.doStuKeyStuff();
		}
	}

	async doStuKeyStuff() {
		if (!this._studentKey) {
			return;
		}

		this.findStudentSubmits();
		await this.loadGradeInfo();

		setTimeout(async () => {
			//if (!this.submittersDict[this._studentKey]) {
				let found = false;
				this.nextStuKey = '';
				this.nextSubmittedStuKey = '';
				let stus = await this.getTheStudents();
				for (let u of stus) {
					if (found) {
						if (!this.nextStuKey) {
							this.nextStuKey = u.dbkey;
						}
						if (!this.nextSubmittedStuKey && this.assignment.submitted &&  this.assignment.submitted[u.dbkey]) {
							this.nextSubmittedStuKey = u.dbkey;
							break;
						}
					}
					else if (u.dbkey === this._studentKey) {
						await this.addInThisStudent(u);
						this.studentSubmitter = this.submittersDict[u.dbkey];
						found = true;
					}
				}
			//}


			if (this.submittersDict[this._studentKey]) {
				this.findNewSubmitForStudent(this.theClass.classId, this.assignmentId, this.submittersDict[this._studentKey]);
			}
		}, 1000);

	}
	get studentKey(): string {
		return this._studentKey;
	}


	actualSubmitId: string;

	_submitId: string = '';
	////@Input()
	set submitId(submitId) {
		this._submitId = submitId || '';
		console.log('submitId set to: ' + submitId);

		this.project = null;

		this.loadSubmittedProject();
	}
	get submitId(): string {
		return this._submitId;
	}

	project: IProjectInfo;

	loadSubmittedProject() {
		console.log('loadSubmittedProject');

		this.loadGradeInfo();

		this.actualSubmitId = this._submitId;
		if (this._submitId === '') {
			if (!this.studentSubmits) {
				console.log('loadSubmittedProject: Trying to load submitted project...  No submits');
				return;
			}
			const submitCount = this.studentSubmits.length;
			if (submitCount === 0) {
				console.log('loadSubmittedProject: Trying to load submitted project...  No submits');
				return;
			}
			this.actualSubmitId = this.studentSubmits[submitCount - 1].submitDate;
			console.log('loadSubmittedProject: Trying to load LAST submitted project...' + this.actualSubmitId);
		}
		console.log('loadSubmittedProject: Trying to load submitted project... id: ' + this.actualSubmitId);

		if (this.studentSubmitsDict[this.actualSubmitId]) {
			console.log('loadSubmittedProject: Loading submitted project...');
			const proj = this.studentSubmitsDict[this.actualSubmitId].project;

			this.project = proj;

			this.project.relatedAssignment = this.assignment;
			if (this.actualSubmitId) {
				this.project.relatedSubmitDate = +this.actualSubmitId; // hm see above why
			}


		}
		else {
			console.log('loadSubmittedProject: Error submitted project was null');
		}
	}






	async findStudentSubmits() {
		console.log('Debug findStudentSubmits');
		this.studentSubmits = [];
		this.studentSubmitsDict = {};

		if (!this.currentClassInfo || !this.currentClassInfo.theClass || !this.currentClassInfo.theClass.classId) {
			return;
		}

		const classId = this.currentClassInfo.theClass.classId;
		const assignmentId = this.assignmentId;
		const studentKey = this.studentKey;

		if (!this.studentKey) {
			this.project = null;
			return;
		}

		const ss = await this.assignmentsService.getSubmitsFor(classId, assignmentId, studentKey);

		if (ss.length === 0) {
			this.project = null;
		}

		console.log('Got submits: ' + ss.length);
		for (const s in ss) {
			const sub = ss[s];

			console.log('SUB: ' + sub.submitDate);
			this.studentSubmits.push(sub);
			this.studentSubmitsDict[sub.submitDate] = sub;
		}

		this.loadSubmittedProject();

		return true;
	}


	grade: string;
	note: string;

	gradeInfo: IGradeInfo;
	gradeHistory: Array<IGradeInfo>;


	async loadGradeInfo() {
		//debugger;
		this.grade = '';
		this.note = '';
		this.gradeHistory = [];
		if (!this.currentClassInfo) {
			console.log('No currentClassInfo yet...');
			return;
		}
		if (!this.currentClassInfo.theClass.classId) {
			console.log('Cannot loadGradeInfo yet... Missing classId');
			return;
		}
		if (!this.assignmentId) {
			console.log('Cannot loadGradeInfo yet... Missing assignmentId');
			return;
		}
		if (!this.studentKey) {
			console.log('Cannot loadGradeInfo yet... Missing studentId');
			return;
		}

		this.gradeInfo = await this.assignmentsService.getGradeInfo(this.currentClassInfo.theClass.classId,
			this.assignmentId, this.studentKey);
		if (!!this.gradeInfo) {
			this.grade = this.gradeInfo.grade;
			this.note = this.gradeInfo.note;
			this.gradeHistory = UtilStuff.objToArray(this.gradeInfo.history);
			//debugger;
		}
	}

	feedback = '';
	feedbackVisible = false;
	feedbackIsError = false;
	flashFeedback(s: string, isError: boolean = false) {
		this.feedback = s;
		this.feedbackVisible = true;
		this.feedbackIsError = isError;
		setTimeout(() => {
			this.feedbackVisible = false;
			this.feedbackIsError = false;
		}, 3000);

	}

	async saveGradeClicked() {
		const sb = document.getElementById('submitGradeButton');
		sb.focus();


		await this.assignmentsService.saveGrade(this.currentClassInfo.theClass.classId,
			this.assignmentId, this.studentKey, this.actualSubmitId,
			this.grade, this.note);

		this.flashFeedback('Saved');

		let r = await CFApiClient.getInstance().lmsPushGrade(this.studentKey, this.currentClassInfo.theClass.classId, this.assignmentId);

		if (r.error) {
			this.flashFeedback('Error Updating LMS Grade!', true);
		}
		else {
			this.flashFeedback('Grade Pushed to LMS');
		}


		//debugger;
		//console.log('fix this');
		//this.findSubmittersForAssignment(this.assignment);

		this.findNewSubmits(); //
		this.reloadStudentData();
	}

	// todo: add types
	addInUsersGradeInfo(u, g) {
		if (!u || !g) {
			console.log('Check this...');
			return;
		}
		u.__gradePlus = g.grade;
		u.__grade = g.grade;
		u.__note = g.note;
		u.__needsGrading = g.needsGrading
		u.__submitId = g.submitDate || g.submitId;
		if (g && g.submitId) {
			u.__earlyLate = this.calcEarlyLate(g.submitId);
		}
		else {
			u.__earlyLate = '';
		}

		u.__click = () => {
			console.log('Click called');
			const link = this.basePath + this.assignmentId + '/' + u.dbkey;
			this.router.navigateByUrl(link);
			setTimeout(() => {
				this.scrollToBottom();
			}, 200);

		}
	}

	async getGradeInfoForStudent(aStudentEmail): Promise<any> {

		if (aStudentEmail.includes('zbobbradley')){
			//debugger;
		}

		if (! aStudentEmail || ! this.assignmentId) {
			console.log('No student or assignmentId');
			return;
		}

		//console.log('DEBUG getGradeInfoForStudent HERE ');
		const gradeInfo = await this.assignmentsService.getGradeInfo(this.currentClassInfo.theClass.classId,
			this.assignmentId, aStudentEmail);
		if (!gradeInfo || typeof(gradeInfo.grade) == 'undefined') {
			//if (this.submittersDict[aStudentEmail]) {
			if (this.assignment.submitted && this.assignment.submitted[aStudentEmail]) {
				//debugger; ///zzz
				return { grade: ' ! ', note: '', needsGrading: true };
			}
			else {
				return { grade: ' - ', note: '' };
			}
		}
		else {
			console.log('Debug gradeInfo here');
		}
		if (gradeInfo.grade === '') {
			gradeInfo.grade = ' -? ';
		}
		return gradeInfo; // {grade:gradeInfo.grade, note:gradeInfo.note};
	}

	ngAfterViewInit() {
		//this.loadAssignments();
	}

	//

	/*
	doLint(){
		const code = this.project.files[1].data;
		//this.zLinter.zLintFile(code);
	}
	*/


	goNextStudent() {
		console.log('Go next student');
		if (this.nextStuKey) {
			const link = this.basePath + this.assignmentId + '/' + this.nextStuKey;
			this.router.navigateByUrl(link);
		}
	}

	goNextSubmit() {
		console.log('Go next submit');
		if (this.nextSubmittedStuKey) {
			const link = this.basePath + this.assignmentId + '/' + this.nextSubmittedStuKey;
			this.router.navigateByUrl(link);
		}
	}


	ngOnDestroy() {
		this.cciSub.unsubscribe();
	}



	manualSubmitProject() {
		console.log("Manual Submit Project");
		console.log('Student Id: ', this.studentKey)
		console.log('Assignment Id: ', this.assignmentId)

		let ans = confirm('Are you sure you want to manually submit the current version of the student\'s project?');
		if (ans) {
			this.manualSubmitProject_Y();
		}
	}

	async manualSubmitProject_Y() {
		console.log("Manual Submit Project (YES)");

		const projPath = this.theClass.classId + ':' + this.assignmentId;

		const p = await this.projectService.getProjectCopy(this.studentKey, projPath);

		if (!p) {
			await alert('Sorry, no project was found!')
			return;
		}

		if (!p.files) {
			await alert('Sorry, no project files were found!');
			return;
		}

		await this.assignmentsService.submit(this.theClass.classId, this.assignment, this.studentKey, p);

		console.log("Manual Submit Done.");


		await alert('Manual Submit Done.');

		//let link = this.basePath + this.assignmentId ;
		//this.router.navigateByUrl(link);

		console.log('Trying to refresh...');

		const tStudentKey = this.studentKey;
		this.studentKey = null;

		setTimeout(() => {
			this.studentKey = tStudentKey;
		}, 500);




	}
}
