import { Component } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { AngularFireDatabase } from '@angular/fire/database/';
import * as firebase from 'firebase/app';

@Component({
	selector: 'app-bs-connection-status',
	templateUrl: './bs-connection-status.component.html',
	styleUrls: ['./bs-connection-status.component.css']
})
export class BsConnectionStatusComponent {
	status: Observable<any>;
	constructor(public afDatabase: AngularFireDatabase) {
		setTimeout(() => {
			this.status = afDatabase.object('.info/connected').valueChanges();
		}, 0);

	}
}
