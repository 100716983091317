import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { map, first } from 'rxjs/operators'
//import { map, first } from 'rxjs-compat';
import { AngularFireDatabase, AngularFireList } from '@angular/fire/database';
import { IUser } from '@cf-platform/cf-core-cms';
//import firebase = require('firebase');
//import { firebase } from 'firebase/firebase-app';
import * as firebase from 'firebase/app';

import {CFApiClient, CFACResult} from '@cf-platform/cf-api-client'
import { IClassSettings } from '../interfaces/IClassSettings';



export interface IEnrollmentRequest {
	user_email: string;
	user_id: string;
	user_name: string;

	class_id: string;
	//class_id_plus_email: string;

	password_given: string;

	allowed?: boolean;
	processed?: boolean;
	error?: string;

	date_of_request?: any | string;
	date_processed?: string;
}

@Injectable()
export class BsClassesUsersService {

	cfApiClient = CFApiClient.getInstance();

	constructor(private db: AngularFireDatabase) { }

	public getClassesForUserId(user_id: string): Observable<any> {
		console.log('Debug getClassesForUserId');
		if (!user_id) {
			console.log('No user given...');
			return null;
		}
		const path = '/users_classes/' + user_id + '/classes';
		const classes = this.db.list(path);
		return classes.valueChanges();
	}

	public async getClassesForUserIdOnce(user_id: string): Promise<Array<{id:string}>> {
		console.log('Debug getClassesForUserIdOnce');
		if (!user_id) {
			console.log('No user given...');
			return [];
		}

		const path = '/users_classes/' + user_id + '/classes';
		const classes = this.db.list(path);
		const v1 = await classes.valueChanges().first().toPromise();
		const v = v1.sort((a,b) => (a['id']>b['id']) ? -1 : 1 );;
		return v as Array<{id:string}>;
	}

	public getUsersInClass(classId: string): AngularFireList<any> {
		return this.db.list('/class/' + classId + '/users');
	}

	public getPendingUsersInClass(classId: string): AngularFireList<any> {
		return this.db.list('/class/' + classId + '/users_pending');
	}

	public async loadClassSettings(classId: string): Promise<IClassSettings | null>  {
		const ref = this.db.database.ref('/class/' + classId + '/settings')
		const snap =  await ref.once('value');
		if (snap) {
			return snap.val() as IClassSettings;
		}

		return null;
	}

	public async saveClassSettings(classId: string, settings: IClassSettings) {
		const ref = this.db.database.ref('/class/' + classId + '/settings')
		const snap =  await ref.update(settings);
		return;
	}

	public async updateUserInfo(classId: string) {
		console.log('updateUserInfo: Updating user info for users in class: ' + classId);

		const cUsersLive = this.getUsersInClass(classId);
		const cUsers = await cUsersLive.valueChanges().first().toPromise<any[]>();

		console.log('updateUserInfo: After getUsersInClass');

		for (const ui in cUsers) {
			const u = cUsers[ui];
			console.log('updateUserInfo: user: ' + u.email + ' to: ' + classId);
			const uid = u.uid;

			const user: any = await this.db.object('/users/' + uid).valueChanges().first().toPromise<any>();

			// just updating the name info right now...
			const userInfo = { name: user.name, firstName: user.firstName, lastName: user.lastName };
			if (user && user.uid === u.uid) {
				const j = await cUsersLive.update(u, userInfo);
			}
		}
	}

	public async updateUserClasses(classId: string) {
		console.log('TODO: FIX');
		/*
		console.log('updateUserClasses: Updating users for class: ' + classId);
		const users = await this.getUsersInClass(classId).valueChanges().first().toPromise<any[]>();
		console.log('updateUserClasses: After getUsersInClass');

		for (const u of users) {
			console.log('updateUserClasses: Adding (userSide) user: ' + u.email + ' to: ' + classId);
			await this.addUserToClass_UserSide(u, classId);
		}
		*/
	}

	public async removeUserFromClass(user: IUser, classId: string) {
		console.log('removeUserFromClass: Remove user: ' + user.email + ' from class: ' + classId);

		return this.cfApiClient.removeUserFromClass(user, classId);
		/*
		await this.removeUserFromClass_UserSide(user, classId);
		await this.removeUserFromClass_ClassSide(user, classId);
		return true;
		*/
	}

	/*
	public async addUserToClass_UserSide(user, classId): Promise<boolean> {
		console.log('addUserToClass_UserSide');
		const uid = (user.uid || user.__key);
		const o = {};
		o['email'] = (user.email || 'unknown');
		o['uid'] = (user.uid || user.__key || 'unknown');
		o[classId] = 1;
		const k = '/users_classes/' + uid;
		const r = await this.db.object(k);
		r.update(o);

		if (!r) {
			console.log('Error adding user to class');
			return false;
		}

		const k2 = k + '/classes/';
		const cls = await this.db.list(k2);
		const classes: any[] = await cls.valueChanges().first().toPromise();

		// check if the class is already there
		for (const c of classes) {
			if (c.id === classId) {
				return true; // and if so we are done
			}
		}

		const co = { id: classId };
		cls.push(co);
		return !!co;  // insert the class into the user's list
	}
	*/

	/*
	public async removeUserFromClass_UserSide(user, classId) {
		console.log('removeUserFromClass_UserSide: Remove user: ' + user.email + ' from class: ' + classId);
		const uid = (user.uid || user.__key);

		const k = '/users_classes/' + uid;
		const k2 = k + '/classes/';
		const cls = await this.db.list(k2);
		const classes: any[] = await cls
		//.valueChanges().first().toPromise();
		.snapshotChanges()
			.map(actions => {
				return actions.map(action => ({ __key: action.key, ...action.payload.val() }));
			})
			.first().toPromise();

		// check if the class is there
		for (const c of classes) {
			if (c.id === classId) {
				const j = cls.remove(c.__key);
				console.log('Removed user');
			}
		}
		return true;
	}
	*/

	private clean(o: any) {
		// https://stackoverflow.com/questions/32344495/remove-hashkey-from-array
		const json = JSON.stringify(o, function (key, value) {
			if (key.indexOf('$') >= 0) {
				return undefined;
			}
			return value;
		});

		return JSON.parse(json); // hacky way to remove $ keys
	}



	public async addUserToClass(user, classId): Promise<CFACResult<void>> {
		console.log('Debug addUserToCla1ss');

		return await this.cfApiClient.addUserToClass(user,classId);

		/*
		const r = await this.addUserToClass_ClassSide(user, classId);
		if (!r) {
			return false;
		}
		const r2 = await this.addUserToClass_UserSide(user, classId);
		return !!r2;
		*/
	}

	enroll_key(user: IUser, classId: string) {
		return classId + '::' + user.dbkey;
	}

	public async getEnrollmentRequest(user: IUser, classId: string): Promise<IEnrollmentRequest> {
		const k = '/class_enroll_requests/' + this.enroll_key(user, classId);
		const rec = await this.db.database.ref(k).once('value');
		const val = rec.val();
		return val;
	}


	public watchEnrollmentRequest(user: IUser, classId: string, onFunc: (IEnrollmentRequest) => void ): () => void {
		const k = '/class_enroll_requests/' + this.enroll_key(user, classId);
		const listener =  this.db.database.ref(k).on('value', (s) => {
			const val = s.val() as IEnrollmentRequest;
			onFunc(val);
		});

		const unsub = () => {
			this.db.database.ref(k).off('value', listener);
		};
		return unsub;
	}

	public async createEnrollmentRequest(user: IUser, classId: string): Promise<IEnrollmentRequest> {
		const enrollmentReq: IEnrollmentRequest = {
			user_email: user.email,
			user_id: user.uid,
			user_name: user.name,
			class_id: classId,
			date_of_request: firebase.database.ServerValue.TIMESTAMP,
			password_given: 'supersecret'
		};
		const k = '/class_enroll_requests/' + this.enroll_key(user, classId);
		const rec = await this.db.database.ref(k).update(enrollmentReq);
		return enrollmentReq;
	}

	public async canAutoEnrollInClass(classId) : Promise<boolean> {
		const k = '/class/' + classId + '/settings/';
		const rec = await this.db.database.ref(k).once('value');
		const val = rec.val();
		if (!val || !val['canAutoEnroll'] ) {
			return false;
		}
		return true;
	}

	public async isUserInClass(userEmail: string, classId: string): Promise<boolean> {
		const k = '/class/' + classId + '/users/';
		const rec = await this.db.database.ref(k).orderByChild('email').equalTo(userEmail).once('value');
		const val = rec.val();
		if (!val || !Object.keys(val) ) {
			return false;
		}
		const key = Object.keys(val)[0];

		return (rec && val && key && val[key] && val[key].email === userEmail );
	}

	/*
	public async addUserToClass_ClassSide(user, classId): Promise<boolean> {
		const uid = (user.uid || user.__key);

		let cleanUser = { ...user };

		if (cleanUser._data) {
			cleanUser = cleanUser._data; // hack to get at raw user data...
		}
		cleanUser = this.clean(cleanUser);

		const o = {};
		o[uid] = cleanUser;
		const k = '/class/' + classId + '/users/';
		return this.db.object(k).update(o).then(u => true).catch(u => false);
	}
	*/

	/*
	public removeUserFromClass_ClassSide(user, classId) {
		console.log('removeUserFromClass_ClassSide: Remove user: ' + user.email + ' from class: ' + classId);
		const uid = (user.uid || user.__key);
		const k = '/class/' + classId + '/users/' + '/' + uid;
		return this.db.object(k).remove();
	}
	*/
}
