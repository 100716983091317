import { Component, OnInit, OnDestroy, NgZone } from '@angular/core';
import { BsCurrentClassInfoService, CFCurrentClassInfo } from '../../services/bs-current-class-info.service';
import { Subscription } from 'rxjs';
import { IClassSettings } from '../../interfaces/IClassSettings';
import {BreadcrumbService} from '../../../../../projects/cf-web/src/app/breadcrumb.service';
import { BsClass } from '../../classes/bs-class';
import { ClassLMSInfo } from '../../../../cf-admin/src/lib/cf-admin';
// todo move breadcrumb service somewhere else

@Component({
  selector: 'cf-class-settings',
  templateUrl: './cf-class-settings.component.html',
  styleUrls: ['./cf-class-settings.component.css']
})
export class CfClassSettingsComponent implements OnInit, OnDestroy {
	cciSub: Subscription;
	currentClassInfo: CFCurrentClassInfo;
	classSettings: IClassSettings = {};
	theClass: BsClass;
	lmsInfo: ClassLMSInfo = {};

  constructor(
	  private currentClassInfoServ: BsCurrentClassInfoService,
	  private ngZone: NgZone,
	  private breadcrumbService: BreadcrumbService,) {

  }

  ngOnInit() {

	this.breadcrumbService.setItems([
		{label: ''},
	]);
	this.breadcrumbService.setPageInfo({title:'Class Settings...', icon: 'settings'});

	  this.cciSub = this.currentClassInfoServ.currentClassInfoHandler.subscribe( cci => {
		  setTimeout(() => {
			  if (!cci) {
				  this.classSettings = {};
				  return;
			  }
			  this.ngZone.run( () => {
				this.currentClassInfo = cci;
				this.theClass = cci.theClass;
				this.loadSettings();


				this.breadcrumbService.setItems([

					{label: this.theClass.classId, routerLink: '/class/utm/' + this.theClass.classId},
					{label: 'Class Settings'}
				]);
				this.breadcrumbService.setPageInfo({title: 'Class Settings: ' + this.theClass.classId, icon: 'settings'});


			  })
		  }, 0);

	  });
  }

  ngOnDestroy() {
	  this.cciSub.unsubscribe();
  }

  async loadSettings() {
	if (this.currentClassInfo && this.currentClassInfo.theClassId) {
		this.classSettings = await this.currentClassInfo.getSettings();
		if (!this.classSettings.lms) {
			this.classSettings.lms = {};
		}
	} else {
		this.classSettings = {};
		this.classSettings.lms = {};

	}
	this.lmsInfo = this.classSettings.lms;
  }

  async saveSettings() {
	  await this.currentClassInfo.saveSettings(this.classSettings);
  }

}
