import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MatToolbar, MatToolbarRow, MAT_DIALOG_DATA } from '@angular/material';
import { FolderItem } from '../../../../services/bs-folder.service';
import { BsFolderTreeComponent } from '../../bs-folder-tree/bs-folder-tree.component';

@Component({
	selector: 'app-bs-move-dialog',
	templateUrl: './bs-move-dialog.component.html',
	styleUrls: ['./bs-move-dialog.component.css']
})
export class BsMoveDialogComponent {
	constructor(
		@Inject(MAT_DIALOG_DATA) public data: any,
		public dialogRef: MatDialogRef<BsMoveDialogComponent>,
		public dialog: MatDialog) {
		this.item = data['item'];
		this.currentPath = this.item.my_path;
		this.rootItem = data['rootItem']; // has to be done after currentPath ... or not...
	}

	rootItem: FolderItem;
	item: FolderItem;
	currentPath: string = '/';

	doMove() {
		this.dialogRef.close(
			{
				cmd: 'Move',
				newPath: this.currentPath
			});
	}

	folderSelected(item: FolderItem) {
		this.currentPath = item.my_path;
		console.log('Folder: ' + this.currentPath);
	}
}
