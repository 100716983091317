export * from "./cf-class-stuff-ng.module";

//export {IGraderTest} from './interfaces/IGraderTest';
// IGraderTest moved to  cf-core-cms

export { BsClass } from './classes/bs-class'
export { OnlyEnrolledGuard } from './guards/only-enrolled.guard';
export { IClassSettings } from './interfaces/IClassSettings';

export { BsAssignmentGraderComponent } from './components/bs-assignment-grader/bs-assignment-grader.component';
export { BsClassComponent } from './components/bs-class/bs-class.component';
export { BsClassUsersComponent } from './components/bs-class-users/bs-class-users.component';
export { BsGradeBookComponent } from './components/bs-grade-book/bs-grade-book.component';
export { BsMyGradesComponent } from './components/bs-my-grades/bs-my-grades.component';
export { EnrollComponent } from './components/enroll/enroll.component';
export { CfClassSettingsComponent } from './components/cf-class-settings/cf-class-settings.component';
export { CfAssignmentCopierComponent } from './components/cf-assignment-copier/cf-assignment-copier.component';


export {BsAssignmentTesterService} from './services/bs-assignment-tester.service';
export { CFCurrentClassInfo} from './services/bs-current-class-info.service';
export { BsClassesUsersService } from './services/bs-classes-users.service';
export { BsCurrentClassInfoService } from './services/bs-current-class-info.service';
export { BsAssignmentsService } from './services/bs-assignments.service';
export { EzClassUserServiceService } from './services/ez-class-user-service.service';



