import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/toPromise';
import 'rxjs/add/operator/map';
import { first} from 'rxjs/operators/first'
import { AngularFireList, AngularFireObject } from '@angular/fire/database/';

import { IProjectInfo } from "./IProjectInfo";
import { IAssignment } from "./IAssignment";


import { iBsUserProjectsService } from './i-bs-user-projects.service';
import { isArray } from 'util';



export interface ProjectType { type: string; name: string; project: any; } // code smell - any



export class BsProjectC implements IProjectInfo {
	name: string;
	description: string;
	type: string;             // project
	projType: string;         // c++, python, html
	projPath: string;
	parent_path: string;
	rel_path: string;
	my_path: string;
	user: string;
	create_timestamp: any;
	sort: string;

	relatedAssignment?: IAssignment;


	get info() {
		return { name: this.name, _key: this.__key, my_path: this.my_path };
	}

	_proj_info_path?: string;
	_proj_user?: string;

	__key: string;
	getIcon2() {
		return { name: 'folder' };
	}
	get _key(): string {
		return this.__key;
	}

	private ups: iBsUserProjectsService;
	public _files: AngularFireList<any>; // AngularFireObject<any>;

	get files(): AngularFireList<any> {
		// console.log('Debug: get files here');
		if (!this._files) {
			this._files = this.ups.getUserProjectFiles(this.user, this.__key);
		}
		return this._files;
	}

	// tslint:disable-next-line:member-ordering
	static async loadFiles(proj: any) {
		console.log('Debug: loadFiles here');

		if (isArray(proj.files)) {
			return proj.files;
		}

		// https://github.com/angular/angularfire2/blob/master/docs/version-5-upgrade.md
		const files = await proj.files.snapshotChanges()
			.map(actions => {
				return actions.map(action => ({ __key: action.key, ...action.payload.val() }));
			})
			.pipe(first()).toPromise();
		return files;
	}

	// toPromise
	// https://stackoverflow.com/questions/34190375/how-can-i-await-on-an-rx-observable
	// https://stackoverflow.com/questions/36777284/angular-2-convert-observable-to-promise
	public async loadFiles(): Promise<any> {
		return BsProjectC.loadFiles(this);
	}

	public async loadFileWithId(id: string): Promise<any> {
		console.log('Debug: loadFileWithId here');
		const files = await this.loadFiles();
		for (const f of files) {
			if (f.__key === id) {
				return f;
			}
		}
		return null; // file was not found
	}

	public saveFile(id: string, file: any) {
		return this.ups.saveFile(this._files, id, file);
	}

	public saveAssignment = null; // will get hooked by assignment editor

	public addFile(file: any) {
		const j = this.files;  // have to preload for the new project case
		return this.ups.addFile(this._files, file);
	}

	public newFile(fileName: string) {
		return this.ups.newFile(this._files, fileName);
	}

	public deleteFile(fileId: string) {
		return this.ups.deleteFile(this, fileId);
	}

	public deleteProject() {
		return this.ups.deleteProject(this);
	}

	public saveInfo() {
		return this.ups.saveProjectInfo(this);
	}

	public isValid: boolean = false;

	fromDBInfo(d: any, userDBKey: string, ups: iBsUserProjectsService) {

		this.name = d.name;
		this.description = d.description;
		this.type = 'project';
		this.projType = d.projType || 'c++';
		this.isValid = (d._lType === 'project' || !!d.type);
		this.projPath = d._path || d.__key;
		this.parent_path = '/my/projects';
		this.rel_path = d.__key,
			this.my_path = '/my/projects/' + d.__key;
		this.user = userDBKey;
		this.create_timestamp = d.create_timestamp || '';
		this.sort = d.sort || d.__key;

		this.__key = d.__key;

		this.ups = ups;

	}

	makeClone(): any {  // HAVE NOT TESTED THIS YET.  MAY NOT WORK...
		const t: BsProjectC = this;
		const copy = { ...t };  // shallow copy
		return copy;

	}

	// tslint:disable-next-line:member-ordering
	static async makeCopy(proj: IProjectInfo) : Promise<IProjectInfo> {
		const t: any = proj;
		const copy = { ...t };  // shallow copy
		delete copy._files;
		copy.__key = copy.__key;
		delete copy.__key;
		const files = await BsProjectC.loadFiles(proj);
		copy.files = files;
		delete copy.ups;
		return copy;
	}

	// todo... track down if anything uses above makeclone and change it to this and delete it
	async makeCopy(): Promise<IProjectInfo> {
		return BsProjectC.makeCopy(this);
	}
}

