// Project editor - manages tabs of editors for a project
import { Component, OnDestroy, Input, ViewChild, ViewChildren, NgZone, EventEmitter, Output } from '@angular/core';
import { MatTab, MatTabGroup } from '@angular/material';

import { BsProjectC } from '@cf-platform/cf-core-cms';
import { BsEditorWrapComponent } from '../bs-editor-wrap/bs-editor-wrap.component';
//import { BsTerminalWrapComponent } from '../bs-terminal-wrap/bs-terminal-wrap.component';

import {IProjectInfo, IAssignment} from "@cf-platform/cf-core-cms";

import {  IFolderItem } from '../../../interfaces/IFolderItem';
import { IGraderTest } from '@cf-platform/cf-core-cms'
// TODONE: fix cross dependencies
//import { IFolderItem } from '../../../../../../../cf-core-cms/src/lib/IFolderItem';
import { ISettings, UserSettings } from '../../../epic-core/services/userSettings';

import {MatSnackBar} from '@angular/material';

import { iBsAssignmentTesterService } from '@cf-platform/cf-core-cms'

@Component({
	selector: 'app-bs-project',
	templateUrl: './bs-project.component.html',
	styleUrls: ['./bs-project.component.css']
})
export class BsProjectComponent implements OnDestroy {

	// todo.  move this somewhere else...
	projTypes = [
		{ name: 'C++', value: 'c++' },
		{ name: 'Python', value: 'python' },
		{ name: 'HTML', value: 'html' }
	];

	projectLoaded = false;  // This is just used to keep the settings and terminal tabs from showing first

	options = {};

	themes = [
		'ambiance',
		'chaos',
		'chrome',
		'clouds',
		'clouds_midnight',
		'cobalt',
		'crimson_editor',
		'dawn',
		'dreamweaver',
		'eclipse',
		'github',
		'idle_fingers',
		'iplastic',
		'katzenmilch',
		'kr_theme',
		'kuroir',
		'merbivore',
		'merbivore_soft',
		'mono_industrial',
		'monokai',
		'pastel_on_dark',
		'solarized_dark',
		'solarized_light',
		'sqlserver',
		'terminal',
		'textmate',
		'tomorrow',
		'tomorrow_night',
		'tomorrow_night_blue',
		'tomorrow_night_bright',
		'tomorrow_night_eighties',
		'twilight',
		'vibrant_ink',
		'xcode'

	];
	theme = 'xcode';

	uSettings: ISettings;
	textSize: string = 'regular';
	textSizePt = '12pt';

	consolePos: string = 'tabbed';
	consolePosChange() {

	}

	async textSizeChange() {
		this.updateTextSize();
		this.uSettings.editor_text_size = this.textSize;
		await this.saveSettings();
		console.log("Maybe saved the textsize!");
	}

	updateTextSize() {
		let s = '12';
		if (this.textSize === 'xsmall') {
			s = '8';
		}
		else if (this.textSize === 'small') {
			s = '10';
		}
		else if (this.textSize === 'large') {
			s = '14';
		}
		else if (this.textSize === 'xlarge') {
			s = '18';
		}
		else if (this.textSize === 'xxlarge') {
			s = '22';
		}
		else if (this.textSize === 'xxxlarge') {
			s = '28';
		}
		this.textSizePt = s + 'pt';
	}


	public _project: BsProjectC;
	private filesSub;
	public _files: any[] = [];

	_assignment: IAssignment;
	_graderTests: Array<IGraderTest>;

	@Input() tester: iBsAssignmentTesterService;

	@Input() set assignment(a: IAssignment) {
		console.log('bs-project set assignment');

		/*
		if (!this.isProjectDifferent(project, this._project)){
			console.log("Ignorning minor project change...");
			return;
		}
		*/

		this._assignment = a;

		if (this.project) {
			this.project.relatedAssignment = a;
		}

		if (a && a.grader_tests) {
			this._graderTests = [];
			for (const k of Object.keys(a.grader_tests)) {
				this._graderTests.push(a.grader_tests[k]);
			}
		}
		else {
			this._graderTests = [];
		}
	};

	doNewTest() {
		console.log('Adding new test...');
		const test = {name:'Test ' + (+this._graderTests.length + 1), type:'greptext', data:''};
		if (this._assignment) {
			if (!this._assignment.grader_tests) {
				this._assignment.grader_tests = [];
			} else {
				if (!this._assignment.grader_tests.push) {
					this._assignment.grader_tests = this.objToArray(this._assignment.grader_tests);
				}
			}
			this._assignment.grader_tests.push(test);
			this.assignment = this._assignment;
		}
	}

	objToArray(obj: any): Array<any> {
		if (Array.isArray(obj)){
			return obj;
		}
		// if it is an object convert to array
		const nA = [];
		for(let k in obj) {
			let v = obj[k];
			//v._old_id = obj[k];
			nA.push(v);
			//nA[k] = obj[k];
		}
		return nA;
	}

	isProjectDifferent(p1: BsProjectC, p2: BsProjectC) {
//selectionChangeand-aid fix to keep project from reloading when someone submits...
		if (!p1 || !p2) { return true;}
		if (p1._key != p2._key) { return true;} // project doesn't always have key??? investigate...
		if (p1._proj_user != p2._proj_user) { return true;}
		if (Object.keys(p1.files).length !== Object.keys(p2.files).length) { return true;}
		for (const fk in p1.files) {
			if (!p2.files[fk]) { return true;}
			const f1 = p1.files[fk];
			const f2 = p2.files[fk];
			if (f1.data !== f2.data) { return true; }
			if (f1.name !== f2.name) { return true; }
			//console.log( 'debug here');
		}
		return false;

	}

	@Input() isInstructor = false;

	@Input() set item(item: IFolderItem) {
		this.project = item as BsProjectC; // hack

	}

	@Input() set project(project: BsProjectC) {
		console.log('bs-project got new project');

		if (!this.isProjectDifferent(project, this._project)){
			console.log("Ignorning minor project change...");
			return;
		}


		this._project = project;
		this._files = [];
		this.projectLoaded = false;

		if (this.project) {
			this.project.relatedAssignment = this.assignment;
		}

		if (this.filesSub) {
			this.filesSub.unsubscribe();
		}
		console.log('bs-project.component: looking for project files...');

		const to_array = function (o: any) {  // todo make this a util function
			const a = [];                    // because I know I have several versions
			for (const k in o) {
				a.push(o[k]);
				o[k].__key = k;
			}
			return a;
		};

		if (Array.isArray(this._project.files)) {
			this._files = this._project.files as any; // CODE SMELL HACK
			this.copyAssignmentInstructionsHack();
			this.projectLoaded = true;
			this.goTab(0, true);  // fired when new project loaded on grader
		}
		else if (!this._project.files || !this._project.files.valueChanges) {
			this._files = to_array(this._project.files as any); // CODE SMELL HACK
			this.copyAssignmentInstructionsHack();
			this.projectLoaded = true;
			this.goTab(0);
		}
		// I don't know what this crap was.
		// all of this needs to be re-done
		else if (this._project.files.snapshotChanges) {
			this.filesSub = this._project.files.snapshotChanges().map(actions => {
				return actions.map(action => ({ // __key: action.key,  // key has to be on right side to overwrite
					...action.payload.val(), __key: action.key
				}));  // rewrite the key
			}).subscribe(files => {
				// We got a files change.  This could be file data or new or deleted files.
				// When we get an update on files,
				//  we try to only update the internal data and not the _files  object.
				// Otherwise the tabs will redraw.
				if (!this._files || (this._files.length === 0) || this._files.length !== files.length) {
					this._files = files;
				}
				else {
					for (const fid in files) {
						if (files[fid]['__key'] === this._files[fid]['__key']) {
							if (this._files[fid].data !== files[fid]['data']) {
								this._files[fid].data = files[fid]['data'];
							}
							// TODO, add more internal stuff here
						}
						else {
							console.log('New or deleted or rearanged files...');
							this._files = files;
							break;
						}
					}
				}
				this.copyAssignmentInstructionsHack();
				this.projectLoaded = true;
				// project text change triggers this...
				this.goTab(0, true);
			});
		}
		else {
			const fArray = [];
			for (const fk in this._project.files) {
				fArray.push(this._project.files[fk]);
			}
			this._files = fArray;
			this.copyAssignmentInstructionsHack();
			this.projectLoaded = true;
			this.goTab(0);
		}


	}

	async copyAssignmentInstructionsHack() {
		console.log('DEBUG copyAssignmentInstructionsHack HERE');



		if (this._assignment && this._files[0] && this._files[0].name == 'Instructions') {

			// add fresch copy of instruction link and frame
			if (this._assignment.instructionsUrl) {
				this._files[0].data = '';
				this._files[0].data = '<a target=_new href="' + this._assignment.instructionsUrl + '">INSTRUCTIONS</a> <br><br>\n';
				this._files[0].data += '<iframe style="width:100%;height:1000px;"\n';
				this._files[0].data += 'src="' + this._assignment.instructionsUrl + '">\n</iframe>';
				this._files[0].data += '<hr>';
			}

			 //copy over fresh copy of additional data if there...
			 // xxxx unless isInstructor (a_edit) mode
			else if (!this.isInstructor && this._assignment.project.files) {
				const aFiles = this.objToArray(this._assignment.project.files);
				if (aFiles.length > 0 && aFiles[0].name == 'Instructions') {
					this._files[0].data = aFiles[0].data;
				}
			}
		}
	}

	async getUserSettings() {
		console.log("Hi from theme");
		// redo this with a settings object

		this.uSettings = await this.userSettings.getCUserSettings();



		setTimeout(() => {
			this.theme = this.uSettings.editor_theme;
			this.textSize = this.uSettings.editor_text_size;
			this.updateTextSize();
			console.log('USettings: ' + JSON.stringify(this.uSettings));

		}, 500);
	}

	constructor(
			private _ngZone: NgZone,
			private userSettings: UserSettings,
			private snackBar: MatSnackBar
		) {
		const me = this;
		// https://stackoverflow.com/questions/35296704/angular2-how-to-call-component-function-from-outside-the-app
		// window['zNgComponentRef'] = {component: this, zone: _ngZone};
		window['pubGoProjectFile'] = this.pubGoProjectFile.bind(this);
		// todo destory this


		setTimeout( () => {
			this.getUserSettings();  // redo this to key off of user or something
		}, 1000
		);


	}

	giveFeedback(message:string) {
		const snackBarRef = this.snackBar.open(message, 'OK', {
			duration: 2000
		});

	}


	public pubGoProjectFile(filename, line, col) {
		this._ngZone.run(() => this.goProjectFile(filename, line, col));
		return false;
	}

	@ViewChildren('editorwrap') editorWraps; // https://stackoverflow.com/questions/36147809/angular2-get-reference-to-element-created-in-ngfor

	hasGone = false;

	public goTab(n: number, onlyOnce: boolean = false) {
		console.log('goTab called');
		if (this.hasGone && onlyOnce) {
			console.log('Have already went to the tab, so ignoring');
			return;
		}

		this.hasGone = true;

		setTimeout(() => {
			this.selectedIndex = -1;
			setTimeout(() => {
				this.selectedIndex = n;
				console.log('goTab fired 2');
			}, 100);

			console.log('goTab fired 1');
		}, 100);
	}

	public goProjectFile(filename, line, col) {
		console.log('You said go to: ' + filename + ':' + line + ':' + col);
		for (const fid in this._files) {
			const file = this._files[fid];
			if (file.name === filename) {
				this.selectedIndex = +fid;
				const ewa = this.editorWraps.toArray();
				if (ewa.length >= fid) {
					setTimeout(function () {
						ewa[fid].goToLine(line, col);
					}, 500);
					break;
				}
			}
		}
	}

	async saveSettings() {
		await this.userSettings.setCUserSettings(this.uSettings);
	}

	async doThemeChange() {
		console.log('Changing theme to: ' + this.theme);
		// The selector changes the theme varible, which is passed to the sub editor controll as an input.
		//  so we don't have to do anything here.

		// but now we are going to try to save it...
		this.uSettings.editor_theme = this.theme;
		await this.saveSettings();

		console.log("Maybe saved the theme!");
	}

	ngOnDestroy() {
		if (this.filesSub) {
			this.filesSub.unsubscribe();
		}
	}

	selectedIndex = 0; // which tab is selected/open

	selectedIndexChange(tabIndex) {
		console.log('selectedIndexChange: ' + tabIndex + ' : ' + this.selectedIndex);
	}


	updateFile(file) {  // used for renaming...
		console.log('HERE');


		if (file.name === '') {
			console.log('Empty file name, not saving!');
			return;
		}

		const key = file.__key;
		this._project.saveFile(key, file)
			.then(function () {
				console.log('updateFile: File updated');
			})
			.catch(function () {
				console.log('updateFile: Error updating file');
			});


	}

	// todo... refactor and move logic to above function
	updateFileData(fileId, fileData) {
		if (fileData === this._files[fileId].data) {
			console.log('updateFileData: no changes to save');
			return;
		}

		if (fileData === '') {
			console.log('updateFileData: Not saving empty file... Why?');
			return;
		}

		this._files[fileId].data = fileData;

		if (this._project.saveFile) {
			this._project.saveFile(this._files[fileId].__key, this._files[fileId])
				.then(function () {
					// console.log('updateFileData: File updated');
				})
				.catch(function () {
					console.log('updateFileData: Error updating file');
				});
		}
		else {
			console.log('Not really saving...');
		}
	}

	updateAssignmentTests() {
		console.log('udateAssingmentTest clicked');
		if (this._project.saveAssignment) {
			this._project.saveAssignment();

		}
	}

	dataChanged(fid, e) {
		this.updateFileData(fid, e);
	}

	newFileName: string = '';
	async addFileClicked() {
		if (this.newFileName !== '') {
			setTimeout( async() => {
				const fileName = this.newFileName;
				await this._project.newFile(this.newFileName);
				this.newFileName = '';
				this.selectedIndex++;
				this.giveFeedback('Added file: ' + fileName);
			}, 0);

		}
	}

	deleteFileClicked(file) {
		if (confirm('Are you sure to delete ' + file.name)) {
			console.log('Deleting file...');
			const curIndex = this.selectedIndex;
			const me = this;
			const fileName = file.name;
			this._project.deleteFile(file.__key)
				.then( () => {
					setTimeout(() => {
						if (curIndex > 0) {
							me.selectedIndex = curIndex - 1;
							this.giveFeedback('Deleted file: ' + fileName);
						}
					}, 20);

				});
		}
	}

	nameCount(fileName: string): number {
		let c = 0;
		for (const f of this._files) {
			if (f.name === fileName) {
				c++;
			}
		}
		return c;
	}
	fileWarning(fileName: string, newFile: boolean): string {

		let w = '';
		const nc = this.nameCount(fileName);

		if (newFile && nc > 0 || !newFile && nc > 1) {
			w = 'Warning: duplicate file name';
		}
		else if (fileName.match(/\s/)) {
			w = 'Warning: file name contains white space';
		}
		else if (fileName.length > 0 && !fileName.includes('.') && fileName !== 'Instructions') {
			w = 'Warning: no file extention';
		}
		else if (!newFile && fileName.length === 0) {
			w = 'Warning: empty file name';
		}
		return w;
	}

	updateProject() {  // used for renaming project
		console.log('updateProject');
		this._project.saveInfo();
	}

	// todo... prob need to make this bubble up with events ...

	@Output() deleteItemClicked = new EventEmitter();
	deleteItem(item) {
		this.deleteItemClicked.emit(item);
	}

	deleteProjectClicked() {
		console.log('DeleteProjectClicked');
		this.deleteItem(this._project);
	}

	projectWarning() {
		console.log('projectWarning');
	}

	@Output() goParentClicked = new EventEmitter();
	goParent() {
		this.goParentClicked.emit();
	}
}
