import { Component, ApplicationRef, ChangeDetectorRef, OnInit, OnDestroy } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import * as firebase from 'firebase/app';
import { UserRepository } from '../services/userRepository';
import { IUser } from '../interfaces/IUser';

// TODO: In Login state, on Safari, button width is too small, why?

@Component({
	selector: 'app-bs-account-button',
	templateUrl: './bs-account-button.component.html',
	styleUrls: ['./bs-account-button.component.css']
})
export class BsAccountButtonComponent implements OnInit, OnDestroy {
	private user: IUser;
	private userSub;
	private showUserTheirPictureOnLogin = false; // Bob might want to do this the first week...

	showUserInfoFlag: boolean = false;

	private loading = true;

	constructor(private userRepository: UserRepository,
				private cdRef: ChangeDetectorRef) {

	}

	ngOnInit() {


	this.userSub = this.userRepository.getCurrentUser((user) => this.onUserAuth(user));
	}

	ngOnDestroy() {
			this.userRepository.unsubscribeCurrentUser(this.userSub);
	}

	onUserAuth(user: IUser) {

		const currentUser = user;
		this.loading = false;

		this.user = currentUser;

		if (this.showUserTheirPictureOnLogin) {
			if (currentUser) {
				this.showUserInfo();

				setTimeout(() => {
					// This is used to show the user their login info / picture for x seconds at login
					this.hideUserInfo();
				}, 2000);
			}
		}
		this.cdRef.detectChanges();

	}

	login = async () => {
		await this.userRepository.login();
	}

	logout = async () => {
		await this.userRepository.logout();
		this.showUserInfoFlag = false;
	}

	showUserInfoToggle = () => {
		this.setUserInfoVisibility( !this.showUserInfoFlag);
	}

	showUserInfo = () => {
		this.setUserInfoVisibility(true);
	}

	hideUserInfo = () => {
		this.setUserInfoVisibility(false);
	}


	setUserInfoVisibility = (visibleFlag: boolean) => {
		this.showUserInfoFlag = visibleFlag;
		this.cdRef.detectChanges();
	}


	isMouseOver: boolean;

	myOverFunction() {
		this.isMouseOver = true;
	}

	myOutFunction() {
		this.isMouseOver = false;
		if (this.showUserInfoFlag) {
		setTimeout(() => {
			if (!this.isMouseOver) {
				this.hideUserInfo();
			}
		}, 1000);
		}
	}

}
