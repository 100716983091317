import { Observable } from 'rxjs/Observable';
import { AngularFireDatabase, AngularFireList, AngularFireObject } from '@angular/fire/database';
import * as firebase from 'firebase/app';
import { Injectable } from '@angular/core';

@Injectable()
export class BsCommentsService {

	constructor(private db: AngularFireDatabase) { }

	getCommentsManager(feedDbPath: string): BsCommentsManager {
		const bs = new BsCommentsManager(this.db, feedDbPath);
		return bs;
	}
}

export class BsCommentsManager {

	constructor(private db: AngularFireDatabase, private feedDbPath: string) { }

	fbQueryList: AngularFireList<any>;

	getComments(objectId: string, limit: number): Observable<any[]> {
		// zzz console.log('DISABLING COMMENT SERVICE FOR NOW...');
		return Observable.empty();
		//return Observable.empty<any[]>();
	}

	addComment(object_id: string, userEmail: string, newCommentText: string) {
		const c = {
			object_id: object_id,
			user: userEmail,
			text: newCommentText,
			create_timestamp: firebase.database.ServerValue.TIMESTAMP
		};

		this.fbQueryList.push(c);
		this.fbQueryList.snapshotChanges();
	}

	deleteComment(commentKey: string) {

		if (!commentKey || commentKey === '/') {
			console.log('Error... No key passed to deleteComment... Not deleting');
			return;
		}
		console.log('Deleting comment key: ' + commentKey);
		this.fbQueryList.remove(commentKey);
		this.fbQueryList.snapshotChanges();
	}

	saveComment(comment) {
		if (!comment) {
			console.log('Error... No comment passed to saveComment... Not saving');
			return;
		}

		const commentKey = comment.__key;

		if (!commentKey) {
			console.log('Error... No key passed to saveComment... Not saving');
			return;
		}

		console.log('Saving comment key: ' + commentKey);
		comment.update_timestamp = firebase.database.ServerValue.TIMESTAMP;

		this.fbQueryList.update(commentKey, comment);
		this.fbQueryList.snapshotChanges();
	}

	// need to move this
	getServerTime(): Promise<number> {
		const p = new Promise<number>((resolve, reject) => {
			const dateNow = firebase.database.ServerValue.TIMESTAMP;
			const ref = this.db.object(this.feedDbPath + '/feed/time');

			ref.set({ time: dateNow })
				.then(function () {
					const sub = ref.valueChanges().subscribe(s => {
						const t = s['time'];
						return resolve(t);
					});
				});
		});
		return p;
	}
}
