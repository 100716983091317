import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CfGuiCoreNgModule } from '@cf-platform/cf-gui-core-ng';

import { AceEditorModule } from 'ng2-ace-editor';


import {BsAttachmentManager, BsAttachmentService} from './services/bs-attachment.service';
import {BsCommentsManager, BsCommentsService} from './services/bs-comments.service';
import {BsFolderManager, BsFolderService} from './services/bs-folder.service';

import { BsLinkDialogComponent } from './components/bs-feed-system/bs-attachment/bs-link-dialog/bs-link-dialog.component';
import { BsCommentListComponent } from './components/bs-feed-system/bs-comment/bs-comment-list/bs-comment-list.component';
import { BsFolderItemComponent } from './components/bs-feed-system/bs-folder/bs-folder-item/bs-folder-item.component';
import { BsFolderItemListComponent } from './components/bs-feed-system/bs-folder/bs-folder-item-list/bs-folder-item-list.component';
import { BsFolderViewComponent, BsFolderItemDialogComponent } from './components/bs-feed-system/bs-folder/bs-folder-view/bs-folder-view.component';
import { BsMoveDialogComponent } from './components/bs-feed-system/bs-folder/bs-move-dialog/bs-move-dialog.component';
import { BsFolderTreeComponent } from './components/bs-feed-system/bs-folder-tree/bs-folder-tree.component';
import { ItemCardFrameComponent } from './components/bs-feed-system/bs-folder/item-card-frame/item-card-frame.component';


import { ItemDetailFrameComponent } from './components/bs-feed-system/bs-folder/item-detail-frame/item-detail-frame.component';
import { ItemFrameComponent } from './components/bs-feed-system/bs-folder/item-frame/item-frame.component';
import { ItemAttachmentsComponent } from './components/bs-feed-system/bs-folder/item-attachments/item-attachments.component';
import { ItemDefaultViewComponent } from './components/bs-feed-system/bs-folder/item-default-view/item-default-view.component';
import { ItemDefaultViewDetailComponent } from './components/bs-feed-system/bs-folder/item-default-view-detail/item-default-view-detail.component';
import { ItemViewFactoryService } from './components/bs-feed-system/bs-folder/item-view-factory.service';
import { ItemDefaultViewSummaryComponent } from './components/bs-feed-system/bs-folder/item-default-view-summary/item-default-view-summary.component';



import { CFUserTableComponent } from './components/cf-user-table/cf-user-table.component';

import { TableModule } from 'primeng/table';
import { CFTableComponent } from './components/cf-table/cf-table.component';

import { BsUserProjectsService } from './services/bs-user-projects.service'

import { BsEditorWrapComponent} from './components/bs-projects/bs-editor-wrap/bs-editor-wrap.component';
import { BsMyProjectsComponent} from './components/bs-projects/bs-my-projects/bs-my-projects.component';
import { BsProjectDialogComponent } from './components/bs-projects/bs-project-dialog/bs-project-dialog.component';
import { BsProjectComponent } from './components/bs-projects/bs-project/bs-project.component';

import { TerminalCoreModule } from '@cf-platform/cf-terminal-core-ng';

// merged this with epic core, because of circular refs..
// split them back up one day...

// MODULES
import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import { FormsModule } from '@angular/forms';
import { SharedModule, BreadcrumbModule, MenuModule, TreeModule, TerminalModule } from 'primeng/primeng';
import { MessageHubService } from './epic-core/services/message-hub.service';


// COMPONENTS
import { BsAccountButtonComponent } from './epic-core/bs-account-button/bs-account-button.component';
import { BsUsersComponent } from './epic-core/bs-admin/bs-users/bs-users.component';

// move these to a material module
import { MatButtonModule, MatMenuModule, MatIconModule, MatCardModule, MatDialogModule, MatSnackBarModule, MatProgressSpinnerModule } from '@angular/material';



// SERVICES
import { UserRepository } from './epic-core/services/userRepository';
import { BsCurrentUserService } from './epic-core/services/bs-current-user.service';
import { BsUsersService } from './epic-core/services/bs-users.service';
import { BsUtilsService } from './epic-core/services/bs-utils.service';
import { BsUsersDialogComponent } from './epic-core/bs-admin/bs-users-dialog/bs-users-dialog.component';
import { UserSettings } from './epic-core/services/userSettings';
import { CfUserProfileComponent } from './components/cf-user-profile/cf-user-profile.component'










@NgModule({
  declarations: [

	BsLinkDialogComponent,
	BsCommentListComponent,
	BsFolderItemComponent,
	BsFolderItemListComponent,
	BsFolderViewComponent,
	BsFolderItemDialogComponent,
	BsMoveDialogComponent,
	BsFolderTreeComponent,
	ItemCardFrameComponent,
	ItemDetailFrameComponent,
	ItemFrameComponent,
	ItemAttachmentsComponent,
	ItemDefaultViewComponent,
	ItemDefaultViewDetailComponent,
	ItemDefaultViewSummaryComponent,


	  CFUserTableComponent,

	  CFTableComponent,

	  BsEditorWrapComponent,
	  BsMyProjectsComponent,
	  BsProjectComponent,
	  BsProjectDialogComponent,



	  // epic core

	  BsAccountButtonComponent,
	  BsUsersComponent,

	  BsUsersDialogComponent,

	  CfUserProfileComponent
	],

	entryComponents: [
		BsFolderItemDialogComponent,
		BsLinkDialogComponent,
		BsMoveDialogComponent,
		//MyAssignmentStatComponent,
		//MyAssignmentDetailComponent,
		// BsProjectDialogComponent,
		ItemDefaultViewComponent,
		ItemDefaultViewDetailComponent,
		ItemDefaultViewSummaryComponent,

		// epic core
		BsUsersDialogComponent,

		BsProjectComponent,
		BsProjectDialogComponent,

	],
  imports: [
	CommonModule,
	FormsModule,

	AceEditorModule,

	CfGuiCoreNgModule,

	TerminalCoreModule,

		// Prime Stuff
		TableModule,
		SharedModule,
		TerminalModule,
		BreadcrumbModule,
		MenuModule,
		TreeModule,

		//
		MatButtonModule, MatMenuModule, MatIconModule, MatCardModule, MatDialogModule, MatSnackBarModule, MatProgressSpinnerModule,

  ],
  providers: [BsFolderService,
	BsCommentsService,
	BsAttachmentService,
	ItemViewFactoryService,

	BsUserProjectsService,

	//epic core
	BsCurrentUserService,
		UserSettings,
		BsUsersService,
		BsUtilsService,
		MessageHubService,
		UserRepository,
		CfCoreCmsNgModule
  ],
  exports: [
	BsLinkDialogComponent,
	BsCommentListComponent,
	BsFolderItemComponent,
	BsFolderItemListComponent,
	BsFolderViewComponent,
	BsFolderItemDialogComponent,
	BsMoveDialogComponent,
	BsFolderTreeComponent,
	ItemDetailFrameComponent,


	CFUserTableComponent,
	CFTableComponent,
	BsEditorWrapComponent,
	BsMyProjectsComponent,
	BsProjectComponent,
	BsProjectDialogComponent,

	//BsProjectC,

	//BsUserProjectsService,

	// epic core
	BsAccountButtonComponent,
		BsUsersComponent,

  ]
})
export class CfCoreCmsNgModule { }
