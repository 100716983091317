import { Component, OnInit } from '@angular/core';
import { BreadcrumbService } from 'projects/cf-web/src/app/breadcrumb.service';
import { UserRepository } from '../../epic-core/services/userRepository';
import { getMatIconFailedToSanitizeUrlError } from '@angular/material';
import { IUser } from '@cf-platform/cf-core-cms';

@Component({
  selector: 'cf-user-profile',
  templateUrl: './cf-user-profile.component.html',
  styleUrls: ['./cf-user-profile.component.css']
})
export class CfUserProfileComponent implements OnInit {
	user: IUser;

  constructor(
	private breadcrumbService: BreadcrumbService,
	private userRepo: UserRepository
  ) {

	this.breadcrumbService.setItems([
		{label: 'Profile'},
	]);
	this.breadcrumbService.setPageInfo({title:'Profile', icon: 'account_circle'});

	this.userRepo.getCurrentUser( u => this.gotUser(u));


  }

  ngOnInit() {
  }

  gotUser(user: IUser) {
	  this.user = user;
  }

}
