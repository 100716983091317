import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/toPromise';
import 'rxjs/add/operator/map';
import { AngularFireAction, AngularFireDatabase, AngularFireList, AngularFireObject } from '@angular/fire/database/';
import * as firebase from 'firebase/app';
// import { IFolderItem } from '../services/bs-folder.service';
import { IFolderItem } from '../interfaces/IFolderItem';
import { Injectable } from '@angular/core';
import { BsLoggerService } from '@cf-platform/cf-log';
import { BsUtilsService } from '../epic-core/services/bs-utils.service';

import {IProjectInfo, IAssignment} from "@cf-platform/cf-core-cms";

import { BsProjectC, ProjectType, iBsUserProjectsService } from '@cf-platform/cf-core-cms';





@Injectable()
export class BsUserProjectsService implements iBsUserProjectsService {

	fbProjList: AngularFireList<any[]>;
	projList: Observable<IProjectInfo[]>;

	constructor(
		private logger: BsLoggerService,
		private db: AngularFireDatabase) { }

	async addProject(userEmail: string, nProject: any, nProjectId: string) {
		console.log('Add Project');
		const me: iBsUserProjectsService = this;
		const userDBKey = BsUtilsService.z_filterEmailAsKey(userEmail);
		const p: BsProjectC = new BsProjectC();
		p.name = nProject.name;
		p.type = nProject.type;
		p._proj_user = userDBKey;

		p._proj_user = userDBKey;
		if (!nProjectId || nProjectId === '') {
			p.projPath = 'p_' + userDBKey + '_' + new Date().getTime();
		}
		else {
			p.projPath = nProjectId;
		}
		p._proj_info_path = '/projects/info/' + userDBKey + '/' + p.projPath;

		console.log('addProject: before saveProject');
		await this.saveProjectInfo(p);
		console.log('addProject: after saveProject');

		console.log('Created assignment project, now copying files');

		console.log('addProject: before getUserProject');
		const p2info = await me.loadUserProject(userEmail, p.projPath);
		console.log('addProject: after getUserProject: ' + p2info.name);

		const p2: BsProjectC = new BsProjectC();
		p2.fromDBInfo(p2info, userDBKey, me);     // this is very convoluted!!!!!!

		console.log('addProject: way before addFile');

		for (const fid in nProject.files) {
			const f = nProject.files[fid];
			console.log('addProject: before addFile: ' + f.name);
			await p2.addFile(f);
			console.log('addProject: after addFile');
		}
		return p2;
	}

	async getProjectCopy(userEmail: string, projPath: string): Promise<IProjectInfo> {
		console.log('Get Project Copy');
		const me = this;
		const userDBKey = BsUtilsService.z_filterEmailAsKey(userEmail);

		const p1: BsProjectC = await this.loadUserProject(userEmail, projPath) as BsProjectC;
		if (!p1) {
			return null;
		}
		let files = null;
		if (p1 && p1.loadFiles) {
			files = await p1.loadFiles();
		}

		const p: IProjectInfo = { ...p1 } as IProjectInfo; // shallow copy
		delete p['ups'];
		p['__key'] = p['__key'];
		delete p['__key'];
		delete p['_files'];
		if (files) {
			p.files = files;
		}
		return p;
	}

	private projectTypes = [
		{
			type: 'html',
			name: 'Web/HTML',
			project: {
				files: [
					{ name: 'index.html', data: '<h1>Hello Web Page</h1>' },
					{ name: 'index.css', data: '/* .css file */' },
					{ name: 'index.js', data: '// .js file' }
				]
			}
		},
		{
			type: 'python',
			name: 'Python3',
			project: {
				files: [{ name: 'main.py', data: 'print ("Hello Python")' }]
			}
		},
		{
			type: 'cpp',
			name: 'C++',
			project: {
				files: [{ name: 'main.cpp', data: '//Put your C++ code here' }]
			}
		}
	];
	private projectDict: object;


	getProjectTypes(): ProjectType[] {
		return this.projectTypes;
	}

	getProjectType(t: string): ProjectType {
		const lt = t.toLowerCase();

		if (!this.projectDict) {
			this.projectDict = {};
			for (const p of this.projectTypes) {
				this.projectDict[p.type] = p;
			}
		}
		if (this.projectDict[lt]) {
			return this.projectDict[lt];
		}
		return this.projectDict['cpp'];
	}

	async newProject(userEmail: string, name: string, type: string): Promise<BsProjectC> {
		const me = this;
		const userDBKey = BsUtilsService.z_filterEmailAsKey(userEmail);
		const p: BsProjectC = new BsProjectC();
		p.name = name;
		p.type = type;
		p._proj_user = userDBKey;
		p.projPath = 'p_' + userDBKey + '_' + new Date().getTime();
		p._proj_info_path = '/projects/info/' + userDBKey + '/' + p.projPath;

		await this.saveProjectInfo(p);

		console.log('Created new project, now adding file');
		const p2info = await me.getUserProject(userEmail, p.projPath).first().toPromise();

		const p2: BsProjectC = new BsProjectC();
		p2.fromDBInfo(p2info, userDBKey, me);     // this is very convoluted!!!!!!

		const t = p2.projType.toLowerCase();

		const pt = this.getProjectType(t);
		for (const f of pt.project.files) {
			p2.addFile(f);
		}
		return p2;
	}

	projToInfo(d: any, userDBKey: string, oPath: string): BsProjectC {
		const o: BsProjectC = new BsProjectC();
		o.fromDBInfo(d, userDBKey, this);
		o._proj_user = userDBKey;
		o._proj_info_path = oPath;
		return o;
	}

	getUserProjectFiles(userKey: string, path: string) {
		const fPath = '/projects/data/' + userKey + '/' + path + '/files';
		return this.db.list(fPath);
	}

	saveFile(files: AngularFireList<any>, id: string, file: any) {
		return files.update(id, file);
	}

	addFile(files: AngularFireList<any>, file: any) {
		return files.push(file);
	}

	async deleteFile(proj: BsProjectC, id: string) {
		console.log('deleteFile: making backup');
		const file = await proj.loadFileWithId(id);

		if (file) {
			await this.logger.logCmd('bs-user-projects', 'delete file', 1, { id: id, file: file, project: proj.info });
			//await proj._files.remove(); // WTF
			await proj._files.remove(file.__key);
			return true;
		}
		return false;
	}

	async deleteProject(proj: BsProjectC) {
		console.log('Delete Project... Backing up...');

		const projCopy = await proj.makeCopy();
		console.log('Project: ' + JSON.stringify(projCopy));

		await this.logger.logCmd('bs-user-projects', 'delete project', 1, { project: projCopy });

		// todo delete the project here

		if (!proj._proj_info_path.startsWith('/projects/info/')) {
			console.error('Invalid project path. Can not delete.  Path: ' + proj._proj_info_path);
			return false;
		}
		if (!proj._proj_info_path.match(/\/projects\/info\/.+\/.+/)) {
			console.error('Invalid project path. Can not delete.  Path: ' + proj._proj_info_path);
			return false;
		}

		const reallyDelete = true;
		if (reallyDelete) {
			console.log('really deleting');
			if (proj._files) {
				await proj._files.remove();
			}

			const projRef: AngularFireObject<any> = await this.getUserProjectRef(proj._proj_info_path);
			await projRef.remove();
		}
		else {
			console.log('Not really deleting.  Info path: ' + proj._proj_info_path);
		}
		return true;
	}

	newFile(files: AngularFireList<any>, fileName: string) {
		const f = {
			name: fileName
		};
		return files.push(f);
	}



	getUsersProjects(userEmail: string): Observable<IProjectInfo[]> {
		const userDBKey = BsUtilsService.z_filterEmailAsKey(userEmail);
		const upPath = '/projects/info/' + userDBKey;
		const fbList = this.db.list(upPath);

		return fbList.valueChanges().map(data => {
			const projList: IProjectInfo[] = [];
			for (const d of data) {
				d['key'] = d['path'];
				d['__key'] = d['path'];
				const oPath = upPath + '/' + d['key'];
				const pi = this.projToInfo(d, userDBKey, oPath);
				projList.push(pi);
			}
			return projList;
		});
	}

	getUserProjectRef(oPath: string): AngularFireObject<any> {
		const fbObj = this.db.object(oPath);
		return fbObj;
	}

	async loadUserProject(userEmail: string, path: string): Promise<IProjectInfo> {
		const po = await this.getUserProject(userEmail, path);
		const p = po.first().toPromise<IProjectInfo>();
		return p;
	}

	getUserProject(userEmail: string, path: string): Observable<IProjectInfo> {
		const userDBKey = BsUtilsService.z_filterEmailAsKey(userEmail);
		const upPath = '/projects/info/' + userDBKey;
		let rPath = path;
		if (rPath.startsWith('/my/projects/')) {
			rPath = rPath.substr('/my/projects/'.length);
		}
		let oPath = upPath + '/' + rPath;

		// todo add other encodings and move to a function
		// AND do the regx /g thing to get all of them...
		oPath = oPath.replace('%3A', ':');
		oPath = oPath.replace('%40', '@');

		const fbObj = this.getUserProjectRef(oPath);
		return fbObj.valueChanges().map(d => {
			if (d) {
				d['__key'] = d['path'];
			}
			if (!!d && d['name']) {
				return this.projToInfo(d, userDBKey, oPath);
			}
			else {
				return null;
			}
		});
	}

	saveProjectInfo(proj: IProjectInfo) {
		const user = proj._proj_user;
		const path = proj._proj_info_path;

		const o = {
			name: proj.name,
			type: 'project',
			projType: proj.projType || proj.type,
			path: proj.projPath
		};

		return this.db.object(path).update(o);
	}
}
