import { Component, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { map } from 'rxjs/operators/';
//import '../../epic-core/pipes/safe-html.pipe';
//import { BsClass } from '../bs-class';
//import { BsClassesUsersService } from '../../services/bs-classes-users.service';
import { SharedModule } from 'primeng/primeng';
//import { HttpClient} from '@angular/common/http'
import { Router } from '@angular/router'

import { IUser } from '@cf-platform/cf-core-cms';
//import { UserRepository } from '../../epic-core/services/userRepository'

import { CFTableCol } from '../cf-table/cf-table.component';




export interface IUserPlus extends IUser{
	_type?: string,			// type || role
	_name?: string,			//
	_status?: string,
	urlPhotoURL?: string,	// We wrap the url('') around the photo

	__link?: string,
	__click?: () => void;
}
//

@Component({
  selector: 'cf-user-table',
  templateUrl: './cf-user-table.component.html',
  styleUrls: ['./cf-user-table.component.css']
})
export class CFUserTableComponent { // implements OnInit {

	_selectedUsers: Array<IUserPlus> = [];
	_usersList: Observable<IUserPlus[]>;


	all_users_array: IUserPlus[];

	_columns: Array<CFTableCol> = [];
	frozenCols: Array<CFTableCol> = [];
	userColumns = [
		{header:'photo', field:'urlPhotoURL', width: '50px', cType:'image', searchable: false},
		{header:'name', field:'_name', width: '150px', cType: 'html'}
	]
	defaultColumns: Array<CFTableCol> = [

		//{header:'email', field:'email', width:'180px'},
		{header:'status', field:'_status', width: '180px'},
		{header:'lms_role', field:'lms_role', width: '150px'},
		{header:'section', field:'section', width: '150px'},
		{header:'lms_id', field:'lms_id', width: '150px'}
	];

	globalFilterFields: Array<string> = [];
	userSub: any;
	_height  = "calc(100vh - 200px)";

	set selectedUsers (sUsers: Array<IUserPlus>) {
		this._selectedUsers = sUsers;
		this.selectedUsersChange.emit(this._selectedUsers);
	};
	get selectedUsers(): Array<IUserPlus> {
		return this._selectedUsers;
	}


	@Output() selectedUsersChange = new EventEmitter<Array<IUserPlus>>();

	@Input() title = 'User Table';
	@Input() set height(height:string) {
		if (height === "short") {
			this._height = "calc(60vh - 200px)";
		}
	}



	@Input() set usersList(uList: Observable<IUser[]>) {
		console.log('cf-user-table usersList was set');
		this._usersList = uList.pipe(map(a => a.map( this.uMapper ) ));

	}

	@Input() set columns(cols) {
		console.log('Debug columns here');
		if (cols) {
			this._columns = [...this.userColumns, ...cols];
		} else {
			this._columns = this.userColumns;
		}
		//this.updateSearchField();
		this.cdRef.detectChanges();
	}

	get columns() {
		return this._columns;
	}



	uMapper = (u) => {
		u['__id'] = u.uid || u.__key; // table row id thing
		//u['__click'] = () => {this.doUserClick(u)};

		u._type = [u.type, u.lms_role].join(', ');
		u._name = u.name;
		if (!u.name || u.name.includes( 'undefined')) {
			if (u.provider && u.provider.displayName) {
				u._name = u.provider.displayName;
			}
		}
		if (u.lastName && !u.lastName.includes('undefined') && u.firstName && !u.firstName.includes('undefined')) {
			u._name = u.lastName + ', ' + u.firstName;
		}
		u._name = '<b>' + u._name + '</b>'
				+ '<br><span style="font-size:.6em">' + u.email + '</span>';

		u['__desc'] = u._name + ' - ' + u.email + ' - ' + (u.uid || u.__key);
		u._status = [u.class_status, u.status, u.where].join(', ');
		if (!u.urlPhotoURL) {
			if (u.provider && u.provider.photoURL) {
				u.urlPhotoURL = "url('" + u.provider.photoURL + "')";
			}
		}
		return u;
	}

	doUserClick(u) {
		console.log('doClick');
		if (u && u.__click) {
			console.log('Try click...');
			u.__click();
		}
		else if (u && u.__link) {
			this.router.navigateByUrl(u.__link);
		}
	}







	constructor(
		//private classesUsersService: BsClassesUsersService,
		//private httpClient: HttpClient,
		//private userRepository: UserRepository
		private cdRef: ChangeDetectorRef,
		private router: Router
		) {

			this._columns = [...this.userColumns, ...this.defaultColumns];

			//this.updateSearchField();

		}



}


//

