import { Component, OnInit, OnDestroy, ComponentFactory, ComponentFactoryResolver, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { Observable } from 'rxjs/Observable';
import { BsFolderService, BsFolderManager, FolderItem, BsFolderItemComponent, ItemViewFactoryService, ViewTypeE, ViewThing, NewableItem } from '@cf-platform/cf-core-cms-ng'; // ../../../../../apps/web-client/src/app/cms-core/services/bs-folder.service';
//import { BsFolderItemComponent } from '../../../../../apps/web-client/src/app/cms-core/bs-feed-system/bs-folder/bs-folder-item/bs-folder-item.component';
import { BreadcrumbModule, MenuItem } from 'primeng/primeng';
//import '../cms-core/bs-feed-system/bs-folder-tree/bs-fo,lder-tree.component';
import { BsUsersComponent , UserRepository} from '@cf-platform/cf-core-cms-ng';
//import { BsMyProjectsComponent } from '../../../../../apps/web-client/src/app/bs-projects/bs-my-projects/bs-my-projects.component';
import { BsAssignmentsService } from '../../services/bs-assignments.service';
import { BsClass } from '../../classes//bs-class';
import { BsCurrentClassInfoService, CFCurrentClassInfo } from '../../services/bs-current-class-info.service';
import { BsClassesUsersService } from '../../services/bs-classes-users.service';
import { AngularFireDatabase } from '@angular/fire/database';
import { MyAssignmentStatComponent } from '../my-assignment-stat/my-assignment-stat.component';
import { IUser } from '@cf-platform/cf-core-cms';
import { MyAssignmentDetailComponent } from '../my-assignment-detail/my-assignment-detail.component';
//import { ItemViewFactoryService, ViewTypeE, ViewThing } from '../../../../../apps/web-client/src/app/cms-core/bs-feed-system/bs-folder/item-view-factory.service';
//import { NewableItem } from '../../../../../apps/web-client/src/app/cms-core/bs-feed-system/bs-folder/newable-item';
import { BreadcrumbService } from 'projects/cf-web/src/app/breadcrumb.service';




@Component({
	selector: 'app-bs-class',
	templateUrl: './bs-class.component.html',
	styleUrls: ['./bs-class.component.css']
})
export class BsClassComponent implements OnInit, OnDestroy {

	private routeSub: any;
	public classInst: string = '';
	public classId: string = '';
	public classPath: string = '';
	public classCmd: string = ''; // will be the first token of classPath
	public classCmdParams: string[]; // everything after the classCmd
	private assignmentId: string = '';
	private assignmentStudentEmail: string = '';
	private submitId: string = '';
	public basePath: string = '';
	private _basePath: string = '';
	private rootItem: Observable<FolderItem>;
	instMenu: any;
	classMenu: any;
	folderData: any;
	insts: any;
	classes: any;
	paths = [];
	bsFolderManager: BsFolderManager;

	currentUser: IUser;
	//currentClassInfo: void;
	currentClassInfo: CFCurrentClassInfo;
	cciSub: any;

	getInst(): Observable<any> {
		const od = Observable.of(this.insts);
		return od;
	}

	getClassesFor(inst): Observable<any> {
		const od = Observable.of(this.classes[inst]);
		return od;
	}

	constructor(
		private cdRef: ChangeDetectorRef,
		private route: ActivatedRoute,
		private router: Router,
		private bsFolders: BsFolderService,
		private location: Location,
		private assignmentService: BsAssignmentsService,
		private currentClassInfoService: BsCurrentClassInfoService,
		private classUsersService: BsClassesUsersService,
		private db: AngularFireDatabase, //??
		private userRepository: UserRepository,
		private resolver: ComponentFactoryResolver,
		private itemViewFacotry: ItemViewFactoryService,
		private breadcrumbService: BreadcrumbService) {

		console.log('bs-class constructor');
		const me = this;
		currentClassInfoService.goItem = function (e) {
			me.goItem(e);
		};

		userRepository.getCurrentUser((u) => {
			this.currentUser = u;
			//this.loadClassFromRouteData();
			this.startStuff();
		});
	}

	currentClassId: string;
	loadClass(classId) {
		console.log('debug loadClass');
		if (classId &&  this.currentClassId !== classId) {
			const feedDbPath: string = '/class/' + classId;
			this.bsFolderManager = this.bsFolders.getFolderManagerFor(feedDbPath);
			this.rootItem = this.bsFolderManager.getItem('/');
		}
		this.currentClassId = classId;
		//this.currentClassInfo.classId = classId;
		//this.currentClassInfo.rootItemS.next(this.rootItem);
		/// this.currentClassInfo = this.currentClassInfoService.setCurrentClass(classId,null,this.rootItem );



	}

	loadClassPath() {
		console.log('Debug class thing here');
		this.basePath = '/class';
		this.folderData = null;
		if (!this.classInst) {
			console.log('Displaying Class Select Institution Menu');
			this.folderData = this.instMenu;
		}
		else {
			this.basePath += '/' + this.classInst; // + '/home';
			if (!this.classId) {
				console.log('Displaying Class Selection Menu');
				this.folderData = this.classMenu;
			}
			else {
				this.basePath += '/' + this.classId; // + '/home';
				this._basePath = this.basePath;
				const locationPath: string = this.location.path();
				if (locationPath.startsWith(this._basePath)) {
					this.classPath = locationPath.substr(this._basePath.length);
					console.log('classCmd here');
					const pParts = this.classPath.split(/\//g);
					if (pParts.length > 1) {
						this.classCmd = pParts[1];
						this.classCmdParams = [];
						if (pParts.length > 2) {
							for (let i = 2; i < pParts.length; i++) {
								this.classCmdParams.push(pParts[i]);
							}
						}
					}
					else {
						this.classCmd = '';
					}
				}
				else {
					this.classPath = '';
				}

				console.log('Debug classCmdParams');



				if (this.classCmd === 'my_grades') {
					this.assignmentStudentEmail = ''; // code smell
					if (this.classCmdParams.length > 0) {
						this.assignmentStudentEmail = this.classCmdParams[0];
					}
				}




				// else if (this.classCmd === 'grade' || this.classCmd === 'a_edit') {
				else if (this.classCmd === 'a_edit') {
				 	this.assignmentId = '';
					this.assignmentStudentEmail = '';
				 	this.submitId = '';
				 	this.assignmentStudentEmail = '';
				 	if (this.classCmdParams.length > 0) {
						this.assignmentId = this.classCmdParams[0];
						// ?? this.currentClassInfo.loadAssignment(this.assignmentId);
					}
					if (this.classCmdParams.length > 1) {
						this.assignmentStudentEmail = this.classCmdParams[1];
					}
					if (this.classCmdParams.length > 2) {
						this.submitId = this.classCmdParams[2];
					}
				}

				let p = '';
				let d = '';
				this.paths = [];
				const lPaths = this.classPath.split(/\//g);

				for (let i = 1; i < lPaths.length; i++) {
					const o = { label: lPaths[i] };
					p += d + lPaths[i];
					d = '/';
					o['routerLink'] = [this.basePath + '/' + p];
					o['routerLinkActiveOptions'] = { exact: true };
					this.paths.push(o);
				}

				if (this.classPath === '') {
					this.classPath = '/';
				}
				//this.currentClassInfo.currentClassPathS.next(this.classPath);
				//this.currentClassInfo.theClassPathStore.setItem(this.classPath);
				console.log('Displaying class.  basePath: ' + this.basePath + '  path: ' + this.classPath);
			}
		}
		console.log('Class Inst: ' + this.classInst + '  Id: ' + this.classId + '  Path: ' + this.classPath);

		this.itemO = this.bsFolderManager.getItem(this.classPath);
		if (this.itemSub) {
			this.itemSub.unsubscribe();
		}

		this.itemSub = this.itemO.subscribe(item => {
			if (item) {
				item['__feedPath'] = '/class/' + this.classId;
				item.classId = this.classId;
			}
			this.item = item;
			/*
			if (this.currentUser && item && item.childItems) {

				item.childItems = item.childItems.map((fa) => fa.map((f) => {
					if (f.type=='assignment') {

					// https://netbasal.com/dynamically-creating-components-with-angular-a7346f4a982d
					const sum_factory: ComponentFactory<MyAssignmentStatComponent> = this.resolver.resolveComponentFactory(MyAssignmentStatComponent);
					f.summary_factory = sum_factory;
					f.summary_inputs = {assignmentId: f.assignmentId, classId: this.classId, currentUserEmail: this.currentUser.email };

					const det_factory: ComponentFactory<MyAssignmentDetailComponent> = this.resolver.resolveComponentFactory(MyAssignmentDetailComponent);
					f.detail_factory = det_factory;
					f.detail_inputs = {item: f, assignmentId: f.assignmentId, classId: this.classId, currentUserEmail: this.currentUser.email };



				}

					return f;
			}
			))}
			*/

			/*
			if (item && item.type === 'assignment') {
				const det_factory: ComponentFactory<MyAssignmentDetailComponent> = this.resolver.resolveComponentFactory(MyAssignmentDetailComponent);
				item.detail_factory = det_factory;
				item.detail_inputs = {item: item, assignmentId: item.assignmentId, classId: this.classId, currentUserEmail: this.currentUser.email };

			}
			*/

		});
	}
	itemO: Observable<FolderItem>;
	item: FolderItem;
	itemSub;
	bsClass: BsClass;

	async startStuff() {
		//const params = this.route.snapshot.params;
		//this.classInst = params['classinst'] || '';
		//this.classId = params['classid'] || '';
		this.loadClass(this.classId);


		this.classId = '?';
		if (this.currentClassInfo) {
			this.classId = this.currentClassInfo.theClass.classId;
			this.loadClassPath();
		}


		this.breadcrumbService.setItems([

			{label: this.classId}
		]);
		this.breadcrumbService.setPageInfo({title: 'Class: ' + this.classId, icon: 'school'});

		if (this.currentClassInfo) {
			setTimeout(async() => {
				await this.currentClassInfo.theClass.loadSettings();
				const className = this.currentClassInfo.theClass.settings.name
				if (className) {
					this.breadcrumbService.setPageInfo({title: className, icon: 'school'});
				}



			}, 1000);
			this.bsClass = this.currentClassInfo.theClass;

			const items: Array<{label:string, routerLink?:string}> = [];
			const cPaths = this.currentClassInfo.theClassPath.split('/');
			cPaths[0] = this.classId;
			let path = '/class/utm';
			for(let i=0; i<cPaths.length; i++) {
				let cPath = cPaths[i];
				path += '/' + cPath;
				if (i < cPaths.length -1){
					items.push({label: cPath, routerLink: path});
				}
				else {
					items.push({label: cPath});
				}
			}
			this.breadcrumbService.setItems(items);

			//console.log('DEBUG ASSIGNMENT HERE');  // TODO, we need to move most of this to current class...
			//this.bsClass = new BsClass(this.assignmentService, this.classId, 'Who Knows', this.classUsersService, this.db);
			// ?? this.bsClass.debugAssignments();

			setTimeout( () => {
				this.setupViews();
				this.setupNewableItems();
				try {
					this.cdRef.detectChanges();
				} catch (error) {
					console.log('Ignoring error.  Prob caused by loging out.')
				}

			});
		}



	}

	setupViews() {
		const det_factory: ComponentFactory<MyAssignmentDetailComponent> = this.resolver.resolveComponentFactory(MyAssignmentDetailComponent);
		const email = this.currentUser ? this.currentUser.email : null;
		const inputs = {classId: this.classId, currentUserEmail: email };

		const det_vt: ViewThing = { factory: det_factory, inputs: inputs , outputs: []};
		this.itemViewFacotry.registerItemType('assignment', ViewTypeE.detail, det_vt);

		const sum_factory: ComponentFactory<MyAssignmentStatComponent> = this.resolver.resolveComponentFactory(MyAssignmentStatComponent);
		const sum_vt: ViewThing = { factory: sum_factory, inputs: inputs, outputs: [] };
		this.itemViewFacotry.registerItemType('assignment', ViewTypeE.summary, sum_vt);


	}

	newableItems: Array<NewableItem> = [];

	setupNewableItems() {
		this.newableItems = [];
		if (this.currentUser && this.currentUser.email === 'zbobbradley@gmail.com') {
			this.newableItems = [{title: 'New Assignment...', type: 'assignment', linkFunctP: () => this.assignmentService.getAssignments(this.classId)},
				{title: 'New Note...', type: 'note'},
				{title: 'New Folder...', type: 'folder'}
			];
		}

	}






	ngOnInit() {
		console.log('bs-class init');
		/*
		this.loadClassFromRouteData();
		this.routeSub = this.router.events.subscribe(v => {
			console.log('Class route params changed');
			this.loadClassFromRouteData();
		});
		*/

		this.cciSub = this.currentClassInfoService.currentClassInfoHandler.subscribe( cci => {
			console.log('bs-class currentClassInfoService sub');
			this.currentClassInfo = cci;
			if (cci) {
				this.classId = cci.theClass.classId;
				this.classInst = cci.theClassInst;
				this.startStuff();
			}
		});
	}

	ngOnDestroy() {
		this.cciSub.unsubscribe();
	}

	goItem(item: FolderItem) {
		console.log('Class goItem called');
		this.goPath(this._basePath + item['my_path']);
	}

	goPath(path: string) {
		console.log('Navigating to path: ' + path);
		const me = this;
		me.router.navigateByUrl(path);
	}

	isMenuOpen = true;
	toggleMenu() {
		this.isMenuOpen = !this.isMenuOpen;
	}
}
