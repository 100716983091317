import { Observable} from 'rxjs/Observable';
import { map, first } from 'rxjs/operators';
//import { } from 'rxjs/compat';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';

import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireDatabase, AngularFireList, AngularFireObject } from '@angular/fire/database/';

import * as firebase from 'firebase/app';
import { BsUser, IBsUser } from '../bs-admin/bs-users/bs-user';
import { Injectable } from '@angular/core';
import { BsUtilsService } from './bs-utils.service';

@Injectable()
export class BsUsersService {
	fbUsersList: AngularFireList<any>;
	usersList: Observable<BsUser[]>;
	usersArray: BsUser[];
	currentUserO: any;
	currentUser: any;
	bsUser: Observable<any>;
	bsUserSub: any;
	needUserCheck: boolean = false;
	bsCurrentUserSubject: BehaviorSubject<BsUser> = new BehaviorSubject<BsUser>(null);

	constructor(public afAuth: AngularFireAuth,
		private db: AngularFireDatabase) {
		this.currentUserO = afAuth.authState;
		const me = this;
	}

	fbuser: AngularFireObject<any>;
	public getCurrentBSUser(): Observable<BsUser> {
		return this.bsCurrentUserSubject;
	}

	getBSUser(id): AngularFireObject<any> {
		console.log('getBSUser: ' + id);
		return this.db.object('/users/' + id);
	}

	getUsers(): Observable<BsUser[]> {
		console.log('BsUsersService: getUsers called');
		this.fbUsersList = this.db.list('/users');

		this.fbUsersList.valueChanges().subscribe(d => {
			console.log('BsUsersService: getUsers: Read in ' + d.length + ' users');
		});

		this.usersList = this.fbUsersList.snapshotChanges().pipe(map(data => {
			const uItems = new Array<BsUser>();

			for (const d in data) {
				const u = new BsUser();
				const dt = data[d];
				u._data = data[d];
				if (u._data.payload && u._data.key) {
					// https://github.com/angular/angularfire2/blob/master/docs/version-5-upgrade.md
					u._data = { __key: u._data.key, ...u._data.payload.val() }; // unpack angular5 hack

				}
				if (!u._data.uid) {
					console.log('ZZZ DEBUG HERE');
					u._data.uid = u._data.__key || u._data.$key; // new hack need to move into bsuser
				}
				uItems.push(u);
			}

			// Sort by last name maybe
			uItems.sort((a, b) => {
				return a.lastName < b.lastName ? -1 : 1;
			});
			return uItems;
		}));

		this.usersList.subscribe(d => {
			this.usersArray = d;
			if (this.needUserCheck) {
				this.updateFBUser(this.currentUser, true);
			}

			console.log('BsUsersService: getUsers p2: Read in ' + d.length + ' users');
		});

		return this.usersList;
	}

	_isOnline: boolean;

	updateStatus(fbUser, isOnline, where: string, what: string): Promise<void> {
		if (!fbUser) {
			console.log('BsUsersService: UpdateFBUser called with no user!');
			return Promise.reject('no user');
		}

		let status = 'offline';
		if (isOnline) {
			status = 'online';
		}
		const o = { status: status, timestamp: firebase.database.ServerValue.TIMESTAMP };
		if (where !== null) {
			o['where'] = where;
		}
		if (what !== null) {
			o['what'] = what;
		}

		const prom = new Promise<void>((resolve, reject) => {
			this.db.object('/users/' + fbUser.uid).update(o)
				.then(() => {
					resolve();
				})
				.catch(() => {
					reject('foo');
				});
		});
		return prom;
	}

	updateFBUser(fbUser, isOnline): Promise<void> {
		if (!fbUser) {
			console.log('BsUsersService: UpdateFBUser called with no user!');
			return Promise.reject('no user');
		}

		this.needUserCheck = false;
		console.log('BsUsersService: UpdateFBUser');

		let status = 'offline';

		this._isOnline = isOnline;

		// TODO:  offline doesn't work, if the user is already signed out!!!!!


		if (isOnline) {
			status = 'online';
		}

		const prom = new Promise<void>((resolve, reject) => {

			this.db.object('/users/' + fbUser.uid).valueChanges().pipe(first()).subscribe(u => {
				if (u && u['uid']) {
					this.updateUserInfo(fbUser, u, status)
						.then(() => {
							resolve();
						})
						.catch(() => {
							reject('foo');
						});
				}
				else {
					this.addNewUserInfo(fbUser, status)
						.then(() => {
							resolve();
						})
						.catch(() => {
							reject('FOO');
						});
				}
			});
		});
		return prom;
	}

	updateUserInfo(fbUser, uData, status): Promise<void> {
		// UPDATE EXISTING USER INFO
		console.log('BsUsersService: updateFBUser: User was found.  Updating...');
		const ud: IBsUser = uData; // as IBsUser;
		ud.email = fbUser.email;
		ud.name = fbUser.displayName;
		if (fbUser.providerData) {
			ud.provider = fbUser.providerData[0];
		}
		ud.photoURL = fbUser.photoURL;
		ud.status = status;
		ud.timestamp = firebase.database.ServerValue.TIMESTAMP;
		ud.uid = fbUser.uid;
		ud.groups = uData.groups || { user: 1 };

		return this.db.object('/users/' + fbUser.uid).update(ud);
	}

	addNewUserInfo(fbUser, status): Promise<any> {
		// NEW USER - ADD USER INFO
		console.log('BsUsersService: updateFBUser: New user');

		const ud: IBsUser = {
			creation: firebase.database.ServerValue.TIMESTAMP,
			email: fbUser.email,
			isAnonymous: fbUser.isAnonymous,
			isAdmin: false, // todo
			name: fbUser.displayName,
			firstName: BsUtilsService.firstName(fbUser.displayName),
			lastName: BsUtilsService.lastName(fbUser.displayName),
			photoURL: fbUser.photoURL,
			status: status,
			timestamp: firebase.database.ServerValue.TIMESTAMP,
			groups: { user: 1 },
			uid: fbUser.uid
		};

		if (fbUser.providerData) {
			ud.provider = fbUser.providerData[0];
		}
		console.log(JSON.stringify(ud));

		const uPath: string = '/users/' + fbUser.uid;
		return this.db.object(uPath).set(ud)
			.then(() => {
				console.log('BsUsersService: User info added ok.');
			}, () => {
				console.log('BsUsersService: Error adding user info!');
			});
	}
}
