import { Component, OnInit, OnDestroy } from '@angular/core';
import { UserRepository } from '@cf-platform/cf-core-cms-ng';
import { BsClassesUsersService } from '../../services/bs-classes-users.service';
import { IUser, IAssignment } from '@cf-platform/cf-core-cms';
import { BsAssignmentsService } from '../../services/bs-assignments.service';
import { BsCurrentClassInfoService, CFCurrentClassInfo } from '../../services/bs-current-class-info.service';

@Component({
  selector: 'cf-assignment-copier',
  templateUrl: './cf-assignment-copier.component.html',
  styleUrls: ['./cf-assignment-copier.component.css']
})
export class CfAssignmentCopierComponent implements OnInit, OnDestroy {
	userSub: any;
	user: IUser;
	classIds: string[] = [];
	selectedClassId = '';
	assignments: IAssignment[] = [];
	selectedAssignmentIds: string[];

	currentClassId = '';
	currentClassInfo: CFCurrentClassInfo;
	cciSub: any;
	currentClassAssignments: IAssignment[];

  constructor(
	  private userRepo: UserRepository,
	  private classesSer: BsClassesUsersService,
	  private assignmentsSer: BsAssignmentsService,
	  private currClassSer: BsCurrentClassInfoService
  ) {
		this.userSub = userRepo.getCurrentUser( (u) => {
			this.user = u;
			this.loadUserClasses();


			if (this.cciSub) {
				this.cciSub.unsubscribe();
			}
			this.cciSub = this.currClassSer.currentClassInfoHandler.subscribe(cci => {
				this.currentClassInfo = cci;
				this.currentClassId = '';
				if (cci && cci.theClass) {
					this.currentClassId = cci.theClass.classId;
				}
			});
		})
  }

  ngOnInit() {
  }

  ngOnDestroy() {
	  if (this.cciSub) {
	  	this.cciSub.unsubscribe();
	  }
  }


  async loadUserClasses() {
	  if (!this.user) {
		  this.classIds = [];
		  this.selectedClassId = '';
		  return;
	  }
	const classThings = await this.classesSer.getClassesForUserIdOnce(this.user.uid);
	this.classIds = classThings.map( c => c.id);

	if (this.classIds && this.classIds.length > 0) {
		//this.selectedClassId = this.classIds[0];
		this.loadAssignments();
	}

  }

	async loadAssignments() {
		console.log('Load assignments...');

		if (this.currentClassId) {
			const assignments = await this.assignmentsSer.getAssignments(this.currentClassId);
			this.currentClassAssignments = assignments;
		} else {
			this.currentClassAssignments = [];
		}


		if (this.selectedClassId) {
			const assignments = await this.assignmentsSer.getAssignments(this.selectedClassId);
			//this.assignments = assignments;
			this.assignments = assignments.map( a => {
				return {...a, iHas: this.iHasAssignment(a.assignmentId)};
			});
		}
		else {
			this.assignments = [];
		}



	}

  doClassChange(classId: string) {
	  console.log('cf-assignment-copier: doClassChange: ' + classId);
	  this.selectedClassId = classId;

	  this.loadAssignments();
  }

  async doCopyAssignments() {
	  console.log("Do Copy Assignments");

	  if (this.selectedClassId === this.currentClassId) {
		  alert('Cannot copy within same class yet...  Select another class.');
		  return;
	  }

	  for(const a of this.selectedAssignmentIds) {
		  if (this.iHasAssignment(a)) {
			  console.log('Already have: ', a);
		  }
		  else {
			  console.log('Copying: ', a)
			  const r = await this.assignmentsSer.copyAssignmentToClass(this.selectedClassId, a, this.currentClassId);
			  console.log( r? 'It worked!': 'It did NOT work!');
		  }
	  }

	  this.loadAssignments();
  }

  iHasAssignment(aId: string): boolean {
	  for(const a of this.currentClassAssignments) {
		  if (a.__key == aId) {
			  return true;
		  }
	  }
	  return false;
  }


}
