import { Component, OnInit, Input, ViewContainerRef, ViewChild, ComponentRef, OnDestroy, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { FolderItem } from '../../../../services/bs-folder.service';
import { ItemViewFactoryService, ViewTypeE } from '../item-view-factory.service';
import { NewableItem } from '../newable-item';

@Component({
	selector: 'app-item-frame',
	templateUrl: './item-frame.component.html',
	styleUrls: ['./item-frame.component.css']
})
export class ItemFrameComponent implements OnInit, OnDestroy, AfterViewInit {

	_item: FolderItem;
	viewReady = false;
	viewMode = '';

	componentRef: ComponentRef<any>;

	@Output() goItemClicked = new EventEmitter();
	@Output() goParentClicked = new EventEmitter();

	@Output() newableItemClicked = new EventEmitter();


	@Input() menuCommands = [];
	@Input() currentUserEmail: string;

	@Input() newableItems: Array<NewableItem> = [];

	@ViewChild('childContainer', /* TODO: add static flag */ { read: ViewContainerRef, static: false }) container: ViewContainerRef;




	@Input() set item(item: FolderItem) {
		if (item !== this._item) {
			this._item = item;
			this.renderItem();
		}

	}

	async renderItem() {

		// console.log('item-frame render');

		if (!this.viewReady) {
			// console.log('Cannot render item yet.  View not really ready yet.');
			return;
		}

		if (this.container) {
			this.container.clear();
			if (this.componentRef){
				this.componentRef.destroy();
			}
		}
		else {
			// console.log('Cannot render item yet: view not ready yet...');
			return;
		}

		if (!this._item) {
			// console.log('Cannot render item yet: no item...');
			return;
		}

		console.log('Debug item-frame render here here here');
		let vt = ViewTypeE.detail;
		if (this.viewMode ==='summary') {
			vt = ViewTypeE.summary;
		}
		this.componentRef = await this.itemViewFactory.createViewFor(this._item, vt, this.container);
		/*
		// console.log('debug item-frame here');
		let factory, inputs;
		if (this.viewMode === 'summary') {
			factory = this._item.summary_factory;
			inputs = this._item.summary_inputs;
		}
		else {
			factory = this._item.detail_factory;
			inputs = this._item.detail_inputs;
		}

		if (factory) {
			setTimeout(() => {  // this has to be done in the next event cycle
				this.container.clear();
				// console.log('creating view for: ' + this._item.name );

				this.componentRef = this.container.createComponent(factory);
				// zzz  inject the inputs here
				for (const k of Object.keys(inputs)) {
					const v = inputs[k];
					this.componentRef.instance[k] = v;
				}
			});
		}
		*/

	}

	constructor(private itemViewFactory: ItemViewFactoryService) { }

	ngOnInit() {
	}

	ngAfterViewInit() {
		// console.log('item-frame ngAfterInit');
		this.viewReady = true;
		if (this._item) {
			this.renderItem();
		}
	}

	ngOnDestroy() {
		if (this.componentRef){
			this.componentRef.destroy();
		}
	}

	//

	get item(): FolderItem {
		return this._item;
	}

	goItem(item) {
		console.log('item: goItem');
		this.goItemClicked.emit(item);
	}


	goParent() {
		this.goParentClicked.emit();
	}


	showNewableItemDialog(newableItem: NewableItem) {
		this.newableItemClicked.emit(newableItem);
	}

}
