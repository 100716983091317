// ported from cioAssignmentManager.js ...

import { Injectable } from '@angular/core';
import * as firebase from 'firebase/app';
import { BsUtilsService } from '@cf-platform/cf-core-cms-ng';


import {IProjectInfo, IAssignment} from "@cf-platform/cf-core-cms";


import { IMyAssignmentStats } from '../interfaces/IMyAssignmentStats';
import { BsUserProjectsService } from '@cf-platform/cf-core-cms-ng';
import { IClassAssignmentStats } from '../interfaces/IClassAssignmentStats';
import { stringLiteral } from '@babel/types';

// NOTE.  I have to edit the tsconfig.json file in this dir
//         to get the nrwl/nx stuff to work in visual code
//           untill I move stuff around
// 		And also edit the top one as well i tink...

import { SubmissionQueueItem, TimeStuff, IGradeInfo } from '@cf-platform/cf-class-stuff';
import { IUser } from '@cf-platform/cf-core-cms';

@Injectable()
export class BsAssignmentsService {
	constructor(
		private projectService: BsUserProjectsService // hm
	) { }

	myAngularFilter(key: string, val) {
		if (key.indexOf('$') >= 0) {
			return undefined;
		}
		return val;
	}

	// TODO: replace this with utilstuffs copy
	objToArray(objWithKeys) {
		// http://stackoverflow.com/questions/14788652/how-to-filter-key-value-with-ng-repeat-in-angularjs
		const a = [];
		let i = 0;
		for (const k in objWithKeys) {
			const v = objWithKeys[k];
			v.__key = k;
			a[i++] = v; // value;
		}
		return a;
	}

	// eventually move and combine these elsewhere
	saveToDB(path, key, origVal) {

		const val = {...origVal};
		val.project = {...val.project};
		delete val.project['saveAssignment'];
		delete val.project['saveFile'];

		// val.project = {};

		// for (const key in origVal.project) {
		// 	if (origVal.hasOwnProperty(key)) {
		// 		val.project[key] = origVal.project[key];
		// 	}
		// }

		// if (origVal.project) {
		// 	val.project.files = {...origVal.project.files};
		// }

		/*
		val.project.files = [];
		for (const fileK of Object.keys(origVal.project.files)) {
			const file = origVal.project.files[fileK];
			//delete file['_old_id'];
			val.project.file.push({...file});
		}
		*/


		console.log('saveToDB - path: ' + path + '  key: ' + key);
		//console.log(' val: ' + JSON.stringify(val));


		const j = JSON.stringify(val, this.myAngularFilter);
		const valCopy = JSON.parse(j); // remove the $$hashkeys and such...
		const entRef = firebase.database().ref(path).child(key);
		return entRef.update(valCopy);
	}

	pushToDB(path, key, val) {

		console.log('pushToDB - path: ' + path + '  key: ' + key);
		console.log(' val: ' + JSON.stringify(val));

		const j = JSON.stringify(val, this.myAngularFilter);
		const valCopy = JSON.parse(j); // remove the $$hashkeys and such...
		const entRef = firebase.database().ref(path).child(key);
		return entRef.push(valCopy); // this somehow will return the new key
	}

	getFromDB(path, key) {
		const pathRef = firebase.database().ref(path).child(key);
		return pathRef.once('value');
	}

	watchDB(path: string, key: string, onData: (any) => void ) {
		const pathRef = firebase.database().ref(path).child(key);
		const listner = pathRef.on('value', (snapshot) => onData(snapshot.val()));
		const unsubThing = () => {
			//console.log('unsubThing from ' + path + '/ ' + key);
			pathRef.off('value', listner);
		}
		return unsubThing;
	}

	public async getAssignments(classId: string): Promise<Array<IAssignment>> {
		const o = await this.getFromDB('/class/' + classId, 'assignments');
		const v = o.val();
		return this.objToArray(v);
	}

	async getAssignment(classId: string, assignmentId: string): Promise<IAssignment> {
		const o = await this.getFromDB('/class/' + classId + '/assignments/', assignmentId);
		let v = o.val();
		if (v) {
			v.classId = classId;
		}
		return v;
	}


	async copyAssignmentToClass(fromClassId: string, assignmentId, toClassId: string): Promise<boolean> {
		// warning this will wipe out an existing assignment and existing student submit data
		// if the assignment exists and is already started in the toClass...
		// so therefore we are checking that first...

		const existingAssignment = await this.getAssignment(toClassId, assignmentId);
		if (existingAssignment) {
			console.log("Cannot copy over current existing assignemnt!");
			return false;
		}


		const assignment = await this.getAssignment(fromClassId, assignmentId);
		if (!assignment) {
			console.log("Could not find assignment to copy!");
			return false;
		}

		// patch assignment
		assignment.classId = toClassId;
		assignment.id = toClassId + ':' + assignmentId;
		assignment.path = assignment.id;
		if (assignment.project) {
			delete assignment.project['path'];
		}
		// fix this soon
		delete assignment.started;
		delete assignment.graded;
		delete assignment.submitted;

		const r = await this.updateAssignmentAsInstructor(toClassId, assignmentId, assignment);

		return !r;
	}





	// for the instructor to update the assignment
	async updateAssignmentAsInstructor(classId, assignmentId, assignment: IAssignment): Promise<any> {
		if (assignment.project && assignment.project._files) {
			delete assignment.project._files; // hack
		}
		const o = await this.saveToDB('/class/' + classId + '/assignments/', assignmentId, assignment);
		return o;
	}

	async markStarted(classId, assignmentId, userEmail: string) {
		const userDBKey = BsUtilsService.z_filterEmailAsKey(userEmail);
		const path = '/class/' + classId + '/assignments/' + assignmentId;
		const key = 'started';
		const o = {};
		o[userDBKey] = firebase.database.ServerValue.TIMESTAMP;
		console.log('markStarted u:' + userDBKey + ' p:' + path);
		console.log(o);
		return await this.saveToDB(path, key, o);
	}

	async hasStarted(classId, assignmentId, userEmail: string) {
		const userDBKey = BsUtilsService.z_filterEmailAsKey(userEmail);
		const path = '/class/' + classId + '/assignments/' + assignmentId + '/started';
		const key = userDBKey;
		const o = await this.getFromDB(path, key);
		const v = o.val();
		return !!v;
	}

	async addToSubQueue(classId, assignment: IAssignment, userDBKey: string, submitKey: string, submitDate: string) {
		//const testThing: SubmissionQueueItem = {};

		const submitQueueRow: SubmissionQueueItem = {
			classId: classId,
			assignmentId: assignment.assignmentId,
			userDBKey: userDBKey,
			submitKey: submitKey,
			submitDate: submitDate,
			autoGrade: assignment.autoGrade
		}
		//const userDBKey = BsUtilsService.z_filterEmailAsKey(userEmail);
		const path = '/class/' + classId; // + '/assignments/' + assignmentId;
		const key = 'sub_queue';
		return await this.pushToDB(path, key, submitQueueRow);
	}

	async markSubmitted(classId, assignmentId, userEmail: string) {
		this.hasStarted(classId, assignmentId, userEmail)
			.then(hasSt => {
				if (!hasSt) {
					this.markStarted(classId, assignmentId, userEmail);
				}
			});

		const userDBKey = BsUtilsService.z_filterEmailAsKey(userEmail);
		const path = '/class/' + classId + '/assignments/' + assignmentId;
		const key = 'submitted';
		const o = {};
		o[userDBKey] = firebase.database.ServerValue.TIMESTAMP;
		return await this.saveToDB(path, key, o);
	}

	async markGraded(classId, assignmentId, userEmail: string) {
		const userDBKey = BsUtilsService.z_filterEmailAsKey(userEmail);
		const path = '/class/' + classId + '/assignments/' + assignmentId;
		const key = 'graded';
		const o = {};
		o[userDBKey] = firebase.database.ServerValue.TIMESTAMP;
		return await this.saveToDB(path, key, o);
	}

	async submit(classId, assignment: IAssignment, userEmail: string, project: any) {
		const userDBKey = BsUtilsService.z_filterEmailAsKey(userEmail);
		const path = '/class/' + classId + '/submissions/' + userDBKey + '/' + assignment.assignmentId;
		const key = new Date().getTime();
		const o = {
			project: project,
			userEmail: userEmail,
			submitDate: firebase.database.ServerValue.TIMESTAMP
		};

		await this.saveToDB(path, key, o);
		const submittedDataSnap = await this.getFromDB(path, key);
		const submittedData = submittedDataSnap.val();
		this.markSubmitted(classId, assignment.assignmentId, userEmail);
		//debugger;

		const submitDate = submittedData['submitDate'];
		this.addToSubQueue(classId, assignment, userDBKey, key.toString(), submitDate);
		return true;
	}

	async hasSubmitted(classId, assignmentId, userEmail) {
		const userDBKey = BsUtilsService.z_filterEmailAsKey(userEmail);
		const path = '/class/' + classId + '/submissions/' + userDBKey;
		const key = assignmentId;
		const b = await this.getFromDB(path, key);
		const v = b.val();
		return !!v;
	}

	async getSubmitsFor(classId, assignmentId, userEmail): Promise<any[]> {
		const userDBKey = BsUtilsService.z_filterEmailAsKey(userEmail);
		const path = '/class/' + classId + '/submissions/' + userDBKey;
		const key = assignmentId;

		console.log('getSubmitsFor: c:' + classId + ' a:' + assignmentId + ' s:' + userDBKey);
		console.log('gs p: ' + path + ' k: ' + key);

		const b = await this.getFromDB(path, key);
		const v = b.val();
		const a = this.objToArray(v);
		return a;
	}

	/*
	// my assignment ids in grade are really assignment submit dates, so this won't work
	// get a single specific submit
	async getSubmitFor(classId, assignmentId, userEmail, submitId): Promise<any> {
		const userDBKey = BsUtilsService.z_filterEmailAsKey(userEmail);
		const path = '/class/' + classId + '/submissions/' + userDBKey + '/' + assignmentId;
		const key = submitId;

		console.log('getSubmitFor: c:' + classId + ' a:' + assignmentId + ' s:' + userDBKey);
		console.log('gs p: ' + path + ' k: ' + key);

		const b = await this.getFromDB(path, key);
		const v = b.val();
		const a = v; // this.objToArray(v);
		return a;
	}
	*/

	async saveGrade(classId, assignmentId, userEmail, submitId, grade, note) {
		const userDBKey = BsUtilsService.z_filterEmailAsKey(userEmail);
		const path = '/class/' + classId + '/grades/' + userDBKey; // + '/' + assignmentId;
		const key = assignmentId;
		const o = {
			submitId: submitId,
			userEmail: userEmail,
			gradeDate: firebase.database.ServerValue.TIMESTAMP,
			grade: grade,
			note: note
		};
		await this.saveToDB(path, key, o); // TODO: might need to make this save the highest of the history grades, if there is one...

		await this.pushToDB(path + '/' + key, 'history', o);

		this.markGraded(classId, assignmentId, userEmail);
		return true;
	}

	async getGradeInfo(classId, assignmentId, userEmail): Promise<IGradeInfo> {
		const userDBKey = BsUtilsService.z_filterEmailAsKey(userEmail);
		const path = '/class/' + classId + '/grades/' + userDBKey;
		const key = assignmentId;

		const b = await this.getFromDB(path, key);
		const v = b.val();
		return v as IGradeInfo; // todo: need to verify it is an IGradeInfo
	}

	// get all the grades in this class for this user...
	async getGradesInfoUser(classId, userEmail): Promise<any> {
		const userDBKey = BsUtilsService.z_filterEmailAsKey(userEmail);
		const path = '/class/' + classId + '/grades';
		const key = userDBKey;

		console.log('getsGradeInfoUser: c:' + classId + ' s:' + userDBKey);
		console.log('gs p: ' + path + ' k: ' + key);

		const b = await this.getFromDB(path, key);
		const v = b.val();
		return v; // a;
	}

	cachedStudents: Array<IUser> = [];

	async getStuInfoFromClassByEmail(classId: string, email: string): Promise<IUser> {
		if (this.cachedStudents.length === 0) {
			const path = '/class/' + classId + '/users';
			const ref = firebase.database().ref(path);
			let eSnap = await ref.once('value');
			let emailsObj = eSnap.val();
			if (emailsObj) {
				this.cachedStudents = this.objToArray(emailsObj);
			}
		}

		for(const u of this.cachedStudents) {
			if (u.email === email || u.dbkey === email) {
				return u;
			}
		}

		return null;

	}

	/*
	pfSaveProjFile(assignment, num, file) {
		// this is a promise that we can hook later...
		const assignmentId = assignment.assignmentId;
		const classId = assignment.classId;
		const classGroup = assignment.classGroup;
		const path = '/class/' + classId + '/assignments/' + assignmentId + '/project/files/';
		const key = num;
		const val = file;
		return this.saveToDB(path, key, val);
	}
	*/

	async newAssignment(classId): Promise<IAssignment> {
		const a: IAssignment = { assignmentId: 'TBD', name: 'New Assignment', path: 'TBD', points: 100, classId: classId };
		const path = '/class/' + classId;
		const entRef = firebase.database().ref(path).child('assignments');

		const ao = await entRef.push(a);
		const k = ao.key;

		a.assignmentId = k;
		a.path = classId + ':' + k;

		const a2 = await ao.update(a);
		console.log('Debug ao here');

		a.update = ao.update;
		return a;					// NEEDS TESTING
		//return ao;
	}

	async newAssignmentWithProject(classId: string, pType: string): Promise<IAssignment> {

		console.log('Debug newAssignmentWithProject here');
		const a = await this.newAssignment(classId);
		//const k = ao.key;

		console.log('and here');

		const proj = { assignmentId: a.assignmentId, class: classId, isAssignment: true, name: 'COPY THIS', path: classId + ':' + a.assignmentId, type: pType, files: [] };
		if (pType === 'cpp') {
			proj.type = 'C++';
			const f1 = { data: 'Instructions', mode: 'html', name: 'Instructions', type: 'html' };
			const f2 = { data: '//stuff', mode: 'c_cpp', name: 'main.cpp' };
			proj.files.push(f1);
			proj.files.push(f2);
		}

		const o = { project: proj };
		const s2 = await a.update(o);
		return a;					// NEEDS TESTING
	}

	//

	getStatStr(stats: IMyAssignmentStats): string {

		if (!stats.isAssignmentLoaded) {
			return '?';
		}
		if (stats.hasGradedAssignment) {
			return 'Graded';
		}
		if (!stats.hasStartedAssignment) {
			return 'Not Started';
		}
		if (stats.hasSubmittedAssignment) {
			return 'Submitted';
		}
		return 'Started';
	}

	// MAYBE BUST THIS UP INTO MyStats and ClassStats???

	async getUserStatsForAssignment(userEmail: string, assignment: IAssignment, project: IProjectInfo) : Promise<IMyAssignmentStats> {
		const stats: IMyAssignmentStats = {
			isAssignmentLoaded: false,

			// my stats
			hasStartedAssignment: false,
			hasSubmittedAssignment: false,
			hasGradedAssignment: false,

			isLate: false,
			lateStat: "",

			myStat: '?',

			gradeInfo: {}
		};

		if (assignment) {
			stats.isAssignmentLoaded = true;


			const projPath = assignment.classId + ':' + assignment.assignmentId;
			const projectUrl = '/my/projects/' + projPath;
			const aName: string = assignment.project.name;
			if (!aName.startsWith(projPath)) {
				assignment.project.name = projPath + ': ' + aName;
			}
			assignment.projPath = projPath;
			if (!project) {
				project = await this.projectService.loadUserProject(userEmail, projPath);
			}
			if (project) {

				stats.hasStartedAssignment = true;
				//this.isAssignmentLoaded = true;

				stats.hasSubmittedAssignment = await this.hasSubmitted(assignment.classId, assignment.assignmentId, userEmail);

				stats.gradeInfo = await this.getGradeInfo(assignment.classId, assignment.assignmentId, userEmail)
				stats.hasGradedAssignment = !!stats.gradeInfo;

				if (!stats.hasSubmittedAssignment) {
					stats.lateStat = BsAssignmentsService.calcEarlylateFA(assignment, Date.now());
					stats.isLate = stats.lateStat.indexOf("Late") >= 0;
				}

			}
			else {
				stats.lateStat = BsAssignmentsService.calcEarlylateFA(assignment, Date.now());
				stats.isLate = stats.lateStat.indexOf("Late") >= 0;

			}
		}

		stats.myStat = this.getStatStr(stats);


		return stats;
	}

	getClassStatsForAssignment(assignment: IAssignment): IClassAssignmentStats {
		const stats: IClassAssignmentStats = {
			isAssignmentLoaded: false,

			startedCount: 0,
			submittedCount: 0,
			gradedCount: 0

		};

		if (assignment) {
			stats.isAssignmentLoaded = true;

			if (assignment.started) {
				stats.startedCount = Object.keys(assignment.started).length;
			}
			if (assignment.submitted) {
				if (assignment.submitted) {
					stats.submittedCount = Object.keys(assignment.submitted).length;
				}
			if (assignment.graded) {
					stats.gradedCount = Object.keys(assignment.graded).length;
				}
			}
		}

		return stats;
	}
	watchClassStatsForAssignment(assignment: IAssignment, onStat: (IClassAssignmentStats) => void) {

		const unsubThing = this.watchDB('/class/' + assignment.classId + '/assignments/', assignment.assignmentId,
		(val) => {
			const updatedAsignment = val as IAssignment;
			const stat = this.getClassStatsForAssignment(updatedAsignment);
			onStat( stat );
		}
		);

		return unsubThing;

	}



	// USES SHARED CODE

	public static calcEarlylateFA(assignment: IAssignment, subDate: number): string {
		if (!assignment || !assignment.dueDate) {
			return '?';
		}

		return TimeStuff.calcEarlylate(assignment.dueDate, subDate);

	}

	// USES SHARED CODE
		// will return negative if early
	public static calcDaysDiff(assignment: IAssignment, subDate: number): number {
		if (!assignment || !assignment.dueDate) {
			return 0;
		}

		return TimeStuff.calcDaysDiff(assignment.dueDate, subDate);
	}
}
