import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ZTurtleCanvasComponent } from './z-turtle-canvas/z-turtle-canvas.component'


@NgModule({
  declarations: [
	ZTurtleCanvasComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
	ZTurtleCanvasComponent
  ]
})
export class CfTurtleNgModule { }
