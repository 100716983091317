import { Component, Input, OnDestroy } from '@angular/core';
import { BsCurrentClassInfoService, CFCurrentClassInfo } from '../../services/bs-current-class-info.service';
import { FolderItem } from '@cf-platform/cf-core-cms-ng'; //'../../../../../apps/web-client/src/app/cms-core/services/bs-folder.service';
import { Observable } from 'rxjs/Observable';
import { UserRepository } from '@cf-platform/cf-core-cms-ng';
import { IUser } from '@cf-platform/cf-core-cms';

@Component({
	selector: 'app-bs-current-class-menu',
	templateUrl: './bs-current-class-menu.component.html',
	styleUrls: ['./bs-current-class-menu.component.css']
})
export class BsCurrentClassMenuComponent implements OnDestroy {
	//currentClassPath: string;
	rootItem: Observable<FolderItem>;
	classId: string;
	currentClassInfo: CFCurrentClassInfo;
	cciSub: any;

	_user: IUser;
	@Input() set user(user: IUser) {
		this._user = user;
		this.doInstructorMenu();
	}
	get user(): IUser {
		return this._user;
	}

	userIsInstructor: boolean = false;

	private _userRepository: UserRepository;

	doInstructorMenu() {
		this.userIsInstructor = false;
		if (this.user && this.currentClassInfo && this.currentClassInfo.theClass) {
			this.currentClassInfo.theClass.userIsInstructor(this.user).then(q => {
				this.userIsInstructor = q;
			});
		}
	}

	constructor(private userRepository: UserRepository,
		private currentClassInfoService: BsCurrentClassInfoService) {
		this._userRepository = userRepository;

		this.cciSub = this.currentClassInfoService.currentClassInfoHandler.subscribe(cci => {
			this.currentClassInfo = cci;
			if (cci && cci.theClass) {
				cci.theClass.userIsInstructor(this.user).then(q => {
					this.userIsInstructor = q;
				});

				this.classId = cci.theClass.classId;
				this.rootItem = cci.rootItem;
			}
		});


		this.doInstructorMenu();
	}

	goItem(e) {
		this.currentClassInfoService.goItem(e);
	}

	ngOnDestroy() {
		this.cciSub.unsubscribe();
	}
}
