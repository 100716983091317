// message-hub.service  by Bob Bradley - June 2018

import { Injectable } from '@angular/core';

export interface IMHMessage {
	subject: string;
	from?: {};
	data?: {};
}

export interface IMHSubscribeOptions {
	subject: string;
	receiverFunct: IMHReceiverFunction;
	receiverName?: string;
	dontReplayValue?: boolean;
}

export type IMHReceiverFunction = (message: IMHMessage) => void;

export interface IMHReceiverInfo extends IMHSubscribeOptions {
	id: Number;
}


@Injectable()

export class MessageHubService {
	private debug = true;

	static nextReceiverId = 0;
	// for types see https://basarat.gitbooks.io/typescript/docs/types/index-signatures.html
	private subjectReceiversDict: { [index: string]: IMHReceiverInfo[] } = {};
	private subjectLastValueDict: { [index: string]: IMHMessage } = {};

	constructor() { }

	post(message: IMHMessage) {
		if (this.debug) {
			console.log('Message Post: ' + message.subject);
		}
		this.subjectLastValueDict[message.subject] = message;
		if (this.subjectReceiversDict[message.subject]) {
			const receivers = this.subjectReceiversDict[message.subject];
			if (this.debug) {
				console.log('Posting to ' + receivers.length + ' Receivers');
			}
			for (const receiver  of receivers) {
				setTimeout(() => {
					if (this.debug) {
						console.log('onMHMessagePosted sent to Receiver ' + receiver.receiverName + '(' + receiver.id + ')');
					}
					if (receiver.receiverFunct) {
						receiver.receiverFunct(message);
					}
					else {
						console.warn('MessageHubService error sent message to non-receiver.');
					}
				}, 0);
			}
		}
		else {
			if (this.debug) {
				console.log('No Receivers');
			}
		}
	}

	subscribe(subOptions: IMHSubscribeOptions): IMHReceiverInfo {

		if (!this.subjectReceiversDict[subOptions.subject]) {
			this.subjectReceiversDict[subOptions.subject] = [];
		}
		const receivers = this.subjectReceiversDict[subOptions.subject];
		const receiver: IMHReceiverInfo = {...subOptions, id: MessageHubService.nextReceiverId++};
		receivers.push(receiver);

		if (this.debug) {
			console.log('Receiver ' + subOptions.receiverName + '(' + receiver.id + ')' + ' subscribe: ' + subOptions.subject);
		}

		if (!subOptions.dontReplayValue && this.subjectLastValueDict[subOptions.subject]) {
			if (this.debug) {
				console.log('Replaying message: ' + subOptions.subject + ' to Receiver ' + receiver.receiverName + '(' + receiver.id + ')' );
			}
			setTimeout(() => {
				receiver.receiverFunct(this.subjectLastValueDict[subOptions.subject]);
			}, 0);
		}

		return receiver;
	}

	unsubscribe(receiverInfo: IMHReceiverInfo) {
		if (this.debug) {
			console.log('Receiver' + receiverInfo.receiverName + '(' + receiverInfo.id + ') unsubscribing from: ' + receiverInfo.subject);
		}

		if (this.subjectReceiversDict[receiverInfo.subject]) {
			const Receivers = this.subjectReceiversDict[receiverInfo.subject];
			this.subjectReceiversDict[receiverInfo.subject] = Receivers.filter(obj => obj.id !== receiverInfo.id);
		}
	}

}
