import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs/Observable';

import { AngularFireAuth } from '@angular/fire/auth';


@Injectable()
export class OnlyLoggedInFireBaseGuard implements CanActivate {

	// private theUser;
	constructor(private afAuth: AngularFireAuth, private router: Router) {

	}
	canActivate(
		next: ActivatedRouteSnapshot,
		state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
		console.log('OnlyLoggedInFireBaseGuard here');
		// https://codereview.stackexchange.com/questions/162995/angular-guards-firebase-loggedinandverified
		return new Observable<boolean>(observer => {
			this.afAuth.authState.subscribe(u => {
				console.log('OnlyLoggedInFireBaseGuard auth observer here');
				if (u) {
					console.log('OK User: ' + u.displayName);
					observer.next(true);
				}
				else {
					console.log('No User');
					// window.alert('You must log in to view this page');
					const url = state.url.toString();
					this.router.navigate(['/login', url]);
					observer.next(false);
				}
			});
		});

		/*
		var u = this.afAuth.authState.first()
		if (!!this.theUser){
		  console.log('User: ' + this.theUser.displayName);
		  return true;
		} else {
		  console.log('No User');
		  window.alert('You must log in to view this page');
		  return false;
		}
		*/

	}
}
