import { Component, Input, ViewChild, OnInit } from '@angular/core';

interface IZBFile {
	fileName: string;
	mode: string;
	data: string;
}

@Component({
	selector: 'app-zb-web-frame',
	templateUrl: './zb-web-frame.component.html',
	styleUrls: ['./zb-web-frame.component.css']
})
export class ZbWebFrameComponent implements OnInit {


	@Input('files') files:  IZBFile[];

	frameLoaded = false;

	constructor() {


	}

	ngOnInit() {

	}

	frameLoad() {
		if (!this.frameLoaded) {
			this.frameLoaded = true;
			console.log('Showing initial web loaded message');
			this.setupFrame('<h1>Web View</h1><p>Click the Render button above to show your page here...</p>');
		}
	}

	setupFrame(s: string) {

		const elm = document.getElementById('frame2') as HTMLIFrameElement;
		const doc = elm.contentWindow.document;
		/*
		doc.open();
		doc.write(s);
		doc.close();
		*/
		this.setInnerHtml(doc.documentElement, s);

	}


	// The following way of running a user script is a really dangerious way to do it.
	// I think this whole thing should be rewritten as a seperate component
	// that maybe puts the page and scripts in a new frame....
	// maybe this: https://stackoverflow.com/questions/1591135/why-does-appending-a-script-to-a-dynamically-created-iframe-seem-to-run-the

	// https://stackoverflow.com/questions/2592092/executing-script-elements-inserted-with-innerhtml
	setInnerHtml = function (elm: HTMLElement, html: string) {
		const filesDict = {};
		this.files.forEach(file => {
			const fn = file.fileName || file.name; // HACK
			filesDict[fn] = file;
		});

		console.log('Debug setInnerHtml');
		elm.innerHTML = html;

		// patch in local css files (added by bobb)
		Array.from(elm.querySelectorAll('link')).forEach(function (el) {
			const rel = el.attributes.getNamedItem('rel') ;
			if (rel && rel.value.toLowerCase() == "stylesheet"){}
				const href = el.attributes.getNamedItem('href');
				if (href) {
					const fn = href.value;
					if (filesDict.hasOwnProperty(fn)) {
						const newEl = document.createElement('style');
						newEl.setAttribute('type', 'text/css');
						newEl.appendChild(document.createTextNode(filesDict[fn].data));
						el.parentNode.replaceChild(newEl, el);
					}
				}
			}
		);

		// patch in local script files and execute
		Array.from(elm.querySelectorAll('script')).forEach(function (el) {
			const src = el.attributes.getNamedItem('src');
			if (src) {  // patch in local script files and execute (added by bobb)
				const fn = src.value;
				if (filesDict.hasOwnProperty(fn)) {
					const newEl = document.createElement('script');
					newEl.appendChild(document.createTextNode(filesDict[fn].data));
					el.parentNode.replaceChild(newEl, el);
				}
			}
			else {  // extract and execute script tags
				console.log('Debug script tag here');
				const newEl = document.createElement('script');
				Array.from(el.attributes).forEach(function (el2) {
					newEl.setAttribute(el2.name, el2.value);
				});
				newEl.appendChild(document.createTextNode(el.innerHTML));
				el.parentNode.replaceChild(newEl, el);
			}
		});
	};

	@ViewChild('miniWeb', { static: false }) miniWeb;
	renderWebPage() {
		console.log('Debug renderWebPage here');
		// this.miniWeb.nativeElement.innerHTML = '<h1>Wait...</h1>';
		this.setupFrame('<h1>Rendering...</h1>');

		let data = '';
		this.files.forEach(file => {
			if (file.mode && file.mode.toLowerCase() === 'html') {
				data = file.data;
			}
		});

		// old way this.setInnerHtml(this.miniWeb.nativeElement, data);

		this.setupFrame(data);
	}
}
