// This code was started by Bob Bradley: SU19
// It is a facade to unify and abstract the 3 different FireBase realtime database APIs
//   (client, admin & function)
//   so that the same code can run on either the frontend, backend or firebase function.



export interface UFBApp {
	database: () => UFBDatabase  ;
	auth: () => UFBAuth | any;
}
export interface UFBDatabase {
	ref: (path?: string) => any;
}

export interface UFBAuth {
	onAuthStateChanged?: (any) => any;  // this is not in the admin auth
}

export interface UFBUser {
	email?: string,
	uid?: string
}

export interface UFBTimestamp {
	'.sv': string;
}

export class UnifiedFirebaseDB {

	public isDBConnected: boolean;

	private ref;

	public static TIMESTAMP = { '.sv': 'timestamp' };


	constructor(
		private db: UFBDatabase,
		private basePath: string) {

			this.ref = this.db.ref(basePath);

			// tslint:disable-next-line:no-eval


	}

	private join(paths: string[]) {
		// todo. check for extra /s on start or end of paths...
		if (!paths) {
			return '';
		}
		return paths.join('/');
	}

	// TODONE: change this have the second param be the name of the key instead of a abool
	async getOnce(paths: string[], key?: string): Promise<any> {
		try {
			const path = this.join(paths);
			console.log('getOnce  p: ' + path);
			const snap = await this.ref.child(path).once('value');
			const v = snap.val();
			if (key) {
				v[key] = snap.key;
			}
			return v;
		} catch (error) {
			console.error('unified-firebase-db getOnce: catch: ', error);
			return null
		}

	}


	async findWhere<T>(paths: string[], field: string, val: string, key?: string): Promise<Array<T>> {

		let ref = this.ref;
		if (paths.length) {
			ref = ref.child(this.join(paths));
		}
		console.log('findWhere: ' , this.join(paths)  + '  ' + field + '="' + val + '"');;
		const snap = await ref.orderByChild(field).equalTo(val).once('value');

		const uObj = snap.val();
		if (!uObj) {
			console.log('got nada');
			return [];
		}
		console.log('got: ' + uObj);

		//console.log('U: ' + JSON.stringify(u));
		const keys = Object.keys(uObj);
		if (keys) {
			const r: Array<T> = [];
			for (const k of keys) {
				const v = uObj[k];
				if (key) {
					v[key] = k;
				}
				r.push(v);
			}
			return r;
		}
		return [];
	}

	async setVal(paths: string[], val: any) {
		await this.ref.child(this.join(paths)).set(val);
	}

	async removeVal(paths: string[]) {
		await this.ref.child(this.join(paths)).remove();
	}

	async updateVal(paths: string[], val: any) {
		const path = this.join(paths);
		//console.log('updateVal: path: ' + path + ' val: ', val);
		const err = await this.ref.child(path).update(val);
		return !err;
	}

	async updateIfNull(paths: string[], val: any ): Promise<boolean> {
		// we use a transaction to only update if the path is currently null
		// https://firebase.google.com/docs/reference/node/firebase.database.Reference.html#transaction
		const p = new Promise<boolean> (  (resolve, reject) => {
			const updateFun = (currentData) => {
				if (currentData !== null) {
					console.log('updateIfNull: Somebody else got it');
					return;
				} else {
					return val;
				}
			}
			const myOnComplete = (error, committed, snapshot) => {
				if (error) {
					console.log('updateIfNull: Transaction failed abnormally!', error);
					return resolve(false);
				  } else if (!committed) {
					console.log('updateIfNull: We aborted the transaction (because something already exists).');
					return resolve(false);
				  } else {
					console.log('updateIfNull: Transaction added!');
					return resolve(true);
				  }
			};
			this.ref.child(this.join(paths)).transaction(updateFun, myOnComplete);
			// todo. may need to rewrite this to check for errors here...

		});
		return p;
	}

	async pushVal(paths: string[], val: any): Promise<string> {
		const snap = await this.ref.child(this.join(paths)).push(val);
		return snap.key;
	}

	async pushValAndKeyIt(paths: string[], val: any, key: string): Promise<any> {
		console.log('pushValAndKeyIt');
		const snap = await this.ref.child(this.join(paths)).push(val);
		if (!snap || !snap.key) {
			return null;
		}
		const keyObj: any = {};
		keyObj[key] = snap.key;
		if (!await this.updateVal([paths, snap.key], keyObj)) {
			return null;
		}
		return await this.getOnce([paths,snap.key]);
	}

	async removeIfOffline(paths: string[]) {
		this.ref.child(this.join(paths)).onDisconnect().remove((err) => {
			// https://firebase.google.com/docs/database/web/offline-capabilities
			if (err) {
				console.error('CFMsg: Could not establish onDisconnect event', err);
				console.error('CFMsg: Path was: ' + this.join(paths));
			}
		});
	}

	async updateIfOffline(paths: string[], val: any) {
		this.ref.child(this.join(paths)).onDisconnect().update(val, (err) => {
			// https://firebase.google.com/docs/database/web/offline-capabilities
			if (err) {
				console.error('CFMsg: Could not establish onDisconnect event', err);
				console.error('CFMsg: Path was: ' + this.join(paths));
			}
		});
	}

	async on(paths: string[],
		filter: {child: string, val: string},
		handler: (r:any) => void, event: string) {

			console.log('Listening for ' + event + ' at: ' + this.join(paths));

			let ref2 = this.ref.child(this.join(paths));
			if (filter) {
				ref2 = ref2.orderByChild(filter.child).equalTo(filter.val);
			}
			await ref2.on(event, (snap) => handler({...snap.val(), __key:snap.key}));
	}

	async on_child_added(paths: string[],
						filter: {child: string, val: string},
						handler: (r:any) => void){
		return this.on(paths, filter, handler, 'child_added');
	}

	// todo
	//async on_child_changed(paths: string[],
	//					filter: {child: string, val: string},
	//					handler: (r:any) => void){
	//	return this.on(paths, filter, handler, 'child_changed');
	//}

	async off_child_added(paths: string[]) {
		await this.ref.child(this.join(paths)).off('child_added');
	}

	// --

	onDBConnected( connectionHandler: (bool) => void) {

		// Listen for database connection
		// https://stackoverflow.com/questions/11351689/detect-if-firebase-connection-is-lost-regained
		const connectedRef = this.db.ref(".info/connected");
		connectedRef.on("value", (snap) => {
			this.isDBConnected = snap.val();
			connectionHandler(this.isDBConnected);
		});
	}

}
