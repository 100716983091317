import { Component, OnInit } from '@angular/core';
import { ItemDefaultViewComponent } from '../item-default-view/item-default-view.component';

@Component({
	selector: 'app-item-default-view-detail',
	templateUrl: './item-default-view-detail.component.html',
	styleUrls: ['./item-default-view-detail.component.css']
})
export class ItemDefaultViewDetailComponent extends ItemDefaultViewComponent implements OnInit {

	constructor() {
		super();
	}

	ngOnInit() {
	}

}
