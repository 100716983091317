import { Injectable } from '@angular/core';
import { BsClass } from '../classes/bs-class';
import { BsAssignmentsService } from './bs-assignments.service';
import { BsClassesUsersService } from './bs-classes-users.service';
import { Observable } from 'rxjs/Observable.d';
import { Subject } from 'rxjs/Subject';
import { BsFolderService, BsFolderManager,  FolderItem } from '@cf-platform/cf-core-cms-ng';
import { AngularFireDatabase } from '@angular/fire/database';

import {IProjectInfo, IAssignment} from "@cf-platform/cf-core-cms";

import { IUser } from '@cf-platform/cf-core-cms';
import { BehaviorSubject } from 'rxjs';

import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { IClassSettings } from '../interfaces/IClassSettings';

import { UserRepository } from '@cf-platform/cf-core-cms-ng';
import { EzClassUserServiceService } from './ez-class-user-service.service';
import { BsUtilsService, BsCurrentUserService } from '@cf-platform/cf-core-cms-ng';


class ZStore<T> {
	private item: T;
	private source: BehaviorSubject<T> = new BehaviorSubject(null);
	public handler = this.source.asObservable();
	setItem(item: T) {
		this.source.next(item);
	}
	constructor(initialValue: T) {
		this.source = new BehaviorSubject(initialValue);
	}
}

export interface CFCurrentClassInfo {
	//user: IUser;
	theClass: BsClass;
	theClassId: string;
	theClassInst: string;
	theClassPath: string;

	rootItem: Observable<FolderItem>;

	getSettings: () => Promise<IClassSettings | null>;
	saveSettings: (settings: IClassSettings) => void;

	//theClassPathStore: ZStore<string>;
	//selectedAssignment: IAssignment;



}

@Injectable()
export class BsCurrentClassInfoService {

	private bsFolderManager: BsFolderManager;

	theUser: any; // foo foo

	constructor(private bsAssignmentsService: BsAssignmentsService,
		private classUsersService: BsClassesUsersService,
		private db: AngularFireDatabase,
		private router: Router,
		private route: ActivatedRoute,
		//,
		private bsFolders: BsFolderService,
		private currentUserService: BsCurrentUserService,
		private ezClassUserServiceService: EzClassUserServiceService
		) {

			console.log("Hello from: BsCurrentClassInfoService");

			router.events.pipe(	// fire whenever the rout changes... you got a better way?
				filter((event: any) => event instanceof NavigationEnd)
			)
				.subscribe(event => {
					console.log(event);

					// doing a hokey way of getting the class path
					// otherwize i have to inject it path from a component...
					//       0  1    2    3                       4           5
					// url:   /class/utm/201940_UTM_CSIS_221_bb/assignments/lab1   /    /
					// url   /class/utm/201940_UTM_CSIS_221_bb/grade /assignmentId / stu /  submitId
					// classPath:                                  0           1      2    3

					const paths = event.url.split('/');
					if (paths.length >= 4) {

						const classInst = paths[2];
						const classId = paths[3];

						const cPaths = [];
						for(let i=4; i<paths.length; i++) {
							cPaths.push(paths[i]);
						}
						const classPath = '/' + cPaths.join('/');

						console.log('Found classId: ' + classId);

						this.loadClass(classId, classInst, classPath);
					}
					else {
						console.log('No classId');
						this.currentClassInfo = this.setCurrentClass('','','',null);
						// todo: clear if no classId
					}




				});



				//this.userRepository.getCurrentUser( u => {
				this.currentUserService.currentUserOb.subscribe(u => {
					this.theUser = u ;
					this.setEZClassInfo();
				});



	}

	userIsInstructor = false;

	private async setUserStuff() {
		console.log('setUserStuff');
		this.userIsInstructor = false;
		if (this.theUser && this.currentClassInfo && this.currentClassInfo.theClass) {
			this.userIsInstructor = await this.currentClassInfo.theClass.userIsInstructor(this.theUser);
		}
		return;
	}

	private async setEZClassInfo() {
		console.log('setEZClassInfo');
		if (this.theUser === null || this.theUser.email === "") {

			this.ezClassUserServiceService.setEZClassUserInfo({
				validUser:false, validClass:false
			});
		}
		else {

			await this.setUserStuff();

			this.ezClassUserServiceService.setEZClassUserInfo({
				validUser: true,
				currentUserEmail: this.theUser.email,
				currentUserKey: this.theUser.dbKey,
				isInstructor: this.userIsInstructor,
				validClass: true,
				classId: this.currentClassId
			});
		}
	}


	private currentClassId: string;
	private rootItem: Observable<FolderItem>;

	loadClass(classId, classInst, classPath) {
		console.log('debug loadClass');
		if (!classId) {
			console.log('No class...');
			// todo clear stuff
			this.currentClassInfo = this.setCurrentClass('','','',null);
			return;
		}
		if (!this.rootItem || this.currentClassId !== classId) {
			const feedDbPath: string = '/class/' + classId;
			this.bsFolderManager = this.bsFolders.getFolderManagerFor(feedDbPath);
			this.rootItem = this.bsFolderManager.getItem('/');
		}
		this.currentClassId = classId;
		//this.currentClassInfo.classId = classId;
		//this.currentClassInfo.rootItemS.next(this.rootItem);
		this.currentClassInfo = this.setCurrentClass(classId,classInst, classPath,this.rootItem );



	}

	//private theClassS: Subject<BsClass>;

	//theClass: BsClass;

	goItem;    // set in bs-class with a function that knows how to go to an item

	private currentClassInfo: CFCurrentClassInfo;
	private currentClassInfoSource: BehaviorSubject<CFCurrentClassInfo> = new BehaviorSubject(null);
	currentClassInfoHandler = this.currentClassInfoSource.asObservable();


	private setCurrentClass(classId: string, classInst: string, classPath: string, classRootItem: Observable<FolderItem>) {
		let curClass: BsClass;
		if (!this.currentClassInfo || this.currentClassInfo.theClass.classId != classId) {
			curClass = new BsClass(this.bsAssignmentsService, classId, 'TODO', this.classUsersService, this.db);
		} else
		{
			curClass = this.currentClassInfo.theClass;
		}
		// , selectedAssignment: null   // ,
		const getSettingsFun = async () => {
			const classSettings: IClassSettings = await this.classUsersService.loadClassSettings(this.currentClassId);
			return classSettings;
		};
		const saveSettingsFun = async (settings: IClassSettings) => {
			await this.classUsersService.saveClassSettings(this.currentClassId, settings);
		};
		this.currentClassInfo = {theClass: curClass, theClassPath: classPath, theClassInst: classInst,
			theClassId: curClass.classId,  rootItem:classRootItem,
			getSettings:getSettingsFun, saveSettings:saveSettingsFun};

		//this.theClassS.next(this.theClass);
		this.currentClassInfoSource.next(this.currentClassInfo)
		this.setEZClassInfo();
		return this.currentClassInfo;
	}


	//currentClassPathS: Subject<string>;

	//rootItemS: Subject<Observable<FolderItem>>;

	/*
	get theClassO(): Observable<BsClass> {
		return this.theClassS.asObservable();
	}

	set classId(classId: string) {
		this.theClass = new BsClass(this.bsAssignments, classId, 'TODO', this.classUsersService, this.db);
		this.theClassS.next(this.theClass);
	}
	assignmentId: string;

	async loadAssignment(assignmentId: string): Promise<IAssignment> {
		this.assignmentId = assignmentId;
		const a = await this.bsAssignments.getAssignment(this.classId, assignmentId);
		return a;
	}
	*/

}
