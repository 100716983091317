import { Injectable } from '@angular/core';

@Injectable()
export class BsUtilsService {
	constructor() { }

	static joinPaths(path1: string, path2: string): string {

		let r: string = path1;
		if (r.endsWith('/')) {
			r = r.substring(0, r.length - 1);
		}
		if (path2.startsWith('/')) {
			r += path2;
		}
		else {
			r += '/' + path2;
		}
		return r;
	}

	// TODO: Build tests for this thing!
	static firstName(name: string): string {
		if (name === undefined) {
			return name;
		}
		const words = name.split(/ /g);
		if (words.length <= 1) {
			return name;
		}
		delete words[words.length - 1];
		return words.join(' ');
	}

	// TODO: Build tests for this thing!
	static lastName(name: string): string {
		if (name === undefined) {
			return name;
		}
		const words = name.split(/ /g);
		if (words.length <= 1) {
			return name;
		}
		return words[words.length - 1];
	}

	static z_filterEmailAsKey(email) {
		let k = email;

		if (!k || k === '') {
			console.log('ERR: z_filterEmailAsKey:  key was empty!!');
			return 'UNKNOWN';
		}

		if (k.indexOf('@') > 0) { // ALL OF THIS NEEDS TO BE TESTED!!!
			let ePart = k.substr(0, k.indexOf('@'));
			let hPart = k.substr(k.indexOf('@') + 1);

			ePart = ePart.replace(/#/g, '\H');
			ePart = ePart.replace(/\$/g, '\S');
			ePart = ePart.replace(/\//g, '\s');
			ePart = ePart.replace(/\./g, '\o');

			hPart = hPart.replace(/\./g, '_'); // in the host part, just . -> _

			k = ePart + '@' + hPart;
		}
		return k;
	}
}
