import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';
import { MAT_DIALOG_DATA } from '@angular/material';

@Component({
	selector: 'app-bs-link-dialog',
	templateUrl: './bs-link-dialog.component.html',
	styleUrls: ['./bs-link-dialog.component.css']

	//selector: 'app-bs-link-dialog'mat-form-fieldbs-link-dialog.cmat-form-fieldyleUrls: ['./bs-link-dialog.component.css']
})
export class BsLinkDialogComponent {

	//constructor(@Inject(MAmat-form-fieldic data: any,
	constructor(@Inject(MAT_DIALOG_DATA) public data: any,
		public dialogRef: MatDialogRef<BsLinkDialogComponent>) {

		if (data['cmd'] === 'new') {
			this.url = '';
			this.name = '';
		}
		else if (data['cmd'] === 'edit') {
			this.url = data['url'];
			this.name = data['name'];
		}
	}

	url: string = '';
	name: string = '';

	doPost() {
		this.dialogRef.close(
		{
			cmd: 'Post',
			url: this.url,
			name: this.name
		});
	}
}
