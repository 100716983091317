import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { AngularFireDatabase, AngularFireList, AngularFireObject } from '@angular/fire/database';
import * as firebase from 'firebase/app';

@Injectable()
export class BsAttachmentService {
	constructor(private db: AngularFireDatabase) { }

	public getAttachmentManagerFor(feedDbPath: string): BsAttachmentManager {
		const bs = new BsAttachmentManager(this.db, feedDbPath);
		return bs;
	}
}

export class BsAttachmentManager {
	constructor(private db: AngularFireDatabase, private feedDbPath: string) { }
	fbQueryList: AngularFireList<any>;

	getAttachments(objectId: string): Observable<any[]> {

		if (objectId) {
			this.fbQueryList = this.db.list(this.feedDbPath + '/feed/attachments', ref => ref.orderByChild('object_id').equalTo(objectId));
			this.fbQueryList.valueChanges().subscribe(console.log);
		}
		else {
			this.fbQueryList = this.db.list(this.feedDbPath + '/feed/attachments', ref => ref.orderByChild('object_id'));
			this.fbQueryList.valueChanges().subscribe(console.log);
		}
		return this.fbQueryList.valueChanges();
	}

	addAttachment(object_id: string, type: string, url: string, name: string) {
		const c = {
			object_id: object_id,
			url: url,
			name: name,
			type: type,
			create_timestamp: firebase.database.ServerValue.TIMESTAMP
		};

		if (!this.fbQueryList) {
			this.getAttachments(object_id);
		}
		this.fbQueryList.push(c);
		this.fbQueryList.snapshotChanges();
	}

	deleteAttachment(attachmentKey: string) {
		if (attachmentKey === undefined || attachmentKey === '/') {
			console.log('Error... No key passed to deleteAttachment... Not deleting');
			return;
		}

		this.fbQueryList.remove(attachmentKey);
		this.fbQueryList.snapshotChanges();
	}
}
