import { BsAssignmentsService } from '../services/bs-assignments.service';
import { BsClassesUsersService } from '../services/bs-classes-users.service';
import { BsUtilsService } from '@cf-platform/cf-core-cms-ng';
import { Observable } from 'rxjs/Observable';
import { FolderItem } from '@cf-platform/cf-core-cms-ng';
import { AngularFireDatabase } from '@angular/fire/database';

import {IProjectInfo, IAssignment} from "@cf-platform/cf-core-cms";



export interface ICLassSettings {
	canAutoEnroll?: boolean;
	image?: string;
	name?: string;
}
export class BsClass {
	name: string;
	classId: string;
	usersFO: any;
	usersO: Observable<any[]>;
	pendingUsersO: Observable<any[]>;
	rootItem?: Observable<FolderItem>;

	settings?: ICLassSettings;

	async loadSettings() {
		const settingsSnap = await this.db.object('/class/' + this.classId + '/settings').valueChanges().first().toPromise();
		this.settings = settingsSnap ;
		return;
	}

	constructor(private assignmentsService: BsAssignmentsService,
		classId: string,
		name: string,
		private classUsersService: BsClassesUsersService,
		private db: AngularFireDatabase
	) {
		this.name = name;
		this.classId = classId;
		this.usersFO = this.classUsersService.getUsersInClass(classId);
		this.usersO = this.usersFO.snapshotChanges()
			.map(d =>
				d.map(u => {
					console.log('ZZZDEBUGHERE');
					const u2 = { __key: u.key, ...u.payload.val() };
					if (!u2.firstName) {
						u2.firstName = BsUtilsService.firstName(u2.name);
					}
					if (!u2.lastName) {
						u2.lastName = BsUtilsService.lastName(u2.name);
					}
					u2.lfName = u2.lastName + ', ' + u2.firstName;
					return u2;
				}));


		this.pendingUsersO = this.classUsersService.getPendingUsersInClass(classId)
		.snapshotChanges()
		.map(d =>
			d.map(u => {
				console.log('ZZZDEBUGHERE');
				const u2 = { __key: u.key, ...u.payload.val() };
				//if (!u2.firstName) {
					u2.firstName = BsUtilsService.firstName(u2.name);
				//}
				//if (!u2.lastName) {
					u2.lastName = BsUtilsService.lastName(u2.name);
				//}
				u2.lfName = u2.lastName + ', ' + u2.firstName;
				return u2;
			}));;

	}

	_assignments: Array<IAssignment>;
	async getAssignments(): Promise<Array<IAssignment>> {
		if (!this.classId) {
			return [];
		}
		this._assignments = await this.assignmentsService.getAssignments(this.classId);
		return this._assignments;
	}

	saveAssignment(assignmentId: string, assignment: IAssignment) {
		return this.assignmentsService.updateAssignmentAsInstructor(this.classId, assignmentId, assignment);
	}

	async debugAssignments() {
		await this.getAssignments();
		for (const c of this._assignments) {
			console.log(c.name);
		}
	}

	// todo - move this to a class group service...
	async userIsInGroup(user: any, groupName: string): Promise<boolean> {
		console.log("Debug userIsInGroup");
		if (!user) {
			return false;
		}
		try {
			const k = '/class/' + this.classId + '/groups/' + groupName + '/';
			const userDbKey = user.dbKey || user.dbkey; //Fix then when converting to IUser
			if (userDbKey) {
				const t1 = await this.db.object(k + userDbKey).valueChanges().first().toPromise();
				if (t1) { // && t1['$value'])
					return t1 === 1;
				}
			}
			const t2 = await this.db.object(k + user.uid).valueChanges().first().toPromise();
			if (t2) {// && t2['$value'])
				return t2 === 1;
			}
			return false;
		}
		catch (e) {
			console.log('User: ' + user.dbkey + '  Not in class group: ' + groupName);
			console.log(e);
			return false;
		}
	}

	async userIsInstructor(user: any) {
		return this.userIsInGroup(user, 'instructor');
	}
}
