import { Component, Inject } from '@angular/core';

import {MatDialogModule, MatDialogRef, MAT_DIALOG_DATA, MatDialog} from '@angular/material/dialog';
//import { componentHostSyntheticProperty } from '@angular/core/src/render3';

//import { MatDialomat-form-fieldtToolbar, MatToolbarRow, MatOption, MatSelect } from '@angular/material';
/*
import { MAT_DIALOG_DATA } from '@angulamat-form-fieldonent({
	*/
@Component({
	selector: 'app-bs-project-dialog',
	templateUrl: './bs-project-dialog.component.html',
	styleUrls: ['./bs-project-dialog.component.css']
})

export class BsProjectDialogComponent {
	// todo.  move this somewhere else...
	projTypes = [
		{ name: 'C++', value: 'c++' },
		{ name: 'Python', value: 'python' },
		{ name: 'HTML', value: 'html' }
	];
	project = { name: 'Name TBD', type: 'c++' };
	projectNum: number;

	constructor(
		@Inject(MAT_DIALOG_DATA) public data: any,
		public dialogRef: MatDialogRef<BsProjectDialogComponent>,
		public dialog: MatDialog
	) {
		this.projectNum = data['projectListCount'] + 1;
		this.project.name = 'Project ' + this.projectNum;
	}

	doPost() {
		this.dialogRef.close(
			{
				cmd: 'Add',
				project: this.project
			});
	}
}
