import { Component, Inject, ViewChild, Input, ChangeDetectorRef } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireDatabase, AngularFireList } from '@angular/fire/database';
import { Pipe, PipeTransform } from '@angular/core';
import * as firebase from 'firebase/app';
import { ActivatedRoute, Router } from '@angular/router';
import { BsFolderItemComponent } from '../bs-folder-item/bs-folder-item.component';
import { BsAttachmentService } from '../../../../services/bs-attachment.service';
import { IFolderItem } from '../../../../interfaces/IFolderItem';
import { BsFolderService, BsFolderManager, FolderItem } from '../../../../services/bs-folder.service';
import { MatDialog, MatDialogRef, MatToolbar, MatToolbarRow } from '@angular/material';
import { MAT_DIALOG_DATA } from '@angular/material';
import { BsLinkDialogComponent } from '../../bs-attachment/bs-link-dialog/bs-link-dialog.component';
import { BsFolderItemListComponent } from '../bs-folder-item-list/bs-folder-item-list.component';
import { BsMoveDialogComponent } from '../bs-move-dialog/bs-move-dialog.component';
import { NewableItem } from '../newable-item';
//zzz import { BsAssignmentsService } from '../../../services/bs-assignments.service';
//zzz import { BsCurrentClassInfoService } from '../../../services/bs-current-class-info.service';
//zzz import { IAssignment } from '@cf-platform/cf-class-stuff';

@Component({
	selector: 'app-bs-folder-view',
	templateUrl: './bs-folder-view.component.html',
	styleUrls: ['./bs-folder-view.component.css']
})
export class BsFolderViewComponent {
	@Input() set item(item: FolderItem) {
		this._item = item;
		if (item != null) {
			const feedDbPath = item['__feedPath'];
			this.bsFolderManager = this.bsFolders.getFolderManagerFor(feedDbPath);
			const queryList = this.bsFolderManager.getFolders(item.rel_path);
		}
	}
	@Input()
	set folderData(data: any) {
		console.log('IGNORING FOLDER DATA');
		return;
	}

	@Input()
	set folderPath(path: string) {

		if (!path || path === '') {
			path = '/';
		}
		if (!path.startsWith('/')) {
			path = '/' + path;
		}
	}

	@Input()
	set basePath(path: string) {
		this._basePath = path;
	}

	@Input() newableItems: Array<NewableItem>;


	_basePath: string;
	_item: FolderItem;
	userInfo: firebase.User;
	currentUserEmail: string;
	bsFolderManager: BsFolderManager;
	fflag: boolean = false;
	_folderData: any;
	od: any;
	authObserver = this.afAuth.authState.subscribe(u => {
		this.userInfo = u;
		if (u) {
			this.currentUserEmail = u.email;
			if (!u.email) {
				if (u.providerData[0]) {
					this.currentUserEmail = u.providerData[0].email; // HACK HACK


				}
			}
		}
		this.setupMenus();
	});

	constructor(public afAuth: AngularFireAuth,
		private db: AngularFireDatabase,
		private activatedRoute: ActivatedRoute,
		private router: Router,
		private bsAttachments: BsAttachmentService,
		private bsFolders: BsFolderService,
		public dialog: MatDialog,
		private cdRef: ChangeDetectorRef
	) {
		const me2 = this;
		console.log('Debug here');
	}

	get item(): FolderItem {
		return this._item;
	}

	scrollToBottom(): void {
		setTimeout(() => {
			document.scrollingElement.scrollTop = document.scrollingElement.scrollHeight;
		}, 600);
	}

	showNewItemDialog(itemType: string) {

	}
	async showNewableItemDialog(newableItem: NewableItem) {
		const itemType = newableItem.type;

		this.scrollToBottom();

		console.log('debug showNewableItemDialog');
		let linkToList = [];
		if (newableItem.linkFunctP) {
			linkToList = await newableItem.linkFunctP();
		}

		const dialogRef = this.dialog.open(BsFolderItemDialogComponent, {
			data: {
				bsFolderManager: this.bsFolderManager,
				cmd: 'new',
				type: itemType,
				linkToList: linkToList,
				parentPath: this._folderPath,
				userEmail: this.userInfo.email
			}
		});

		dialogRef.afterClosed().subscribe(result => {
			if (!result || !result.cmd) {
				console.log('Invalid dialog result');
			}
			else if (result.cmd.toLowerCase() === 'cancel') {
				console.log('Dialog canceled');
			}
			else {
				console.log('you said: ' + result.cmd);
				console.log('Item Name: ' + result.item.name);
				console.log('Item desc: ' + result.item.description);

				const t_path = 'item' + new Date().getTime();

				if (result.item.rel_path === '') {
					result.item.rel_path = t_path;
				}

				// zzz ?? zssignmentId
				this.bsFolderManager.addFolderItem(result.item.type, result.item.rel_path,
						result.item.name, result.item.description, this.userInfo.email, result.item.assignmentId);

				this.scrollToBottom();

			}
		});
	}

	canEdit = () => {
		// hook this into a group system later
		return this.currentUserEmail === 'zbobbradley@gmail.com';
	}
	canNotEdit = () => {
		return ! this.canEdit();
	}
	canNotMakeVisible = (item) => {
		//console.log('debug canNotMakeVisible');
		return !item || !(this.canEdit() && item.hidden);
	}
	canNotMakeHidden = (item) => {
		//console.log('debug canNotMakeHidden');
		return !item || !(this.canEdit() && !item.hidden);
	}

	allMenuCommands = [
		{title: 'Open', iconName: 'details', click: (item) => this.goItem(item), disabled: (item) => false},
		{title: 'Edit...', iconName: 'edit', click: (item) => this.showEditFolderItemDialog(item), disabled: this.canNotEdit},
		{title: 'Move...', iconName: 'folder_open', click: (item) => this.moveItem(item), disabled: this.canNotEdit},
		{title: 'Delete...', iconName: 'delete', click: (item) => this.deleteItem(item), disabled: this.canNotEdit},

		{title: 'Hide', iconName: 'grid_off', click: (item) => this.hideItem(item), disabled: this.canNotMakeHidden},
		{title: 'Show', iconName: 'grid_on', click: (item) => this.showItem(item), disabled: this.canNotMakeVisible}

	];

	menuCommands = [];

	setupMenus() {
		if (this.userInfo && this.canEdit()) {
			this.menuCommands = this.allMenuCommands;
		}
		else {
			this.menuCommands = [this.allMenuCommands[0]];
		}
		//this.cdRef.detectChanges();
		console.log('debug setupMenu here');
	}

	showItem(item: FolderItem) {
		this.setVisible(item, true);
	}

	hideItem(item: FolderItem) {
		this.setVisible(item, false);
	}

	setVisible(item: FolderItem, visible: boolean) {
		item.hidden = !visible;
		this.bsFolderManager.saveFolderItem(item);
	}

	showEditFolderItemDialog(item: FolderItem) {
		console.log('showEditFolderItemDialog here');
		const dialogRef = this.dialog.open(BsFolderItemDialogComponent, {
			data: {
				bsFolderManager: this.bsFolderManager,
				cmd: 'edit',
				type: item.type,
				item: item,
				parent_path: this._folderPath
			}
		});

		dialogRef.afterClosed().subscribe(result => {
			if (!result || !result.cmd) {
				console.log('Invalid dialog result');
			}
			else if ((result.cmd as string).toLowerCase() === 'cancel') {
				console.log('Dialog canceled');
			}
			else {
				console.log('you said: ' + result.cmd);
				console.log('Item Name: ' + result.item.name);
				console.log('Item desc: ' + result.item.description);

				this.bsFolderManager.saveFolderItem(result.item);

				const attachsToDel = [];
				for (const ak in result.attachesToDel) {
					console.log('Delete attachment: ' + ak);
					this.bsFolderManager.bsAttachmentsManger.deleteAttachment(ak);
				}
			}
		});
	}

	moveItem(item: FolderItem) {
		this.showMoveItemDialog(item);
	}

	showMoveItemDialog(item: FolderItem) {
		console.log('showMoveItemDialog here');
		const ri = this.bsFolderManager.getItem('/');

		const dialogRef = this.dialog.open(BsMoveDialogComponent,
			{
				data: {
					rootItem: ri,
					item: this.item
				}
			});

		dialogRef.afterClosed().subscribe(result => {

			if (!result || !result.cmd) {
				console.log('Invalid dialog result');
			}
			else if ((result.cmd as string).toLowerCase() === 'cancel') {
				console.log('Dialog canceled');
			}
			else {
				console.log('you said: ' + result.cmd);
				console.log('New Path: ' + result.newPath);

				this.bsFolderManager.moveItem(item, result.newPath);

				console.log('Go Path: ' + this._basePath + result.newPath);
				this.goPath(this._basePath + result.newPath);
			}
		});
	}

	_folderPath: string = '';
	pathSearch = new Subject<string>(); // import {Subject} from 'rxjs/Subject';
	attachmentsTest: Observable<any[]>;

	deleteItem(item: FolderItem) {
		if (confirm('Are you sure you want to delete this?')) {
			console.log('You said delete item: ' + item._key);
			this.bsFolderManager.deleteFolderItem(item);
		}
		else {
			console.log('You canceled the delete');
		}
	}

	goParent() {
		console.log('debug goParent');
		let p = this.item.parent_path;
		if (p === '' || p === '_/') {
			this.goPath('/');
		}
		else {
			this.goPath(this._basePath + p);
			/*
			const pos = p.lastIndexOf('/');
			if (pos >= 0) {
				p = p.substring(0, pos);
			}
			this.goPath(this._basePath + p);
			*/
		}
	}

	goItem(item: FolderItem) {
		if (!this._basePath) {
			this._basePath = '/folder';
		}
		let p = item['my_path'];
		if (!p.startsWith('/')) {
			p = '/' + p;
		}
		this.goPath(this._basePath + p);
	}

	goPath(path: string) {
		console.log('Navigating to path: ' + path);
		this.router.navigateByUrl(path);
	}
}

// --- END OF MAIN COMPONENT --


interface ILinkable {  // used by assignments and maybe soon to be used by events, quizzes and docs
	name: string;
	assignmentId: string; // change to link ID later maybe or store a link key
}



// The following dialog is used for both new and editing folder items...
// public dialogRef: MdDialogRef<BsFolderItemDialogComponent>
@Component({
	selector: 'app-bs-folder-item-dialog',
	templateUrl: './bs-folder-item-dialog.html'
})
export class BsFolderItemDialogComponent {
	bsFolderManager: BsFolderManager;
	selectedAssignmentId: string; // zzz
	assignments: Array<ILinkable>; // zzz

	linkToList: any[];

	constructor(
		@Inject(MAT_DIALOG_DATA) public data: any,
		public dialogRef: MatDialogRef<BsFolderItemDialogComponent>,
		public dialog: MatDialog,
		//zzz private assignmentsService: BsAssignmentsService,
		//zzz private currentClassInfo: BsCurrentClassInfoService
	) {
		this.bsFolderManager = data['bsFolderManager'];
		if (data['cmd'] === 'new') {
			this.cmdName = 'New';
			this.origItem = this.bsFolderManager.newFolderItem(data['type'], data['rel_path'], data['parentPath'], data['userEmail']);
			this.item = this.origItem;
			this.linkToList = data['linkToList'];
			this.assignments = this.linkToList; // zzz
		}
		else if (data['cmd'] === 'edit') {
			this.origItem = data['item'];
			this.item = this.bsFolderManager.newFolderItem(this.origItem.type, this.origItem['rel_path'], this.origItem['parentPath'], this.origItem['userEmail']);
			this.cmdName = 'Edit';
			this.item.name = data['item'].name;
			this.item.description = data['item'].description;
		}

		if (data['type'] === 'note') {
			this.typeName = 'Note';
		}
		else if (data['type'] === 'folder') {
			this.typeName = 'Folder';
		}
		else if (data['type'] === 'assignment') {
			this.typeName = 'Assignment';

			/* zzz
			this.assignmentsService.getAssignments(this.currentClassInfo.theClass.classId)
				.then(a => {
					this.assignments = a;
				});
			*/
		}
	}
	cmdName: string = '...';
	typeName: string = '...';
	item: IFolderItem;
	origItem: IFolderItem;
	attachesToDel = {};

	onNameChange() {
		let p = this.item.name;
		p = p.toLowerCase();
		p = p.replace(/ /g, '_');
		// todo add others
		this.item.rel_path = p;
	}

	// zzz
	findAssignmentById(id: string):ILinkable {
		for (const a of this.assignments) {
			if (a.assignmentId === id) {
				return a;
			}
		}
		return null;
	}

	// zzz
	onAssignmentIdChange() {
		console.log('Assignment Id Change: ' + this.selectedAssignmentId);
		const assignment = this.findAssignmentById(this.selectedAssignmentId);
		if (assignment) {
			this.item.name = assignment.name;
			this.item.rel_path = assignment.assignmentId;
			this.selectedAssignmentId = this.item.assignmentId; // zzz
		}
		else {
			this.item.name = '';
			this.item.rel_path = '';
		}
	}


	doPost() {
		this.origItem.name = this.item.name;
		this.origItem.description = this.item.description;
		this.origItem.rel_path = this.item.rel_path;
		if (this.typeName === 'Assignment') {
			if (this.selectedAssignmentId) {
				this.origItem.assignmentId = this.selectedAssignmentId; // zzz ??
			}
		}
		this.dialogRef.close(
			{
				cmd: 'Post',
				item: this.origItem,
				attachesToDel: this.attachesToDel
			});
	}

	removeAttachment(attachment) {
		console.log('Remove Attachment');
		attachment.deletedFlag = !attachment.deletedFlag;
		this.attachesToDel[attachment.__key] = attachment.deletedFlag;
	}

	showNewLinkDialog() {

		const dialogRef = this.dialog.open(BsLinkDialogComponent, {
			data: {
				cmd: 'new',
				type: 'link',
				objectId: ''
			}
		});

		dialogRef.afterClosed().subscribe(result => {
			if (!result || !result.cmd) {
				console.log('Invalid dialog result');
			}
			else if (result.cmd === 'cancel') {
				console.log('Dialog canceled');
			}
			else {
				console.log('you said: ' + result.cmd);
				console.log('Link: ' + result.url);
				const url: string = result.url;
				const name: string = result.name;
				this.bsFolderManager.bsAttachmentsManger.addAttachment(this.origItem['_data']['__key'], 'note', url, name);

				// TODO: FIX:  New items will not have keys, so I don't know what to do yet...
			}
		});
	}
}
