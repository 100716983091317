import {Component, ChangeDetectorRef, NgZone} from '@angular/core';
import {BreadcrumbService} from '../breadcrumb.service';

import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import { IUser } from '@cf-platform/cf-core-cms';
import { Observable } from 'rxjs';

// TODONE: move the main services into an nx library
import { BsClassesUsersService, BsClass } from '@cf-platform/cf-class-stuff-ng';
//import { BsClass } from '../../../../../apps/web-client/src/app//bs-class/bs-class';
import { Router } from '@angular/router';
import { UserRepository } from '@cf-platform/cf-core-cms-ng';


import { MessageService } from 'primeng/api';

//import {EventService} from '../demo/service/eventservice';



@Component({
	templateUrl: './cfhome.component.html',
	providers: [MessageService], // now sure how this works, but it is in the demo code,
	styles: [`
        :host ::ng-deep button {
            margin-right: .25em;
            min-width: 8em;
        }

        :host ::ng-deep .ui-message {
            margin-left: .25em;
        }
    `]
})
export class CFHomeComponent {

	private classesList: Observable<BsClass[]>;
	public _classesList: BsClass[] = [];
	classCount = 0;
	firstClassLoad = true;

    fullcalendarOptions: any;
	isReady: boolean;
    //events: any[];

    constructor(
        private breadcrumbService: BreadcrumbService,
		//private eventService: EventService
		private classesUsers: BsClassesUsersService,
		private cd: ChangeDetectorRef,
		private router: Router,
		private ngZone: NgZone,
		private userRepository: UserRepository,
		private msgService: MessageService
        ) {
        this.breadcrumbService.setItems([
            {label: ''},
		]);
		this.breadcrumbService.setPageInfo({title:'Home', icon: 'home'});

		/*
        this.eventService.getEvents().then(events => {this.events = events; });

        this.fullcalendarOptions = {
            plugins: [ dayGridPlugin, timeGridPlugin, interactionPlugin ],
            defaultDate: '2016-01-12',
            header: {
                left: 'prev,next, today',
                center: 'title',
                right: 'dayGridMonth,timeGridWeek,timeGridDay'
            }
		};
		*/

		userRepository.getCurrentUser((user) => {
			this.getUsersClasses(user);
		})
		// todo unsub


	}

	showToast(title: string, message: string, mType='success') {
		console.log('Toast: ', mType, ' Msg: ', title, ' - ', message);
        this.msgService.add({ key: 'tst', severity: mType, summary: title, detail: message });
    }

	// code below duped from original my-classes-component...

	getUsersClasses(u: IUser) {

			if (u) {
				this.classesList = this.classesUsers.getClassesForUserId(u.uid);
				this.classesList.subscribe(d => {
					console.log('Debug user classes here');

					this._classesList = d.sort((a,b) => (a['id']>b['id']) ? -1 : 1 );

					console.log('New class count: ', this._classesList.length);
					console.log('Last class count: ', this.classCount);
					console.log('firstClassLoad: ', this.firstClassLoad);

					//this.classesTitle = '(' + d.length + ')';
					try {
						this.cd.detectChanges()
					} catch (error) {

					}
					;
					setTimeout(() => {
						this.getClassSettings(); // why not async?
					}, 50);

					const c = this._classesList.length;
					if (!this.firstClassLoad) {
						if (c > this.classCount  ) {
							this.ngZone.run( () => {
								this.showToast('Class Added', 'Looks like you have a new class.');
							});
						}
						else if (c < this.classCount) {
							this.ngZone.run( () => {
								this.showToast('Class Removed', 'Looks like you had a class removed.', 'warn');
							});
						}
					}
					this.classCount = c;
					this.firstClassLoad = false;
				});
			}
			else {
				this.classesList = null;
				//this.classesTitle = '(No User)';
			}
			try {
				this.cd.detectChanges();
			} catch (error) {

			}

		}

		async getClassSettings() {

			// this._classesList.forEach(async c => {
			for (const k in this._classesList) {
				const c = this._classesList[k];
				console.log('debug me');
				let id = c.classId || c['id'];
				c.settings = await this.classesUsers.loadClassSettings(id);
				this._classesList[k].settings = c.settings; // ? do we have to
				let s: any = {width: '100%', height: '120px'};
				if (c.settings && c.settings.image) {
					console.log('found a settings');
					s =	{...s, 'background-position' : 'center',
								'background-image': 'url("' + c.settings.image + '"'};
				}
				else {
					s = {...s, 'background-color' : 'gray'};
				}
				c['style'] = s;
				this._classesList[k]['style'] = c['style'];
				console.log(this._classesList[k]['style']);
				try {
					this.cd.detectChanges();
				} catch (error) {

				}


				console.log('and here');
			}
			setTimeout(() => {
				this.isReady = true;
				try {
					this.cd.detectChanges();
				} catch (error) {

				}

			}, 500);


		}

		joinClassId: string;

		joinClass(id) {
			if (id !== '') {
				//setTimeout(() => {
					this.ngZone.run(() => this.router.navigateByUrl('/class/utm/' + id));
				//}, 50);

			}
		}

		goMyProjects() {
			this.router.navigateByUrl('/my/projects');
		}

		goClass(c: BsClass) {
			this.router.navigateByUrl('/class/utm/' + c['id']);
		}



}
