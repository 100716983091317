import {Component} from '@angular/core';
import {AppMainComponent} from './app.main.component';
import { UserRepository } from '@cf-platform/cf-core-cms-ng';
import { IUser } from '@cf-platform/cf-core-cms';
import { BreadcrumbService } from './breadcrumb.service';



@Component({
    selector: 'app-topbar',
    templateUrl: './app.topbar.component.html'
})
export class AppTopBarComponent {
	user: IUser;
	subscription: any;
	title: string;
	pageIcon: string;

	constructor(public app: AppMainComponent,
		private userRepository: UserRepository,
		public breadcrumbService: BreadcrumbService) {
			userRepository.getCurrentUser((u) => {
				this.user = u;
			})

			this.subscription = breadcrumbService.pageInfoHandler.subscribe(response => {
				this.pageIcon = response.icon;
				this.title = response.title || '...';
			});
		}

		logout() {
			this.userRepository.logout(true);
		}




		ngOnDestroy() {
			if (this.subscription) {
				this.subscription.unsubscribe();
			}
		}

}
