import { Component, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { FolderItem } from '../../../../services/bs-folder.service';
import { BsAttachmentService } from '../../../../services/bs-attachment.service';



import { ActivatedRoute, Router } from '@angular/router';
import { AngularFireDatabase } from '@angular/fire/database';


@Component({
	selector: 'app-bs-folder-item',
	templateUrl: './bs-folder-item.component.html',
	styleUrls: ['./bs-folder-item.component.css']
})
export class BsFolderItemComponent {
	@Input() set item(item: FolderItem) {
		this._item = item;
		console.log('bs-folder-item: item changed');


	}
	@Input() showNewProjectButton: boolean;
	@Input() folderPath: string = '/';
	@Input() basePath: string = '';
	@Input() currentUserEmail: string;
	@Input() viewAs: string; // full, summary  // -- prob new to change to an enum
	@Output() goItemClicked = new EventEmitter();
	@Output() editItemClicked = new EventEmitter();
	@Output() deleteItemClicked = new EventEmitter();
	@Output() newItemClicked = new EventEmitter();
	@Output() goParentClicked = new EventEmitter();
	@Output() moveItemClicked = new EventEmitter();

	_foo: string;
	_item: FolderItem;
	classId: string;

	gradeInfo: any;

	constructor(
		private bsAttachments: BsAttachmentService,
		private route: ActivatedRoute,
		private afDb: AngularFireDatabase,
		private cdRef: ChangeDetectorRef
	) {

		const params = this.route.snapshot.params;
		this.classId = params['classid'] || '';


	}



	/* zzz

	*/

	get item(): FolderItem {
		return this._item;
	}

	goItem(item) {
		console.log('item: goItem');
		this.goItemClicked.emit(item);
	}

	editItem(item) {
		this.editItemClicked.emit(item);
	}

	deleteItem(item) {
		this.deleteItemClicked.emit(item);
	}

	showNewItemDialog(type) {
		this.newItemClicked.emit(type);
	}

	goParent() {
		this.goParentClicked.emit();
	}

	moveItem(item) {
		this.moveItemClicked.emit(item);
	}
}
