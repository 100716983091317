import { Injectable } from '@angular/core';
import { Subject ,  Observable } from 'rxjs';
import { MenuItem } from 'primeng/primeng';

// bob added page title and icon

interface PageInfo{
	title: string,
	icon: string;
}

@Injectable()
export class BreadcrumbService {

	private itemsSource = new Subject<MenuItem[]>();
	private pageInfoSource = new Subject<PageInfo>();

	itemsHandler = this.itemsSource.asObservable();
	pageInfoHandler = this.pageInfoSource.asObservable();

    setItems(items: MenuItem[]) {
        this.itemsSource.next(items);
	}

	setPageInfo(pageInfo: PageInfo) {				// bb added this
		this.pageInfoSource.next(pageInfo);
	}
}
